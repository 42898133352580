import butterfly from '~/assets/images/content/introCharter/butterflyFill.png';

const styles = (theme) => ({
  mainGrid: {
    flexDirection: 'row',
    fontSize: '1.4rem',
    lineHeight: '2rem',
    marginBottom: '1rem',
    [theme.breakpoints.only('md')]: {
      fontSize: '1.2rem',
      lineHeight: '1.8rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.4rem',
      flexDirection: 'column'
    }
  },

  title: {
    backgroundColor: 'rgb(0,0,0,0.43)',
    border: '1px solid #FFFFFF',
    padding: '1.5rem',
    color: '#FFFFFF',
    textAlign: 'left',
    width: '400px',
    height: '400px',
    [theme.breakpoints.only('md')]: {
      width: '50vw',
      height: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80vw',
      height: '100%'
    }
  },

  number: {
    textAlign: 'center'
  },

  butterfly: {
    width: '100%',
    backgroundImage: 'url(' + butterfly + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'bottom center',
    marginTop: '1rem'
  },

  textGrid: {
    backgroundColor: 'rgb(255,255,255,0.89)',
    borderWidth: '1px 1px 1px 0px',
    borderStyle: 'solid',
    borderColor: '#FFFFFF',
    color: '#818181',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    visibility: 'hidden',
    overflow: 'hidden',
    transition: 'all 0.5s ease-in-out',

    width: '0px',
    height: '400px',

    [theme.breakpoints.only('md')]: {
      width: '50vw',
      height: '0px',
      borderWidth: '0px 1px 1px 1px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80vw',
      height: '0px',
      borderWidth: '0px 1px 1px 1px'
    }
  },

  showTextGrid: {
    visibility: 'visible',
    width: '784px',

    [theme.breakpoints.only('md')]: {
      width: '50vw',
      height: '400px'
    },

    [theme.breakpoints.only('sm')]: {
      width: '80vw',
      height: '320px'
    },

    [theme.breakpoints.only('xs')]: {
      width: '80vw',
      height: '450px'
    }
  },

  textBox: {
    opacity: 0,
    visibility: 'hidden',
    margin: '2rem',
    textAlign: 'left',
    transition: 'all 0.5s ease-in-out'
  },

  showText: {
    opacity: 1,
    visibility: 'visible'
  }
});

export default styles;
