import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import VideosMain from './Sections/VideosMain';
import VideoDetail from './VideoDetailPage/VideoDetail';
import styles from './VideoLandingMainPageStyles';
import { useQuery } from 'react-query';
import { fetchHCNArticleCategoriesWarper } from 'shared/react-query/strapiAPI';

const VideoLandingMain = ({
  classes,
  videos,
  history,
  location,
  pagination,
  handleGetCategories,
  handleGetInitialVideos,
  handleGetMoreVideosByPage,
  handleGetPagination
}) => {
  const [goDetail, setGoDetail] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [videoId, setVideoId] = useState(null);
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState(null);
  const [categoryIndex, setCategoryIndex] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [noMoreVideos, setNoMoreVideos] = useState(false);

  const allCategories = useQuery("HCNArticleCategories", () =>
    fetchHCNArticleCategoriesWarper(),
    { staleTime: Infinity }
  );

  const goDetailHandler = (id, categoryId) => {
    setGoDetail(true);
    setVideoId(id);
    setCategoryId(categoryId);
  };

  const onCategoriesChange = (selectedCategories) => {
    setSelectedCategories(selectedCategories);
  };

  const handleDataFetch = (dataFetched) => {
    setFilteredData(dataFetched);
  };

  const handleCategoryChange = (categoryId) => {
    setGoDetail(false);
  };

  const loadMoreVideos = () => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.scrollingElement.scrollHeight) {
      if (page < pagination.lastPage) {
        let nextPage = page + 1;
        setPage(nextPage);
        handleGetMoreVideosByPage(nextPage);
      } else {
        setNoMoreVideos(true);
      }
    }
  };

  useEffect(() => {
    // handleGetPagination();
    // window.addEventListener('scroll', loadMoreVideos);
    // return () => window.removeEventListener('scroll', loadMoreVideos);
  }, []);

  return (
    <div className={classes.backgroundWrapper}>
      <div className={classes.content}>
        {goDetail ? (
          <VideoDetail
            allCategories={allCategories.data ?? []}
            filteredCategories={selectedCategories} videos={filteredData}
            categoryId={categoryId} videoId={videoId}
            onCategoryChange={handleCategoryChange}
          />
        ) : (
          <VideosMain
            goDetail={goDetailHandler}
            // titles={tableTitles}
            onCategoriesChange={onCategoriesChange}
            onDataFetch={handleDataFetch}
            noMoreVideos={noMoreVideos}
          />
        )}
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(VideoLandingMain);
