export const sponsorStyles = (theme) => ({
  contributeContentForm: {
    margin: '1.25rem 5rem 5rem 5rem',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 1rem 1.5rem 1rem'
    },
    overflow: 'auto'
  },
  contentTypeIcon: {
    width: 20,
    height: 20
  },

  contributeContentHeading: {
    display: 'block',
    fontVariant: 'small-caps',
    fontSize: '2.7rem',
    fontWeight: 550,
    letterSpacing: '2px',
    textTransform: 'Uppercase',
    margin: '0'
  },
  contributeHeadingDescription: {
    display: 'block',
    fontSize: '0.8rem',
    fontWeight: 0,
    letterSpacing: '1px',
    textTransform: 'Uppercase',
    margin: '0px 0px 25px 0px'
  },
  contributeContentSubHeading: {
    display: 'block',
    fontSize: '0.8rem',
    fontWeight: 700,
    letterSpacing: '1px',
    textTransform: 'uppercase',
    '& a': {
      color: '#3433f0',
      textDecoration: 'none'
    },
    '& a:hover': {
      textDecoration: 'underline'
    }
  },
  contributeContentIntroText: {
    marginTop: 0,
    fontSize: '0.8rem',
    lineHeight: '1rem',
    letterSpacing: '1px'
  },

  submitButton: {
    color: 'white',
    backgroundColor: 'rgb(210,166,95,1)',
    borderColor: 'rgb(210,166,95,1)',
    borderRadius: '0.25rem',
    padding: '0.75rem 2.5rem',
    fontSize: '0.66rem',
    fontWeight: 600,
    margin: '0.25rem 0.75rem',
    transition: 'background-color 1s linear 0',
    '&:hover': {
      backgroundColor: 'rgb(210,166,95,0.8)'
    }
  },

  cardDivider: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      margin: '0 auto',
      width: '35.5%',
      top: '15%',
      height: '70%',
      borderLeft: '2px solid #ccc'
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto 0',
      width: '70%',
      left: '15%',
      height: '35.5%',
      borderTop: '2px solid #ccc'
    }
  },
  termsModal: {
    overflow: 'auto'
  }
});
