import React, {useState, useEffect} from 'react';
import {withStyles, Typography} from '@material-ui/core';
import {ProfileCard} from '~/components/ui';
import userAvatar from '~/assets/images/icons/userAvatar.png';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },

  title: {
    fontSize: '1rem',
    textAlign: 'center',
    color: '#262626',
    margin: '10px auto 5px'
  },
  infoBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '2px auto'
  },
  info: {marginLeft: '10px'},
  friendName: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    lineHeight: '1.2',
    color: "rgba(0, 0, 0, 0.60)"
  },
  description: {
    fontSize: '0.8rem',
    lineHeight: '1.2',
    color: "rgba(0, 0, 0, 0.60)"
  }
});

function InsightsCard(props) {
  const {classes} = props;
  const [isMobile, setIsMobile] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 850); // Adjust the width threshold as needed
    };
    handleResize(); // Check initial width
    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up
    };
  }, []);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <ProfileCard className={classes.root}>
      <Typography onClick={isMobile ? toggleCollapse : undefined} className={classes.title} component="div" style={{cursor:isMobile?"pointer":"initial", margin: isCollapsed ? '0px auto' : '10px auto 5px'}}>
        LATEST SOLUTIONS
      </Typography>
      {(!isMobile || !isCollapsed) && (
      <div>
      <div className={classes.infoBox}>
        <div>
          <img src={userAvatar} alt="user avatar"></img>
        </div>
        <div className={classes.info}>
          <Typography component="div" className={classes.friendName}>
            Friend 1
          </Typography>
          <Typography component="div" className={classes.description}>
            I help reduce global warming by choosing to eat only 100gms of meat once per week
          </Typography>
        </div>
      </div>
      <div className={classes.infoBox}>
        <div>
          <img src={userAvatar} alt="user avatar"></img>
        </div>
        <div className={classes.info}>
          <Typography component="div" className={classes.friendName}>
            Friend 2
          </Typography>
          <Typography component="div" className={classes.description}>
            I help reduce global warming by choosing to eat only 100gms of meat once per week
          </Typography>
        </div>
      </div>
      </div>
      )}
    </ProfileCard>
  );
}

export default withStyles(styles)(InsightsCard);
