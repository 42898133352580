import React, {useRef, useEffect} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import './html-content.css';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '1rem'
  },
  hidden: {
    visibility: 'hidden'
  },
  entered: {
    height: 'auto',
    overflow: 'visible'
  },
  contentContainer: {
    height: 0,
    overflow: 'hidden',
    transition: `height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`
  },
  contentContainerWrapper: {
    display: 'flex'
  },
  contentContainerWrapperInner: {
    padding: '25px 35px',
    margin: '10px 0',
    backgroundColor: '#f9f9f9',
    width: '100%',
    [`@media (max-width: 660px)`]: {
      padding: '15px 25px'
    }
  }
}));

const HtmlContent = ({className, content, timeout = 300}) => {
  const classes = useStyles({timeout});
  const rootRef = useRef();
  useEffect(() => {
    const handleToggle = function(e) {
      const parentNode = this.parentNode;
      const isOpenClass = 'is-open';

      const isOpen = parentNode.classList.contains('is-open');
      const isClosing = parentNode.dataset.isClosing === 'true' || false;
      const contentControlWrapperInner = parentNode.querySelector('.js-contentitem-infocard-content-wrapper-inner');
      const contentControlWrapper = parentNode.querySelector('.js-contentitem-infocard-content-wrapper');
      const contentControlContainer = parentNode.querySelector('.js-contentitem-infocard-content-container');
      if (isOpen && !isClosing) {

        if (contentControlContainer.dataset.timerId) {
          clearTimeout(contentControlContainer.dataset.timerId);
        }
        contentControlContainer.style.height = `${contentControlWrapper.clientHeight}px`;
        parentNode.dataset.isClosing = true;
        setTimeout(() => {
          contentControlContainer.style.height = `0px`;
          contentControlContainer.classList.remove(classes.entered);

          const timerId = setTimeout(() => {
            contentControlContainer.classList.add(classes.hidden);

            contentControlWrapperInner.innerHTML = '';
            parentNode.classList.remove(isOpenClass);
            parentNode.dataset.isClosing = false;
          }, timeout);
          contentControlContainer.dataset.timerId = timerId;
        }, 0);
      } else {
        if (contentControlContainer.dataset.timerId) {
          clearTimeout(contentControlContainer.dataset.timerId);
        }

        contentControlWrapperInner.innerHTML = parentNode.dataset.content;
        parentNode.classList.add(isOpenClass);
        parentNode.dataset.isClosing = false;

        setTimeout(() => {
          contentControlContainer.style.height = `${contentControlWrapper.clientHeight}px`;
          contentControlContainer.classList.remove(classes.hidden);

          const timerId = setTimeout(() => {
            contentControlContainer.style.height = 'auto';
            contentControlContainer.classList.add(classes.entered);
          }, timeout);
          contentControlContainer.dataset.timerId = timerId;
        }, 0);
      }
    };

    let spanToggles = [];
    if (rootRef.current) {
      spanToggles = rootRef.current.querySelectorAll('.js-contentitem-infocard-toggle');
      spanToggles.forEach((spanToggle) => {
        const parentNode = spanToggle.parentNode;
        const contentControl = parentNode.querySelector('.js-contentitem-infocard-content');
        contentControl.innerHTML = `<div class='${classes.contentContainer} ${classes.hidden} js-contentitem-infocard-content-container' style='min-height: 0px;transition-duration: ${timeout}ms;'>
            <div class='${classes.contentContainerWrapper} js-contentitem-infocard-content-wrapper'>
              <div class='${classes.contentContainerWrapperInner} js-contentitem-infocard-content-wrapper-inner'></div>
            </div>
          </div>`;

        spanToggle.addEventListener('click', handleToggle);
      });
    }
    return () => {
      spanToggles.forEach((spanToggle) => {
        spanToggle.removeEventListener('click', handleToggle);
        const parentNode = spanToggle.parentNode;
        const contentControlContainer = parentNode.querySelector('.js-contentitem-infocard-content-container');
        if (contentControlContainer.dataset.timerId) {
          clearTimeout(contentControlContainer.dataset.timerId);
        }
      });
    };
  }, [content]);
  return (
    <div
      ref={rootRef}
      className={clsx(classes.root, 'html-content', className)}
      dangerouslySetInnerHTML={{__html: content}}
    />
  );
};

export default HtmlContent;
