import React from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';

import IconHeart from '~/assets/images/profile/ic-item-business-fav-fav.png'

const styles = (theme) => ({
  root: {
  },
  icon: {
    height: '20px'
  },
  tag: {
    padding: '3px 20px',
    borderRadius: '5px',
    marginLeft: '10px',
    textAlign: 'center',
    color: 'white',
    fontSize: '13px'
  }
});

function TopRow(props) {
  const { classes, tag, tagColor, tagSideIcon } = props;
  return (
    <Grid container alignItems="center">
      <img src={IconHeart} alt='FavButton' className={classes.icon} />
      <div style={{flex: '1'}}/>
      {
        tagSideIcon ? <img src={tagSideIcon} alt={tag} className={classes.icon} /> : null
      }
      <Typography
        className={classes.tag}
        variant="subtitle2"
        style={{
          background: tagColor
        }}>
        {tag}
      </Typography>
    </Grid>
  )
}

export default withStyles(styles)(TopRow);
