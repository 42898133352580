import React, { Component } from 'react';
import { withStyles, Card, CardMedia, CardContent, Typography, Button } from '@material-ui/core';
import styles from './CardBoxStyles';


const TopButton = withStyles({
  root: {
    position: 'absolute',
    right: 20,
    top: 20,
    color: 'white',
    fontSize: 9,
    fontWeight: 700,
    borderRadius: 3,
    padding: '8px 10px 6px 10px',
    backgroundColor: '#5fd26e',
    '&:hover': {
      backgroundColor: '#5fd26e'
    }
  }
})(Button);

const BottomButton = withStyles({
  root: {
    border: 1,
    borderStyle: 'solid',
    borderColor: '#b2650c',
    borderRadius: 3,
    color: '#b2650c',
    fontSize: 9,
    padding: '4px 8px 2px 8px',
    '&:hover': {
      backgroundColor: 'white'
    }
  }
})(Button);

class CardBox extends Component {
  render() {
    const { id, image, title, content, topbtntext, bottombtntext } = this.props.item;
    const { classes, handleClick } = this.props;
    return (
      <Card elevation={0} square={true}>
        <CardMedia className={classes.media} image={image}>
          <Typography>
            <TopButton onClick={() => handleClick(id)}>{topbtntext}</TopButton>
          </Typography>
          <Typography className={classes.title}>{title}</Typography>
        </CardMedia>
        {content === undefined ? null : (
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p" className={classes.content}>
              {content}
            </Typography>
            {bottombtntext === undefined ? null : (
              <Typography align="center" className={classes.buttonBox}>
                <BottomButton onClick={() => handleClick(id)}>{bottombtntext}</BottomButton>
              </Typography>
            )}
          </CardContent>
        )}
      </Card>
    );
  }
}

export default withStyles(styles)(CardBox);
