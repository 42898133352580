import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';
import SectionTitle from './SectionTitle';
import ReaderComment from './ReaderComment';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
    marginBottom: 48
  },
  commentsContainer: {
    marginTop: 24
  }
}));

const ReaderComments = ({comments}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SectionTitle>{intl.get(messageKeys.ARTICLE_DETAIL_READER_COMMENTS_TITLE)}</SectionTitle>
      <Box className={classes.commentsContainer}>
        {comments.map((comment, idx) => (
          <ReaderComment key={`comment_${idx}`} comment={comment} />
        ))}
      </Box>
    </div>
  );
};

export default ReaderComments;
