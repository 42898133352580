import React from 'react';

import Icon from '~/assets/images/profile/ic-item-business-profile.png';
import IconItem from '~/assets/images/profile/ic-item-business-profile-list-item.png';
import IconMail from '~/assets/images/profile/ic-item-business-profile-mail.png';
import IconChat from '~/assets/images/profile/ic-item-business-profile-chat.png';
import IconContact from '~/assets/images/profile/ic-item-business-profile-contact.png';
import IconGroup from '~/assets/images/profile/ic-item-business-profile-group.png';
import IconLocate from '~/assets/images/profile/ic-item-business-profile-locate.png';
import IconWeb from '~/assets/images/profile/ic-item-business-profile-web.png';

import ProfileTemplate from '../../common/Profile';

const titleList = [
  {
    title: 'About us',
    icon: IconItem,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  },
  {
    title: 'Our Mission',
    icon: IconItem,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  },
  {
    title: 'Our Vission',
    icon: IconItem,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  }
];

const detailList = [
  {
    icon: IconWeb,
    title: 'Website',
    desc: 'https//dummywebsite.com'
  },
  {
    icon: IconContact,
    title: 'Contact',
    desc: '+64000000'
  },
  {
    icon: IconLocate,
    title: 'Adress',
    desc: 'Auckland'
  },
  {
    icon: IconMail,
    title: 'Email',
    desc: 'info@xyz.com'
  },
  {
    icon: IconGroup,
    title: 'Category',
    desc: 'Food & Drink'
  },
  {
    icon: IconChat,
    title: 'Personal Quote ',
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor.'
  }
];

function Profile(props) {
  return (
    <ProfileTemplate
      titleIcon={Icon}
      title="PROFILE"
      titleList={titleList}
      detailTitle="OUR DETAILS"
      detailList={detailList}
    />
  );
}

export default Profile;
