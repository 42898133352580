const styles = (theme) => ({
  root: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    backgroundColor: '#ffffff',
    paddingTop: 40,
    paddingBottom: 20,
    flex: '1 0 auto'
  },
  container: {
    display: 'flex',
    marginLeft: '5%',
    marginRight: '5%',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginLeft: 16,
      marginRight: 16
    }
  },
  article: {
    marginRight: 36,
    [theme.breakpoints.down('md')]: {
      marginRight: 0
    }
  },
  sideContainer: {
    width: 450,
    marginTop: 135,
    [theme.breakpoints.down('md')]: {
      marginTop: 50,
      width: '100%'
    }
  }
});

export default styles;
