import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Card, Typography, Link, CardContent} from '@material-ui/core';
import {useInView} from 'react-hook-inview';
import {styles} from './ShadableCardStyles';

const useStyles = makeStyles(styles);

function ShadableCard(props) {
  const classes = useStyles(props);
  const {item, handleClick, completed} = props;
  const [ref, inView] = useInView({unobserveOnEnter: true});

  return (
    <Link ref={ref} className={classes.root} href="#" onClick={() => handleClick(item.id, item.category)}>
      <Card className={clsx(classes.container, {[classes.hide]: completed && !inView})} square={true}>
        <Box className={classes.media}>
          <img src={item.cover_image} className={classes.image} alt={item.title} />
        </Box>
        <CardContent className={classes.meta}>
          <Typography className={classes.title} variant="subtitle1" align="center">
            {item.title}
          </Typography>
          <Typography className={classes.content} variant="body2" gutterBottom align="center" component="div">
            {item.content}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}

export default ShadableCard;
