import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Box, Typography} from '@material-ui/core';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';
import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';
import shareBtn from '~/assets/images/icons/butterflyAppBar.png';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 12,
    marginBottom: 36,
    [theme.breakpoints.only('xs')]: {
      marginBottom: 24
    }
  },
  tagsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'flex-start'
    }
  },
  tagIcon: {
    color: '#b1b1b1',
    marginRight: 8,
    width: 28,
    height: 28
  },
  tag: {
    borderRadius: 6,
    paddingTop: 2,
    paddingBottom: 4,
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 8,
    border: 'solid 2px #c5c5c5',
    fontSize: '0.875rem',
    fontWeight: 700,
    textAlign: 'center',
    color: '#b1b1b1'
  },
  actionContainer: {
    display: 'flex',
    marginRight: 26,
    alignItems: 'flex-end',
    cursor: 'pointer',
    [theme.breakpoints.only('xs')]: {
      marginRight: 16,
      marginBottom: 16
    }
  },
  actionIcon: {
    width: '1.5rem',
    height: '1.5rem',
    color: '#b1b1b1'
  },
  actionText: {
    marginLeft: 8,
    color: '#b1b1b1',
    fontSize: '1rem',
    fontWeight: 700
  },
  shareIcon: {
    width: '1.875rem',
    height: '1.325rem',
    marginBottom: 2
  },
  shareText: {
    letterSpacing: 1.9
  }
}));

const ArticleToolbar = ({tags, likesCount, favoritesCount, commentsCount}) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="space-between" className={classes.root}>
      <Grid item xs={12} sm={6} container justifyContent="flex-start">
        <Box className={classes.actionContainer}>
          <ThumbUpAltOutlinedIcon className={classes.actionIcon} />
          <Typography className={classes.actionText}>{likesCount}</Typography>
        </Box>
        <Box className={classes.actionContainer}>
          <FavoriteBorderOutlinedIcon className={classes.actionIcon} />
          <Typography className={classes.actionText}>{favoritesCount}</Typography>
        </Box>
        <Box className={classes.actionContainer}>
          <ModeCommentOutlinedIcon className={classes.actionIcon} />
          <Typography className={classes.actionText}>{commentsCount}</Typography>
        </Box>
        <Box className={classes.actionContainer}>
          <img
            src={shareBtn}
            alt={intl.get(messageKeys.ARTICLE_DETAIL_TOOLBAR_ACTION_SHARE)}
            className={clsx(classes.actionIcon, classes.shareIcon)}
          />
          <Typography className={clsx(classes.actionText, classes.shareText)}>
            {intl.get(messageKeys.ARTICLE_DETAIL_TOOLBAR_ACTION_SHARE)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className={classes.tagsContainer}>
          <LocalOfferOutlinedIcon className={classes.tagIcon} />
          {tags.map((tag, idx) => (
            <Box component="span" className={classes.tag} key={`tag_${idx}`}>
              {tag}
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ArticleToolbar;
