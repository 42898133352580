import React, {useState} from 'react';
import {withStyles, Typography, IconButton, Popover, Button, Switch, Badge} from '@material-ui/core';

import networkIcon from '~/assets/images/icons/network.png';
import notificationIcon from '~/assets/images/icons/notifications.png';
import settingIcon from '~/assets/images/icons/setting.png';
import Butterfly from '~/assets/images/icons/butterflyAppBar3.png';

const styles = (theme) => ({
  header: {
    backgroundColor: '#C2C2C2',
    height: '50px',
    color: '#878787',
    padding: '0px 32px',
    textShadow: ' 0 3px 6px rgba(0, 0, 0, 0.16)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      padding: '0px 10px'
    }
  },
  space: {
    margin: 'auto 10px'
    // [theme.breakpoints.only('xs')]: {
    //   margin: 'auto',
    //   display: 'block'
    // }
  },
  iconSpace: {
    margin: 'auto 4px'
  },

  icons: {
    width: '20px'
  },
  placeHolder: {
    width: '13vw',
    [theme.breakpoints.only('sm')]: {
      width: '0'
    },
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  title: {
    fontSize: '1.5rem',
    [theme.breakpoints.only('sm')]: {
      fontSize: '1.2rem'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.8rem',
      lineHeight: '1.2'
    }
  },
  networkPopover: {
    padding: '10px'
  },
  settingPopover: {
    padding: '10px',
    maxWidth: '240px'
  },
  networkTitle: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    margin: '5px auto'
  },

  friendName: {
    fontSize: '0.8rem',
    marginRight: '15px'
  },
  friendRequest: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '4px auto'
  },

  networkActionBtn: {
    fontSize: '0.625rem',
    color: 'black',
    backgroundColor: '#C4C5C5',
    height: '24px',
    lineHeight: '1.4',
    borderRadius: '12px'
  },
  settingItem: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    margin: '2px auto'
  },

  appPopover: {
    padding: '10px',
    maxWidth: '275px'
  },
  collection: {textAlign: 'center', marginTop: '10px'},
  appTitle: {
    fontWeight: 'bold'
  },
  groupSet: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  },
  iconGroup: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: '85px'
  },
  largeIcons: {
    width: '56px',
    height: '56px'
  },
  iconBtn: {
    margin: 'auto',
    padding: '10px'
  },
  appName: {
    fontSize: '0.8rem'
  },
  integrateBtn: {
    marginTop: '5px',
    width: '240px',
    height: '24px',
    fontSize: '12px',
    color: '#A07936',
    letterSpacing: '0.1px',
    border: 'solid 1px #A07936',
    borderRadius: '13px',
    lineHeight: '0.8',
    fontWeight: '100'
  }
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#0099ff',
    color: 'white',
    padding: '0',
    margin: 'auto 4px'
  }
}))(Badge);

function SecondHeader({classes, switchData}) {
  const [settinganchorEl, setSettingAnchorEl] = useState(null);
  const [networkanchorEl, setNetworkAnchorEl] = useState(null);

  const handelPopoverOpen = (e, setAnchorEI) => {
    setAnchorEI(e.currentTarget);
  };
  const handelPopoverClose = (setAnchorEI) => {
    setAnchorEI(null);
  };

  const open = (anchorEI) => {
    return Boolean(anchorEI);
  };

  return (
    <div className={classes.header}>
      <div className={classes.placeHolder}>
        <Switch
          onChange={switchData}
          color="primary"
          name="checkedB"
          inputProps={{'aria-label': 'primary checkbox'}}
          component="span"
        />
      </div>
      <div style={{display:"flex",flexDirection:"row", alignItems:'center'}}>
        <img src={Butterfly} />
        <Typography component="div" className={classes.title}>
          <span className={classes.space}>ENRICH</span> <span className={classes.space}>EMPOWER</span>{' '}
          <span className={classes.space}>INSPIRE</span>
        </Typography>
      </div>
      <div className={classes.iconSet}>
        <IconButton onClick={(e) => handelPopoverOpen(e, setNetworkAnchorEl)}>
          {' '}
          <StyledBadge badgeContent={2} className={classes.badge} overlap="rectangular">
            <img src={networkIcon} alt="network icon" className={classes.icons} />
          </StyledBadge>
        </IconButton>
        <Popover
          open={open(networkanchorEl)}
          anchorEl={networkanchorEl}
          onClose={() => handelPopoverClose(setNetworkAnchorEl)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <div className={classes.networkPopover}>
            <div>
              <Typography component="div" className={classes.networkTitle}>
                Network Request
              </Typography>
              <div className={classes.friendRequest}>
                <div className={classes.friendName}>Friend 1</div>
                <div>
                  <Button className={classes.networkActionBtn}>Accept</Button>
                  <Button className={classes.networkActionBtn}>Reject</Button>
                </div>
              </div>
              <div className={classes.friendRequest}>
                <div className={classes.friendName}>Friend 1</div>
                <div>
                  <Button className={classes.networkActionBtn}>Accept</Button>
                  <Button className={classes.networkActionBtn}>Reject</Button>
                </div>
              </div>
            </div>
            <div>
              <Typography component="div" className={classes.networkTitle}>
                Network Suggestion
              </Typography>
              <div className={classes.friendRequest}>
                <div className={classes.friendName}>Suggestion 1</div>
                <div>
                  <Button className={classes.networkActionBtn}>Add</Button>
                  <Button className={classes.networkActionBtn}>Remove</Button>
                </div>
              </div>
              <div className={classes.friendRequest}>
                <div className={classes.friendName}>Suggestion 2</div>
                <div>
                  <Button className={classes.networkActionBtn}>Add</Button>
                  <Button className={classes.networkActionBtn}>Remove</Button>
                </div>
              </div>
            </div>
          </div>
        </Popover>

        <IconButton>
          {' '}
          <StyledBadge badgeContent={3} className={classes.badge} overlap="rectangular">
            <img src={notificationIcon} alt="notification" className={classes.icons} />
          </StyledBadge>
        </IconButton>

        <IconButton onClick={(e) => handelPopoverOpen(e, setSettingAnchorEl)}>
          {' '}
          <StyledBadge badgeContent={null} className={classes.badge} overlap="rectangular">
            {' '}
            <img src={settingIcon} alt="setting" className={classes.icons} />
          </StyledBadge>
        </IconButton>

        <Popover
          open={open(settinganchorEl)}
          anchorEl={settinganchorEl}
          onClose={() => handelPopoverClose(setSettingAnchorEl)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <div className={classes.settingPopover}>
            <div>
              <Typography component="div" className={classes.settingItem}>
                Privacy Setting
              </Typography>
              <div className={classes.friendName}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar.{' '}
              </div>
            </div>
            <div>
              <Typography component="div" className={classes.networkTitle}>
                Language
              </Typography>
              <div className={classes.friendName}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar.{' '}
              </div>
            </div>
            <div>
              <Typography component="div" className={classes.networkTitle}>
                Account Setting
              </Typography>
              <div className={classes.friendName}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar.{' '}
              </div>
            </div>
            <div>
              <Typography component="div" className={classes.networkTitle}>
                Payment Details
              </Typography>
              <div className={classes.friendName}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar.{' '}
              </div>
            </div>
            <div>
              <Typography component="div" className={classes.networkTitle}>
                Logout
              </Typography>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default withStyles(styles)(SecondHeader);
