import React from 'react';
import * as messageKeys from '~/locales';
import intl from 'react-intl-universal';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

const useStyles = (bgImage) =>
  makeStyles({
    content: {
      backgroundImage: `url(${bgImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'none',
      padding: '5%',
      overflow: 'hidden',
      height: '100%',
      boxSizing: 'border-box'
    },
    textContent: {
      float: 'right',
      width: '35%',
      [`@media (max-width:768px)`]: {
        float: 'none',
        width: '100%'
      }
    },
    textBg: {
      backgroundColor: 'rgba(255,255,255,0.7)',
      padding: '4%',
      fontSize: '0.9rem'
    },
    heading: {
      fontWeight: 'bold',
      letterSpacing: '3px'
    }
  });

function OrganizeArticleBox(props) {
  const classes = useStyles(props.articlebBgImage)();
  return (
    <div className={classes.content}>
      <div className={props.isContentTop ? null : classes.textContent}>
        <div className={classes.textBg}>
          <Typography className={classes.heading} align={props.isContentTop ? 'center' : 'left'}>
            {props.articleTitle ? props.articleTitle : intl.get(messageKeys.ORGANIZE_ARTICLEBOX_TITLE)}
          </Typography>

          <Typography>{props.articleContent}</Typography>
        </div>
      </div>
    </div>
  );
}

export default OrganizeArticleBox;
