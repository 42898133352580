import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { WebTitle } from '../../ui';
import {
  withStyles,
  Button,
  IconButton,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Typography,
  Drawer,
  List,
  Collapse
} from '@material-ui/core';
import { styles } from './NavigationBarStyles';
import MenuContent from './Submenu/MenuContent';
import menuData from './Submenu/submenuData';
import UserInfoIconButtons from './UserInfoIconButtons/UserInfoIconButtons';
import HeaderLink from './HeaderLink';
import ListItemLink from './ListItemLink';
import PersonIcon from '@material-ui/icons/Person';
import { MenuIcon } from '../../ui/icons';
import routes from '~/routes';
import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';
import { openLoginModal } from '~/utils/commonFunc';
import { useAuth } from 'shared/auth';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const getRoutePath = (name) => {
  const route = routes.find((route) => route.name === name);
  return route ? route.path : null;
};

const getRootPath = () => {
  return getRoutePath('Home') + '?showHome=false';
};

const ModifiedIconButton = withStyles((theme) => ({
  root: {
    padding: 5,
    minWidth: '0px !important',
  },
}))(Button);

const NavigationBar = ({ classes }) => {


  const location = useLocation();
  const history = useHistory();


  const {auth} = useAuth();

  const handleOpen = () => {
    if (!auth?.token?.length) {
      openLoginModal(history, location);
    } else {
      history.push('/profile');
    }
  };



  const getSideMenuState = (menuList) => {
    if (!menuList || menuList.length === 0) {
      return null;
    }

    let stateArr = [];
    menuList.forEach((menu) => {
      stateArr.push({
        open: false,
        subState: getSideMenuState(menu.submenu)
      });
    });

    return stateArr;
  };


  const [state, setState] = useState({
    data: null,
    open: false,
    anchorEl: null,
    trianglePosition: 0,
    menuAnchorEl: null,
    activatedMenuName: '',
    left: false,
    sideSubmenuOpen: getSideMenuState(menuData),
  });
  
  const headerRef = useRef(null);
  const menuCloseTimerRef = useRef(null);
  const lastCloseMenuTimeRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (state.open) {
        setState((prevState) => ({
          ...prevState,
          open: false,
          data: null
        }));
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(menuCloseTimerRef.current);
    };
  }, [state.open]);

  const handleMenuOpen = (submenuData, headerLinkName) => (e) => {
    if (lastCloseMenuTimeRef.current && Date.now() - lastCloseMenuTimeRef.current < 200 && headerLinkName === state.activatedMenuName) {
      return;
    }

    if (menuCloseTimerRef.current) {
      clearTimeout(menuCloseTimerRef.current);
    }

    const leftPosition = e.target.getBoundingClientRect().left;
    const menuWidth = e.target.getBoundingClientRect().width;
    setState((prevState) => ({
      ...prevState,
      data: submenuData,
      open: true,
      anchorEl: headerRef.current,
      trianglePosition: leftPosition + menuWidth / 2.3,
      activatedMenuName: headerLinkName
    }));
  };

  const handleClose = () => {
    if (menuCloseTimerRef.current) {
      clearTimeout(menuCloseTimerRef.current);
    }

    lastCloseMenuTimeRef.current = Date.now();
    setState((prevState) => ({
      ...prevState,
      open: false,
      anchorEl: null
    }));
  };

  const handleDelayClose = () => {
    if (menuCloseTimerRef.current) {
      clearTimeout(menuCloseTimerRef.current);
    }
    menuCloseTimerRef.current = setTimeout(() => handleClose(), 300);
  };

  const handleSubmenuMouseEnter = () => {
    if (menuCloseTimerRef.current) {
      clearTimeout(menuCloseTimerRef.current);
    }
  };

  const handleMenuClose = () => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: null
    }));
  };

  const handleOpenPage = (url) => {
    history.push(url);
  };

  const renderMenuItem = (menuItems, routeNames) => {
    const { anchorEl } = state;
    const open = Boolean(anchorEl);
    return (
      <Menu
        id="main-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: 400,
            width: 120
          }
        }}
      >
        {menuItems.map((itemName, index) => (
          <MenuItem key={index} onClick={() => handleOpenPage(routeNames[index])}>{itemName}</MenuItem>
        ))}
      </Menu>
    );
  };

  const toggleSidemenu = (side, open) => () => {
    setState((prevState) => ({
      ...prevState,
      [side]: open
    }));
  };

  const handleSideSubmenuOpen = (arr) => (e) => {
    e.stopPropagation();

    let openState = state.sideSubmenuOpen;
    for (let i = 0; i < arr.length; i++) {
      if (i === arr.length - 1) {
        openState[arr[i]].open = !openState[arr[i]].open;
      } else {
        openState = openState[arr[i]].subState;
      }
    }
    setState((prevState) => ({
      ...prevState,
      sideSubmenuOpen: state.sideSubmenuOpen
    }));
  };



  const getMenuList = () => {
    const sideMenu = [];
    for (let i = 0; i < menuData.length; i++) {
      sideMenu.push(renderSideMenuItem(menuData[i], 1, [i], menuData[i].bgColor));
    }
    return sideMenu;
  };

  const renderSideMenuItem = (menu, level, stateArr, bgColor) => {
    const hasSubmenu = menu.submenu && menu.submenu.length > 0;
    let isOpen = false;
    if (hasSubmenu) {
      let openState = state.sideSubmenuOpen;
      for (let i = 0; i < stateArr.length; i++) {
        if (i === stateArr.length - 1) {
          isOpen = openState[stateArr[i]].open;
        } else {
          openState = openState[stateArr[i]].subState;
        }
      }
    }

    const icon = menu.icon ? <img src={menu.icon} className={classes.sideMenuIcon} alt="icon" /> : null;
    return (
      <div key={menu.name} className={classes['sideMenuLevel' + level]}>
        <ListItemLink
          primary={intl.get(menu.name) || menu.name}
          icon={icon}
          to={menu.route ? menu.route.path : menu.link || '/'}
          hasSubmenu={hasSubmenu}
          isOpen={isOpen}
          onClick={handleSideSubmenuOpen(stateArr)}
          color={bgColor}
        />
        {hasSubmenu ? (
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {menu.submenu.map((m, index) => renderSideMenuItem(m, level + 1, [...stateArr, index], bgColor))}
            </List>
          </Collapse>
        ) : null}
      </div>
    );
  };

  const sideList = (side) => (
    <div role="presentation" onClick={toggleSidemenu(side, false)}>
      <Typography className={classes.websiteTitle}>HCN</Typography>
      <List className={classes.list}>{getMenuList()}</List>
    </div>
  );

  const renderMenuIcon = () => (
    <div className={classes.menuButtonContainer}>
      <IconButton className={classes.menuButton} onClick={toggleSidemenu('left', true)}>
        <MenuIcon className={classes.menuButtonIcon} />
      </IconButton>
      <Drawer open={state.left} onClose={toggleSidemenu('left', false)}>
        {sideList('left')}
      </Drawer>
    </div>
  );

  const renderHeaderLink = (headerLinkName, submenu) => {
    const { open, activatedMenuName } = state;
    const menuEffect = {
      INTRO: intl.get(messageKeys.HEADER_INTROL_SUB),
      CONTENT: intl.get(messageKeys.HEADER_CONTENT_SUB),
      ORGANIZE: intl.get(messageKeys.HEADER_ORGANIZE_SUB),
      NETWORK: intl.get(messageKeys.HEADER_INTROL_SUB)
    };

    return (
      <div className={classes.navHeaderLink}>
        <div>
          <Button className={classes.navLink} onClick={handleMenuOpen(submenu, headerLinkName)}>
            {headerLinkName}
          </Button>
        </div>
        {open && headerLinkName === activatedMenuName ? (
          <Typography className={classes.navHover} component="div">
            {menuEffect[headerLinkName]}
          </Typography>
        ) : null}
      </div>
    );
  };

  return (
    <AppBar position="fixed" className={classes.fixBar} ref={headerRef}>
      <Toolbar className={classes.toolbar} component="nav" variant="dense">
        <div className={classes.appBarContainer}>
          {renderMenuIcon()}
          <div className={classes.navContainer}>
            <div className={classes.navLinkContainer}>
              <HeaderLink
                headerLinkName={intl.get(messageKeys.HEADER_INTROL)}
                activatedMenuName={state.activatedMenuName}
                isMenuOpen={state.open}
                menuEffect={intl.get(messageKeys.HEADER_INTROL_SUB)}
                to={getRoutePath('Intro')}
                onMenuOpen={(e, headerLinkName) => handleMenuOpen(menuData[0].submenu, headerLinkName)(e)}
                onMenuClose={handleDelayClose}
              />
              <HeaderLink
                headerLinkName={intl.get(messageKeys.HEADER_CONTENT)}
                activatedMenuName={state.activatedMenuName}
                isMenuOpen={state.open}
                menuEffect={intl.get(messageKeys.HEADER_CONTENT_SUB)}
                to={getRoutePath('Content')}
                onMenuOpen={(e, headerLinkName) => handleMenuOpen(menuData[1].submenu, headerLinkName)(e)}
                onMenuClose={handleDelayClose}
              />
            </div>
            <Button className={classes.navHomeLink} component={RouterLink} to={getRootPath()}>
              <WebTitle />
            </Button>
            <div className={classes.navLinkContainer}>
              <HeaderLink
                headerLinkName={intl.get(messageKeys.HEADER_ORGANIZE)}
                activatedMenuName={state.activatedMenuName}
                isMenuOpen={state.open}
                menuEffect={intl.get(messageKeys.HEADER_ORGANIZE_SUB)}
                to={getRoutePath('Organize')}
                onMenuOpen={(e, headerLinkName) => handleMenuOpen(menuData[2].submenu, headerLinkName)(e)}
                onMenuClose={handleDelayClose}
              />
              <HeaderLink
                headerLinkName={intl.get(messageKeys.HEADER_NETWORK)}
                activatedMenuName={state.activatedMenuName}
                isMenuOpen={state.open}
                menuEffect={intl.get(messageKeys.HEADER_NETWORK_SUB)}
                to={getRoutePath('Network')}
                onMenuOpen={(e, headerLinkName) => handleMenuOpen(menuData[3].submenu, headerLinkName)(e)}
                onMenuClose={handleDelayClose}
              />
            </div>
          </div>
          <MenuContent
            submenuData={state.data}
            submenuOpen={state.open}
            submenuAnchorEl={state.anchorEl}
            submenuClose={handleClose}
            onMouseEnter={handleSubmenuMouseEnter}
            onMouseLeave={handleDelayClose}
          />
          <div
            className={classes.triangle}
            style={{
              left: state.trianglePosition,
              display: state.open ? 'block' : 'none'
            }}
          />
          <ModifiedIconButton style={{ color: '#8c8c8c' }} onClick={handleOpen}>
            <PersonIcon />
          </ModifiedIconButton>
          <UserInfoIconButtons />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(NavigationBar);
