const animationDuration = '500ms';

const SlidingMediaContentStyles = (theme) => ({
  '@keyframes slideInFromRight': {
    from: {
      opacity: 0,
      transform: 'translateX(20%)'
    },

    to: {
      opacity: 1,
      transform: 'translateX(0)'
    }
  },

  '@keyframes slideInFromLeft': {
    from: {
      opacity: 0,
      transform: 'translateX(-20%)'
    },

    to: {
      opacity: 1,
      transform: 'translateX(0)'
    }
  },

  '@keyframes slideOutToLeft': {
    from: {
      opacity: 1,
      transform: 'translateX(0)'
    },

    to: {
      opacity: 0,
      transform: 'translateX(-20%)'
    }
  },

  '@keyframes slideOutToRight': {
    from: {
      opacity: 1,
      transform: 'translateX(0)'
    },

    to: {
      opacity: 0,
      transform: 'translateX(20%)'
    }
  },

  '@keyframes slideInFromTop': {
    from: {
      opacity: 0,
      transform: 'translateY(-5%)'
    },

    to: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  },

  '@keyframes slideInFromBottom': {
    from: {
      opacity: 0,
      transform: 'translateY(5%)'
    },

    to: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  },

  '@keyframes slideOutToTop': {
    from: {
      opacity: 1,
      transform: 'translateY(0)'
    },

    to: {
      opacity: 0,
      transform: 'translateY(-5%)'
    }
  },

  '@keyframes slideOutToBottom': {
    from: {
      opacity: 1,
      transform: 'translateY(0)'
    },

    to: {
      opacity: 0,
      transform: 'translateY(5%)'
    }
  },

  slideInFromLeft: {
    animation: '$slideInFromLeft ease-out',
    animationDuration: animationDuration
  },

  slideInFromRight: {
    animation: '$slideInFromRight ease-out',
    animationDuration: animationDuration
  },

  slideOutToLeft: {
    animation: '$slideOutToLeft ease-in',
    animationDuration: animationDuration
  },

  slideOutToRight: {
    animation: '$slideOutToRight ease-in',
    animationDuration: animationDuration
  },

  slideInFromTop: {
    animation: '$slideInFromTop ease-out',
    animationDuration: animationDuration
  },

  slideInFromBottom: {
    animation: '$slideInFromBottom ease-out',
    animationDuration: animationDuration
  },

  slideOutToTop: {
    animation: '$slideOutToTop ease-in',
    animationDuration: animationDuration
  },

  slideOutToBottom: {
    animation: '$slideOutToBottom ease-in',
    animationDuration: animationDuration
  },

  mediaDetailContent: {
    flexWrap: 'nowrap',
    textAlign: 'center',
    padding: '2.5rem 4rem',
    height: '100%',
    maxHeight: 'calc(100vh - 194.4px)',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '1.5rem 2rem'
    },
    '& img': {
      width: '60px'
    }
  }
});
export default SlidingMediaContentStyles;
