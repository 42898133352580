import React from 'react';
import { withStyles, Box } from '@material-ui/core';

import GridLayout from './GridLayout'
import ArticleCard from './ArticleCard'
import Index  from '~/components/layout/MyFeed'; 

import Image1 from '~/assets/images/profile/bottom-business-fav/2-1.png'
import Image2 from '~/assets/images/profile/bottom-business-fav/2-2.png'
import Image3 from '~/assets/images/profile/bottom-business-fav/2-3.png'
import Image4 from '~/assets/images/profile/bottom-business-fav/2-4.png'

const styles = (theme) => ({
  root: {
    padding: '20px 40px'
  }
});

const DataList = [
  {
    tag: {
      color: '#7ECF79',
      text: 'TUTORIAL'
    },
    desc: 'LOREM IPSUM DOLOR SITAMET',
    background: Image1
  },
  {
    tag: {
      color: '#7ECF79',
      text: 'TUTORIAL'
    },
    desc: 'LOREM IPSUM DOLOR SITAMET',
    background: Image2
  },
  {
    tag: {
      color: '#7ECF79',
      text: 'TUTORIAL'
    },
    desc: 'LOREM IPSUM DOLOR SITAMET',
    background: Image3
  },
  {
    tag: {
      color: '#7ECF79',
      text: 'TUTORIAL'
    },
    desc: 'LOREM IPSUM DOLOR SITAMET',
    background: Image4
  }
]

function Articles(props) {
  const { classes } = props;
  return (
    <Box className={classes.root}>
      <GridLayout>
        {
          DataList.map(data => (
            <Index data={data} />
          ))
        }
      </GridLayout>
    </Box>
  )
}

export default withStyles(styles)(Articles);
