import React from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles, Box} from '@material-ui/core';
import {SuspendisBox, MoreCardBox, FlowGrid, ToggleContentTitles} from '~/components/ui';
import InitiativeExpandedCardBox from './InitiativeExpandedCardBox';

import styles from './InitiativesMainExpandedStyles';

const useStyles = makeStyles(styles);

const InitiativesMainExpanded = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const {data, titles, selectedTitleIds, onTitlesChange} = props;

  const getInitiativesData = () => {
    return data
      .reduce((prev, cur) => {
        if (cur.id) prev.push(cur);
        return prev;
      }, [])
      .concat([{card: 'MORE_INITIATIVES', content: 'LOAD MORE REGIONAL INITIATIVES'}]);
  };

  return (
    <>
      <Box className={classes.root}>
        <div className={classes.titleContainer}>
          <ToggleContentTitles titles={titles} selectedIds={selectedTitleIds} onChange={onTitlesChange} />
        </div>
        <FlowGrid data={getInitiativesData()} cardHeight="150%" maxColumns={4}>
          {(v) => {
            let card = null;
            const goDetailPage = () => {
              history.push({
                pathname: `/content/initiatives/detail`,
                itemData: v
              });
            };
            if (v.id) {
              card = (
                <InitiativeExpandedCardBox
                  item={v}
                  goDetail={props.goDetail}
                  handleClick={goDetailPage}
                ></InitiativeExpandedCardBox>
              );
            } else if (v.card === 'SUSPENDIS_BOX') {
              card = (
                <Box className={classes.cardContainer}>
                  <Box className={classes.card}>
                    <Box className={classes.suspendisBox}>
                      <SuspendisBox />
                    </Box>
                  </Box>
                </Box>
              );
            } else if (v.card === 'MORE_INITIATIVES') {
              card = (
                <Box className={classes.cardContainer}>
                  <Box className={classes.card}>
                    <MoreCardBox content={v.content}></MoreCardBox>
                  </Box>
                </Box>
              );
            }
            return card;
          }}
        </FlowGrid>
      </Box>
    </>
  );
};

export default InitiativesMainExpanded;
