import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';
import SectionTitle from './SectionTitle';
import Post from './Post';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 48,
    marginBottom: 48
  },
  postsContainer: {
    marginTop: 24
  }
}));

const LatestPosts = ({posts}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SectionTitle>{intl.get(messageKeys.ARTICLE_DETAIL_LATEST_POSTS_TITLE)}</SectionTitle>
      <Box className={classes.postsContainer}>
        {posts.map((post, idx) => (
          <Post key={`post_${idx}`} post={post} />
        ))}
      </Box>
    </div>
  );
};

export default LatestPosts;
