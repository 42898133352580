// @ts-check
import React, { useState, useEffect } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import AnimatedArrow from '~/components/ui/AnimatedArrow/AnimatedArrow';
import CubeIcon from '~/components/ui/CubeIcon/CubeIcon';

import AnimatedArrowGroupStyles from './AnimatedArrowGroupStyles';

const animationDelay = 500;

/**
 * @typedef {Object} AnimatedArrowGroupProps
 * @property {number} categoryId
 * @property {string} itemId
 * @property {import("shared/react-query/strapiAPI").HCNCategory[]} allCategories
 * @property {import("shared/react-query/strapiAPI").HCNCategory[]} filteredCategories
 * @property {Object} items
 * @property {Function} handleGetQuotesByCategory
 * @property {Function} updateGlobalItemId
 * @property {Function} toggleSlide
 * @property {Object} classes
 * @property {boolean} slideInFromLeft
 * @property {boolean} slideInFromRight
 * @property {boolean} slideOutToLeft
 * @property {boolean} slideOutToRight
 * @property {Function} onCategoryChange
 */

/**
 * AnimatedArrowGroup component
 * @param {AnimatedArrowGroupProps} props - component props
 */
const AnimatedArrowGroup = (props) => {
  const {
    categoryId: initialCategoryId,
    items,
    itemId,
    allCategories,
    filteredCategories,
    handleGetQuotesByCategory,
    updateGlobalItemId,
    toggleSlide,
    classes,
    slideInFromLeft,
    slideInFromRight,
    slideOutToLeft,
    slideOutToRight,
    onCategoryChange,
  } = props;

  const [disabledUpArrow, setDisabledUpArrow] = useState(false);
  const [disabledDownArrow, setDisabledDownArrow] = useState(false);
  const [filteredItemIndex, setFilteredItemIndex] = useState(null);
  const [maxFilteredItemsIndex, setMaxFilteredItemsIndex] = useState(null);
  const [categoryId, setCategoryId] = useState(initialCategoryId);

  const disableUpDownArrow = (index, max) => {
    if (index === 0) {
      setDisabledUpArrow(true);
    } else if (index === max) {
      setDisabledDownArrow(true);
    } else {
      setDisabledUpArrow(false);
      setDisabledDownArrow(false);
    }
  };

  useEffect(() => {
    const filteredItemIndex = items.findIndex((item) => item.id === itemId);
    const maxFilteredItemsIndex = items.length - 1;
    disableUpDownArrow(filteredItemIndex, maxFilteredItemsIndex);
  }, [items, itemId]);

  const changeCategory = (index) => {
    const newCategory = filteredCategories.find((cate, idx) => idx === index);

    handleGetQuotesByCategory(newCategory.id);

    const firstItemId = items[0].id;
    updateGlobalItemId(firstItemId);

    // setCategoryCode(newCategory.code);
    setDisabledUpArrow(true);
    setDisabledDownArrow(false);
  };

  const goToPrevCategory = () => {
    toggleSlide('slideOutToRight');
    const categoryIndex = filteredCategories.findIndex((cate) => cate.id === categoryId);
    let prevCategoryIndex;
    if (categoryIndex === 0) {
      prevCategoryIndex = filteredCategories.length - 1;
    } else {
      prevCategoryIndex = categoryIndex - 1;
    }

    changeCategory(prevCategoryIndex);

    setTimeout(() => {
      setCategoryId(prevCategoryIndex);
      toggleSlide('slideInFromLeft');
    }, animationDelay);
  };

  const goToNextCategory = () => {
    toggleSlide('slideOutToLeft');
    const categoryIndex = filteredCategories.findIndex((cate) => cate.id === categoryId);
    let nextCategoryIndex;
    if (categoryIndex === filteredCategories.length - 1) {
      nextCategoryIndex = 0;
    } else {
      nextCategoryIndex = categoryIndex + 1;
    }

    setTimeout(() => {
      setCategoryId(nextCategoryIndex);
      changeCategory(nextCategoryIndex);
      toggleSlide('slideInFromRight');
    }, animationDelay);
  };

  const updateItemId = (index) => {
    disableUpDownArrow(index, items.length - 1);
    const itemId = items[index].id;
    updateGlobalItemId(itemId);
  };

  const goToPrevItem = () => {
    toggleSlide('slideOutToBottom');
    const filteredItemIndex = items.findIndex((item) => item.id === itemId);

    setFilteredItemIndex(filteredItemIndex - 1);
    setCategoryId(items[filteredItemIndex - 1].category.id);
    setTimeout(() => {
      let prevItemIndex = filteredItemIndex - 1;
      updateItemId(prevItemIndex);
      toggleSlide('slideInFromTop');
    }, animationDelay);
  };

  const goToNextItem = () => {
    toggleSlide('slideOutToTop');
    const filteredItemIndex = items.findIndex((item) => item.id === itemId);

    setFilteredItemIndex(filteredItemIndex + 1);
    setCategoryId(items[filteredItemIndex + 1].category.id);

    setTimeout(() => {
      let nextItemIndex = filteredItemIndex + 1;
      updateItemId(nextItemIndex);
      toggleSlide('slideInFromBottom');
    }, animationDelay);
  };

  let category = allCategories.find((cat) => cat.id === categoryId);

  return (
    <Grid
      item
      container
      className={clsx({
        [classes.arrowGroup]: true,
        [classes.slideInFromLeft]: slideInFromLeft,
        [classes.slideInFromRight]: slideInFromRight,
        [classes.slideOutToLeft]: slideOutToLeft,
        [classes.slideOutToRight]: slideOutToRight
      })}
      direction="column"
      justifyContent="space-between"
      alignItems="center"
    >
      {/* <AnimatedArrow
        extraClass={clsx({
          [classes.disabled]: disabledUpArrow
        })}
        handleClick={goToPrevItem}
      /> */}
      <Grid item container justifyContent="space-between" alignItems="center">
        <AnimatedArrow
          extraClass={clsx({
            [classes.disabled]: disabledUpArrow,
            [classes.arrowIconLeft]: true
          })}
          handleClick={goToPrevItem}
        />
        <Grid item className={classes.category}>
          <div className={classes.cubeIcon} onClick={() => onCategoryChange(categoryId)}>
            <CubeIcon categoryIndex={categoryId} />
          </div>
          <div className={classes.categoryTitle}>{category?.title}</div>
        </Grid>
        <AnimatedArrow
          extraClass={clsx({
            [classes.disabled]: disabledDownArrow,
            [classes.arrowIconRight]: true
          })}
          handleClick={goToNextItem}
        />
      </Grid>
      {/* <AnimatedArrow
        extraClass={clsx({
          [classes.arrowIconDown]: true,
          [classes.disabled]: disabledDownArrow
        })}
        handleClick={goToNextItem}
      /> */}
    </Grid>
  );
};

export default withRouter(withStyles(AnimatedArrowGroupStyles)(AnimatedArrowGroup));
