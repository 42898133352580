import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    opacity: 0.82,
    fontSize: '1rem'
  },
  date: {
    marginLeft: 8,
    fontSize: '0.875rem',
    opacity: 0.82,
    letterSpacing: 2.1
  }
}));

const PublishDate = ({date, className}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <CalendarTodayIcon className={classes.icon} />
      <Typography className={classes.date}>{date}</Typography>
    </div>
  );
};

export default PublishDate;
