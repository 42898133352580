import React from 'react';
import * as messageKeys from '~/locales';
import intl from 'react-intl-universal';
import {Typography, Avatar} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = (textColor) =>
  makeStyles({
    content: {
      padding: '4% 20px'
    },
    qaHeading: {
      fontWeight: 'bold',
      letterSpacing: '5px',
      marginTop: '1em'
    },
    avatar: {
      width: '80px',
      height: '80px',
      margin: '20px auto',
      [`@media (max-width:768px)`]: {
        width: '60px',
        height: '60px',
        margin: '10px auto'
      }
    },
    questionBox: {
      fontSize: '0.9em',
      fontWeight: 'normal',
      letterSpacing: '2px',
      lineHeight: '1.5',
      [`@media (max-width:768px)`]: {
        fontSize: '0.75em',
        lineHeight: '1.2'
      }
    },
    listQuestions: {
      color: textColor,
      marginTop: '1em'
    },
    listAnswer: {
      opacity: '0.9',
      color: '#fff',
      marginBottom: '1.4em'
    },
    viewBtn: {
      margin: '1.5em auto',
      width: '100px',
      height: '28px',
      border: 'solid 2px #d2a75f',
      fontSize: '0.8em',
      lineHeight: '2.2',
      textAlign: 'center',
      letterSpacing: '1.5px',
      '&:hover': {
        backgroundColor: '#d2a75f'
      }
    },
    linkStyle: {
      textDecoration: 'none',
      color: 'white',
      fontWeight: 'bold'
    }
  });

function QuestionBox(props) {
  const classes = useStyles(props.textColor)();
  return (
    <div className={classes.content}>
      <Typography align="center" className={classes.qaHeading}>
        {intl.get(messageKeys.ORGANIZE_QUESTIONBOX_QA)}
      </Typography>
      <Avatar src={props.imagesrc} className={classes.avatar} />
      <div className={classes.questionBox}>
        {' '}
        <div className={classes.listQuestions}>{props.questions[0]} </div>
        <div className={classes.listAnswer}>{props.questions[1]}</div>
        <div className={classes.listQuestions}>{props.questions[0]}</div>
        <div className={classes.listAnswer}>{props.questions[1]}</div>
      </div>
      <div className={classes.viewBtn}>
        <a href="/#" className={classes.linkStyle}>
          {intl.get(messageKeys.ORGANIZE_QUESTIONBOX_VIEWMORE_BUTTON)}
        </a>
      </div>
    </div>
  );
}

export default QuestionBox;
