// import React, { useRef, useEffect, useState } from 'react';
// import logo from "../../../assets/images/organize/share_btn.png"

// const TextOverlayImage = ({ src, text, color }) => {
//     const canvasRef = useRef(null);
//     const [downloadUrl, setDownloadUrl] = useState('');

//     useEffect(() => {
//         const canvas = canvasRef.current;
//         const context = canvas.getContext('2d');
//         const image = new Image();
//         const logoImage = new Image();

//         // Function to draw the logo
//         const drawLogo = (textHeight) => {
//             // Calculate logo dimensions to maintain aspect ratio
//             const logoHeight = textHeight;
//             const logoWidth = (logoImage.width / logoImage.height) * logoHeight;

//             // Position the logo to the right of the text
//             const textMetrics = context.measureText(text);
//             const logoX = textMetrics.width + 30; // Adjust this for spacing between text and logo
//             const logoY = 15; // Center the logo vertically

//             context.drawImage(logoImage, logoX, logoY, logoWidth, logoHeight);
//         };

//         logoImage.src = logo;

//         image.onload = () => {
//             const targetWidth = 500;
//             const targetHeight = 500;
//             canvas.width = targetWidth;
//             canvas.height = targetHeight;

//             // Scaling dimensions
//             let drawWidth = image.width;
//             let drawHeight = image.height;

//             // Scale the image up if it is smaller than 500x500
//             if (image.width < targetWidth || image.height < targetHeight) {
//                 const scale = Math.max(targetWidth / image.width, targetHeight / image.height);
//                 drawWidth = image.width * scale;
//                 drawHeight = image.height * scale;
//             }

//             // Center the image on the canvas
//             const dx = (targetWidth - drawWidth) / 2;
//             const dy = (targetHeight - drawHeight) / 2;

//             // Draw the image
//             context.drawImage(image, dx, dy, drawWidth, drawHeight);

//             // Text properties
//             const textSizePercentage = 0.05; // 5% of the canvas height
//             const fontSize = canvas.height * textSizePercentage;
//             context.font = `${fontSize}px Arial`;
//             const textX = 15; // Small padding from left edge
//             const textY = fontSize + 10; // Position the text slightly below the top edge

//             // Draw green border on the left edge of the text
//             context.strokeStyle = color;
//             context.lineWidth = 5;
//             context.beginPath();
//             context.moveTo(textX - context.lineWidth, textY + 6.9 - fontSize); // Start at the top-left of text
//             context.lineTo(textX - context.lineWidth, textY + 0.5); // Draw down to the bottom-left of text
//             context.stroke();

//             // Draw the text
//             context.fillStyle = 'white';
//             context.fillText(text, textX, textY);

//             // Draw the logo with height equal to text height
//             drawLogo(fontSize);

//             // Update the download URL
//             setDownloadUrl(canvas.toDataURL('image/png'));
//         };

//         image.src = src;
//     }, [src, text, color]);
//     return (
//         <div style={{ width: '100%', height: '100%' }}>
//             <canvas ref={canvasRef} style={{ display: 'none' }} />
//             {downloadUrl && (
//                 <img src={downloadUrl} style={{ width: '100%', objectFit: 'cover', height: '100%' }} alt="Image with Text" />
//             )}
//         </div>
//     );
// };

// export default TextOverlayImage;


import React, { useRef, useEffect, useState, memo } from 'react';
import logo from "../../../assets/images/organize/share_btn.png"; 

/**
 * @typedef {Object} TextOverlayImageProps
 * @property {string} src
 * @property {string} text
 * @property {string} color
 */

/**
 * Component to overlay text on an image with a colored border
 * @param {TextOverlayImageProps} props
 */
const TextOverlayImage = memo(({ src, text, color }) => {
    const canvasRef = useRef(null);
    const [downloadUrl, setDownloadUrl] = useState('');

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const image = new Image();

        // Fix CORS issue with images
        // https://stackoverflow.com/a/22716873/3161505
        image.crossOrigin="anonymous";
        
        const logoImage = new Image();

        const drawLogo = (textWidth, textHeight, textX, textY) => {
            // Calculate logo dimensions to maintain aspect ratio
            const logoHeight = textHeight;
            const logoWidth = (logoImage.width / logoImage.height) * logoHeight;

            // Calculate logo position to the right of the text
            const logoX = textX + textWidth / 2 + 10; // 10px padding from text
            const logoY = textY - textHeight / 1.2;

            context.drawImage(logoImage, logoX, logoY, logoWidth, logoHeight);
        };
        
                logoImage.src = logo;

        image.onload = () => {
            const targetWidth = 1000;
            const targetHeight = 1000;
            canvas.width = targetWidth;
            canvas.height = targetHeight;

            // Scaling dimensions
            let drawWidth = image.width;
            let drawHeight = image.height;
            image.style.opacity = 0.4
            // Scale the image up if it is smaller than 500x500
            if (image.width < targetWidth || image.height < targetHeight) {
                const scale = Math.max(targetWidth / image.width, targetHeight / image.height);
                drawWidth = image.width * scale;
                drawHeight = image.height * scale;
            }

            // Center the image on the canvas
            const dx = (targetWidth - drawWidth) / 2;
            const dy = (targetHeight - drawHeight) / 2;
            context.drawImage(image, dx, dy, drawWidth, drawHeight);

            // Text properties
            const textSizePercentage = 0.05; // 5% of the canvas height
            const fontSize = canvas.height * textSizePercentage;
            context.font = `${fontSize}px Arial`;
            context.fillStyle = 'white';
            context.textAlign = 'center';

            // Text position (center bottom)
            const textX = canvas.width / 2;
            const textY = canvas.height - fontSize; // Position text above the bottom

            // Calculate text width and height for background
            const textMetrics = context.measureText(text);
            const textWidth = textMetrics.width;
            const textHeight = fontSize;

            // Draw the text
            context.fillText(text, textX, textY);

            // Draw green border to the left of the text
            if (color) {
                context.strokeStyle = color;
                context.lineWidth = 5;
                context.beginPath();
                context.moveTo(textX - (textWidth / 2) - 10, textY - (textHeight / 2) - 11 ); // Start at the top-left of text
                context.lineTo(textX - (textWidth / 2) - 10, textY + (textHeight / 2) - 22); // Draw down to the bottom-left of text
                context.stroke();
            }

            drawLogo(textWidth, textHeight, textX, textY);
            // Update the download URL
            setDownloadUrl(canvas.toDataURL('image/png'));
        };

        image.src = src;
    }, [src, text, color]);

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            {downloadUrl && (
                <img src={downloadUrl} style={{ width: '100%', objectFit: 'cover', height: '100%' }} alt="Image with Text" />
            )}
        </div>
    );
});

export default TextOverlayImage;
