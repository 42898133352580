import React, {useState} from 'react';
import {withStyles, Grid, Button} from '@material-ui/core';

import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Tooltip from '@material-ui/core/Tooltip';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import iconDonate from '~/assets/images/content/guides/ic_donate.png';

const styles = (theme) => ({
  root: {},
  icon: {
    height: '20px',
    verticalAlign: 'middle',
    marginRight: '10px',
    color: 'white',
    cursor: 'pointer'
  },
  tag: {
    padding: '3px 20px',
    borderRadius: '5px',
    marginLeft: '10px',
    textAlign: 'center',
    color: 'white',
    fontSize: '13px',
    letterSpacing: '0',
    fontWeight: '300'
  },
  red: {
    color: '#FF4D4F'
  },
  paidIcon: {
    color: '#D2A75F'
  },
  donateIcon: {
    height: '20px'
  }
});

function TopRow(props) {
  const [favorite, setFavorite] = useState(false);
  const handleAddFavotite = (e) => {
    e.stopPropagation();
    setFavorite(true);
  };

  const handleRemoveFavotite = (e) => {
    e.stopPropagation();
    setFavorite(false);
  };

  const {classes, tag, tagColor, tagSideIcon, type} = props;
  return (
    <Grid container alignItems="center">
      {type === 'person' ? (
        <>
        <Tooltip title="Paid" placement="top">
          <MonetizationOnIcon className={classes.paidIcon} />
        </Tooltip>
        </>
      ) : (
        <>
        <Tooltip title="Donate" placement="top">
          <img src={iconDonate} alt="icon" className={classes.donateIcon} />
        </Tooltip>
        </>
      )}

      {favorite ? (
        <FavoriteIcon onClick={handleRemoveFavotite} className={classes.icon + ' ' + classes.red} />
      ) : (
        <>
        <Tooltip title="Add to favorite" placement="top">
          <FavoriteBorderIcon onClick={handleAddFavotite} className={classes.icon} />
        </Tooltip>
        </>
      )}

      <div style={{flex: '1'}} />
      <Button
        className={classes.tag}
        variant="subtitle2"
        style={{
          background: tagColor
        }}
      >
        {tagSideIcon ? <img src={tagSideIcon} alt={tag} className={classes.icon} /> : null}
        {tag}
      </Button>
    </Grid>
  );
}

export default withStyles(styles)(TopRow);
