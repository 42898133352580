import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles, Grid, FormGroup, InputLabel, Checkbox, Button} from '@material-ui/core';
import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';
import CheckboxOutlineCheckedIcon from '~/components/base/auth/ui/CheckboxOutlineCheckedIcon';

import {termsModalStyles} from './Styles';

export class TermsModal extends Component {
  render() {
    const {classes} = this.props;
    return (
      <section className={classes.termsModalCard}>
        <div className={classes.triangle} />
        <div className={classes.cardBorderBottom} />
        <div className={classes.cardBorderTopA} />
        <div className={classes.cardBorderTopB} />
        <div className={classes.cardBorderLeft} />
        <div className={classes.cardBorderRight} />
        <p className={classes.modalHeading}> {intl.get(messageKeys.CONTRIBUTE_TERM_MODAL_HEADING)}</p>

        <Grid container>
          <Grid item xs={12}>
            <section className={classes.termsDetails}>
              <section>
                <p className={classes.termsHeading}>{intl.get(messageKeys.CONTRIBUTE_TERMS_HEADING_1)}</p>
                <p className={classes.termsText}>{intl.get(messageKeys.CONTRIBUTE_TERMS_TEXT)}</p>
              </section>
              <section>
                <p className={classes.termsHeading}>{intl.get(messageKeys.CONTRIBUTE_TERMS_HEADING_2)}</p>
                <p className={classes.termsText}>{intl.get(messageKeys.CONTRIBUTE_TERMS_TEXT)}</p>
              </section>
              <section>
                <p className={classes.termsHeading}>{intl.get(messageKeys.CONTRIBUTE_TERMS_HEADING_2)}</p>
                <p className={classes.termsText}>{intl.get(messageKeys.CONTRIBUTE_TERMS_TEXT)}</p>
              </section>
              <section>
                <p className={classes.termsHeading}>{intl.get(messageKeys.CONTRIBUTE_TERMS_HEADING_2)}</p>
                <p className={classes.termsText}>{intl.get(messageKeys.CONTRIBUTE_TERMS_TEXT)}</p>
              </section>
            </section>
          </Grid>

          <form className={classes.termsForm} onSubmit={this.props.handleTermsAccepted}>
            <Grid item xs={12}>
              <FormGroup row className={classes.acceptCheckBoxGroup}>
                <Checkbox
                  color="default"
                  classes={{root: classes.checkbox, checked: classes.checkboxChecked}}
                  checkedIcon={<CheckboxOutlineCheckedIcon />}
                  checked={this.props.termsAccepted}
                  onChange={this.props.toggleTermsModalCheckBox}
                  name="acceptCheckBox"
                />
                <InputLabel className={classes.checkBoxLabel}>
                  {intl.get(messageKeys.CONTRIBUTE_TERM_MODAL_CHECKBOX_LABEL)}
                </InputLabel>
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" className={classes.submitButton}>
                {intl.get(messageKeys.CONTRIBUTE_FORM_BUTTON_SUBMIT)}
              </Button>
            </Grid>
          </form>
        </Grid>
      </section>
    );
  }
}

TermsModal.propTypes = {
  classes: PropTypes.object.isRequired,
  termsAccepted: PropTypes.bool.isRequired,
  handleTermsAccepted: PropTypes.func.isRequired,
  toggleTermsModalCheckBox: PropTypes.func.isRequired
};

export default withStyles(termsModalStyles)(TermsModal);
