const QuoteDetailStyle = (theme) => ({
  container: {
    width: '100vw',
    backgroundColor: '#fff'
  },

  quotesDetailImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },

  share: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '1.25rem'
    },
    '& p': {
      color: '#b3b9bb',
      fontSize: '0.625rem',
      fontWeight: '600',
      paddingLeft: '0.25rem',
      paddingTop: '1px'
    }
  },

  sider: {
    minHeight: 'calc(100vh - 53.4px)',
    height: '100%',
    backgroundColor: '#fff',
    zIndex: 2
  },

  longDivider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#ccc'
  },

  quoteContentTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    letterSpacing: '2px'
  },

  quoteContentText: {
    padding: '1.25rem 2.5rem',
    fontSize: '0.75rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      padding: '1.25rem 1rem'
    }
  },

  authorTitle: {
    fontSize: '0.75rem',
    letterSpacing: '2px',
    opacity: '0.8'
  },

  authorDetail: {
    marginBottom: '2rem',
    fontSize: '0.75rem',
    letterSpacing: '1px',
    opacity: '0.7'
  },

  shortDivider: {
    width: '2.5rem',
    margin: '0 auto',
    backgroundColor: 'rgba(0,0,0,1)'
  },

  quoteSubTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    letterSpacing: '2px',
    marginTop: '0.5rem'
  },

  quoteDetailText: {
    marginBottom: '1rem',
    padding: '0.75rem 2.5rem',
    fontSize: '0.75rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0.5rem',
      padding: '0.75rem 1rem'
    }
  },

  socialMediaImg: {
    marginTop: '1.25rem',
    '& img': {
      margin: '0.25rem'
    }
  }
});
export default QuoteDetailStyle;
