import {GraphQLClient} from 'graphql-request';

const SHOPIFY_STOREFRONT_API_TOKEN = process.env.REACT_APP_SHOPIFY_STOREFRONT_API_TOKEN ?? ''

const SHOPIFY_ENDPOINT = `https://${process.env.REACT_APP_SHOPIFY_STORE}.myshopify.com/api/2023-01/graphql.json`

const client = new GraphQLClient(SHOPIFY_ENDPOINT, {
  headers: {
    'X-Shopify-Storefront-Access-Token': SHOPIFY_STOREFRONT_API_TOKEN,
  },
})

export default client;
