import mainBackground from '~/assets/images/layout/mainBackground.png';

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    backgroundImage: `url(${mainBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'
  },
  mainContent: {
    marginTop: 0,
    // marginLeft: '60px',
    // width: 'calc(100vw - 60px)'
  },

  userSection: {
    padding: '20px'
  },

  personCard: {
    height: '280px'
  },

  bottomWrapper: {
    padding: '0 20px 20px 20px'
  },

  bottomSection: {
    minHeight: '500px',
    border: 'solid 2px white',
    borderRadius: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.55)'
  },

  blSection: {
    border: 'solid 2px white',
    borderRadius: '20px',
    borderTopRightRadius: '0px',
    margin: '-2px',
    flexGrow: 0,
    maxWidth: '25%',
    flexBasis: '25%'
  },

  brSection: {
    flexGrow: 1,
    width: "inherit"
    // maxWidth: '75%',
    // flexBasis: '75%',
    // marginLeft: '2px'
  }
});

export default styles;
