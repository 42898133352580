import React from 'react';
import {withStyles} from '@material-ui/core';

const styles = (_) => ({
  icon: {
    width: '60px',
    height: '60px',
    verticalAlign: 'middle'
  },
  name: {
    fontSize: '25px',
    marginLeft: '10px'
  }
});

function ContentHeader(props) {
  const {classes, icon, name} = props;

  return (
    <div>
      <img src={icon} className={classes.icon} alt="icon" />
      <span className={classes.name}>{name}</span>
    </div>
  );
}

export default withStyles(styles)(ContentHeader);
