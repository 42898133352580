const styles = (theme) => ({
  iconButtonContainer: {
    marginRight: '35px',
    padding: '0 5px',
    marginTop: 5,
    marginBottom: 5,
    border: '1px solid #BBBBBB',
    borderRadius: 36,
    display: 'flex',
    [`@media (max-width:${theme.navigationBar.width}px)`]: {
      marginRight: '10px'
    }
  },
  metaButton: {
    marginRight: 4,
    padding: 6,
    paddingLeft: 8,
    paddingRight: 8,
    [`@media (min-width:${theme.navigationBar.width + 1}px)`]: {
      paddingLeft: 10,
      paddingRight: 8,
      paddingTop: 6,
      paddingBottom: 6
    }
  },
  butterflyButton: {
    padding: 6,
    paddingLeft: 8,
    paddingRight: 8,
    [`@media (min-width:${theme.navigationBar.width + 1}px)`]: {
      paddingLeft: 8,
      paddingRight: 10,
      paddingTop: 6,
      paddingBottom: 6
    }
  },
  metaIcon: {
    width: 24,
    height: 24,
    '@media (max-width:801px)': {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5)
    },
    '@media screen and (max-width:761px)': {
      width: theme.spacing(2.25),
      height: theme.spacing(2.25)
    },
    '@media screen and (max-width:480px)': {
      width: theme.spacing(2),
      height: theme.spacing(2)
    },
    '@media screen and (max-width:360px)': {
      width: theme.spacing(2),
      height: theme.spacing(2)
    }
  },
  butterflyIcon: {
    width: 24,
    height: 24,
    '@media (max-width:801px)': {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5)
    },
    '@media screen and (max-width:761px)': {
      width: theme.spacing(2.25),
      height: theme.spacing(2.25)
    },
    '@media screen and (max-width:480px)': {
      width: theme.spacing(2),
      height: theme.spacing(2)
    },
    '@media screen and (max-width:360px)': {
      width: theme.spacing(2),
      height: theme.spacing(2)
    }
  },
  appPopover: {
    padding: '10px',
    maxWidth: '275px'
  },
  collection: {textAlign: 'center', marginTop: '10px'},
  appTitle: {
    fontWeight: 'bold'
  },
  groupSet: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  },
  iconGroup: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: '85px'
  },
  largeIcons: {
    width: '48px',
    height: '48px'
  },
  iconBtn: {
    margin: 'auto',
    padding: '10px'
  },
  appName: {
    fontSize: '0.8rem'
  },
  integrateBtn: {
    marginTop: '5px',
    width: '240px',
    height: '24px',
    fontSize: '12px',
    color: '#A07936',
    letterSpacing: '0.1px',
    border: 'solid 1px #A07936',
    borderRadius: '13px',
    lineHeight: '0.8',
    fontWeight: '100'
  }
});

export {styles};
