// @ts-check
import React, { useState, useEffect } from 'react';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { withStyles, Grid, Divider } from '@material-ui/core';

import SlidingImgAndVideo from '~/components/ui/SlidingImgAndVideo/SlidingImgAndVideo';
import AnimatedArrowGroup from '~/components/ui/AnimatedArrowGroup/AnimatedArrowGroup';
import SlidingMediaContent from '~/components/ui/SlidingMediaContent/SlidingMediaContent';

import ButterflyIcon from '~/assets/images/icons/share_btn.png';

import QuoteDetailStyle from './QuoteDetailStyle';


/**
 * @typedef {Object} QuoteDetailProps
 * @property {Object} classes
 * @property {import("shared/react-query/strapiAPI").HCNCategory[]} allCategories
 * @property {import("shared/react-query/strapiAPI").HCNCategory[]} filteredCategories
 * @property {import("shared/react-query/strapiAPI").QuoteEntity[]} quotes
 * @property {string} quoteId
 * @property {string} categoryId
 * @property {Function} handleGetQuoteById
 * @property {Function} handleGetQuotesByCategory
 * @property {Function} onCategoryChange
 */

/**
 * QuoteDetail component
 * @param {QuoteDetailProps} props - component props
 */
const QuoteDetail = (props) => {
  const {
    classes,
    allCategories,
    filteredCategories,
    quotes,
    quoteId: initialQuoteId,
    categoryId: initialCategoryId,
    // handleGetQuoteById,
    handleGetQuotesByCategory,
    onCategoryChange,
  } = props;
  const [slideState, setSlideState] = useState({
    slideInFromLeft: false,
    slideInFromRight: false,
    slideOutToLeft: false,
    slideOutToRight: false,
    slideInFromTop: false,
    slideInFromBottom: false,
    slideOutToTop: false,
    slideOutToBottom: false,
  });

  const [quoteId, setQuoteId] = useState(initialQuoteId);
  const [categoryId, setCategoryCode] = useState(initialCategoryId);

  const toggleSlide = (key) => {
    setSlideState((prevState) => ({
      ...prevState,
      [key]: true,
    }));
    setTimeout(() => {
      setSlideState((prevState) => ({
        ...prevState,
        [key]: false,
      }));
    }, 500);
  };

  const updateGlobalQuoteId = (id) => {
  // setQuoteId(id);
  // handleGetQuoteById(id);
  };

  // useEffect(() => {
  //   handleGetQuoteById(quoteId);
  //   handleGetQuotesByCategory(categoryId);
  // }, [quoteId, categoryId, handleGetQuoteById, handleGetQuotesByCategory]);

  const quote = quotes.find((quote) => quote.id === quoteId);

  const categoryIndex = allCategories.findIndex((cate) => cate.id === quote.category.id);

  return (
    <div className={classes.container}>
      <Grid container alignItems="flex-start">
        <SlidingImgAndVideo {...slideState}>
          <img className={classes.quotesDetailImage} src={quote.cover_image} alt="Quote" />
          <RouterLink to="#">
            <div className={classes.share}>
              <img src={ButterflyIcon} alt="share" />
              <Typography component="p">SHARE</Typography>
            </div>
          </RouterLink>
        </SlidingImgAndVideo>

        <Grid item container xs={12} md={4} direction="column" justifyContent="flex-start" className={classes.sider}>
          <AnimatedArrowGroup
            itemId={quoteId}
            updateGlobalItemId={updateGlobalQuoteId}
            handleGetQuotesByCategory={handleGetQuotesByCategory}
            categoryCode={categoryId}
            allCategories={allCategories}
            filteredCategories={filteredCategories}
            categoryIndex={categoryIndex}
            items={quotes}
            toggleSlide={toggleSlide}
            {...slideState}
            onCategoryChange={onCategoryChange}
          />

          <Divider className={classes.longDivider} />

          <SlidingMediaContent {...slideState}>
            <Typography component="h3" className={classes.quoteContentTitle}>
              {quote.title}
            </Typography>
            <Typography component="p" className={classes.quoteContentText}>
              {quote.content}
            </Typography>

            <Typography component="h4" className={classes.authorTitle}>
              AUTHOR
            </Typography>
            <Typography component="p" className={classes.authorDetail}>
              {quote.author_name}
            </Typography>

            <Divider className={classes.shortDivider} />
            <Typography component="h3" className={classes.quoteSubTitle}>
              {quote.socialMediaTitle}
            </Typography>
            <Grid item container justifyContent="center" className={classes.socialMediaImg}>
              {/* {socialMediaImg} */}
            </Grid>
          </SlidingMediaContent>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(QuoteDetailStyle)(QuoteDetail);
