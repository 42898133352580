import {connect} from 'react-redux';
import {getQuoteById, getQuotesByCategory} from '../../../../store/actions/quotesActions';
import QuoteDetail from './QuoteDetail';

const mapStateToProps = (state) => {
  return {
    quote: state.quotesReducer.quote,
    quotes: state.quotesReducer.quotes,
    serverError: state.quotesReducer.serverError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetQuoteById(id) {
      dispatch(getQuoteById(id));
    },

    handleGetQuotesByCategory(category) {
      dispatch(getQuotesByCategory(category));
    }
  };
};

export default QuoteDetail;
