import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Checkbox, FormControlLabel, Grid, Breadcrumbs, Typography} from '@material-ui/core';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  DoubleArrow as DoubleArrowIcon
} from '@material-ui/icons';
import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';
import styles from './ExpandBarStyles';
import clsx from 'clsx';

import IconPerson from '~/assets/images/content/main/ic_tab_human.png';
import IconEarth from '~/assets/images/content/main/ic_tab_earth.png';

function ExpandBar({nav, subNav, goExpanded, classes, switcher}) {
  return (
    <Grid container className={classes.expandBar} alignItems="center">
      <Grid item style={{flex: 1}}>
        <Breadcrumbs
          className={classes.breadcrumbs}
          separator={<DoubleArrowIcon className={classes.icon} />}
          aria-label="breadcrumb"
        >
          <Typography className={classes.expandBarText} variant="h5">
            {nav}
          </Typography>
          <Typography className={classes.expandBarText} variant="h5">
            {subNav}
          </Typography>
        </Breadcrumbs>
      </Grid>
      {/* <Grid item className={classes.expandBarCheckBox}>
        <FormControlLabel
          className={classes.checkBoxLabel}
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon color="primary" className={classes.checkBoxIcon} />}
              checkedIcon={<CheckBoxIcon className={classes.checkBoxIcon} />}
              color="primary"
              onClick={goExpanded}
            />
          }
          label={<span className={classes.checkBoxLabel}>{intl.get(messageKeys.EXPAND_BAR_EXPAND_LABEL)}</span>}
        />
      </Grid> */}
      {switcher && (
        <Grid item>
          <Grid container alignItems="center" className={classes.switch}>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              className={clsx(classes.switchLeft, {
                [classes.switchActive]: switcher.checkedIndex === 0
              })}
              onClick={() => switcher.checkedIndex === 1 && switcher.onChange()}
            >
              <img src={IconPerson} className={classes.switcherIcon} alt="icon" />
              <Typography>{switcher.items[0]}</Typography>
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              className={clsx(classes.switchRight, {
                [classes.switchActive]: switcher.checkedIndex === 1
              })}
              onClick={() => switcher.checkedIndex === 0 && switcher.onChange()}
            >
              <img src={IconEarth} className={classes.switcherIcon} alt="icon" />
              <Typography>{switcher.items[1]}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default withStyles(styles)(ExpandBar);
