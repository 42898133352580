import React from 'react';
import { withStyles, Typography } from '@material-ui/core';

import TopRow from './TopRow'
import Card from './Card'

import IconButterfly from '~/assets/images/profile/ic-item-business-fav-butterfly.png'

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    background: 'rgba(23, 23, 23, 0.73)',
    padding: '10px',
    boxSizing: 'border-box',
    textAlign: 'center'
  },
  title: {
    marginTop: '10%',
    color: 'white'
  },
  desc: {
    color: 'rgba(255, 255, 255, 0.5)',
    marginTop: '10%',
    minHeight: '30%',
    letterSpacing: '2px'
  },
  iconBottom: {
    marginTop: '10%',
    width: '25px'
  }
});

function QuotesCard(props) {
  const { classes, data } = props;

  const meta = (
    <div className={classes.root}>
      <TopRow
        tag={data.tag.text}
        tagColor={data.tag.color}
      />
      <Typography
        className={classes.title}
        variant="subtitle1">
        {data.title}
      </Typography>
      <Typography
        className={classes.desc}
        variant="body2">
        {data.desc}
      </Typography>
      <img src={IconButterfly}
        className={classes.iconBottom}
        alt="Butterfly" />
    </div>
  )

  return (
    <Card
      hoverShow
      imageSrc={data.background}
      meta={meta}
    />
  )
}

export default withStyles(styles)(QuotesCard);
