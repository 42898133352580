const AnimatedSwitchStyles = () => ({
  switchContainer: {
    width: '70px',
    height: '40px',
    borderRadius: '20px',
    background: '#c6e1fc',
    display: 'flex',
    alignItems: 'center',
    transform: 'scale(0.7)'
  },

  animationBG: {
    width: '70px',
    height: '40px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    background: 'linear-gradient(90deg, #c6e1fc, #fff, #c6e1fc)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50%',
    color: 'rgba(2, 59, 120, 1)',
    animation: '$leftToRight 2s infinite linear'
  },

  switchSlider: {
    background: '#fff',
    width: '38px',
    height: '38px',
    borderRadius: '19px',
    position: 'relative',
    left: '0px',
    zIndex: 60,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1px',
    transition: 'all 0.2s ease-in-out'
  },

  '@keyframes leftToRight': {
    '0%': {
      backgroundPosition: '-200%'
    },

    '100%': {
      backgroundPosition: '200%'
    }
  },
  '@keyframes rightToLeft': {
    '0%': {
      backgroundPosition: '200%'
    },

    '100%': {
      backgroundPosition: '-200%'
    }
  },

  arrowIconRight: {
    transform: 'rotate(90deg)',

    '& div': {
      backgroundColor: '#023B78'
    }
  },

  arrowIconLeft: {
    transform: 'rotate(-90deg)',
    '& div': {
      backgroundColor: '#fff'
    }
  },

  checked: {
    '& $animationBG': {
      animation: '$rightToLeft 2s infinite linear'
    },

    '& $switchSlider': {
      backgroundColor: '#023B78'
    }
  }
});
export default AnimatedSwitchStyles;
