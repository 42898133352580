import {
  LOADING,
  GET_PAGE,
  GET_PAGE_ERROR,
  GET_CATEGORIES,
  GET_CATEGORIES_ERROR,
  GET_VIDEOS,
  GET_MORE_VIDEOS,
  GET_VIDEOS_ERROR,
  GET_VIDEO,
  GET_VIDEO_ERROR
} from '../actions/videosActions';

const initialState = {
  video: {},
  pagination: {},
  videos: [],
  categories: [],
  loading: false,
  serverError: null
};

const videos = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true
      };

    case GET_PAGE:
      return {
        ...state,
        pagination: action.payload,
        loading: false
      };

    case GET_PAGE_ERROR:
      return {
        ...state,
        serverError: action.payload,
        loading: false
      };

    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        loading: false
      };

    case GET_CATEGORIES_ERROR:
      return {
        ...state,
        serverError: action.payload,
        loading: false
      };

    case GET_VIDEOS:
      return {
        ...state,
        videos: action.payload,
        loading: false
      };

    case GET_VIDEOS_ERROR:
      return {
        ...state,
        serverError: action.payload,
        loading: false
      };

    case GET_MORE_VIDEOS:
      return {
        ...state,
        videos: state.videos.concat(action.payload),
        loading: false
      };

    case GET_VIDEO:
      return {
        ...state,
        video: action.payload,
        loading: false
      };

    case GET_VIDEO_ERROR:
      return {
        ...state,
        serverError: action.payload,
        loading: false
      };

    default:
      return state;
  }
};

export default videos;
