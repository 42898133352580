import { Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import FormBuilder from 'shared/formBuilder';
import { register } from 'shared/formStructures/register';
import { useCreateCustomer } from 'shared/react-query/hooks';

const useStyles = makeStyles((theme) => ({
  blueprint: {
    margin: 0,
    width: '100%',
    paddingTop: '20px',
  },
  class1: {
    borderLeft: '1px rgba(0, 0, 0, 0.12) solid',
    borderRight: '1px rgba(0, 0, 0, 0.12) solid',
  },
  registerTitle: {
    fontWeight: '100',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
}));

export default function Register() {
  const classes = useStyles();
  const location = useLocation();
  const firstName = Object.fromEntries(new URLSearchParams(location.search)).firstName ?? '';
  const email = Object.fromEntries(new URLSearchParams(location.search)).email ?? '';
  const modifiedRegister = register(firstName, email);
  const customerCreate = useCreateCustomer();
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      item
      xs={12}
      className={clsx(classes.blueprint, classes.class1)}
    >
      <Grid item xs={12} className={classes.blueprint}>
        <Typography variant="h4" className={classes.registerTitle}>
          BECOME A VISIONAIRE
        </Typography>
      </Grid>
      <Grid container item xs={12} className={classes.blueprint}>
        <FormBuilder
          xs={12}
          spacing={2}
          justify="center"
          structure={modifiedRegister}
          onSubmit={(values) => {
            return customerCreate
              .mutateAsync({
                input: {
                  email: values.email,
                  password: values.password,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  acceptsMarketing: values.tacCheckbox,
                },
              })
              .then((res) => {
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (res.customerCreate.userErrors.length) {
                      resolve({
                        error: res.customerCreate.userErrors.reduce((acc, cv) => {
                          return `${cv.message}` + ` ${acc}`
                        }, '')
                      });
                    } else {
                      resolve({ info: 'Signup successful. You can sign in now.' });
                    }
                  }, 300);
                });
              });
          }}
        />
      </Grid>
    </Grid>
  )
}
