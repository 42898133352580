import React from 'react';
import { withStyles, Box, Typography } from '@material-ui/core';

import TopRow from './TopRow'
import Card from './Card'

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    padding: '15px'
  },
  topRow: {
    padding: 0
  },
  title: {
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'white'
  },
  desc: {
    position: 'absolute',
    bottom: '15px',
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'white',
  }
});

function GuideCard(props) {
  const { classes, data } = props;

  const meta = (
    <div className={classes.root}>
      <Box className={classes.topRow}>
        <TopRow
          tag={data.tag.text}
          tagColor={data.tag.color}
          tagSideIcon={data.tag.icon}
        />
      </Box>

      <Typography
        className={classes.title}
        variant="subtitle1">
        {data.title}
      </Typography>
      <Typography
        className={classes.desc}
        variant="body2">
        {data.desc}
      </Typography>
    </div>
  )

  return (
    <Card
      imageSrc={data.background}
      ratio="141%"
      meta={meta}
    />
  )
}

export default withStyles(styles)(GuideCard);