import initiativeImage1 from '~/assets/images/content/initiatives/1.png';
import initiativeImage2 from '~/assets/images/content/initiatives/2.png';
import initiativeImageLarge2 from '~/assets/images/content/initiatives/2.large.jpg';
import initiativeImage3 from '~/assets/images/content/initiatives/3.png';
import initiativeImage4 from '~/assets/images/content/initiatives/4.png';
import initiativeImage5 from '~/assets/images/content/initiatives/5.png';
import initiativeImage6 from '~/assets/images/content/initiatives/6.png';
import initiativeImage7 from '~/assets/images/content/initiatives/7.png';
import initiativeImage8 from '~/assets/images/content/initiatives/8.png';
import initiativePlanetImage from '~/assets/images/content/initiatives/initiative_planet.png';

const bottomBtnText = 'READ MORE';

export const initiativesPersonData = [
  {
    id: 1,
    title: 'HOMELESS ART',
    titleTop: 'Monday 7PM',
    image: initiativeImage1,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Family & Community',
    categoryId: 1,
    bottombtntext: bottomBtnText
  },
  {
    id: 2,
    title: 'PEARLS OF WISDOM',
    titleTop: 'Tuesday 2PM',
    image: initiativeImage2,
    imageLarge: initiativeImageLarge2,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Family & Community',
    categoryId: 1,
    bottombtntext: bottomBtnText
  },
  {
    card: 'SUSPENDIS_BOX'
  },
  {
    id: 3,
    title: 'SCHOLARSHIP PROGRAM',
    titleTop: 'Tuesday 6PM',
    image: initiativeImage3,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Education',
    categoryId: 4,
    bottombtntext: bottomBtnText
  },
  {
    id: 4,
    title: 'PHILOSOPHER KINGS',
    titleTop: 'Tuesday 8PM',
    image: initiativeImage4,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Philosophy',
    categoryId: 3,
    bottombtntext: bottomBtnText
  },
  {
    id: 5,
    title: 'ARTIST EVENING',
    titleTop: 'Friday 6PM',
    image: initiativeImage5,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Family & Community',
    categoryId: 2,
    bottombtntext: bottomBtnText
  },
  {
    id: 6,
    title: 'DOCUMENTARY EVENING',
    titleTop: 'Thursday 8PM',
    image: initiativeImage6,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Politics/Law',
    categoryId: 2,
    bottombtntext: bottomBtnText
  },
  {
    id: 7,
    title: 'ORGANIC SUSTAINABLE',
    titleTop: 'Tuesday 6PM',
    image: initiativeImage7,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Health / Economy',
    categoryId: 4,
    bottombtntext: bottomBtnText
  },
  {
    id: 8,
    title: 'PLANTO FRANCHISE SYSTEM',
    titleTop: 'Tuesday 8PM',
    image: initiativeImage8,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Economy',
    categoryId: 1,
    bottombtntext: bottomBtnText
  }
];

export const initiativesPlanetData = [
  {
    id: 1,
    title: 'GLOBAL CITIZEN',
    image: initiativePlanetImage,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Humanity / Media',
    categoryId: 2,
    bottombtntext: bottomBtnText
  },
  {
    id: 2,
    title: 'SUNDAY INCREASE',
    image: initiativePlanetImage,
    imageLarge: initiativeImageLarge2,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Media / Self / Humanity',
    categoryId: 1,
    bottombtntext: bottomBtnText
  },
  {
    card: 'SUSPENDIS_BOX'
  },
  {
    id: 3,
    title: 'GLOBAL PRAY',
    image: initiativePlanetImage,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Religion / Media',
    categoryId: 3,
    bottombtntext: bottomBtnText
  },
  {
    id: 4,
    title: 'E-DEMOCRACY',
    image: initiativePlanetImage,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Media / Law / Politics...',
    categoryId: 1,
    bottombtntext: bottomBtnText
  },
  {
    id: 5,
    title: 'GLOBAL PRODUCE MODEL',
    image: initiativePlanetImage,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Economy / Business / Health',
    categoryId: 4,
    bottombtntext: bottomBtnText
  },
  {
    id: 6,
    title: 'VISION CITY',
    image: initiativePlanetImage,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Family & Community',
    categoryId: 3,
    bottombtntext: bottomBtnText
  },
  {
    id: 7,
    title: 'VISION PRODUCTS',
    image: initiativePlanetImage,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Business',
    categoryId: 2,
    bottombtntext: bottomBtnText
  },
  {
    id: 8,
    title: 'GLOBAL ELEVATION INDICATOR',
    image: initiativePlanetImage,
    content:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.',
    type: 'Politics',
    categoryId: 2,
    bottombtntext: bottomBtnText
  }
];

export const tableTitles = [
  {id: 1, text: 'VITALITY & LONGEVITY'},
  {id: 2, text: 'WISDOM & SUCCESS'},
  {id: 3, text: 'EMPOWERMENT & SIGNIFICANCE'},
  {id: 4, text: 'CLARITY & BEAUTY'}
];

export default null;
