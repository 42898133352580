// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { InstagramGallery } from '~/components/ui';
import ArticleContent from './Sections/ArticleContent';
import AddComment from './Sections/AddComment';
import ReaderComments from './Sections/ReaderComments';
import ArticleAuthor from './Sections/ArticleAuthor';

import styles from './ArticleDetailsPageStyles';
import AuthorImage from '~/assets/images/profile/temp-photos/IMG_4964.jpg';

import InstagramImage1 from '~/assets/images/content/articles/instagram-gallery/1.jpg';
import InstagramThumbnailImage1 from '~/assets/images/content/articles/instagram-gallery/t-1.jpg';
import InstagramImage2 from '~/assets/images/content/articles/instagram-gallery/2.jpg';
import InstagramThumbnailImage2 from '~/assets/images/content/articles/instagram-gallery/t-2.jpg';
import InstagramImage3 from '~/assets/images/content/articles/instagram-gallery/3.jpg';
import InstagramThumbnailImage3 from '~/assets/images/content/articles/instagram-gallery/t-3.jpg';
import InstagramImage4 from '~/assets/images/content/articles/instagram-gallery/4.jpg';
import InstagramThumbnailImage4 from '~/assets/images/content/articles/instagram-gallery/t-4.jpg';
import video from './../../../../assets/videos/darkChocolate.mp4';
import LatestPosts from './Sections/LatestPosts';
import { Route, Router, Switch, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from 'react-query';
import { fetchArticle } from 'shared/react-query/strapiAPI';
import NotFound from './../../../../pages/NotFound';
import spinner from '~/assets/images/spinner-loading-dots.svg';
import { useFetchPublicProfile } from 'shared/react-query/hooks-user-profile';

const LoadingSpinner = () => {
  const spinnerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh' // Full viewport height
  };

  return (
    <div style={spinnerStyle}>
      <img src={spinner} alt="Loading..." />
    </div>
  );
};

const useStyles = makeStyles(styles);
const formatDate = (utcString) => {
  const date = new Date(utcString);
  const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString('en-US', options); // Converts to a format similar to toDateString()
};

const ArticleDetailsPage = () => {

  const { id: slug } = useParams()

  const { data, isLoading } = useQuery(['article', slug], () => fetchArticle(slug),
    {
      enabled: !!slug,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    });

  const { data: publicProfileData } = useFetchPublicProfile(data?.data[0]?.attributes?.visionaire?.data.attributes.slug);

  // TODO: 1. Render loading spinner when isLoading true
  // 2. if article is fetched then render it.
  // 3. if article is not returned then redirect user to 404 page.

  const classes = useStyles();

  // Accessing the attributes
  if (isLoading === false && data.data.length === 0) {
    return (
      <Router>
        <Switch>
          {/* Other routes can go here */}
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
  if (isLoading === false && data && data.data && data.data[0]) {
    const firstItem = data.data[0];
    const { attributes } = firstItem;
    const { Body, publishedAt, title, carousel, hcn_article_category } = attributes;

    const articleData =
    {
      id: '1',
      title: title,
      publishDate: formatDate(publishedAt),
      body: Body,
      coverImage: carousel.data[0].attributes.url,
      summaryPoints: [
        `Dark chocolate is a valuable superfood: With a broad range of antioxidants and minerals that help enhance various areas of health: Cell and heart health, cognitive function, balancing the immune system, boosting athletic
      performance, reducing stress, enhancing mood, and even helping protect the
      skin from sun damage.`,
        `Heavy metals: Unfortunately contains levels of two dangerous heavy metals; cadmium and lead.`,
        `Cadmium: The cacao plants take up cadmium from the soil. Cadmium can cause a variety of adverse health outcomes, including endocrine disruption, osteoporosis, and has been classified as a carcinogen.`,
        `Lead: Most contamination seems to accumulate during ArticleContenting,
      manufacturing, and shipping of the beans. Lead exposure can damage the
      nervous system, kidneys, and reproductive organs. It is particularly harmful to
      children, leading to developmental delays, learning difficulties, and
      behavioural problems.`,
        `Upper limits: Only consume up to 100 grams per week, or 14 grams per day – to maximize its health benefits, while limiting exposure to cadmium and lead, as well as sugar.`,
        `Utilise detoxifying foods: Such as broccoli, garlic, green tea, yogurt, ginger,
      grapes, and blueberries, which can help mitigate the effects of heavy metal
      exposure.`,
        `Essential minerals: Many studies have shown that a deficiency in essential
      metals such as zinc, calcium, or iron can lead to greater absorption and
      toxicity of Cd and Pb. Hence, a diet rich in such essential minerals may also
      provide protective effects against Cd and Pb toxicity.`
      ],
      video: video,
      tags: ['HERBS', 'SUCCESS', 'HEALTH'],
      likesCount: 120,
      favoritesCount: 609,
      commentsCount: 120,
      category: {
        id: hcn_article_category.data.id,
        title: hcn_article_category.data.attributes.title,
        parentId: hcn_article_category.data.attributes.Parent.data.id,
        color: hcn_article_category.data.attributes.Parent.data.attributes.color,
      }
    };

    // const author = {
    //   authorName: 'Kim Larking',
    //   authorImage: AuthorImage,
    //   authorIntro: `Human advancement researcher and architect

    // I help people empower themselves - to live their best life.`
    // };
    const comments = [
      {
        authorName: 'JANE DOE',
        authorImage: AuthorImage,
        publishDate: '20 June, 2017',
        content: `Quisque sit amet condimentum orci. Etiam venenatis quam quis augue scelerisque interdum. Mauris lectus magna, congue ac laoreet id, imperdiet et purus. Suspendisse placerat quam eu est porttitor, eget dapibus mauris laoreet. Vivamus commodo pulvinar arcu at ullamcorper. Sed sit amet sapien quis lacus mattis tempus. Nam gravida accumsan neque, nec suscipit est maximus vitae.`
      },
      {
        authorName: 'JANE DOE',
        authorImage: AuthorImage,
        publishDate: '20 June, 2017',
        content: `Quisque sit amet condimentum orci. Etiam venenatis quam quis augue scelerisque interdum. Mauris lectus magna, congue ac laoreet id, imperdiet et purus. Suspendisse placerat quam eu est porttitor, eget dapibus mauris laoreet. Vivamus commodo pulvinar arcu at ullamcorper. Sed sit amet sapien quis lacus mattis tempus. Nam gravida accumsan neque, nec suscipit est maximus vitae.`
      },
      {
        authorName: 'JANE DOE',
        authorImage: AuthorImage,
        publishDate: '20 June, 2017',
        content: `Quisque sit amet condimentum orci. Etiam venenatis quam quis augue scelerisque interdum. Mauris lectus magna, congue ac laoreet id, imperdiet et purus. Suspendisse placerat quam eu est porttitor, eget dapibus mauris laoreet. Vivamus commodo pulvinar arcu at ullamcorper. Sed sit amet sapien quis lacus mattis tempus. Nam gravida accumsan neque, nec suscipit est maximus vitae.`
      }
    ];

    const posts = [
      {
        title: 'SAMPLE TEXT 1',
        thumbnail: InstagramThumbnailImage1,
        category: 'VITALITY & LONGEVITY',
        content: `Lorem ipsum dolor sit amet, consectetur 
    adipiscing elit. Nulla purus velit, adipiscing elit. Nulla purus velit,adipiscing elit. Nulla purus velit,adipiscing elit. Nulla purus velit,adipiscing elit. Nulla purus velit,`,
        source: 'PERSON'
      },
      {
        title: 'SAMPLE TEXT 2',
        thumbnail: InstagramThumbnailImage2,
        category: 'EMPOWERMENT & SIGNIFICANCE',
        content: `Lorem ipsum dolor sit amet, consectetur 
    adipiscing elit. Nulla purus velit, `,
        source: 'PLANET'
      },
      {
        title: 'SAMPLE TEXT 3',
        thumbnail: InstagramThumbnailImage3,
        category: 'CLARITY & BEAUTY',
        content: `Lorem ipsum dolor sit amet, consectetur 
    adipiscing elit. Nulla purus velit, `,
        source: 'PERSON'
      }
    ];
    const images = [
      { thumbnail: InstagramThumbnailImage1, image: InstagramImage1, link: '' },
      { thumbnail: InstagramThumbnailImage2, image: InstagramImage2, link: '' },
      { thumbnail: InstagramThumbnailImage3, image: InstagramImage3, link: '' },
      { thumbnail: InstagramThumbnailImage4, image: InstagramImage4, link: '' }
    ];

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Box className={classes.container}>
            <Box className={classes.article}>
              <ArticleContent article={articleData} />
              <ReaderComments comments={comments} />
              <AddComment />
            </Box>
            <Box className={classes.sideContainer}>
              <ArticleAuthor author={publicProfileData} />
              <LatestPosts posts={posts} />
              <InstagramGallery images={images} />
            </Box>
          </Box>
        </div>
      </div>
    );
  }
  else {
    return (
      <LoadingSpinner />
    );
  }
};

export default ArticleDetailsPage;
