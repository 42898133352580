import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles, Box} from '@material-ui/core';
import {SuspendisBox, MoreCardBox, FlowGrid} from '~/components/ui';
import GuidesCardBox from './GuidesCardBox';
import Filter from './Filter';

import styles from './GuidesMainExpandedStyles';

const useStyles = makeStyles(styles);

const GuidesMainExpanded = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const [columnCount, setColumnCount] = useState(4);
  const {data, typeListData, selectIndex, onFilterItemClick} = props;

  const getGuidesData = () => {
    return data.slice(0, 7).concat([{card: 'MORE_GUIDES', content: 'LOAD MORE REGIONAL GUIDES'}]);
  };

  const onColumnChange = (newCount) => {
    if (columnCount !== newCount) {
      setColumnCount(newCount);
    }
  };

  return (
    <>
      <Box className={classes.root}>
        <Filter
          background="#434343"
          itemList={typeListData}
          selectIndex={selectIndex}
          handleItemClick={onFilterItemClick}
          columnCount={columnCount}
        />
        <FlowGrid data={getGuidesData()} maxColumns={4} cardHeight="141%" onSetColumnsCount={onColumnChange}>
          {(v) => {
            let card = null;
            const goDetailPage = () => {
              history.push({
                pathname: `/content/guides/detail`,
                itemData: v
              });
            };
            if (v.id) {
              card = <GuidesCardBox item={v} goDetail={props.goDetail} handleClick={goDetailPage} />;
            } else if (v.card === 'SUSPENDIS_BOX') {
              card = (
                <Box className={classes.cardContainer}>
                  <Box className={classes.card}>
                    <Box className={classes.suspendisBox}>
                      <SuspendisBox desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tempus enim eu eros molestie, eget porta tellus fermentum. Pellentesque in ultricies tortor, ac fringilla libero. Nullam id sem a ipsum vulputate porta sed eget velit. " />
                    </Box>
                  </Box>
                </Box>
              );
            } else if (v.card === 'MORE_GUIDES') {
              card = (
                <Box className={classes.cardContainer}>
                  <Box className={classes.card}>
                    <MoreCardBox content={v.content} />
                  </Box>
                </Box>
              );
            }
            return card;
          }}
        </FlowGrid>
      </Box>
    </>
  );
};

export default GuidesMainExpanded;
