import React from 'react';
import {withStyles, Grid} from '@material-ui/core';

import MediaCard from './MediaCard';

const styles = (_) => ({
  subtitle: {
    width: '100%',
    fontSize: '16px',
    padding: '0 4px',
    cursor: 'pointer'
  },
  imageItem: {},
  bottomNavWrapper: {
    width: '100%',
    textAlign: 'right'
  },
  bottomNav: {
    color: '#0079E2',
    fontSize: '16px',
    cursor: 'pointer'
  }
});

function MediaGridCard(props) {
  const {category, classes, expand, showCategory, goBack} = props;
  return (
    <Grid item container md={expand ? 12 : 6} spacing={1}>
      <div className={classes.subtitle} onClick={goBack}>
        {expand ? '← All' : category.title}
      </div>
      {category.mediaList.map((mediaItem) => (
        <Grid item xs={12} sm={6} md={3} className={classes.imageItem}>
          <MediaCard media={mediaItem} shortDesc={!expand} />
        </Grid>
      ))}
      {expand ? null : (
        <div className={classes.bottomNavWrapper}>
          <span className={classes.bottomNav} onClick={() => showCategory(category)}>
            >>
          </span>
        </div>
      )}
    </Grid>
  );
}

export default withStyles(styles)(MediaGridCard);
