import React from 'react';
import IntroScene from './IntroScene';

import Background from '~/assets/images/intro/metaWheel/scene9_bg.png';

function Scene9(props) {
  return (
    <IntroScene
      background={Background}
      ratio={0.476}
      container
      titleList={[
        {text: 'Create ', highlight: true},
        'your ',
        {text: ' Desired Being ', highlight: true},
        'using all of the Sanctuaries services in the most advanced wellness centre on earth  and experience your physical, mental, emotional and spiritual magnificence. '
      ]}
      descList={[
        '',
        '',
        '',
        'Whether online or at the Sanctuary, activate your Recommendation Schedule in a highly conducive environment for personal advancement. Be a powerful creator and chisel out the destiny you have determined. ',
        '',
        ''
      ]}
    ></IntroScene>
  );
}

export default Scene9;
