import Image1 from '~/assets/images/content/guides/1.png';

import IconPlanet from '~/assets/images/profile/ic-item-business-fav-planet.png';
import IconHuman from '~/assets/images/profile/ic-item-business-fav-human.png';

export const guidesPlanetData = [
  {
    id: 1,
    tag: {
      color: '#4F91E9',
      text: 'PROSPERITY',
      icon: IconPlanet
    },
    type: 'planet',
    title: 'Auctor lectus proin',
    desc: 'True Democracy',
    background: Image1
  },
  {
    id: 2,
    tag: {
      color: '#BC79BC',
      text: 'INSPIRATION',
      icon: IconPlanet
    },
    type: 'planet',
    title: 'Diam ipsum tristique',
    desc: 'Net Neutrality',
    background: Image1
  },
  {
    card: 'SUSPENDIS_BOX'
  },
  {
    id: 3,
    tag: {
      color: '#7ECF79',
      text: 'HEALTH',
      icon: IconPlanet
    },
    type: 'planet',
    title: 'Auctor lectus proin',
    desc: 'Diversity Protection',
    background: Image1
  },
  {
    id: 4,
    tag: {
      color: '#7ECF79',
      text: 'HEALTH',
      icon: IconPlanet
    },
    type: 'planet',
    title: 'Auctor lectus proin',
    desc: 'Diversity Protection',
    background: Image1
  },
  {
    id: 5,
    tag: {
      color: '#4F91E9',
      text: 'PROSPERITY',
      icon: IconPlanet
    },
    type: 'planet',
    title: 'Auctor lectus proin',
    desc: 'True Democracy',
    background: Image1
  },
  {
    id: 6,
    tag: {
      color: '#BC79BC',
      text: 'INSPIRATION',
      icon: IconPlanet
    },
    type: 'planet',
    title: 'Diam ipsum tristique',
    desc: 'Net Neutrality',
    background: Image1
  },
  {
    card: 'SUSPENDIS_BOX'
  },
  {
    id: 7,
    tag: {
      color: '#7ECF79',
      text: 'HEALTH',
      icon: IconPlanet
    },
    type: 'planet',
    title: 'Auctor lectus proin',
    desc: 'Diversity Protection',
    background: Image1
  }
];

export const guidesPersonData = [
  {
    id: 1,
    tag: {
      color: '#4F91E9',
      text: 'PROSPERITY',
      icon: IconHuman
    },
    type: 'person',
    title: 'Auctor lectus proin',
    desc: 'True Democracy',
    background: Image1
  },
  {
    id: 2,
    tag: {
      color: '#BC79BC',
      text: 'INSPIRATION',
      icon: IconHuman
    },
    type: 'person',
    title: 'Diam ipsum tristique',
    desc: 'Net Neutrality',
    background: Image1
  },
  {
    card: 'SUSPENDIS_BOX'
  },
  {
    id: 3,
    tag: {
      color: '#7ECF79',
      text: 'HEALTH',
      icon: IconHuman
    },
    type: 'person',
    title: 'Auctor lectus proin',
    desc: 'Diversity Protection',
    background: Image1
  },
  {
    id: 4,
    tag: {
      color: '#7ECF79',
      text: 'HEALTH',
      icon: IconHuman
    },
    type: 'person',
    title: 'Auctor lectus proin',
    desc: 'Diversity Protection',
    background: Image1
  },
  {
    id: 5,
    tag: {
      color: '#4F91E9',
      text: 'PROSPERITY',
      icon: IconHuman
    },
    type: 'person',
    title: 'Auctor lectus proin',
    desc: 'True Democracy',
    background: Image1
  },
  {
    id: 6,
    tag: {
      color: '#BC79BC',
      text: 'INSPIRATION',
      icon: IconHuman
    },
    type: 'person',
    title: 'Diam ipsum tristique',
    desc: 'Net Neutrality',
    background: Image1
  },
  {
    card: 'SUSPENDIS_BOX'
  },
  {
    id: 7,
    tag: {
      color: '#7ECF79',
      text: 'HEALTH',
      icon: IconHuman
    },
    type: 'person',
    title: 'Auctor lectus proin',
    desc: 'Diversity Protection',
    background: Image1
  }
];

export const typeListData = [
  {
    text: 'Bio-Interdependence',
    type: 'HEALTH'
  },
  {
    text: 'Inter-generational equity',
    type: 'INSPIRATION'
  },
  {
    text: 'Unification and Belonging',
    type: 'JOY'
  },
  {
    text: 'Spiritual authenticity',
    type: 'PROSPERITY'
  }
];
