export const styles = (theme) => ({
  suspendisBox: {
    alignSelf: 'center',
    [theme.breakpoints.only('xs')]: {
      margin: '1em auto'
    }
  },
  contentQA: {
    background: 'rgba(255,255,255,0.15)',
    border: 'solid 1px rgb(255,255,255)',
    color: 'white'
  },
  contentGuide: {
    background: 'rgba(255,255,255,0.15)',
    border: 'solid 1px rgb(255,255,255)',
    color: 'white'
  },
  qaHeading: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    letterSpacing: '4px'
  },
  shareVideo: {
    alignSelf: 'center',
    padding: '2%',
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      margin: '2em auto'
    }
  }
});
