import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Box, Typography, CardMedia, Button} from '@material-ui/core';
import IconPerson from '~/assets/images/content/main/ic_tab_human.png';
import IconEarth from '~/assets/images/content/main/ic_tab_earth.png';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 16,
    marginRight: 0,
    marginLeft: 0,
    textTransform: 'none',
    width: '100%'
  },
  container: {
    width: '100%',
    display: 'flex'
  },
  media: {
    width: 75,
    height: 75,
    marginLeft: 11
  },
  mediaContainer: {
    height: 75,
    position: 'relative',
    '&::before': {
      content: '" "',
      borderRadius: 5,
      position: 'absolute',
      top: 0,
      bottom: 0,
      backgroundColor: (props) => props.color,
      width: 5
    }
  },
  contentContainer: {
    marginLeft: 8,
    marginBottom: 6,
    flexGrow: 1
  },
  title: {
    opacity: 0.58,
    letterSpacing: 2.4
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 6
  },
  content: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  }
}));

const CategoryColorMap = {
  'VITALITY & LONGEVITY': '#56B701',
  'WISDOM & SUCCESS': '#4F91E9',
  'EMPOWERMENT & SIGNIFICANCE': '#FD22FE',
  'CLARITY & BEAUTY': '#D2A75F'
};

const getColorFromCategory = (category) => {
  return CategoryColorMap[category] || '#F8C600';
};

const Post = ({post}) => {
  const {thumbnail, title, content, category, source} = post;
  const classes = useStyles({color: getColorFromCategory(category)});
  const sourceIcon = source === 'PERSON' ? IconPerson : IconEarth;

  return (
    <Button className={classes.root} component="a">
      <div className={classes.container}>
        <Box className={classes.mediaContainer}>
          <CardMedia src={thumbnail} component="img" className={classes.media} />
        </Box>
        <Box className={classes.contentContainer}>
          <Grid container justifyContent="space-between">
            <Typography className={classes.title} variant="subtitle1">
              {title}
            </Typography>
            <img src={sourceIcon} alt={source} className={classes.icon} />
          </Grid>
          <Typography className={classes.content} variant="body2">
            {content}
          </Typography>
        </Box>
      </div>
    </Button>
  );
};

export default Post;
