import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import auth from './auth';
import profileReducer from './profileReducer';
import quotesReducer from './quotesReducer';
import videosReducer from './videosReducer';

const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    profileReducer,
    quotesReducer,
    videosReducer
  });

export default reducer;
