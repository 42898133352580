import seaBG from '~/assets/images/content/creationCompass/compassBackground.jpg';
import compassBG from '~/assets/images/content/creationCompass/compass.png';

const styles = (theme) => ({
  compassImgContainer: {
    width: '80%',
    backgroundImage: 'url(' + compassBG + ')',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    padding: '48px 0px 48px 48px'
  },
  header: {
    color: 'white',
    textTransform: 'uppercase',
    letterSpacing: '0.8rem',
    [theme.breakpoints.only('md')]: {
      fontSize: '2rem'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '1.8rem'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.6rem'
    }
  },
  bodyContainer: {
    width: '100%',
    minHeight: 'calc(100vh - 53.4px)',
    backgroundImage: 'url(' + seaBG + ')',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    padding: '0'
  },
  gridContainer: {
    width: '90%',
    padding: '0px 0px 100px 0px'
  }
});

export default styles;
