// @ts-check
import * as React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import { ExpandBar, ToggleContentTitles } from "~/components/ui";
import styles from "./QuotesMainStyles";
import MyFeed, { useCategoryFilter } from "~/components/layout/MyFeed/index";
import { fetchQuotesByCategoryIds } from "shared/react-query/strapiAPI";
import ShadableCard from "./ShadableCard";
import DisplayContentItems from "../../contentData/DisplayContentItems";
import { useState } from "react";

const PERSON = "person";
const PLANET = "planet";

const customStylesMyFeed = (theme) => ({
  subCategoryContainer: {
    backgroundColor: 'transparent',
  },
  subCategoryButton: {
  },
});

const MyFeedCustomStyles = withStyles(customStylesMyFeed)(MyFeed);

const customStylesArticles = (theme) => ({
  loading: {
    color: "white",
  }
});

const CustomStylesDisplayContentItems = withStyles(customStylesArticles)(DisplayContentItems);

const useStyles = makeStyles(styles);

const QuotesMain = (props) => {
  const classes = useStyles(props);
  const {
    onTitlesChange,
    noMoreQuotes,
    onDataFetch,
    onCategoriesChange,
  } = props;

  const {
    allCategories,
    selectedCategoryIds, setSelectedCategoryIds,
    selectedCategories,
    mode, setMode,
  } = useCategoryFilter();

  const [categories, setCategories] = useState(null);

  const onCategoriesChangeHandler = (selectedCategories) => {
    setCategories(selectedCategories);
    onCategoriesChange(selectedCategories);
  };

  const handleSwitch = () => {
    const nextMode = mode === PERSON ? PLANET : PERSON;
    setMode(nextMode);
  }

  return (
    <Container className={classes.root}>
      <ExpandBar
        nav="Quotes"
        subNav={mode}
        goExpanded={props.goExpanded}
        switcher={{
          items: ["Person", "Planet"],
          checkedIndex: mode === "planet" ? 1 : 0,
          onChange: handleSwitch,
        }}
      />
      <ToggleContentTitles
        selectedCategories={selectedCategories}
        onChange={onTitlesChange}
      />
      <MyFeedCustomStyles allCategories={allCategories.data ?? []} mode={mode}
        selectedCategoryIds={selectedCategoryIds}
        onCategoriesChange={setSelectedCategoryIds} />

      {noMoreQuotes ? (
        <Grid container justifyContent="center">
          <Typography component="p" className={classes.noMoreQuotes}>
            No More Quotes
          </Typography>
        </Grid>
      ) : (
        <CustomStylesDisplayContentItems
          allCategories={allCategories.data ?? []}
          filteredCategories={selectedCategories}
          goDetail={props.goDetail}
          // how to fetch data
          dataName="videos"
          fetcher={fetchQuotesByCategoryIds}
          onDataFetch={onDataFetch}
          // how to display data
          itemComponent={ShadableCard}
        />)}
    </Container>
  );
};

export default QuotesMain;
