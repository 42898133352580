import React, { useState } from 'react';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { withStyles, Grid, Divider } from '@material-ui/core';

import SlidingImgAndVideo from '~/components/ui/SlidingImgAndVideo/SlidingImgAndVideo';
import AnimatedArrowGroup from '~/components/ui/AnimatedArrowGroup/AnimatedArrowGroup';
import SlidingMediaContent from '~/components/ui/SlidingMediaContent/SlidingMediaContent';

import ButterflyIcon from '~/assets/images/icons/share_btn.png';

import VideoData from '~/data/cardVideoData';

import VideoDetailStyle from './VideoDetailStyle';

const VideoDetail = ({ classes, allCategories, filteredCategories, videos, categoryId, videoId: initialVideoId,
  onCategoryChange,
}) => {
  const [slideState, setSlideState] = useState({
    slideInFromLeft: false,
    slideInFromRight: false,
    slideOutToLeft: false,
    slideOutToRight: false,
    slideInFromTop: false,
    slideInFromBottom: false,
    slideOutToTop: false,
    slideOutToBottom: false,
  });

  const [videoId, setVideoId] = useState(initialVideoId);

  const toggleSlide = (key) => {
    setSlideState((prevState) => ({
      ...prevState,
      [key]: true,
    }));
    setTimeout(() => {
      setSlideState((prevState) => ({
        ...prevState,
        [key]: false,
      }));
    }, 500);
  };

  const updateGlobalVideoId = (id) => {
    setVideoId(id);
  };

  const newVideos = videos.map((video) => ({
    ...VideoData[0],
    id: video.id,
    title: video.title,
    content: video.content,
    videoUrl: video.videoUrl,
    category: video.category,
  }));
  const videoIndex = newVideos.findIndex((video) => video.id === videoId);

  const detailInfo = newVideos[videoIndex].detailInfo?.map((item) => (
    <section key={item.id}>
      <Typography component="h4" className={classes.detailInfoTitle}>
        {item.title}
      </Typography>
      <Typography component="p" className={classes.detailInfoDescription}>
        {item.description}
      </Typography>
    </section>
  ));

  const socialMediaImg = newVideos[videoIndex].socialImageURLs?.map((img) => (
    <img src={img.url} key={img.id} alt={img.id} />
  ));

  return (
    <div className={classes.container}>
      <Grid container alignItems="center">
        <SlidingImgAndVideo
          slideInFromLeft={slideState.slideInFromLeft}
          slideInFromRight={slideState.slideInFromRight}
          slideOutToLeft={slideState.slideOutToLeft}
          slideOutToRight={slideState.slideOutToRight}
          slideInFromTop={slideState.slideInFromTop}
          slideInFromBottom={slideState.slideInFromBottom}
          slideOutToTop={slideState.slideOutToTop}
          slideOutToBottom={slideState.slideOutToBottom}
        >
          <video className={classes.video} controls src={newVideos[videoIndex].videoUrl} />
          <RouterLink to="#">
            <div className={classes.share}>
              <img src={ButterflyIcon} alt="share" />
              <Typography component="p">SHARE</Typography>
            </div>
          </RouterLink>
        </SlidingImgAndVideo>

        <Grid item container xs={12} md={4} direction="column" justifyContent="flex-start" className={classes.sider}>
          <AnimatedArrowGroup
            items={newVideos}
            itemId={videoId}
            updateGlobalItemId={updateGlobalVideoId}
            allCategories={allCategories}
            filteredCategories={filteredCategories}
            categoryId={categoryId}
            toggleSlide={toggleSlide}
            slideInFromLeft={slideState.slideInFromLeft}
            slideInFromRight={slideState.slideInFromRight}
            slideOutToLeft={slideState.slideOutToLeft}
            slideOutToRight={slideState.slideOutToRight}
            onCategoryChange={onCategoryChange}
          />

          <Divider className={classes.longDivider} />

          <SlidingMediaContent
            slideInFromLeft={slideState.slideInFromLeft}
            slideInFromRight={slideState.slideInFromRight}
            slideOutToLeft={slideState.slideOutToLeft}
            slideOutToRight={slideState.slideOutToRight}
            slideInFromTop={slideState.slideInFromTop}
            slideInFromBottom={slideState.slideInFromBottom}
            slideOutToTop={slideState.slideOutToTop}
            slideOutToBottom={slideState.slideOutToBottom}
          >
            <Typography component="h3" className={classes.videoDetailTitle}>
              {newVideos[videoIndex].title}
            </Typography>
            <Typography component="p" className={classes.videoDetailText}>
              {newVideos[videoIndex].content}
            </Typography>

            {/* {detailInfo} */}
            {/*
            <Divider className={classes.shortDivider} />
            <Typography component="h3" className={classes.socialMediaTitle}>
              {newVideos[videoIndex].socialMediaTitle}
            </Typography>
            <Grid item container justifyContent="center" className={classes.socialMediaImg}>
              {socialMediaImg}
            </Grid> */}
          </SlidingMediaContent>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(withStyles(VideoDetailStyle)(VideoDetail));
