// @ts-check
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { makeStyles } from "@material-ui/core/styles";
import { FlowGrid } from "~/components/ui";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    textAlign: "center",
    aspectRatio: "1/1",
    color: theme.palette.text.secondary,
  },
  loading: {
    padding: 20,
  }
}));

/**
 * @typedef {Object} DisplayArticlesProps
 * @property {Object} classes
 * @property {import("shared/react-query/strapiAPI").HCNCategory[]} allCategories
 * @property {import("shared/react-query/strapiAPI").HCNCategory[]} filteredCategories
 * @property {Function} goDetail
 * @property {string} dataName
 * @property {Function} fetcher
 * @property {Function?} onDataFetch
 * @property {React.ComponentType<{item: any, category: import("shared/react-query/strapiAPI").HCNCategory, handleClick: Function}>} itemComponent
 */

/**
 * @param {DisplayArticlesProps} props
 */
const DisplayContentItems = (props) => {
  const { allCategories, filteredCategories, dataName, fetcher, itemComponent, onDataFetch } = props;
  const classes = useStyles();

  //* fetch articles based on the selected category or sub category
  let filteredArticles = useQuery([dataName, filteredCategories?.map(cat => cat.id).join("-")], () =>
    fetcher(filteredCategories?.map(cat => cat.id), 1),
    {
      enabled: !!filteredCategories && filteredCategories.length > 0 && !!allCategories && allCategories.length > 0,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    }
  );

  useEffect(() => {
    if (filteredArticles.isSuccess) {
      if (onDataFetch) {
        onDataFetch(filteredArticles.data);
      }
    }
  }, [filteredArticles.data]);

  if (filteredArticles.isLoading) {
    return (
      <Typography className={clsx(classes.loading, props.classes?.loading)} variant="body1">Loading</Typography>
    );
  }

  if (filteredArticles?.data?.length == 0) {
    return (
      <Typography className={clsx(classes.loading, props.classes?.loading)} variant="body1">Empty</Typography>
    );
  }

  //* If there's any category/subcategory is selected, display articles wrt selection.
  //* else, display all articles from every category.
  const CardContent = itemComponent;
  return (
    <div style={{ flexDirection: "column", display: "flex", gap: 4 }}>
      <FlowGrid data={!filteredArticles?.data ? [] : filteredArticles?.data} cardHeight="200%">
        {(article) => {
          let card = null;
          let category = allCategories.find((cat) => cat.id === article.category.id);
          if (article.id) {
            card = <CardContent item={article} category={category} handleClick={props.goDetail} />;
          }
          return card;
        }}
      </FlowGrid>
    </div>
  )
};

export default DisplayContentItems;
