import React from 'react';
import IntroScene from './IntroScene';

import Background from '~/assets/images/intro/metaWheel/scene4_bg.png';

function Scene4(props) {
  return (
    <IntroScene
      background={Background}
      ratio={0.421}
      container
      right
      titleList={[
        'Identify the',
        {text: ' Shift ', highlight: true},
        'areas in your life that you wish to embark upon'
      ]}
      descList={[
        '',
        '',
        '',
        '',
        'Become self empowered through constructive choose; identify for yourself the blockages once removed, and your inherent key strengths, that have the potential to catalyse a transformational Shift to greater fulfilment.',
        '',
        '',
        ''
      ]}
    ></IntroScene>
  );
}

export default Scene4;
