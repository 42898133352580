import React from 'react';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';
import {TransitionGroup as ReactCSSTransitionGroup} from 'react-transition-group';

import Image1 from '~/assets/images/intro/metaWheel/scene5_1.png';
import Image2 from '~/assets/images/intro/metaWheel/scene5_2.png';
import Image3 from '~/assets/images/intro/metaWheel/scene5_3.png';
import Image4 from '~/assets/images/intro/metaWheel/scene5_4.png';
import Image5 from '~/assets/images/intro/metaWheel/scene5_5.png';

const $defaultHeight = 80;
const $defaultWidth = 35;

const $level2HeightVal = $defaultHeight - 8;
const $level2WidthVal = $defaultWidth - 8;
const $level1HeightVal = $defaultHeight - 2;
const $level1WidthVal = $defaultWidth - 2;
const $level0HeightVal = $defaultHeight + 5;
const $level0WidthVal = $defaultWidth + 5;

const $level2Height = $level2HeightVal + '%';
const $level2Width = $level2WidthVal + '%';
const $level1Height = $level1HeightVal + '%';
const $level1Width = $level1WidthVal + '%';
const $level0Height = $level0HeightVal + '%';
const $level0Width = $level0WidthVal + '%';

const $level_2Left = '25%';
const $level_1Left = '35%';
const $level0Left = '50%';
const $level1Left = '65%';
const $level2Left = '75%';
const $level_2Bs = '-.1em .1em .2em #212121';
const $level_1Bs = '-.2em .2em .4em #212121';
const $level2Bs = '.1em .1em .2em #212121';
const $level1Bs = '.2em .2em .4em #212121';
const $level0Bs = '0 .4em .8em #212121';

const styles = (theme) => ({
  root: {
    width: '100%',
    paddingTop: '30px',
    position: 'relative',
    background: '#CECECE'
  },
  title: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#707070'
  },
  scrollBox: {
    width: '100%',
    height: 'calc(28.13vw)',
    position: 'relative'
  },

  item: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'box-shadow 1s, height 1s, width 1s, left 1s, margin-top 1s, lineHeight 1s, background-color 1s'
  },

  level_2: {
    boxShadow: $level_2Bs,
    height: $level2Height,
    width: $level2Width,
    lineHeight: $level2Height,
    left: $level_2Left,
    zIndex: 1
  },

  level_1: {
    boxShadow: $level_1Bs,
    height: $level1Height,
    width: $level1Width,
    lineHeight: $level1Height,
    left: $level_1Left,
    zIndex: 2
  },

  level0: {
    boxShadow: $level0Bs,
    height: $level0Height,
    width: $level0Width,
    lineHeight: $level0Height,
    left: $level0Left,
    zIndex: 3
  },

  level1: {
    boxShadow: $level1Bs,
    height: $level1Height,
    width: $level1Width,
    lineHeight: $level1Height,
    left: $level1Left,
    zIndex: 2
  },

  level2: {
    boxShadow: $level2Bs,
    height: $level2Height,
    width: $level2Width,
    lineHeight: $level2Height,
    left: $level2Left,
    zIndex: 1
  }
});

class AppCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {id: 1, image: Image1},
        {id: 2, image: Image2},
        {id: 3, image: Image3},
        {id: 4, image: Image4},
        {id: 5, image: Image5},
        {id: 6, image: Image1},
        {id: 7, image: Image2},
        {id: 8, image: Image3},
        {id: 9, image: Image4},
        {id: 10, image: Image5}
      ],
      active: 0,
      direction: ''
    };
    this.rightClick = this.moveRight.bind(this);
    this.leftClick = this.moveLeft.bind(this);

    this.clickScrollBox = (e) => {
      if (e.nativeEvent.clientX < window.innerWidth / 2) {
        this.rightClick();
      } else {
        this.leftClick();
      }
    };
  }

  generateItems(classes) {
    let items = [];
    let level;
    for (let i = this.state.active - 2; i < this.state.active + 3; i++) {
      let index = i;
      if (i < 0) {
        index = this.state.items.length + i;
      } else if (i >= this.state.items.length) {
        index = i % this.state.items.length;
      }
      level = this.state.active - i;
      const item = this.state.items[index];
      items.push(<Item key={index} id={item.id} item={item} level={level} classes={classes} />);
    }
    return items;
  }

  moveLeft() {
    let newActive = this.state.active;
    newActive--;
    this.setState({
      active: newActive < 0 ? this.state.items.length - 1 : newActive,
      direction: 'left'
    });
  }

  moveRight() {
    var newActive = this.state.active;
    this.setState({
      active: (newActive + 1) % this.state.items.length,
      direction: 'right'
    });
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.title}>A Look in to the MetaWheel App</div>
        <div className={classes.scrollBox} onClick={this.clickScrollBox}>
          <ReactCSSTransitionGroup
            transitionLeaveTimeout={1000}
            transitionEnterTimeout={1000}
            transitionName={this.state.direction}
          >
            {this.generateItems(classes)}
          </ReactCSSTransitionGroup>
        </div>
      </div>
    );
  }
}

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      level: this.props.level
    };
  }

  render() {
    const {level, classes, item} = this.props;
    const className = clsx(classes.item, classes[`level${level < 0 ? '_' : ''}${Math.abs(level)}`]);

    return (
      <div
        className={className}
        style={{
          backgroundImage: `url(${item.image}`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center'
        }}
      ></div>
    );
  }
}

export default withStyles(styles)(AppCarousel);
