// @ts-check
import React, { useState } from "react";
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  ExpandBar,
  ToggleContentTitles,
} from "~/components/ui";
import styles from "./VideosMainStyles";

import MyFeed, { useCategoryFilter } from "~/components/layout/MyFeed/index";
import VideoCardBox from "./VideoCardBox";
import DisplayContentItems from "../../contentData/DisplayContentItems";
import { fetchVideosByCategoryIds } from "shared/react-query/strapiAPI";

const PERSON = "person";
const PLANET = "planet";

const useStyles = makeStyles(styles);

const customStylesMyFeed = (theme) => ({
  subCategoryContainer: {
    backgroundColor: 'transparent',
  },
  subCategoryButton: {
  },
});

const MyFeedCustomStyles = withStyles(customStylesMyFeed)(MyFeed);

const customStylesArticles = (theme) => ({
  loading: {
    color: "white",
  }
});

const CustomStylesDisplayContentItems = withStyles(customStylesArticles)(DisplayContentItems);

const VideosMain = (props) => {
  const classes = useStyles(props);
  const {
    onTitlesChange,
    noMoreVideos,
    onDataFetch,
  } = props;

  const {
    allCategories,
    selectedCategoryIds, setSelectedCategoryIds,
    selectedCategories,
    mode, setMode,
  } = useCategoryFilter();

  const [categories, setCategories] = useState(null);

  const onCategoriesChangeHandler = (selectedCategories) => {
    setCategories(selectedCategories);
    props.onCategoriesChange(selectedCategories);
  };

  const handleSwitch = () => {
    const nextMode = mode === PERSON ? PLANET : PERSON;
    setMode(nextMode);
  }

  return (
    <>
      <Container className={classes.root}>
        <ExpandBar
          nav="Videos"
          subNav={mode}
          goExpanded={props.goExpanded}
          switcher={{
            items: ["Person", "Planet"],
            checkedIndex: mode === "planet" ? 1 : 0,
            onChange: handleSwitch,
          }}
        />
        <ToggleContentTitles
          selectedCategories={selectedCategories}
          onChange={onTitlesChange}
        />
        <MyFeedCustomStyles allCategories={allCategories.data} mode={mode}
          selectedCategoryIds={selectedCategoryIds}
          onCategoriesChange={setSelectedCategoryIds} />
        {noMoreVideos ? (
          <Grid container justifyContent="center">
            <Typography component="p" className={classes.noMoreVideos}>
              No More Videos
            </Typography>
          </Grid>
        ) :
          (
            <CustomStylesDisplayContentItems
              allCategories={allCategories.data}
              filteredCategories={selectedCategories}
              goDetail={props.goDetail}
              // how to fetch data
              dataName="videos"
              fetcher={fetchVideosByCategoryIds}
              onDataFetch={onDataFetch}
              // how to display data
              itemComponent={VideoCardBox}
            />)}
      </Container>
    </>
  );
};

export default VideosMain;
