const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  content: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal',
    lineHeight: (props) => `${props.lineHeight}rem`,
    maxHeight: (props) => `${props.lineHeight * props.linesCount}rem`,
    display: '-webkit-box',
    '-webkit-line-clamp': (props) => props.linesCount,
    '-webkit-box-orient': 'vertical'
  }
});

export default styles;
