import React from 'react';
import {withStyles, Typography} from '@material-ui/core';
import Background from '~/assets/images/intro/metaWheel/scene10_bg.png';
import Background2 from '~/assets/images/intro/metaWheel/scene10_decoration.png';

const styles = (theme) => ({
  root: {
    backgroundSize: 'cover',
    backgroundImage: `url(${Background})`,
    position: 'relative',
    color: 'white'
  },
  contentBox: {
    background: 'rgba(4, 4, 4, 0.2)',
    padding: '5% 10%'
  },
  title: {},
  subTitle: {
    marginTop: '5px'
  },
  desc: {
    marginTop: '20px',
    fontSize: '0.8rem'
  },
  decoration: {
    position: 'absolute',
    height: '80%',
    top: '10%',
    bottom: '10%',
    left: '50%',
    transform: 'translate(-50%, 0)',
    maxWidth: '80%',
    objectFit: 'contain'
  }
});

function Scene10(props) {
  const {classes} = props;

  return (
    <div className={classes.root}>
      <img src={Background2} className={classes.decoration} alt="Decoration" />

      <div className={classes.contentBox}>
        <Typography variant="h4" className={classes.title}>
          Discover Shift Evolve Create
        </Typography>
        <Typography variant="h6" className={classes.subTitle}>
          You are Self Evolutionary
        </Typography>
        <Typography variant="body1" className={classes.desc}>
          In the context of evolution, living things develop over millions of years through a gradual process of
          adaptation and advancement. As a human being you have a mind that enables SELF-EVOLUTION, which by definition
          is to develop by inherent quality or power. That is, through your inherent quality or power. In nature we
          witness the caterpillar become the butterfly in a matter of weeks, as evidence of complete transformation.
          This is evolution at its finest, which over millions of years has perfected a second tier, highly accelerated,
          transformational process. How remarkable. As human beings we also possess remarkable abilities to create or to
          transform at a rapid rate. This is possible through the proper utilisation of the mind. “The mind holds the
          creation powers of; imagination, planning & self determination.” Once you are conscious of your own self
          evolution, you can utilise the mind to direct and design your life. Become a master creator with the
          MetaWheel. As a master creator you continuously Discover, Shift, Evolve and Create. As you are creating you
          are discovering, shifting and evolving effecting new creation concepts and roads to then journey upon. This
          cycle is what makes you human, powerful and evolutionary. In this cycle you have the ability to continually
          Increase; to experience greater joy, vibrancy and success now, but to also forge a life signature – in
          planning long term to leave a legacy.
        </Typography>
        <Typography variant="body1" className={classes.desc}>
          The Sanctuary Metawheel has been designed to accelerate this incredible self-evolutionary process, to help you
          become the architect of your own life by providing four corner stone learning arenas essential to human
          development: Vitality and Longevity, Wisdom and Success, Empowerment and Significance, Clarity and Beauty.
          These arenas holistically interlock to bring out your full Life Force, Holistic Human Vibrancy, significance
          and Purpose. Your inherent magnificence!
        </Typography>
        <Typography variant="body1" className={classes.desc}>
          The Metawheel provides the human architecture that enables you to; Discover who you are and take stock of your
          life, reflect upon what Shifts you wish to make in your life, to Evolve the insights, knowledge, emotional
          ability, spiritual understanding and beliefs to enable these changes to seed, and then provides the tools to
          orchestrate exactly how to manifest these changes in your life.
        </Typography>
      </div>
    </div>
  );
}

export default withStyles(styles)(Scene10);
