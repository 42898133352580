import React from 'react';

import Icon from '~/assets/images/profile/ic-item-personal-profile-myprofile.png';
import IconItemBlue from '~/assets/images/profile/ic-item-personal-profile-myprofile-list-blue.png';
import IconItemGreen from '~/assets/images/profile/ic-item-personal-profile-myprofile-list-green.png';
import IconItemPink from '~/assets/images/profile/ic-item-personal-profile-myprofile-list-pink.png';
import IconItemYellow from '~/assets/images/profile/ic-item-personal-profile-myprofile-list-yellow.png';
import IconChat from '~/assets/images/profile/ic-item-business-profile-chat.png';
import IconLocate from '~/assets/images/profile/ic-item-business-profile-locate.png';

import IconCake from '~/assets/images/profile/ic-item-personal-profile-myprofile-cake.png';
import IconHouse from '~/assets/images/profile/ic-item-personal-profile-myprofile-house.png';
import IconSuitcase from '~/assets/images/profile/ic-item-personal-profile-myprofile-suitcase.png';
import IconUniversity from '~/assets/images/profile/ic-item-personal-profile-myprofile-university.png';
import IconGender from '~/assets/images/profile/ic-item-personal-profile-myprofile-gender.png';

import ProfileTemplate from '../../common/Profile';

const titleList = [
  {
    title: 'Health',
    icon: IconItemGreen,
    desc:
      'I help reduce global warming by choosing to eat only 100gms of meat once per week. \nI have a keep cup and have eliminated the waste of approximately 700 paper cups.'
  },
  {
    title: 'Prosperity',
    icon: IconItemBlue,
    desc:
      'I help reduce global warming by choosing to eat only 100gms of meat once per week. \nI have a keep cup and have eliminated the waste of approximately 700 paper cups.'
  },
  {
    title: 'Inspiration',
    icon: IconItemPink,
    desc:
      'I help reduce global warming by choosing to eat only 100gms of meat once per week. \nI have a keep cup and have eliminated the waste of approximately 700 paper cups.'
  },
  {
    title: 'Joy',
    icon: IconItemYellow,
    desc:
      'I help reduce global warming by choosing to eat only 100gms of meat once per week. \nI have a keep cup and have eliminated the waste of approximately 700 paper cups.'
  }
];

const detailList = [
  {
    icon: IconSuitcase,
    title: 'Works at',
    desc: 'Vision for humanity'
  },
  {
    icon: IconCake,
    title: 'Birthday',
    desc: 'August 26'
  },
  {
    icon: IconUniversity,
    title: 'School',
    desc: 'Lorem ipsum dolor'
  },
  {
    icon: IconGender,
    title: 'Gender',
    desc: 'Female'
  },
  {
    icon: IconLocate,
    title: 'Lives in',
    desc: 'Auckland'
  },
  {
    icon: IconChat,
    title: 'Personal Quote ',
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor.'
  },
  {
    icon: IconHouse,
    title: 'From',
    desc: 'Wellington'
  }
];

function Profile(props) {
  return (
    <ProfileTemplate
      titleIcon={Icon}
      title="MY PROFILE"
      titleList={titleList}
      detailTitle="OUR DETAILS"
      detailList={detailList}
    />
  );
}

export default Profile;
