import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {ListItem, ListItemText, IconButton} from '@material-ui/core';
import {ExpandMore, ChevronRight} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      color: (props) => (props.color ? 'white' : null),
      backgroundColor: (props) => props.color
    }
  }
}));

export default function ListItemLink(props) {
  const classes = useStyles(props);
  const {icon, primary, to, isOpen, hasSubmenu, onClick} = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to]
  );

  return (
    <li>
      <ListItem className={classes.root} button component={renderLink}>
        {icon && icon}
        <ListItemText primary={primary} />
        {hasSubmenu && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (onClick) {
                onClick(e);
              }
            }}
            size="small"
          >
            {isOpen ? <ExpandMore /> : <ChevronRight />}
          </IconButton>
        )}
      </ListItem>
    </li>
  );
}
