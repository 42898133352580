import React, {useState} from 'react';
import {withStyles, Grid} from '@material-ui/core';

import ContentHeader from '../../ContentHeader';
import MediaGridCard from './media/MediaGridCard';

import Icon from '~/assets/images/profile/ic-item-personal-profile-mymedia.png';
import Image1 from '~/assets/images/profile/img-item-personal-profile-mynetwork-1.png';
import Image2 from '~/assets/images/profile/img-item-personal-profile-mynetwork-2.png';
import Image3 from '~/assets/images/profile/img-item-personal-profile-mynetwork-3.png';
import Image4 from '~/assets/images/profile/img-item-personal-profile-mynetwork-4.png';
import Image5 from '~/assets/images/profile/img-item-personal-profile-mynetwork-5.png';

function genMedias(kind = 0, number = 8) {
  const mediaList = [];
  const sourceList = [Image1, Image2, Image3, Image4, Image5];
  while (number-- > 0) {
    mediaList.push({
      src: sourceList[Number.parseInt(Math.random() * sourceList.length)],
      isVideo: kind === 1,
      desc: kind === 2 ? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' : null
    });
  }
  return mediaList;
}

const CategoryList = [
  {
    title: 'MY PHOTOS',
    mediaList: genMedias(0)
  },
  {
    title: 'MY VIDEOS',
    mediaList: genMedias(1)
  },
  {
    title: 'MY QUOTES',
    mediaList: genMedias(2)
  },
  {
    title: 'MY ARTICLES',
    mediaList: genMedias(2)
  }
];

const styles = (theme) => ({
  root: {
    padding: '30px 40px'
  },
  contentWrapper: {
    margin: '10px'
  },
  flexExpand: {
    flex: 1
  }
});

function MyMedia(props) {
  const [activeCategory, setActiveCategory] = useState(null);
  const {classes} = props;

  return (
    <div className={classes.root}>
      <ContentHeader name={activeCategory ? activeCategory.title : 'MY MEDIA'} icon={Icon} />
      <div className={classes.contentWrapper}>
        <Grid container spacing={3}>
          {(activeCategory ? [activeCategory] : CategoryList).map((category) => (
            <MediaGridCard
              category={category}
              expand={!!activeCategory}
              showCategory={(categoryToActive) => {
                setActiveCategory(categoryToActive);
              }}
              goBack={() => {
                setActiveCategory(null);
              }}
            />
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default withStyles(styles)(MyMedia);
