// @ts-check
import React from 'react';
import { useFetchPublicProfile } from 'shared/react-query/hooks-user-profile';

export const PublicProfileThumbnail = (props) => {
  const { style, slug } = props;
  const { data: publicProfileData } = useFetchPublicProfile(slug);

  if (!publicProfileData) {
    return <></>
  }

  let fullName = publicProfileData?.data?.attributes?.firstName;
  if (publicProfileData?.data?.attributes?.lastName) {
    fullName += ' ' + publicProfileData?.data?.attributes?.lastName;
  }
  const title = publicProfileData?.data?.attributes?.title;

  return (
    <div
      style={{
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: 10,
        ...style
      }}
    >
      <img
        src={publicProfileData.data.attributes.displayPhotosAndVideos.data[0]?.attributes.url}
        style={{ width: 40, aspectRatio: '1/1', borderRadius: 7, objectFit: 'cover' }}
      />
      <div style={{ textAlign: 'left' }}>
        <h1 style={{ fontSize: '14px', margin: 0 }}>{fullName}</h1>
        {!!title && <span style={{ fontSize: '13px' }}>{title}</span>}
      </div>
    </div>
  );
}