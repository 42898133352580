import React from 'react';
import { withStyles, Box } from '@material-ui/core';

import TopRow from './TopRow'
import Card from './Card'

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    position: 'relative'
  },
  topRow: {
    padding: '15px'
  },
  bttomWrapper: {
    background: 'rgba(23, 23, 23, 0.73)',
    padding: '5% 10%',
    color: 'white',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    letterSpacing: '2px',
    fontSize: '13px',
    lineHeight: '24px',
    fontWeight: 'bold'
  }
});

function ArticleCard(props) {
  const { classes, data } = props;

  const meta = (
    <div className={classes.root}>
      <Box className={classes.topRow}>
        <TopRow
          tag={data.tag.text}
          tagColor={data.tag.color}
        />
      </Box>

      <div className={classes.bttomWrapper}>
        {data.desc}
      </div>
    </div>
  )

  return (
    <Card
      imageSrc={data.background}
      meta={meta}
    />
  )
}

export default withStyles(styles)(ArticleCard);