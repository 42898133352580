const styles = (theme) => ({
  root: {},
  measureGrid: {
    height: 0,
    width: '100%'
  },
  flowGrid: {},
  cardColumn: {
    transform: 'translate(0, 0)',
    transition: 'transform 1.3s cubic-bezier(.68,0,.42,1)',
    '&:nth-child(2)': {
      transitionDelay: '100ms'
    },
    '&:nth-child(3)': {
      transitionDelay: '200ms'
    },
    '&:nth-child(4)': {
      transitionDelay: '300ms'
    },
    '&:nth-child(5)': {
      transitionDelay: '400ms'
    },
    '&:nth-child(6)': {
      transitionDelay: '500ms'
    },
    '&:nth-child(7)': {
      transitionDelay: '600ms'
    },
    '&:nth-child(8)': {
      transitionDelay: '700ms'
    },
    '&:nth-child(9)': {
      transitionDelay: '800ms'
    }
  },
  cardColumnLanding: {transform: `translate(0, calc(100vh + 130px))`},
  cardContainer: {
    width: '100%',
    position: 'relative',
    paddingTop: (props) => props.cardHeight || '100%',
    overflow: 'hidden'
  },
  card: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    margin: 'auto'
  }
});

export default styles;
