import { createTheme } from '@material-ui/core/styles'

const defaultTheme = createTheme();

const defaults = {
  navigationBar: {
    width: 960
  },
  cardBox: {
    translate: 20,
    timeout: 500,
    transition: '.3s ease-out'
  }
};

const theme = createTheme({
  ...defaults,
  palette: {
    primary: {
      main: '#ffffff'
    }
  },
  typography: {
    button: {
      letterSpacing: '3px',
      padding: '0.25rem',
      fontWeight: 'bold',
      margin: '0 8px',
      fontSize: '0.5rem',
      [defaultTheme.breakpoints.only('xl')]: {
        fontSize: '1rem'
      },
      [defaultTheme.breakpoints.only('lg')]: {
        fontSize: '0.875rem'
      },
      [defaultTheme.breakpoints.only('md')]: {
        fontSize: '0.75rem'
      }
    }
  }
});

const styles = {
  footer: {
    gridRowStart: '2',
    gridRowEnd: '3'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: 'inherit'
  },
  contentContainer: {
    paddingTop: '53px',
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column'
  }
};

export {styles, theme};
