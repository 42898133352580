import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';

const useStyles = (btnColor, bgImage) =>
  makeStyles({
    content: {
      backgroundImage: `url(${bgImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'none',
      height: '320px',
      width: '100%',
      color: 'white',
      position: 'relative'
    },
    btn: {
      width: '80px',
      height: '24px',
      backgroundColor: btnColor,
      borderRadius: '4px',
      fontSize: '0.8em',
      fontWeight: 'normal',
      letterSpacing: '1px',
      textAlign: 'center',
      lineHeight: '1.8',
      float: 'right',
      marginRight: '20px',
      marginTop: '20px'
    },
    linkStyle: {
      textDecoration: 'none',
      color: 'white'
    },
    bottomContainer: {
      height: '25%',
      backgroundColor: 'rgba(23, 23, 23,0.7)',
      position: 'absolute',
      bottom: '0',
      padding: '4%',
      overflow: 'hidden',
      [`@media (max-width:768px)`]: {
        height: '20%'
      }
    },
    videoHeading: {
      fontWeight: 'bold',
      fontSize: '1em',
      letterSpacing: '4px',
      marginBottom: '0.5em'
    },
    playBtn: {
      top: '30%',
      margin: 'auto',
      width: '50px',
      height: '50px',
      border: 'solid 1px white',
      padding: 'auto',
      borderRadius: '26px',
      position: 'relative',
      cursor: 'pointer'
    },
    videoDes: {
      fontWeight: 'normal',
      fontSize: '0.6em',
      letterSpacing: '1px',
      lineHeight: '1.5',
      opacity: '0.5'
    },
    triangle: {
      position: 'absolute',
      top: '20%',
      left: '35%',
      width: 0,
      height: 0,
      borderTop: '15px solid transparent',
      borderBottom: '15px solid transparent',
      borderLeft: '20px solid white'
    }
  });

function OrganizeVideoBox(props) {
  const classes = useStyles(props.btnColor, props.videoBgImage)();
  return (
    <div className={classes.content}>
      {props.btnTitle ? (
        <div className={classes.btn}>
          <Link to="#" className={classes.linkStyle}>
            {props.btnTitle}
          </Link>
        </div>
      ) : null}
      <div className={classes.playBtn}>
        <div className={classes.triangle}></div>
      </div>
      <div className={classes.bottomContainer}>
        <Typography className={classes.videoHeading}>{props.videoName}</Typography>
        <Typography className={classes.videoDes}>{props.videoDescription}</Typography>
      </div>
    </div>
  );
}

export default OrganizeVideoBox;
