import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from './components/layout/Layout';
import routes from './routes';
import { useAuth } from 'shared/auth';
import { isNil } from 'lodash';
import { matchPath, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';


const PrivateRoute = ({ children, ...rest }) => {
  const { auth, isLoading } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !!auth.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              search: "?login=",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const App = (props) => {
  const { auth, isLoading } = useAuth();
  const location = useLocation()
  const isSecondGenomeSite = process.env.REACT_APP_2NDGENOME_SITE == 1;

  if (isLoading) {
    return <p>Loading...</p>
  }
  const routesFor2ndGenome = routes.filter(r => (!!r.isSecondGenomePage));
  const routesForHCN = routes.filter(r => (!r.isSecondGenomePage));

  const renderRoutes = (routeList) => {
    return routeList.map((route, index) => {
      if (route.isProtected) {
        return (
          <PrivateRoute key={index} path={route.path}>
            <route.component />
          </PrivateRoute>
        );
      }
      return <Route exact key={index} path={route.path} component={route.component} />;
    });
  };
  const link = (location) => {
    const path = location.pathname;

    const inSecondGenome = routesFor2ndGenome.some(route => !!matchPath(path, {
      path: route.path,
      exact: true,
    }));
    const inHCN = routesForHCN.some(route => !!matchPath(path, {
      path: route.path,
      exact: true,
    }));

    if ((isSecondGenomeSite && inHCN && path !== "/")) {
      window.location.href = `https://${process.env.REACT_APP_HCN_DOMAIN}${path}`
      return
    }
    if ((!isSecondGenomeSite && inSecondGenome && path !== "/")) {
      window.location.href = `https://${process.env.REACT_APP_SECONDGENOME_DOMAIN}${path}`
      return
    }

    if ((isSecondGenomeSite && inSecondGenome) || (!isSecondGenomeSite && inHCN)) {
      return <Redirect to={path} />;
    }

    return <Redirect to={'/404'}/>;
  }
  return (
    <Layout>
      <Switch>
        {renderRoutes(isSecondGenomeSite ? routesFor2ndGenome : routesForHCN)}
        <Route path="*">
          {link(location)}
          <Route path={'/404'} component={() => <h1>404 page</h1>} />
        </Route>
      </Switch>
    </Layout>
  );
};

export default App;