import {connect} from 'react-redux';

import ArticleDetailsPage from './ArticleDetailsPage';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleDetailsPage);
