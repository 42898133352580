import {GET_PROFILE, PROFILE_ERROR} from '../actions/profileActions';

const profile = (state = {profile: null, processing: false, serverError: null}, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        processing: true,
        profile: action.payload
      };

    case PROFILE_ERROR:
      return {
        ...state,
        processing: false,
        serverError: action.payload
      };

    default:
      return state;
  }
};

export default profile;
