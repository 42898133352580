const my_padding = 40;

const styles = (theme) => ({
  root: {
    width: '95%',
    color: '#666666'
  },
  media: {
    padding: '0px 0px ' + my_padding + 'px 0px'
  },
  gridColumnTop: {
    padding: my_padding + 'px  0px 10px  0px'
  },
  gridColumn1: {
    padding: '0px  0px ' + my_padding + 'px  0px'
  },
  gridColumn2: {
    padding: '0px  ' + my_padding + 'px ' + my_padding + 'px  ' + my_padding + 'px'
  },
  myH1: {
    fontSize: '2.5rem',
    color: '#333333',
    textTransform: 'uppercase',
    fontWeight: 'Normal',
    letterSpacing: '0.3rem',
    margin: '0'
  },
  myH2: {
    textTransform: 'uppercase',
    fontWeight: 'Normal',
    letterSpacing: '0.1rem',
    margin: '0px 0px 10px 0px',
    fontSize: '1rem'
  },
  backHref: {
    display: 'block',
    fontSize: '1.2rem',
    color: '#333333',
    textTransform: 'uppercase',
    border: '1px'
  }
});

export default styles;
