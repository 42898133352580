import React, {useState, useEffect, useCallback, useRef} from 'react';
import clsx from 'clsx';
import Measure from 'react-measure';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Grid, Box} from '@material-ui/core';
import styles from './FlowGridStyles';

const useStyles = makeStyles(styles);

const FlowGrid = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();

  const {data, maxColumns, children} = props;

  const [columnsCount, setColumnsCount] = useState(0);
  const [landing, setLanding] = useState(true);
  const [rendering, setRendering] = useState(false);
  const [completed, setCompleted] = useState(false);

  const getColumns = useCallback(() => {
    const getColumnCards = (columnIndex) => {
      return data
        .reduce((acc, cur, idx) => {
          if (idx % columnsCount === columnIndex) {
            return acc.concat([cur]);
          }
          return acc;
        }, [])
        .map((v, idx) => (
          <Box className={classes.cardContainer} key={`card_${columnIndex}_${idx}`}>
            <Box className={classes.card}>{children && children(v, completed)}</Box>
          </Box>
        ));
    };

    if (columnsCount === 0) {
      return null;
    }

    return [...new Array(columnsCount)].map((_, idx) => (
      <Grid
        item
        xs
        key={`card_column_${idx}`}
        className={clsx(classes.cardColumn, {[classes.cardColumnLanding]: landing})}
      >
        {getColumnCards(idx)}
      </Grid>
    ));
  }, [columnsCount, data, landing, completed]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (rendering) {
        setLanding(false);
      }
    }, 10);
    return () => {
      clearTimeout(timerId);
    };
  }, [rendering]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (!landing) {
        setCompleted(true);
      }
    }, columnsCount * 100 + 1.3 * 1000 + 10);
    return () => clearTimeout(timerId);
  }, [landing]);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const timer = useRef(null);
  const scheduleResize = (contentRect) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => handleResize(contentRect), 10);
  };

  const handleResize = (contentRect) => {
    const {width} = contentRect.bounds;
    let count;
    if (width < theme.breakpoints.values['sm'] - 32) {
      count = 1;
    } else if (width < theme.breakpoints.values['md'] - 48) {
      count = 2;
    } else if (width < theme.breakpoints.values['lg'] - 48) {
      count = 3;
    } else {
      count = 4 + Math.floor((width + 48 - theme.breakpoints.values['lg']) / 400);
    }
    count = maxColumns === undefined ? count : Math.min(count, maxColumns);

    if (count !== columnsCount) {
      if (!rendering) {
        setRendering(true);
      }
      setColumnsCount(count);
    }
  };

  return (
    <div className={classes.root}>
      <Measure bounds onResize={scheduleResize}>
        {({measureRef}) => <div className={classes.measureGrid} ref={measureRef}></div>}
      </Measure>
      <Grid container className={classes.flowGrid} direction="row" justifyContent="flex-start">
        {getColumns()}
      </Grid>
    </div>
  );
};

export default FlowGrid;
