import * as Yup from 'yup';

export const nameValidation = (str = 'Name') => {
  return Yup.string()
    .matches(/^[A-Za-z ]*$/, `Please enter a valid ${str.toLowerCase()}.`)
    .max(40, `${str} can't be longer than 40 characters.`)
    .required(`${str} is required.`);
};

export const mealPlanNameValidation = (str = 'Name') => {
  return Yup.string().max(40, `${str} can't be longer than 40 characters.`).required(`${str} is required.`);
};

export const emailValidation = () => {
  return Yup.string().email('Please enter a vaild email address.').required('Email is required.');
};

export const passwordValidation = () => {
  return Yup.string().min(8, 'Password should be atleast 8 characters long.').required(`Password is required.`);
};

export const passwordConfirmValidation = (str = 'password') => {
  return Yup.string().oneOf([Yup.ref(str), null], 'Passwords must match.');
};

export const requiredCheckboxValidation = (message = 'Required') => {
  return Yup.bool().oneOf([true], message);
};

// const emailValidation = (str) => {
//   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return re.test(String(str).toLowerCase());
// };

// // const nameValidation = (str) => {
// //   const re = /^[ a-zA-Z]+$/;
// //   return re.test(String(str));
// // };

// const passwordValidation = (str) => {
//   return str.length >= 6;
// };

// const phoneNumberValidation = (str) => {
//   const re = /^(\+\d{1,3}[- ]?)?\d{10}$/;
//   return re.test(String(str));
// };

export const signUpValidation = (obj) => {
  // return new Promise((res, rej) => {
  //   const error = {};
  //   if (!emailValidation(obj.email)) {
  //     error.email = 'Please input a valid email address.';
  //   }
  //   if (!nameValidation(obj.name)) {
  //     error.name1 = 'Please input a valid name.';
  //   }
  //   if (!passwordValidation(obj.password)) {
  //     error.password = 'Password should be atleast 6 character long.';
  //   }
  //   if (obj.password !== obj.confirmPassword) {
  //     error.confirmPassword = 'Password does not match.';
  //   }
  //   if (!obj.tacCheck) {
  //     error.tacCheck = 'Please accept terms and conditions.';
  //   }
  //   if (Object.keys(error).length) {
  //     rej(error);
  //   } else {
  //     res();
  //   }
  // });
};

export const changePasswordValidation = (obj) => {
  // return new Promise((res, rej) => {
  //   const error = {};
  //   if (!passwordValidation(obj.currentPassword)) {
  //     error.currentPassword = 'Password should be atleast 6 character long.';
  //   }
  //   if (!passwordValidation(obj.newPassword)) {
  //     error.newPassword = 'Password should be atleast 6 character long.';
  //   }
  //   if (obj.newPassword !== obj.confirmNewPassword) {
  //     error.confirmNewPassword = 'Password does not match.';
  //   }
  //   if (Object.keys(error).length) {
  //     rej(error);
  //   } else {
  //     res();
  //   }
  // });
};

export const loginValidation = (obj) => {
  // return new Promise((res, rej) => {
  //   const error = {};
  //   if (!emailValidation(obj.email)) {
  //     error.email = 'Please input a valid email address.';
  //   }
  //   if (!passwordValidation(obj.password)) {
  //     error.password = 'Password should be atleast 6 character long.';
  //   }
  //   if (Object.keys(error).length) {
  //     rej(error);
  //   } else {
  //     res();
  //   }
  // });
};

export const profileValidation = (obj) => {
  // return new Promise((res, rej) => {
  //   const error = {};
  //   if (obj.nickname.length > 0 && !nameValidation(obj.nickname)) {
  //     error.nickname = 'Please input a valid nickname.';
  //   }
  //   if (!nameValidation(obj.name)) {
  //     error.name = 'Please input a valid name.';
  //   }
  //   if (obj.mobileNumber.length > 0 && !phoneNumberValidation(obj.mobileNumber)) {
  //     error.mobileNumber = 'Please input a valid mobile number.';
  //   }
  //   if (Object.keys(error).length) {
  //     rej(error);
  //   } else {
  //     res();
  //   }
  // });
};
