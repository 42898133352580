import {connect} from 'react-redux';
import UserInfoPopover from './UserInfoPopover';
import {signOut} from '~/store/actions/auth';

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isSignedIn: state.auth.isSignedIn
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(signOut())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoPopover);
