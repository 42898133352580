const styles = (theme) => ({
  root: {
    marginBottom: 80
  },
  title: {
    letterSpacing: 8,
    lineHeight: 1.4,
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.5rem',
      letterSpacing: 2.1
    }
  },
  coverImage: {
    width: '100%'
  }
});

export default styles;
