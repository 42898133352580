import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {
  withStyles,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Divider,
  Popover
} from '@material-ui/core';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  RedditShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  RedditIcon
} from 'react-share';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import styles from './DisperseCardStyles';
import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';

class DisperseCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
  }

  handlePopoverOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };
  handlePopoverClose() {
    this.setState({
      anchorEl: null
    });
  }

  render() {
    const {id, image, title, content, tags, disperseDate, video, showSingle} = this.props;
    const tags_arr = tags.split(', ');
    const {classes} = this.props;
    const anchorEl = this.state.anchorEl;
    const open = Boolean(anchorEl);
    const popId = open ? 'simple-popover' : undefined;
    const shareUrl = showSingle ? window.location.href : window.location.href + '?id=' + id;

    return (
      <Card square={true} variant="outlined" className={classes.root}>
        <CardMedia component={video ? 'iframe' : 'img'} image={image} className={classes.media} />
        <CardContent>
          <Typography className={classes.title}>
            {showSingle ? (
              title
            ) : (
              <Link to={'?id=' + id} onClick={() => this.props.showSingleTrigger(id)} className={classes.titleHref}>
                {title}
              </Link>
            )}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.content}
            dangerouslySetInnerHTML={{__html: content}}
          />
        </CardContent>
        <Divider variant="fullWidth" />
        <CardActions disableSpacing>
          <IconButton
            aria-label="Share"
            className={classes.iconButtonArea}
            onClick={this.handlePopoverOpen}
            aria-describedby={popId}
          >
            <ShareOutlinedIcon />
            {intl.get(messageKeys.ARTICLE_SHARE_LABEL)}
          </IconButton>
          <Popover
            id={popId}
            open={open}
            anchorEl={anchorEl}
            onClose={this.handlePopoverClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <div className={classes.popoverContent}>
              <div className={classes.shareButton}>
                <FacebookShareButton url={shareUrl}>
                  <FacebookIcon size="22px" borderRadius="10" />
                </FacebookShareButton>
              </div>
              <div className={classes.shareButton}>
                <TwitterShareButton url={shareUrl}>
                  <TwitterIcon size="22px" borderRadius="10" />
                </TwitterShareButton>
              </div>
              <div className={classes.shareButton}>
                <RedditShareButton url={shareUrl}>
                  <RedditIcon size="22px" borderRadius="10" />
                </RedditShareButton>
              </div>
              <br />
              <div className={classes.shareButton}>
                <LinkedinShareButton url={shareUrl}>
                  <LinkedinIcon size="22px" borderRadius="10" />
                </LinkedinShareButton>
              </div>
              <div className={classes.shareButton}>
                <WhatsappShareButton url={shareUrl}>
                  <WhatsappIcon size="22px" borderRadius="10" />
                </WhatsappShareButton>
              </div>
            </div>
          </Popover>
          <IconButton aria-label="Comment" className={classes.iconButtonArea}>
            <SmsOutlinedIcon />
            {intl.get(messageKeys.ARTICLE_COMMENT_LABEL)}
          </IconButton>
          <div className={classes.tags}>
            <IconButton aria-label="Comment" className={classes.iconButtonArea} disabled>
              <QueryBuilderOutlinedIcon />
              {disperseDate}
            </IconButton>
            {tags_arr.map((tag) => (
              <div className={classes.tag} key={id + tag}>
                {tag}
              </div>
            ))}
          </div>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(DisperseCard);
