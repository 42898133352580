export const contentTypeRadioBtnStyles = () => ({
  contentTypeIcon: {
    width: 20,
    height: 20
  },
  contentTypeRadioBtn: {
    display: 'block',
    '& input': {
      marginLeft: 3
    }
  },

  radioBtnGroup: {
    margin: '0 0.5rem'
  }
});
