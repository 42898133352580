import React from 'react';
import {withStyles} from '@material-ui/core';

import ContentHeader from '../../ContentHeader';
import {Panel} from '~/components/ui';

const styles = (theme) => ({
  root: {
    padding: '30px 40px'
  },
  contentWrapper: {
    margin: '10px',
    fontSize: '14px'
  },
  flexExpand: {
    flex: 1
  },
  detailItem: {
    marginTop: '10px',
    lineHeight: '22px'
  },
  detailKey: {
    fontWeight: 'bold'
  },
  plusWrapper: {
    marginTop: '15px'
  },
  plusItem: {
    fontSize: '16px',
    marginBottom: '10px'
  }
});

function Content(props) {
  const {classes, tab} = props;
  return (
    <div className={classes.root}>
      <ContentHeader name={tab.name} icon={tab.content.icon} />
      <div className={classes.contentWrapper}>
        <div>{tab.content.summary}</div>
        <div>
          {tab.content.detailList.map((detail) => (
            <div className={classes.detailItem} key={detail.key}>
              <span className={classes.detailKey}>{detail.key}: </span>
              <span>{detail.value}</span>
            </div>
          ))}
        </div>

        <div className={classes.plusWrapper}>
          {tab.content.plusList.map((plus) => (
            <div className={classes.plusItem} key={plus.title}>
              <Panel panelTitle={plus.title} panelContent={plus.content} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Content);
