import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';

import InitiativesMain from './Sections/InitiativesMain';
import InitiativesMainExpanded from './Sections/InitiativesMainExpanded';

import {styles} from './InitiativesMainPageStyles';

import {initiativesPersonData, initiativesPlanetData, tableTitles} from '~/data/initiativesData';
import initiativesPersonBackground from '~/assets/images/content/initiative_bg.png';
import initiativesPlanetBackground from '~/assets/images/content/initiative_planet_bg.png';

const PERSON = 'person';
const PLANET = 'planet';

class Initiatives extends Component {
  state = {
    expanded: false,
    mode: PERSON,
    selectedTitleIds: []
  };

  goExpanded = (e) => {
    this.setState((preState) => ({expanded: !preState.expanded}));
    this.props.history.push(`?expanded=true&mode=${this.state.mode}`);
  };

  setExpended = () => {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const expanded = Boolean(params.get('expanded') && params.get('expanded').toUpperCase() === 'TRUE');
    const mode = Boolean(params.get(PLANET)) ? PLANET : PERSON
    this.setState((prev) => {
      if (mode !== prev.mode || expanded !== prev.expanded) {
        return {
          mode,
          expanded
        }
      } else {
        return null
      }
    })
  };

  handleTitlesChange = (selectedTitleIds) => {
    this.setState({selectedTitleIds});
  };

  componentDidMount() {
    const path = this.props.match.path;
    let mode = PERSON;
    if (path.endsWith(PERSON)) {
      mode = PERSON;
    } else if (path.endsWith(PLANET)) {
      mode = PLANET;
    }
    this.setState((prev) => ({...prev, mode}));

    this.setExpended();
  }

  componentDidUpdate() {
    this.setExpended();
  }

  render() {
    const {classes} = this.props;

    const background = this.state.mode === PERSON ? initiativesPersonBackground : initiativesPlanetBackground;
    const cardData = this.state.mode === PERSON ? initiativesPersonData : initiativesPlanetData;
    const data =
      this.state.selectedTitleIds.length > 0
        ? cardData.filter((item) => this.state.selectedTitleIds.indexOf(item.categoryId) > -1)
        : cardData;
    const Content = this.state.expanded ? InitiativesMainExpanded : InitiativesMain;

    return (
      <div className={classes.backgroundWrapper} style={{backgroundImage: `url(${background})`}}>
        <div className={classes.content}>
          <Content
            data={data}
            mode={this.state.mode}
            goDetail={this.goDetail}
            goExpanded={this.goExpanded}
            titles={tableTitles}
            handleSwitch={() => {
              const nextMode = this.state.mode === PERSON ? PLANET : PERSON
              this.setState({mode: nextMode});
              this.props.history.replace(`?${nextMode}=true`);
            }}
            selectedTitleIds={this.state.selectedTitleIds}
            onTitlesChange={this.handleTitlesChange}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Initiatives);
