import React from 'react';
import {withStyles, Grid, Typography} from '@material-ui/core';

import MetaWheel from '~/assets/images/intro/metaWheel/scene3_metawheel.png';
import MetaWheelSmall from '~/assets/images/intro/metaWheel/scene3_metawheel_small.png';

const styles = (theme) => ({
  root: {
    background: 'white'
  },
  metaWheel: {
    width: '84%',
    marginLeft: '16%',
    [theme.breakpoints.only('xs')]: {
      width: '80%',
      marginLeft: '10%'
    }
  },
  rightContainer: {
    color: '#707070',
    height: '100%',
    padding: '20px 25% 20px 5%',
    '& > p': {
      padding: '10px 0',
      fontSize: '0.8rem'
    },
    [theme.breakpoints.only('xs')]: {
      width: '80%',
      marginLeft: '10%',
      padding: '20px 0'
    }
  },
  title: {
    lineHeigth: '0.9'
  },
  desc: {
    fontSize: '0.8rem'
  },
  highlight: {
    fontWeight: 'bold'
  },
  metawheelSmall: {
    width: '65px',
    marginRight: '10px'
  }
});

function Scene3(props) {
  const {classes} = props;
  return (
    <Grid container className={classes.root}>
      <Grid child xs={12} sm={6}>
        <img className={classes.metaWheel} src={MetaWheel} alt="MetaWheel" />
      </Grid>
      <Grid child xs={12} sm={6}>
        <Grid container direction="column" justifyContent="center" className={classes.rightContainer}>
          <Grid container alignItems="center">
            <img src={MetaWheelSmall} alt="MetaWheel" className={classes.metawheelSmall} />
            <div>
              <Typography variant="h4" className={classes.title}>
                Meta Wheel
              </Typography>
              <Typography variant="body1" className={classes.desc}>
                Discover, Shift, Evolve, Create
              </Typography>
            </div>
          </Grid>
          <Typography variant="body1" component="p" className={classes.highlight}>
            Human advancement architecture
          </Typography>
          <Typography variant="body1" component="p">
            Use our comprehensive questionnaire process and testing facilities to help understand your current life
            state. <span className={classes.highlight}>Discover</span> deeper aspects to yourself that you may not have
            considered before; identifying areas in your life that you are excelling at, and areas of concern that are
            undermining your full Life Force that you wish to <span className={classes.highlight}>Shift</span>.
          </Typography>
          <Typography variant="body1" component="p">
            Use our personalised Recommendation Schedule to establish your Desired Being, the 2nd Genome blue print of
            the very best you. <span className={classes.highlight}>Evolve</span> your human potential horizon beyond
            your expectations to encompass your full Holistic Human Vibrancy, Significance and Purpose.
          </Typography>
          <Typography variant="body1" component="p">
            Then with the help of this personalised advancement system and our specialists,{' '}
            <span className={classes.highlight}>Create</span> your Desired Being using all of our Sanctuary services in
            the most advanced wellness centre on earth and experience your physical, mental, emotional and spiritual
            magnificence.
          </Typography>
          <Typography variant="body1" component="p" className={classes.highlight}>
            Discover your Magnificence
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(Scene3);
