import backgroundImg from '~/assets/images/intro/meta_background.jpg';
import articleBackgroundImg from '~/assets/images/intro/meta_article_bg.jpg';
import decorImg from '~/assets/images/intro/meta_decor_img.jpg';
import wheel2 from '~/assets/images/intro/meta_wheel2.png';
import wheel3 from '~/assets/images/intro/meta_wheel3.png';
import wheel1 from '~/assets/images/intro/meta_wheel1.png';

export const styles = (theme) => ({
  backgroundWrapper: {
    background: `linear-gradient(
            rgba(0, 0, 0, 0.55), 
            rgba(0, 0, 0, 0.55)
          ), url(${backgroundImg})`,

    // backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    minHeight: '100%',
    paddingBottom: '50px'
  },
  title: {
    fontSize: '80px',
    textAlign: 'center',
    color: 'white',
    margin: '200px auto',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2.2rem',
      margin: '100px auto'
    }
  },
  contentContainer: {
    color: 'white'
  },
  subTitle: {
    fontSize: '40px',
    textAlign: 'center',
    letterSpacing: '5px',
    margin: '20px auto',
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.6rem'
    }
  },
  desc: {
    border: 'solid 1px rgba(255, 255, 255, 0.23)',
    backgroundColor: 'rgba(0, 0, 0, 0.23)',
    padding: '15px'
  },
  descTitle: {
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '2px',
    fontWeight: 'bold'
  },
  descContent: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '1.5',
    letterSpacing: '1px'
  },
  article: {
    background: `url(${articleBackgroundImg})`,
    backgroundSize: 'cover',
    position: 'relative'
  },
  articleBg: {
    content: `url(${articleBackgroundImg})`,
    width: '100%',
    visibility: 'hidden'
  },
  articleCard: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.61)',
    padding: '20px'
  },
  articleTitle: {
    fontSize: '14px',
    textAlign: 'center',
    letterSpacing: '2px',
    fontWeight: 'bold'
  },
  articleContent: {
    fontSize: '14px',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.8)',
    letterSpacing: '1px'
  },
  quote: {
    padding: '40px 20px'
  },
  quoteTitle: {
    fontSize: '18px',
    textAlign: 'center',
    letterSpacing: '2px',
    fontWeight: 'bold'
  },
  quoteContent: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '14px',
    lineHeight: '1.5',
    letterSpacing: '1px',
    marginTop: '30px'
  },
  decorImg: {
    content: `url(${decorImg})`,
    width: '100%',
    height: 'auto'
  },
  humanAdvance: {
    padding: '30px',
    border: 'solid 1px rgba(255, 255, 255, 0.23)',
    backgroundColor: 'rgba(0, 0, 0, 0.23)'
  },
  humanAdvanceTitle: {
    fontSize: '18px',
    letterSpacing: '2px',
    fontWeight: 'bold'
  },
  humanAdvanceContent: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '14px',
    lineHeight: '1.5',
    letterSpacing: '1px',
    marginTop: '15px'
  },
  wheel1: {
    content: `url(${wheel1})`,
    width: '30%',
    display: 'block',
    transform: 'translate(-20%, 0)'
  },
  wheel2: {
    content: `url(${wheel2})`,
    width: '36%',
    display: 'block',
    marginLeft: '10%',
    transform: 'translate(0, -20%)'
  },
  wheel3: {
    content: `url(${wheel3})`,
    width: '65%',
    display: 'block',
    marginLeft: '35%',
    marginTop: '-12%'
  },
  bottomLine: {
    textAlign: 'right'
  },
  bottomButton: {
    display: 'inline-block',
    padding: '5px 35px',
    fontSize: '15px',
    borderRadius: '5px',
    color: 'white',
    border: 'solid 1px #d2a75f',
    backgroundColor: 'rgba(255, 255, 255, 0.23)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#d2a75f'
    }
  }
});
