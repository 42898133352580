import axios from '../../axios-api';

export const LOADING = 'LOADING';
export const GET_PAGE = 'GET_PAGE';
export const GET_PAGE_ERROR = 'GET_PAGE_ERROR';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const GET_VIDEOS = 'GET_VIDEOS';
export const GET_VIDEOS_ERROR = 'GET_VIDEOS_ERROR';
export const GET_MORE_VIDEOS = 'GET_MORE_VIDEOS';
export const GET_VIDEO = 'GET_VIDEO';
export const GET_VIDEO_ERROR = 'GET_VIDEO_ERROR';

//Get Pagination
export const getPagination = () => (dispatch) => {
  dispatch({type: LOADING});
  axios
    .get('/video/list')
    .then((res) => {
      const {code, message} = res.data;
      if (code === 200) {
        dispatch(getPage(res.data.data.pagination));
      } else if (code === 400) {
        dispatch(getPageError(message));
      }
    })
    .catch((err) => {
      dispatch(getPageError(err));
    });
};

const getPage = (pageData) => {
  return {
    type: GET_PAGE,
    payload: pageData
  };
};

const getPageError = (error) => {
  return {
    type: GET_PAGE_ERROR,
    payload: error.response.data
  };
};

//Get Categories
export const getCategories = () => (dispatch) => {
  dispatch({type: LOADING});
  axios
    .get('/video/config')
    .then((res) => {
      const {code, message} = res.data;
      if (code === 200) {
        dispatch(getAllCategories(res.data.data.categories));
      } else if (code === 400) {
        dispatch(getCategoriesError(message));
      }
    })
    .catch((err) => {
      dispatch(getCategoriesError(err));
    });
};

const getAllCategories = (categories) => {
  return {
    type: GET_CATEGORIES,
    payload: categories
  };
};

const getCategoriesError = (error) => {
  return {
    type: GET_CATEGORIES_ERROR,
    payload: error.response.data
  };
};

//Get All Videos
export const getInitialVideos = (page) => (dispatch) => {
  dispatch({type: LOADING});
  axios
    .get(`/video/list/?size=8&sort_order=asc&page=${page}`)
    .then((res) => {
      const {code, message} = res.data;
      if (code === 200) {
        dispatch(getVideos(res.data.data.items));
      } else if (code === 400) {
        dispatch(getVideosError(message));
      }
    })
    .catch((err) => {
      dispatch(getVideosError(err));
    });
};

const getVideos = (videos) => {
  return {
    type: GET_VIDEOS,
    payload: videos
  };
};

const getVideosError = (error) => {
  return {
    type: GET_VIDEOS_ERROR,
    payload: error.response.data
  };
};

//Get More Videos
export const getMoreVideosByPage = (page) => (dispatch) => {
  dispatch({type: LOADING});
  axios
    .get(`/video/list/?size=8&sort_order=asc&page=${page}`)
    .then((res) => {
      const {code, message} = res.data;
      if (code === 200) {
        dispatch(getMoreVideos(res.data.data.items));
      } else if (code === 400) {
        dispatch(getVideosError(message));
      }
    })
    .catch((err) => {
      dispatch(getVideosError(err));
    });
};

const getMoreVideos = (videos) => {
  return {
    type: GET_MORE_VIDEOS,
    payload: videos
  };
};

//Get Videos By Category
export const getVideosByCategory = (category) => (dispatch) => {
  dispatch({type: LOADING});
  axios
    .get(`/video/list?size=8&sort_order=asc&category=${category}`)
    .then((res) => {
      const {code, message} = res.data;
      if (code === 200) {
        dispatch(getVideos(res.data.data.items));
      } else if (code === 400) {
        dispatch(getVideosError(message));
      }
    })
    .catch((err) => {
      dispatch(getVideosError(err));
    });
};

//Get Video By ID
export const getVideoById = (id) => (dispatch) => {
  dispatch({type: LOADING});
  axios
    .get(`/video/detail?id=${id}`)
    .then((res) => {
      const {code, message} = res.data;
      if (code === 200) {
        dispatch(getVideo(res.data.data.items[0]));
      } else if (code === 400) {
        dispatch(getVideoError(message));
      }
    })
    .catch((err) => {
      dispatch(getVideoError(err));
    });
};

const getVideo = (video) => {
  return {
    type: GET_VIDEO,
    payload: video
  };
};

const getVideoError = (error) => {
  return {
    type: GET_VIDEO_ERROR,
    payload: error.response.data
  };
};
