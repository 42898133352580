import React from 'react';
import { withStyles, Box, Typography } from '@material-ui/core';

import TopRow from './TopRow'
import Card from './Card'

import IconPlay from '~/assets/images/profile/ic-item-business-fav-video.png'

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    position: 'relative'
  },
  topRow: {
    padding: '15px'
  },
  bttomWrapper: {
    background: 'rgba(23, 23, 23, 0.73)',
    padding: '5% 10%',
    color: 'white',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  iconPlay: {
    width: '30px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  title: {
    color: 'white'
  },
  desc: {
    color: 'rgba(255, 255, 255, 0.5)',
    fontSize: '12px',
    letterSpacing: '2px',
    lineHeight: '16px',
    maxHeight: '32px',
    overflow: 'hidden'
  }
});

function VideoCard(props) {
  const { classes, data } = props;

  const meta = (
    <div className={classes.root}>
      <Box className={classes.topRow}>
        <TopRow
          tag={data.tag.text}
          tagColor={data.tag.color}
        />
      </Box>

      <img src={IconPlay} className={classes.iconPlay} alt="PlayButton" />

      <div className={classes.bttomWrapper}>
        <Typography
          className={classes.title}
          variant="subtitle1">
          {data.title}
        </Typography>
        <Typography
          className={classes.desc}
          variant="body2">
          {data.desc}
        </Typography>
      </div>
    </div>
  )

  return (
    <Card
      imageSrc={data.background}
      meta={meta}
    />
  )
}

export default withStyles(styles)(VideoCard);