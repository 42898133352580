import React from 'react';
import {AppCarousel} from '~/components/ui';

export default function Scene5() {
  return (
    <div>
      <AppCarousel />
    </div>
  );
}
