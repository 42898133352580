const styles = {
  btnStyle: {
    position: 'fixed',
    bottom: '10px',
    right: '1px',
    backgroundColor: 'rgba(255,255,255,0.1)'
  }
};

export default styles;
