import React from 'react';
import {withStyles, Grid, Typography} from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    backgroundSize: 'cover',
    minHeight: 'calc(31.4vw)'
  },
  contentBox: {
    color: 'white',
    minHeight: 'calc(31.4vw)',
    background: 'rgba(4, 4, 4, 0.2)',
    padding: '20px 55% 20px 10%',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 15%'
    }
  },
  contentBoxRight: {
    padding: '20px 10% 20px 55%',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 15%'
    }
  },
  titleBox: {
    whiteSpace: 'pre-wrap'
  },
  title: {
    display: 'inline-block',
    fontWeight: 'bold'
  },
  highlight: {
    fontSize: '1.4rem',
  },
  desc: {
    marginTop: '10px',
    fontSize: '0.8rem'
  }
});

function IntroScene(props) {
  const {classes, background, ratio, descList, titleList, right} = props;

  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: `url(${background})`,
        minHeight: `calc(${ratio * 100}vw)`
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        style={{
          minHeight: `calc(${ratio * 100}vw)`
        }}
        className={clsx(classes.contentBox, {
          [classes.contentBoxRight]: right
        })}
      >
        <div className={classes.titleBox}>
          {titleList.map((title, index) => (
            <Typography
              className={clsx(classes.title, {
                [classes.highlight]: title.highlight
              })}
              component="span"
              variant="subtitle1"
            >
              {title.text || title}
            </Typography>
          ))}
        </div>

        {descList.map((desc, index) => (
          <Typography variant="body1" className={classes.desc} key={index}>
            {desc}
          </Typography>
        ))}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(IntroScene);
