import React from 'react';
import {withStyles, Grid, TextField, Typography, Radio, RadioGroup, FormControlLabel, Button} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    padding: '20px'
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 10%'
  },
  label: {
    textAlign: 'right'
  },
  input: {
    textAlign: 'center',
    backgroundColor: 'rgba(255,255,255,0.7)',
    width: '250px',
    [theme.breakpoints.only('md')]: {
      width: '200px'
    },
    [theme.breakpoints.only('xs')]: {
      width: '100px'
    }
  },
  changePass: {
    fontWeight: 'bold',
    marginLeft: '10%'
  },
  align: {
    marginLeft: '20px'
  },
  submitGroup: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '10px 20%',
    [theme.breakpoints.only('xs')]: {
      margin: '10px 5%'
    }
  },
  btn: {
    backgroundColor: '#8D8D8D',
    color: 'black',
    width: '80px'
  }
});

function FormItem(props) {
  const {classes, label, textMultiline, row} = props;
  return (
    <div className={classes.flex}>
      <div className={classes.label}>{label}</div>
      <div>
        <TextField
          label={null}
          variant="outlined"
          className={classes.input}
          size="small"
          multiline={textMultiline}
          rows={row}
        />
      </div>
    </div>
  );
}

function DateItem(props) {
  const {classes, label} = props;

  return (
    <div className={classes.flex}>
      <div className={classes.label}>{label}</div>
      <div>
        <TextField
          id="date"
          type="date"
          className={classes.input}
          InputLabelProps={{
            shrink: true
          }}
        />
      </div>
    </div>
  );
}

function RadioItem(props) {
  const {classes, label} = props;
  const [value, setValue] = React.useState('female');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div className={classes.flex}>
      <div className={classes.label}>{label}</div>
      <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
        <div className={classes.flex + ' ' + classes.align}>
          <FormControlLabel value="female" control={<Radio />} label="Female" />
          <FormControlLabel value="male" control={<Radio />} label="Male" />
        </div>
      </RadioGroup>
    </div>
  );
}

function EditProfile(props) {
  const {classes} = props;
  const textMultiline = true;
  return (
    <div className={classes.root}>
      <Grid container space={4}>
        <Grid item xs={12} sm={12} md={6}>
          <FormItem label="Name" classes={classes} />
          <FormItem label="Email" classes={classes} />
          <Typography className={classes.changePass}>Change Password</Typography>
          <FormItem label="New Password" classes={classes} />
          <FormItem label="Confirm" classes={classes} />
          <FormItem label="Work" classes={classes} />
          <FormItem label="Working as" classes={classes} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormItem label="School" classes={classes} />
          <FormItem label="Hometown" classes={classes} />
          <FormItem label="Live In" classes={classes} />
          <DateItem label="Birthday" classes={classes} />
          <RadioItem label="Gender" classes={classes} />
          <FormItem label="Personal Quote" classes={classes} textMultiline={textMultiline} row="3" />
        </Grid>
      </Grid>
      <div className={classes.submitGroup}>
        <Button className={classes.btn}>Edit</Button>
        <Button className={classes.btn}>Save</Button>
        <Button className={classes.btn}>Cancel</Button>
      </div>
    </div>
  );
}

export default withStyles(styles)(EditProfile);
