import {connect} from 'react-redux';

import InitiativesPageDetail from './InitialtivesDetailPage';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(InitiativesPageDetail);
