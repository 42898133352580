import guidesPersonBackground from '~/assets/images/content/guides/guides_bg.png';

export const styles = (theme) => ({
  topBox: {
    padding: '50px 10% 30px 10%',
    background: `url(${guidesPersonBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    color: 'white'
  },
  subtitle: {
    color: 'rgba(255, 255, 255, 0.49)',
    fontSize: '16px'
  },
  title: {
    margin: '10px 0 15px 0'
  },
  contentCard: {
    width: '85%',
    marginLeft: '7%',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
      width: '100%'
    },
    position: 'relative'
  },
  cardDesc: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    position: 'absolute',
    left: '20%',
    right: '20%',
    top: '60%',
    color: 'rgba(0, 0, 0, 0.7)',
    fontSize: '16px',
    padding: '40px 30px'
  },
  contentInfo: {
    width: '85%',
    marginLeft: '8%',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
      width: '100%'
    }
  },
  topDesc: {
    marginTop: '10px'
  },
  highLight: {
    color: '#9AA200'
  },
  cartBox: {
    margin: '30px 0',
    padding: '20px 30px',
    background: 'white',
    color: '#595959'
  },
  cartLeft: {
    borderRight: '1px solid #dfdfdf'
  },
  cartBoxTitle: {
    marginBottom: '25px',
    fontWeight: 'bold'
  },
  categorySelect: {
    position: 'relative'
  },

  selectBtn: {
    minWidth: '120px'
  },

  inputLabel: {width: '50px', backgroundColor: 'white', textAlign: 'center'},

  price: {
    color: '#9AA200',
    marginLeft: '20px',
    fontSize: '28px'
  },
  btnAddCart: {
    height: '50px',
    background: '#9AA200',
    borderRadius: '5px',
    fontSize: '18px',
    lineHeight: '50px',
    textAlign: 'center',
    color: 'white',
    marginTop: '30px',
    width: '90%'
  },
  btnViewCart: {
    height: '50px',
    borderRadius: '5px',
    border: '1px solid #9AA200',
    fontSize: '18px',
    lineHeight: '50px',
    textAlign: 'center',
    color: '#9AA200',
    marginTop: '20px',
    width: '90%'
  },
  cartRight: {
    paddingLeft: '10%'
  },
  infoTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#8C8C8C'
  },
  infoRow: {
    justifyContent: 'space-between',
    color: '#434343',
    fontSize: '14px',
    marginTop: '10px'
  },
  cartBottomDesc: {
    marginTop: '15px',
    color: '#8C8C8C'
  },
  bottomDesc: {
    marginTop: '25px',
    fontSize: '16px'
  },
  recContainer: {
    padding: '40px 30px 20px 30px'
  },
  recTitle: {
    textAlign: 'center',
    color: '#595959'
  },
  viewAllWrapper: {
    paddingRight: '60px'
  },
  viewAllItem: {
    width: '240px',
    cursor: 'pointer',
    '& img': {
      width: '20px'
    }
  },
  btnAddCardSmall: {
    background: '#9AA200',
    borderRadius: '3px',
    fontSize: '13px',
    textAlign: 'center',
    color: 'white',
    marginTop: '10px',
    display: 'inline-box',
    padding: '3px 5px'
  },
  btnLeft: {
    width: '20px',
    cursor: 'pointer'
  },
  btnRight: {
    width: '20px',
    cursor: 'pointer'
  },
  cardRowBox: {
    marginTop: '15px'
  },
  cardRow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flex: '1',
    margin: '0 30px',
    position: 'relative'
  },
  cardRowItem: {
    display: 'inline-box',
    textAlign: 'center',
    marginRight: '10px',
    width: 'calc(20% - 8px)',
    [theme.breakpoints.only('sm')]: {
      width: 'calc(25% - 7.5px)'
    },
    [theme.breakpoints.only('xs')]: {
      width: 'calc(33.33% - 6.66px)'
    }
  }
});
