export const StarsStyles = (theme) => ({
  SubHeading: {
    display: 'block',
    fontSize: '0.8rem',
    fontWeight: 700,
    letterSpacing: '1px',
    textTransform: 'uppercase',
    margin: 0
  },
  day: {
    lineHeight: '16px',
    display: 'inline-block',
    textAlign: 'center',
    margin: '2px',
    cursor: 'pointer',
    width: '16px',
    heigt: '16px',
    borderRadius: '50%',
    '&:hover': {
      boxShadow: '2px 4px 6px #888888'
    }
  },
  daySelected: {
    backgroundColor: '#55aac7'
  },
  starImage: {
    width: '20px',
    margin: '1px',
    borderRadius: '10%',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '2px 4px 6px #888888'
    }
  },
  StarRow: {
    borderWidth: '0px 0px 1px 0px',
    borderStyle: 'solid',
    borderColor: '#FFFFFF'
  },
  StarRowSelected: {
    borderWidth: '0px 0px 1px 0px',
    borderStyle: 'solid',
    borderColor: '#55aac7'
  },
  infoIcon: {
    fontSize: '0.7rem',
    color: '#55aac7'
  },
  spanPopover: {
    cursor: 'pointer'
  },
  popoverText: {
    fontSize: '0.8rem',
    lineHeight: '1rem',
    letterSpacing: '1px'
  }
});
