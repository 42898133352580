import {connect} from 'react-redux';

import GuidesDetailPage from './GuidesDetailPage';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GuidesDetailPage);
