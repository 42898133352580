// @ts-check
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Card, CardMedia, CardContent, Typography, Box, Link } from '@material-ui/core';

import { TopButton, SummaryCard } from '~/components/ui';
import { PlayCircleOutlineIcon } from '~/components/ui/icons';

import useStyles from './VideoCardBoxStyles';

/**
 * @typedef {Object} VideoCardBoxProps
 * @property {import('shared/react-query/strapiAPI').VideoEntity} item
 * @property {import('shared/react-query/strapiAPI').HCNCategory} category
 * @property {Function} handleClick
 * @property {string} className
 */

/**
 * 
 * @param {VideoCardBoxProps} props 
 * @returns 
 */
const VideoCardBox = (props) => {
  const classes = useStyles();
  const { item, category, handleClick } = props;
  const { id, title, content, videoUrl } = item;
  const { className } = props;

  return (
    <Link
      className={clsx(classes.root, className)}
      onClick={() => handleClick(id, category.id)}
    >
      <Card className={classes.container} elevation={0} square={true}>

        <CardMedia className={classes.media}>
          <div className={classes.videoThumbnailContainer}>
            <video>
              <source src={`${videoUrl}#t=0.1`} type="video/mp4" />
            </video>
          </div>
          <Box className={classes.playButtonContainer} component="div">
            <PlayCircleOutlineIcon className={classes.playButton} />
          </Box>
          <Typography>
            <TopButton style={{ backgroundColor: category?.color }} onClick={() => handleClick(id, category.id)}>{category?.title}</TopButton>
          </Typography>
        </CardMedia>

        <CardContent className={classes.contentContainer}>
          <Typography className={classes.title}>{title}</Typography>
          <Box className={classes.contentBox}>
            <SummaryCard className={classes.content} content={content} />
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
};

export default VideoCardBox;
