import React from 'react';
import {withStyles, Box, Grid} from '@material-ui/core';
import styles from './IntroCharterCardStyles';
import clsx from 'clsx';

function IntroCharterCard(props) {
  const {classes} = props;
  const {number, title, text, displayGrid, displayText} = props;

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" className={classes.mainGrid}>
        <Grid item container className={classes.title} justifyContent="center" alignContent="center">
          <Grid item>
            <Box element="div" className={classes.number}>
              {number + 1}
            </Box>
            <div className={classes.titleTypography} dangerouslySetInnerHTML={{__html: title}} />
            <Box element="div" className={classes.butterfly}>
              &nbsp;
            </Box>
          </Grid>
        </Grid>

        <Grid item className={clsx({[classes.textGrid]: true, [classes.showTextGrid]: displayGrid})}>
          <Box
            element="div"
            className={clsx({[classes.textBox]: true, [classes.showText]: displayText})}
            dangerouslySetInnerHTML={{__html: text}}
          ></Box>
        </Grid>
      </Grid>
    </>
  );
}

export default withStyles(styles)(IntroCharterCard);
