import MetaWheel_Symbol from '~/assets/images/organize/MetaWheel_Symbol.png';
import MetaWheel_Personal from '~/assets/images/organize/MetaWheel_Personal.png';
import MetaWheel_Global from '~/assets/images/organize/MetaWheel_Global.png';
import MetaWheel_Entire from '~/assets/images/organize/MetaWheel_Entire.png';

const organizeLandingData = {
  getAppText: 'GET METAWHEEL APPLICATION',
  image: [MetaWheel_Symbol, MetaWheel_Personal, MetaWheel_Global, MetaWheel_Entire],
  hashText: ["MetaWheelSymbol", "MetaWheelPersonal", "MetaWheelGlobal", "MetaWheelEntire"],
  switcherText: ['METAWHEEL SYMBOL', 'METAWHEEL PERSONAL', 'METAWHEEL GLOBAL', 'METAWHEEL ENTIRE ARCHITECTURE'],
  descriptionTop: [
    'METAWHEEL SYMBOL DESCRIPTION TOP Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates soluta neque molestiae reiciendis est porro saepe explicabo, voluptatem corporis fugit illo exercitationem atque maxime dolorem quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque? quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque?',
    'METAWHEEL PERSONAL DESCRIPTION TOP   Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates soluta neque molestiae reiciendis est porro saepe explicabo, voluptatem corporis fugit illo exercitationem atque maxime dolorem quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque?quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque?',
    'METAWHEEL GLOBAL DESCRIPTION TOP  Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates soluta neque molestiae reiciendis est porro saepe explicabo, voluptatem corporis fugit illo exercitationem atque maxime dolorem quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque?quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque?',
    'METAWHEEL ENTIRE ARCHITECTURE DESCRIPTION TOP  Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates soluta neque molestiae reiciendis est porro saepe explicabo, voluptatem corporis fugit illo exercitationem atque maxime dolorem quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque?quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque?'
  ],
  descriptionBottom: [
    'METAWHEEL SYMBOL DESCRIPTION BOTTOM  Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates soluta neque molestiae reiciendis est porro saepe explicabo, voluptatem corporis fugit illo exercitationem atque maxime dolorem quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque?Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates soluta neque molestiae reiciendis est porro saepe explicabo, voluptatem corporis fugit illo exercitationem atque maxime dolorem quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque?',
    'METAWHEEL PERSONAL DESCRIPTION BOTTOM  Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates soluta neque molestiae reiciendis est porro saepe explicabo, voluptatem corporis fugit illo exercitationem atque maxime dolorem quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque?Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates soluta neque molestiae reiciendis est porro saepe explicabo, voluptatem corporis fugit illo exercitationem atque maxime dolorem quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque?',
    'METAWHEEL GLOBAL DESCRIPTION BOTTOM  Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates soluta neque molestiae reiciendis est porro saepe explicabo, voluptatem corporis fugit illo exercitationem atque maxime dolorem quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque?Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates soluta neque molestiae reiciendis est porro saepe explicabo, voluptatem corporis fugit illo exercitationem atque maxime dolorem quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque?',
    'METAWHEEL ENTIRE ARCHITECTURE DESCRIPTION BOTTOM  Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates soluta neque molestiae reiciendis est porro saepe explicabo, voluptatem corporis fugit illo exercitationem atque maxime dolorem quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque?Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates soluta neque molestiae reiciendis est porro saepe explicabo, voluptatem corporis fugit illo exercitationem atque maxime dolorem quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit atque?'
  ]
};

export default organizeLandingData;
