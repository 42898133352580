import React from 'react';
import { useState, useEffect } from 'react';
import {withStyles, Typography, IconButton} from '@material-ui/core';
import {ProfileCard} from '~/components/ui';

import TwitterIcon from '~/assets/images/icons/iconTwitter.png';
import InstagramIcon from '~/assets/images/icons/iconIns.png';
import FacebookIcon from '~/assets/images/icons/iconFacebook.png';
import LinkedInIcon from '~/assets/images/icons/iconLinkenin.png';
import WhatsAppIcon from '~/assets/images/icons/iconWhatsup.png';
import YouTubeIcon from '~/assets/images/icons/iconYoutube.png';
import VIcon from '~/assets/images/icons/iconV.png';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },

  title: {
    fontSize: '1rem',
    textAlign: 'center',
    color: '#262626',

  },
  iconSet: {
    textAlign: 'center'
  },
  icons: {
    width: '32px'
  }
});

function SocialMediaCard(props) {
  const {classes} = props;
  const [isMobile, setIsMobile] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 850); // Adjust the width threshold as needed
    };
    handleResize(); // Check initial width
    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up
    };
  }, []);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };


  return (
    <ProfileCard className={classes.root}>
      <Typography onClick={isMobile ? toggleCollapse : undefined} className={classes.title} component="div" style={{cursor:isMobile?"pointer":"initial", margin: isCollapsed ? '0px auto' : '10px auto 5px'}}>
        EMPOWERMENT ADMIN
      </Typography>
      {(!isMobile || !isCollapsed) && (
      <div className={classes.iconSet}>
        <IconButton>
          {' '}
          <img className={classes.icons} alt="icon" src={TwitterIcon} />
        </IconButton>
        <IconButton>
          <img className={classes.icons} alt="icon" src={InstagramIcon} />
        </IconButton>
        <IconButton>
          {' '}
          <img className={classes.icons} alt="icon" src={FacebookIcon} />
        </IconButton>
        <IconButton>
          {' '}
          <img className={classes.icons} alt="icon" src={LinkedInIcon} />
        </IconButton>
        <IconButton>
          {' '}
          <img className={classes.icons} alt="icon" src={WhatsAppIcon} />
        </IconButton>
        <IconButton>
          {' '}
          <img className={classes.icons} alt="icon" src={YouTubeIcon} />
        </IconButton>
        <IconButton>
          {' '}
          <img className={classes.icons} alt="icon" src={VIcon} />
        </IconButton>
      </div>
      )}
    </ProfileCard>
  );
}

export default withStyles(styles)(SocialMediaCard);
