import React from 'react';
import {withStyles} from '@material-ui/core';

import ContentHeader from '../ContentHeader';
import IconShare from '~/assets/images/profile/ic-item-business-newsfeed-share.png';
import IconComment from '~/assets/images/profile/ic-item-business-newsfeed-comment.png';
import IconVideo from '~/assets/images/profile/ic-item-personal-profile-myfeed-video.png';

const styles = (theme) => ({
  root: {
    padding: '30px 40px'
  },
  contentWrapper: {
    margin: '10px',
    borderRadius: '10px',
    border: 'solid 1px #707070',
    padding: '10px',
    overflow: 'hidden'
  },
  flexExpand: {
    flex: 1
  },
  articleImgWrapper: {
    position: 'relative'
  },
  articleImg: {
    width: '100%'
  },
  icVideo: {
    backgroundImage: `url("${IconVideo}")`,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60px',
    height: '60px',
    backgroundSize: 'cover'
  },
  articleTitle: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  articleDesc: {
    fontSize: '14px',
    marginTop: '10px'
  },
  bottomSection: {
    marginTop: '15px',
    fontSize: '13px'
  },
  bottomLeft: {
    float: 'left'
  },
  bottomRight: {
    float: 'right'
  },
  shareWrapper: {
    marginRight: '20px'
  },
  commentWrapper: {},
  iconBottom: {
    height: '20px',
    verticalAlign: 'middle',
    display: 'inline-block',
    marginRight: '5px'
  },
  time: {
    fontSize: '13px'
  },
  btnVideo: {
    borderRadius: '3px',
    background: '#DEAF43',
    display: 'inline-block',
    padding: '2px 30px',
    color: 'white',
    marginRight: '10px',
    marginLeft: '10px',
    cursor: 'pointer'
  },
  btnHealth: {
    borderRadius: '3px',
    background: '#51BE58',
    padding: '2px 30px',
    display: 'inline-block',
    color: 'white',
    cursor: 'pointer'
  }
});

function NewsFeed(props) {
  const {classes, title, titleIcon, articleTitle, articleDesc, articleImg, isArticleVideo} = props;
  return (
    <div className={classes.root}>
      <ContentHeader name={title} icon={titleIcon} />
      <div className={classes.contentWrapper}>
        <div className={classes.articleImgWrapper}>
          <img src={articleImg} className={classes.articleImg} alt="bg"></img>
          {isArticleVideo ? <span className={classes.icVideo}></span> : null}
        </div>
        <div className={classes.articleTitle}>{articleTitle}</div>
        <div className={classes.articleDesc}>{articleDesc}</div>
        <div className={classes.bottomSection}>
          <div className={classes.bottomLeft}>
            <span className={classes.shareWrapper}>
              <img src={IconShare} className={classes.iconBottom} alt="share"></img>
              <span>SHARE</span>
            </span>
            <span className={classes.commentWrapper}>
              <img src={IconComment} className={classes.iconBottom} alt="comment"></img>
              <span>COMMENT</span>
            </span>
          </div>
          <div className={classes.bottomRight}>
            <span className={classes.time}>20 March, 2019</span>
            <span className={classes.btnVideo}>VIDEO</span>
            <span className={classes.btnHealth}>HEALTH</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(NewsFeed);
