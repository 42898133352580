import React from 'react';
import {withStyles} from '@material-ui/core';
import IntroScene from './IntroScene';

import Background from '~/assets/images/intro/metaWheel/scene2_bg.png';

const styles = (theme) => ({});

function Scene2(props) {
  return (
    <IntroScene
      background={Background}
      ratio={0.314}
      container
      titleList={[{text: 'Discover ', highlight: true}, 'deeper aspects to yourself.']}
      descList={[
        'The only way to grow yourself is to know yourself. Use our comprehensive questionnaire process and testing facilities to help understand your current life state. Start your personal Self discovery today with the Metawheel.'
      ]}
    ></IntroScene>
  );
}

export default withStyles(styles)(Scene2);
