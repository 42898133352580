export const styles = (theme) => ({
  expandBar: {
    paddingTop: 0,
    paddingBottom: 0
  },
  icon: {
    marginBottom: -3,
    height: 18,
    width: 18
  },
  breadcrumbs: {
    color: '#FFFFFF'
  },
  expandBarText: {
    color: '#FFFFFF',
    fontWeight: '600',
    textTransform: 'capitalize',
    letterSpacing: 0.5
  },
  expandBarCheckBox: {
    fontSize: '0.875rem',
    color: '#FFFFFF'
  },
  checkBoxIcon: {
    height: 16,
    width: 16
  },
  checkBoxLabel: {
    display: 'inline-block',
    marginLeft: -8,
    fontSize: '0.875rem',
    transform: 'translateY(1px)'
  },
  switch: {
    borderRadius: '15px',
    background: 'white',
    height: '30px',
    width: '180px',
    color: 'black',
    cursor: 'pointer',
    textAlign: 'center'
  },

  switcherIcon: {
    display: 'inline-block',
    marginRight: '8px',
    width: '15px'
  },

  switchLeft: {
    flex: 1,
    height: '30px',
    lineHeight: '30px',
    borderTopLeftRadius: '15px',
    borderBottomLeftRadius: '15px'
  },
  switchRight: {
    flex: 1,
    height: '30px',
    lineHeight: '30px',
    borderTopRightRadius: '15px',
    borderBottomRightRadius: '15px'
  },
  switchActive: {
    background: '#023B78',
    color: 'white'
  }
});

export default styles;
