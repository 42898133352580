import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, CardMedia, Link} from '@material-ui/core';
import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';
import SectionTitle from '../SectionTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 36,
    marginBottom: 36
  },
  container: {
    marginTop: 32,
    minWidth: 350,
    '& a:not(:last-child)': {
      marginRight: 15
    }
  },
  media: {
    width: 75,
    height: 75,
    transition: `transform .3s ease-out`,
    '&:hover': {
      transform: 'scale(1.05, 1.05)'
    }
  }
}));

const InstagramGallery = ({className, images}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <SectionTitle>{intl.get(messageKeys.COMPONENT_INSTAGRAM_GALLERY_TITLE)}</SectionTitle>
      <Grid container justifyContent="center" className={classes.container}>
        {images.slice(0, 4).map((image, idx) => (
          <Link key={`image_${idx}`} href={image.image} target="_blank" rel="noopener">
            <CardMedia src={image.thumbnail} component="img" className={classes.media} />
          </Link>
        ))}
      </Grid>
    </div>
  );
};

export default InstagramGallery;
