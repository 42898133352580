import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import clsx from 'clsx';
import IconChatLeft from '~/assets/images/profile/ic-item-personal-profile-mymessenger-chat-left.png'
import IconChatRight from '~/assets/images/profile/ic-item-personal-profile-mymessenger-chat-right.png'

const styles = (_) => ({
  contentWrapperRight: {
    flexDirection: 'row-reverse'
  },
  messageContentWrapper: {
    maxWidth: '60%',
    marginLeft: '5px'
  },
  messageContentWrapperRight: {
    marginRight: '5px'
  },
  userHead: {
    width: '45px',
  },
  userName: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '14px'
  },
  sendTime: {
    fontSize: '12px',
    color: '#7B7B7B',
    fontWeight: 'bold'
  },
  alignTextRight: {
    textAlign: 'right'
  },
  popupWrapper: {
    backgroundColor: 'rgba(241, 244, 245)',
    borderRadius: '10px',
    border: '1px solid white',
    position: 'relative',
    padding: '15px',
    marginTop: '5px'
  },
  popupWrapperRight: {
    backgroundColor: '#B5B5B5',
    borderColor: '#9C9C9C'
  },
  popupTip: {
    width: '25px',
    position: 'absolute',
    left: '-10.8px',
    top: '-10px'

  },
  popupTipRight: {
    right: '-10.8px',
    top: '-10.5px',
    left: 'unset'
  }
});

function ChatItem(props) {
  const { message, classes } = props
  return (
    <Grid container className={message.isSender ? classes.contentWrapperRight : ''}>
      <Grid item>
        <img src={message.userHead} className={classes.userHead} alt="user head" />
      </Grid>
      <Grid item className={
        clsx(classes.messageContentWrapper, {
          [classes.messageContentWrapperRight]: message.isSender
        })
      }>
        <div className={clsx(classes.userName, {
          [classes.alignTextRight]: message.isSender
        })}>{message.userName}</div>

        <div className={clsx(classes.sendTime, {
          [classes.alignTextRight]: message.isSender
        })}>{message.sendTime}</div>

        <div className={
          clsx(classes.popupWrapper, {
            [classes.popupWrapperRight]: message.isSender
          })
        }>
          <img
            src={message.isSender ? IconChatRight : IconChatLeft}
            className={
              clsx(classes.popupTip, {
                [classes.popupTipRight]: message.isSender
              })
            }
            alt="popup tip" />
          <div>{message.text}</div>
        </div>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(ChatItem);
