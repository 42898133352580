import React, {useRef, useEffect, useState} from 'react';
import * as d3 from 'd3';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import {TransitionsModal} from '~/components/ui';
import BackgroundImage from '~/assets/images/organize/MetaWheel_Personal_Core.png';

const outerLayer = [
  {percentage: '0.9', name: 'Health Psychology'},
  {percentage: '0.3', name: 'Life Force Food'},
  {percentage: '1', name: 'Longevity Activities'},

  {percentage: '0.1', name: 'Contextual Intelligence'},
  {percentage: '0', name: 'Experiential Learning'},
  {percentage: '0.5', name: "Key Literacy's"},

  {percentage: '0.8', name: 'Significant Media'},
  {percentage: '0.2', name: 'Abundant Living'},
  {percentage: '0.9', name: 'Advanced Parenting'},

  {percentage: '1', name: 'Source'},
  {percentage: '0.8', name: 'Magnanimity & Grace'},
  {percentage: '0.5', name: 'Godforce'}

];

const innerLayer = [
  {percentage: '0.1', name: 'Physiological Efficiencies'},
  {percentage: '0.2', name: 'Natural Remedies'},
  {percentage: '0.3', name: 'Purity'},

  {percentage: '0.4', name: 'Unique Passion Flow'},
  {percentage: '0.5', name: 'Masterminds S.P.'},
  {percentage: '0.6', name: 'Ethical Entrepreneur'},

  {percentage: '0.7', name: 'New Being Nuture'},
  {percentage: '0.8', name: 'Soul Mate Nuture'},
  {percentage: '0.9', name: 'Conscious Com.'},

  {percentage: '0.4', name: 'Capacity & Service'},
  {percentage: '0', name: 'Authenticity & P.O.Ps'},
  {percentage: '0', name: 'Vib. Transcendence'}
];

const layerTitles = [
  {percentageColor: '#51BE58', backgroundColor: '#DBF0DC', name: 'Vitality and Longevity', s: 0, e: 3},
  {percentageColor: '#1EAAF1', backgroundColor: '#D0ECFB', name: 'Wisdom and Successes', s: 3, e: 6},
  {
    percentageColor: '#FA44B3',
    backgroundColor: '#FCD8EE',
    name: 'Empowerment and Significance',
    s: 6,
    e: 9
  },
  {percentageColor: '#FED130', backgroundColor: '#FDF4D4', name: 'Clarity and Beauty', s: 9, e: 12}
];

const useStyles = makeStyles((theme) => ({
  root: {
    background: `url(${BackgroundImage}) no-repeat`,
    backgroundPosition: '4px 0px'
  }
}));

const MetalWheelPersonalChart = () => {
  const ref = useRef();
  const w = 960;
  const h = w;
  const outerThickness = 30;
  const outerMargin = 3;

  const classes = useStyles();

  const tau = 2 * Math.PI;

  const [currentSection, setCurrentSection] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (currentSection) {
      setOpen(true);
    }
  }, [currentSection]);

  const handleClose = () => {
    setOpen(false);
    setCurrentSection(null);
  };

  const isTextInverted = (d) => {
    return d.startAngle > (tau / 8) * 3 - 0.1 && d.endAngle < (tau / 8) * 5 + 0.1;
  };

  const getLabelArc = (d, startAngle, endAngle) => {
    //A regular expression that captures all in between the start of a string
    //(denoted by ^) and the first capital letter L
    // var firstArcSection = /(^.+?)L/;

    //The [1] gives back the expression between the () (thus not the L as well)
    //which is exactly the arc statement
    // var newArc = firstArcSection.exec( d3.select(this).attr("d") )[1];
    //Replace all the comma's so that IE can handle it -_-
    //The g after the / is a modifier that "find all matches rather than
    //stopping after the first match"
    //newArc = newArc.replace(/,/g , " ");
    // console.log(newArc);

    //Search pattern for everything between the start and the first capital L
    const firstArcSection = /(^.+?)L/;

    //Grab everything up to the first Line statement
    // var newArc = firstArcSection.exec( d3.select(this).attr("d") )[1];
    let newArc = firstArcSection.exec(d)[1];

    //Replace all the commas so that IE can handle it
    //newArc = newArc.replace(/,/g , " ");

    //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
    //flip the end and start position
    if (isTextInverted({startAngle, endAngle})) {
      //Everything between the capital M and first capital A
      var startLoc = /M(.*?)A/;
      //Everything between the capital A and 0 0 1
      var middleLoc = /A(.*?)0,0,1/;
      //Everything between the 0 0 1 and the end of the string (denoted by $)
      var endLoc = /0,0,1,(.*?)$/;
      //Flip the direction of the arc by switching the start and end point
      //and using a 0 (instead of 1) sweep flag
      var newStart = endLoc.exec(newArc)[1];
      var newEnd = startLoc.exec(newArc)[1];
      var middleSec = middleLoc.exec(newArc)[1];

      //Build up the new arc notation, set the sweep-flag to 0
      newArc = 'M' + newStart + 'A' + middleSec + '0,0,0,' + newEnd;
    } //if

    return newArc;
  };

  const layerLabelRenderer = (layer) => (node) => {
    node
      .append('text')
      .attr('dy', function(d, i) {
        return isTextInverted(d) ? -16 : 14;
      })
      .append('textPath')
      .attr('startOffset', '50%')
      .style('text-anchor', 'middle')
      .attr('xlink:href', function(d, i) {
        return `#${layer}_${i}`;
      })
      .attr('dominant-baseline', 'central')
      .text(function(d) {
        return d.name;
      })
      .attr('font-size', (12 / 750) * w + 'px')
      .style('fill', '#000')
      .style('pointer-events', 'none')
      .attr('font-weight', 700);
  };

  const renderLayerTitles = (arcOuterLayer_OuterRadius, g) => {
    // define separate arc for titles on the layer arc
    const arcLayerTitles_innerRadius = arcOuterLayer_OuterRadius;
    const arcLayerTitles_OuterRadius = arcLayerTitles_innerRadius + 18;
    const arcLayerTitlesArc = d3
      .arc()
      .innerRadius(arcLayerTitles_innerRadius)
      .outerRadius(arcLayerTitles_OuterRadius);

    const arcLayerTitlesStartAngle = -tau / layerTitles.length / 2;
    var arcLayerTitlesPie = d3
      .pie()
      .startAngle(arcLayerTitlesStartAngle)
      .endAngle(arcLayerTitlesStartAngle + tau)
      .value(function(d) {
        return 1;
      })
      .padAngle(0.01)
      .sort(null);

    g.selectAll('.layerTitle')
      .data(arcLayerTitlesPie(layerTitles))
      .enter()
      .each(function(d, i) {
        const labelArc = getLabelArc(arcLayerTitlesArc(d), d.startAngle, d.endAngle);

        //Create a new invisible arc that the text can flow along
        g.append('path')
          .classed('layerTitle', true)
          .attr('class', 'hiddenLayerTitleArcs')
          .attr('id', 'layerTitleArc' + i)
          .attr('d', labelArc)
          .style('fill', 'none');
      });

    //Append the outer label names to each slice
    g.selectAll('.layerTitleText')
      .data(arcLayerTitlesPie(layerTitles))
      .enter()
      .append('text')
      .attr('class', 'layerTitleText')
      .attr('dy', function(d, i) {
        return isTextInverted(d) ? 18 : -11;
      })
      .append('textPath')
      .attr('startOffset', '50%')
      .style('text-anchor', 'middle')
      .attr('xlink:href', function(d, i) {
        return '#layerTitleArc' + i;
      })
      .text(function(d) {
        return d.data.name;
      })
      .attr('font-size', (18 / 750) * w + 'px')
      .attr('font-weight', 700)
      .attr('letter-spacing', 2.2);
  };

  const renderLayer = (g, layerName, layerData, layerArc) => {
    layerData.forEach(calcArcAngle(layerData));

    // draw layer pie
    const pieLayer = g
      .selectAll(`.${layerName}`)
      .data(layerData)
      .enter();

    pieLayer
      .append('path')
      .classed(`${layerName}`, true)
      .attr('id', function(d, i) {
        return `${layerName}Arc_${i}`;
      })
      .style('fill', function(d) {
        return d.backgroundColor;
      })
      .attr('d', layerArc)
      .each(function(d, i) {
        const labelArc = getLabelArc(layerArc(d), d.startAngle, d.endAngle);

        //Create a new invisible arc that the text can flow along
        g.append('path')
          .classed(`${layerName}Label`, true)
          .attr('class', `hidden${layerName}LabelArc`)
          .attr('id', `${layerName}LabelArc_${i}`)
          .attr('d', labelArc)
          .style('fill', 'none');

        const percentAngle = d.startAngle + (d.endAngle - d.startAngle) * d.percentage;
        g.append('path')
          .classed(`${layerName}Percentage`, true)
          .attr('class', `${layerName}Percentage`)
          .attr('id', `${layerName}Percentage_${i}`)
          .attr('d', layerArc({startAngle: d.startAngle, endAngle: percentAngle}))
          .style('pointer-events', 'none')
          .style('fill', d.percentageColor);
      })
      .style('cursor', 'pointer')
      .on('mouseover', function(d, i) {
        g.append('path')
          .classed('outline', true)
          .attr('id', `${layerName}_outline_${i}`)
          .attr('d', layerArc(d))
          .style('stroke', '#C3C3C3')
          .style('stroke-width', '2')
          .style('pointer-events', 'none')
          .style('fill', 'none');
      })
      .on('mouseout', function(d, i) {
        d3.select(`#${layerName}_outline_${i}`).remove();
      })
      .on('click', (data) => setCurrentSection(data));

    pieLayer.call(layerLabelRenderer(`${layerName}LabelArc`));
  };

  const getLayerTitle = (index) => layerTitles.find((l) => index < l.e && index >= l.s);

  const calcArcAngle = (layer) => (el, i, arr) => {
    const spliterSeg = tau / 72;
    const seg = (tau - spliterSeg * layerTitles.length) / layer.length;
    const offset = Math.floor(i / (layer.length / layerTitles.length)) * spliterSeg - (tau / 8 - (tau / 256) * 2);
    el.startAngle = i * seg + offset;
    el.endAngle = (i + 1) * seg + offset;
    const layerTitle = getLayerTitle(i);
    el.backgroundColor = layerTitle.backgroundColor;
    el.percentageColor = layerTitle.percentageColor;
  };

  useEffect(() => {
    const svg = d3.select(ref.current);

    const g = svg.append('g').attr('transform', 'translate(' + w / 2 + ',' + h / 2 + ')');

    // define inner layer arc
    const arcInnerLayer_OuterRadius = 350;
    const arcInnerLayer_innerRadius = arcInnerLayer_OuterRadius - outerThickness;
    const arcInnerLayer = d3
      .arc()
      .innerRadius(arcInnerLayer_OuterRadius)
      .outerRadius(arcInnerLayer_innerRadius)
      .padAngle(0.001 * tau);

    // define outer layer arc
    const arcOuterLayer_OuterRadius = arcInnerLayer_OuterRadius + outerMargin + outerThickness;
    const arcOuterLayer_innerRadius = arcOuterLayer_OuterRadius - outerThickness;
    const arcOuterLayer = d3
      .arc()
      .innerRadius(arcOuterLayer_OuterRadius)
      .outerRadius(arcOuterLayer_innerRadius)
      .padAngle(0.0009 * tau);

    renderLayer(g, 'innerLayer', innerLayer, arcInnerLayer);
    renderLayer(g, 'outerLayer', outerLayer, arcOuterLayer);

    renderLayerTitles(arcOuterLayer_OuterRadius, g);
  }, []);
  return (
    <>
      <svg className={classes.root} width={w} height={h} ref={ref}></svg>
      {currentSection && (
        <TransitionsModal open={open} onClose={handleClose} title={currentSection.name}>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1} style={{backgroundColor: `${currentSection.backgroundColor}`}} height={35}>
              <Box
                style={{backgroundColor: `${currentSection.percentageColor}`}}
                width={`${Math.round(currentSection.percentage * 100)}%`}
                height={35}
              ></Box>
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(
                currentSection.percentage * 100
              )}%`}</Typography>
            </Box>
          </Box>
          <p>
            METAWHEEL SYMBOL DESCRIPTION BOTTOM Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates
            soluta neque molestiae reiciendis est porro saepe explicabo, voluptatem corporis fugit illo exercitationem
            atque maxime dolorem quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos
            facere fugit atque?Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates soluta neque
            molestiae reiciendis est porro saepe explicabo, voluptatem corporis fugit illo exercitationem atque maxime
            dolorem quaerat illum quam dolore deleniti ipsum unde veritatis! Voluptatum, esse! Cum quos facere fugit
            atque?
          </p>
        </TransitionsModal>
      )}
    </>
  );
};

export default MetalWheelPersonalChart;
