import {Grid, Typography, Link} from '@material-ui/core';
import React from 'react';
import BiosphericallyCorrectIcon from '../assets/app-drawer-icons/BiosphericallyCorrectIcon.js';
import HealthCreatorIcon from '../assets/app-drawer-icons/HealthCreatorIcon.js';
import MasterSanctuaryIcon from '../assets/app-drawer-icons/MasterSanctuaryIcon.js';
import MetaWheelIcon from '../assets/app-drawer-icons/MetaWheelIcon.js';
import SanctuaryStoreIcon from '../assets/app-drawer-icons/SanctuaryStoreIcon.js';
import UrbanSanctuaryIcon from '../assets/app-drawer-icons/UrbanSanctuaryIcon.js';
import VisionForHumanityIcon from '../assets/app-drawer-icons/VisionForHumanityIcon.js';
import VisionProductsIcon from '../assets/app-drawer-icons/VisionProductsIcon.js';
import ButterflyIcon from '../assets/butterflyAppBar.png';
import HCNIcon from '../assets/hcn.png';

const data = [
  {
    title: 'NETWORKS',
    icons: [
      {image: <VisionForHumanityIcon />, title: 'Vision For Humanity', to: 'https://vfh.org.nz'},
      {
        image: <BiosphericallyCorrectIcon />,
        title: 'Biospherically Correct',
        to: 'http://www.biosphericallycorrect.org',
      },
      { image: <VisionProductsIcon />, title: 'Vision Products', to: 'https://visionproductsdirect.com/' },
    ],
  },
  {
    title: 'VFH SANCTUARIES',
    icons: [
      {image: <SanctuaryStoreIcon />, title: 'Sanctuary Store', to:'https://sanctuarystore.co.nz/'},
      {image: <UrbanSanctuaryIcon />, title: 'Urban Sanctuary', to: 'https://vfhurbansanctuary.com'},
      {
        image: <MasterSanctuaryIcon />,
        title: 'Master Sanctuary',
        to: 'https://vfhurbansanctuary.com/master-sanctuary',
      },
    ],
  },
  {
    title: 'MEDIA',
    icons: [
      {
        image: <img src={HCNIcon}  alt="HCN Icon" style={{ maxWidth: '40%', height: 'auto' }}/>, 
        title: <div style={{width: '180px'}}>Higher Consciousness Network</div>, 
        to: 'https://higherconsciousnessnetwork.org/'
      },
      {
        image: <img src={ButterflyIcon} alt="Butterfly Icon" style={{ maxWidth: '65%', height: 'auto', marginLeft: '50px' }} />,
        title: <div style={{width: '120px', marginLeft: '50px'}}>2nd Genome</div>, 
        to: 'https://2ndgenome.com/'
      },
    ],
  },
  {
    title: 'APPS',
    icons: [
      {image: <MetaWheelIcon />, title: 'Meta Wheel', to: 'https://urban-sanctuary-dev.web.app/discover#metawheel'},
      {
        image: <HealthCreatorIcon />,
        title: 'Health Creator',
        to: 'https://urban-sanctuary-dev.web.app/discover#healthcreator',
      },
      {
        image: <HealthCreatorIcon />,
        title: 'Weight Shifter',
        to: 'https://urban-sanctuary-dev.web.app/discover#weightshifter',
      },
    ],
  },
 
];
function AppDrawerPopOver() {
  const renderLink = (icon) => {
    const isAbsoluteURL = icon.to.startsWith("http");
    const linkProps = isAbsoluteURL
      ? {
          href: icon.to,
          target: "_blank",
          rel: "noopener noreferrer",
        }
      : {
          to: icon.to,
        };

    return (
      <Link
        {...linkProps}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <div style={{ height: 80, display:'flex', alignItems:'center', justifyContent:"center"}}>{icon.image}</div>
        <Typography variant="body2">{icon.title}</Typography>
      </Link>
    );
  };

return (
  <div style={{ maxWidth: 370, padding: "24px 20px" }}>
    <Grid container spacing={2}>
      {data.map((section, sectionIndex) => (
        <React.Fragment key={`section-${sectionIndex}`}>
          <Grid item xs={12} style={{ textAlign: "center"}}>
            <Typography variant="h6" style={{marginTop: '20px'}}>{section.title}</Typography>
          </Grid>

          <Grid container spacing={3}>
          {section.icons.map((icon, iconIndex) => (
            <Grid key={`icon-${sectionIndex}-${iconIndex}`} item xs={4}>
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{textAlign: 'center' }}
              >
                {renderLink(icon)}
              </Grid>
            </Grid>
          ))}
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  </div>
);

}

export default AppDrawerPopOver;
