import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {withStyles, Grid} from '@material-ui/core';

import {contentTypeRadioBtnStyles} from './Styles';
import globeIcon from '~/assets/images/icons/globe-02.png';
import sanctuaryIcon from '~/assets/images/icons/sanctuary-03.png';

class ContentTypeRadioBtn extends Component {
  render() {
    const {classes} = this.props;

    return (
      <Grid container item direction="column" xs={3} sm={2}>
        <Grid item>
          <Typography align="center">{this.props.contentTypeTitle}</Typography>
        </Grid>

        <Grid item container direction="row" justifyContent="center" alignItems="center">
          <Grid item className={classes.radioBtnGroup}>
            <img className={classes.contentTypeIcon} src={sanctuaryIcon} alt="" />
            <div className={classes.contentTypeRadioBtn}>
              <input
                type="radio"
                value={this.props.personalRadioBtnValue}
                onChange={this.props.selectContentType}
                name="contentType"
              />
            </div>
          </Grid>

          <Grid item className={classes.radioBtnGroup}>
            <img className={classes.contentTypeIcon} src={globeIcon} alt="" />
            <div className={classes.contentTypeRadioBtn}>
              <input
                type="radio"
                value={this.props.globalRadioBtnValue}
                onChange={this.props.selectContentType}
                name="contentType"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ContentTypeRadioBtn.propTypes = {
  classes: PropTypes.object.isRequired,
  contentTypeTitle: PropTypes.string.isRequired,
  personalRadioBtnValue: PropTypes.string.isRequired,
  globalRadioBtnValue: PropTypes.string.isRequired,
  selectContentType: PropTypes.func.isRequired
};

export default withStyles(contentTypeRadioBtnStyles)(ContentTypeRadioBtn);
