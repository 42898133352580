import React from 'react';
import { withStyles, Box, Grid } from '@material-ui/core';

import GridLayout from './GridLayout'
import GuideCard from './GuideCard'

import Image1 from '~/assets/images/profile/bottom-business-fav/4-1.png'
import Image2 from '~/assets/images/profile/bottom-business-fav/4-2.png'
import Image3 from '~/assets/images/profile/bottom-business-fav/4-3.png'
import Image4 from '~/assets/images/profile/bottom-business-fav/4-4.png'
import Image5 from '~/assets/images/profile/bottom-business-fav/4-5.png'
import Image6 from '~/assets/images/profile/bottom-business-fav/4-6.png'

import IconPlanet from '~/assets/images/profile/ic-item-business-fav-planet.png'
import IconHuman from '~/assets/images/profile/ic-item-business-fav-human.png'

const styles = (theme) => ({
  root: {
    padding: '20px 40px'
  },
  header: {
    padding: '10px 0',
    '& img': {
      width: '20px',
      marginRight: '5px'
    },
    '& span': {
      fontWeight: 'bold'
    }
  }
});

const DataList = [
  {
    title: 'Metawheel Global Guides',
    icon: IconPlanet,
    childList: [
      {
        tag: {
          color: '#7ECF79',
          text: 'HEALTH',
          icon: IconPlanet
        },
        title: 'Auctor lectus proin',
        desc: 'Diversity Protection',
        background: Image1
      },
      {
        tag: {
          color: '#D2A75F',
          text: 'JOY',
          icon: IconPlanet
        },
        title: 'Enim porttitor dolor',
        desc: 'Spiritual Authenticity',
        background: Image2
      },
      {
        tag: {
          color: '#BC79BC',
          text: 'INSPIRATION',
          icon: IconPlanet
        },
        title: 'Efficitur morbi gravida',
        desc: 'S & I Media',
        background: Image3
      }
    ]
  },
  {
    title: 'Metawheel Personal Guides',
    icon: IconHuman,
    childList: [
      {
        tag: {
          color: '#7ECF79',
          text: 'HEALTH',
          icon: IconHuman
        },
        title: 'Your Emotional Immune System',
        desc: 'Health Psychology',
        background: Image4
      },
      {
        tag: {
          color: '#D2A75F',
          text: 'JOY',
          icon: IconHuman
        },
        title: 'Nulla vulputate',
        desc: 'Key Literacy’s',
        background: Image5
      },
      {
        tag: {
          color: '#BC79BC',
          text: 'INSPIRATION',
          icon: IconHuman
        },
        title: 'Efficitur morbi gravida',
        desc: 'Advanced Parenting',
        background: Image6
      }
    ]
  }
]

function Guides(props) {
  const { classes } = props;
  return (
    <Box className={classes.root}>
      {
        DataList.map(data => (
          <div>
            <Grid container alignItems="center" className={classes.header}>
              <img src={data.icon} alt={data.title} />
              <span>{data.title}</span>
            </Grid>
            <GridLayout>
              {
                data.childList.map(data => (
                  <GuideCard data={data} />
                ))
              }
            </GridLayout>
          </div>
        ))
      }
    </Box>
  )
}

export default withStyles(styles)(Guides);
