import React from 'react';
import {makeStyles, Grid, Typography} from '@material-ui/core';

import styles from './MoreCardBoxStyles';

const useStyles = makeStyles(styles);

const MoreCardBox = ({content, clicked}) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" justifyContent="center" onClick={clicked} className={classes.root}>
      <Typography className={classes.content}>{content}</Typography>
    </Grid>
  );
};

export default MoreCardBox;
