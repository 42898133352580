import React, {useState} from 'react';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';

import grayCube from '~/assets/images/icons/grayCube.png';
import {cubeIcons} from '~/data/cardVideoData';

const styles = () => ({
  cubeIcons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {margin: '1px'}
  },

  threeCubeGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  transparent: {
    animation: '$transparent 1.5s infinite'
  },

  '@keyframes transparent': {
    '0%': {
      opacity: 1
    },

    '50%': {
      opacity: 0
    },

    '100%': {
      opacity: 1
    }
  }
});

function CubeIcon(props) {
  const {classes} = props;
  const [pulseTop, SetPulseTop] = useState(false);
  const [pulseMiddle, SetPulseMiddle] = useState(false);
  const [pulseBottom, SetPulseBottom] = useState(false);

  const handleMouseEnter = () => {
    SetPulseTop(true);
    setTimeout(() => {
      SetPulseMiddle(true);
    }, 300);
    setTimeout(() => {
      SetPulseBottom(true);
    }, 600);
  };

  const handleMouseLeave = () => {
    SetPulseTop(false);
    setTimeout(() => {
      SetPulseMiddle(false);
    }, 300);
    setTimeout(() => {
      SetPulseBottom(false);
    }, 600);
  };

  let greyCube = (idx) => (
    <div className={classes.threeCubeGroup} key={`grey${idx}`}>
      <img src={grayCube} alt={`Grey Cube ${idx}`} />
      <img src={grayCube} alt={`Grey Cube ${idx}`} />
      <img src={grayCube} alt={`Grey Cube ${idx}`} />
    </div>
  );

  let coloredCubeGroup = (idx) => (
    <div className={classes.threeCubeGroup} key={`colored${idx}`}>
      <img
        className={clsx({[classes.transparent]: pulseTop})}
        src={cubeIcons[props.categoryIndex]}
        alt={`Colored Cube ${idx}`}
      />
      <img
        className={clsx({[classes.transparent]: pulseMiddle})}
        src={cubeIcons[props.categoryIndex]}
        alt={`Colored Cube ${idx}`}
      />
      <img
        className={clsx({[classes.transparent]: pulseBottom})}
        src={cubeIcons[props.categoryIndex]}
        alt={`Colored Cube ${idx}`}
      />
    </div>
  );

  let renderCubeIcon = cubeIcons.map((cubeIcon, index) =>
    index === props.categoryIndex ? coloredCubeGroup(index) : greyCube(index)
  );

  return (
    <div className={classes.cubeIcons} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {renderCubeIcon}
    </div>
  );
}
export default withStyles(styles)(CubeIcon);
