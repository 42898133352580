import React from 'react';
import {withStyles} from '@material-ui/core';

import {styles} from './MetaWheelMainPageStyle';

import Scene1 from './Sections/Scene1';
import Scene2 from './Sections/Scene2';
import Scene3 from './Sections/Scene3';
import Scene4 from './Sections/Scene4';
import Scene5 from './Sections/Scene5';
import Scene6 from './Sections/Scene6';
import Scene7 from './Sections/Scene7';
import Scene8 from './Sections/Scene8';
import Scene9 from './Sections/Scene9';
import Scene10 from './Sections/Scene10';
import Scene11 from './Sections/Scene11';
import Scene12 from './Sections/Scene12';

function MetaWheelMainPage(props) {
  return (
    <div>
      <Scene1 />
      <Scene2 />
      <Scene3 />
      <Scene4 />
      <Scene5 />
      <Scene6 />
      <Scene7 />
      <Scene8 />
      <Scene9 />
      <Scene10 />
      <Scene11 />
      <Scene12 />
    </div>
  );
}

export default withStyles(styles)(MetaWheelMainPage);
