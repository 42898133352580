import React from 'react';
import {withRouter} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import styles from './SectionHeadingStyle';
// Styled Component
import {Typography, Grid} from '@material-ui/core';
import {Sphere} from '~/components/ui';

const useStyles = makeStyles(styles);

function SectionHeading({humanImage, title, description, subHeading, history}) {
  const classes = useStyles();
  const pathname = history.location.pathname;

  return (
    <Grid container className={classes.content}>
      <Grid item md={8} sm={8} xs={12} className={classes.headingPart}>
        <div className={classes.humanimg}>
          <img src={humanImage} className={classes.img} alt="health body" />
        </div>
        <Typography className={classes.mainHeading}>{title}</Typography>
        {pathname === '/organize/health' ? <div className={classes.healthLinkContainer}>{subHeading()}</div> : null}
        {pathname === '/organize/prosperity' ? (
          <div className={classes.prosperityLinkContainer}>{subHeading()}</div>
        ) : null}
        {pathname === '/organize/inspiration' ? (
          <div className={classes.inspirationLinkContainer}>{subHeading()}</div>
        ) : null}
        {pathname === '/organize/joy' ? <div className={classes.joyLinkContainer}>{subHeading()}</div> : null}
        <Typography className={classes.contentDescription}>{description}</Typography>
      </Grid>
      <Grid item md={4} sm={8} xs={12} className={classes.spherePart}>
        <div className={pathname === '/organize/health' ? classes.sphereHealth : null}>
          <RouterLink to="/organize/health">
            <Sphere type={0} sphereRad={60} width={120} height={120} />
          </RouterLink>
        </div>
        <div className={pathname === '/organize/prosperity' ? classes.sphereProsperity : null}>
          {' '}
          <RouterLink to="/organize/prosperity">
            <Sphere type={1} sphereRad={60} width={120} height={120} />
          </RouterLink>
        </div>
        <div className={pathname === '/organize/inspiration' ? classes.sphereInspiration : null}>
          {' '}
          <RouterLink to="/organize/inspiration">
            <Sphere type={2} sphereRad={60} width={120} height={120} />
          </RouterLink>
        </div>
        <div className={pathname === '/organize/joy' ? classes.sphereJoy : null}>
          {' '}
          <RouterLink to="/organize/joy">
            <Sphere type={3} sphereRad={60} width={120} height={120} />
          </RouterLink>
        </div>
      </Grid>
    </Grid>
  );
}

export default withRouter(SectionHeading);
