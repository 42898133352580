import React from 'react';

import FeedTemplate from '../../common/Feed';

import ArticleBg from '~/assets/images/profile/bg-item-business-newsfeed-article.png';
import IconTitle from '~/assets/images/profile/ic-item-business-newsfeed.png';

function NewsFeed(props) {
  return (
    <FeedTemplate
      title="NEWS FEED"
      titleIcon={IconTitle}
      articleContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor. Ut ut libero ullamcorper, tincidunt risus nec."
      articleDesc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor. Ut ut libero ullamcorper, tincidunt risus nec, dapibus felis. Morbi tortor dolor, posuere placerat sapien nec, condimentum varius leo. Pellentesque at fringilla risus, ut ultricies sapien. Nunc rhoncus nunc non purus congue, et dictum risus pulvinar. Donec non ante quam. Pellentesque bibendum, turpis et laoreet mattis, quam libero volutpat augue, a consequat diam nulla eget nisi."
      articleImg={ArticleBg}
    />
  );
}
export default NewsFeed;
