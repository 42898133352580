import guidesBackground from '~/assets/images/content/guides/guides_bg.png';

const styles = (theme) => ({
  backgroundWrapper: {
    background: `url(${guidesBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    backgroundColor: 'rgba(23, 23, 23, 0.31)',
    flex: '1 0 auto'
  }
});

export {styles};
