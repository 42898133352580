import routes from '../../../../routes';

import * as messageKeys from '~/locales';
// import IconNetworkVisionHumanity from '~/assets/images/layout/sidemenu/networks_vision_humanity.png';
// import IconNetworkVisionProducts from '~/assets/images/layout/sidemenu/networks_vision_products.png';
// import IconNetworkSecondGenome from '~/assets/images/layout/sidemenu/networks_second_genome.png';
// import IconAppsComm from '~/assets/images/layout/sidemenu/apps_comm.png';
// import IconAppsHealthCreator from '~/assets/images/layout/sidemenu/apps_health_creator.png';
// import IconAppsMetaWheel from '~/assets/images/layout/sidemenu/apps_meta_wheel.png';
// import IconAppsProsperotyPal from '~/assets/images/layout/sidemenu/apps_prosperoty_pal.png';
// import IconAppsTreasurebox from '~/assets/images/layout/sidemenu/apps_treasurebox.png';
// import IconAppsWeightShifter from '~/assets/images/layout/sidemenu/apps_weight_shifter.png';
// import IconNetworkBioCorrect from '~/assets/images/layout/sidemenu/networks_bio_correact.png';

// const globeLink = '/globe';
// const sanctuaryLink = '/sanctuary';

const contentSubmenu = routes
  .filter((route) => route.menu === 'content')
  .map((route, index) => ({
    name: route.name,
    key: index,
    title: route.name,
    image: route.image,
    link: route.path,
    linkType: route.type
  }));

const organizeSubmenu = routes
  .filter((route) => route.menu === 'organize')
  .map((route, index) => ({
    name: route.name,
    key: index,
    title: route.name,
    image: route.image,
    link: route.path
  }));

const networkSubmenu = routes
  .filter((route) => route.menu === 'network')
  .map((route, index) => ({
    name: route.name,
    key: index,
    title: route.name,
    image: route.image,
    link: route.path
  }));

const introSubmenu = routes
  .filter((route) => route.menu === 'intro')
  .map((route, index) => ({
    name: route.name,
    key: index,
    title: route.name,
    image: route.image,
    link: route.path
  }));

const menuData = [
  {
    name: messageKeys.HEADER_INTROL,
    route: routes.find((route) => route.name === 'Intro'),
    submenu: introSubmenu
  },
  {
    name: messageKeys.HEADER_CONTENT,
    route: routes.find((route) => route.name === 'Content'),
    submenu: contentSubmenu
  },
  {
    name: messageKeys.HEADER_ORGANIZE,
    route: routes.find((route) => route.name === 'Organize'),
    submenu: organizeSubmenu
  },
  {
    name: messageKeys.HEADER_NETWORK,
    route: routes.find((route) => route.name === 'Network'),
    submenu: networkSubmenu
  }
];

export default menuData;
