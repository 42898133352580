import React, { useState } from 'react'
import { withStyles, Grid } from '@material-ui/core';

import Tab from './Sections/Tab'
import ContentItemList from './Sections/ContentItemList'

import { TabList, MainContentList } from '~/data/contentMainData'
import { useQuery } from 'react-query';
import { fetchHCNArticleCategoriesWarper } from 'shared/react-query/strapiAPI';

const getContentList = (allCategories, mode) => {
  return allCategories.data
    ?.filter(e => e.parentTitle?.toUpperCase() === mode.toUpperCase())
    .map(cate => ({
      id: cate.id,
      title: cate.subText,
      titleColor: cate.color,
      itemList: [
        {
          text: 'Quotes',
          type: 'quotes'
        },
        {
          text: 'Articles',
          type: 'articles'
        },
        {
          text: 'Videos',
          type: 'videos'
        },
        {
          text: 'Metawheel Global Guides',
          subList: allCategories.data.filter(subCate => subCate.parentId === cate.id).map(subCate => subCate.title)
        }
      ]
    }));
}

const styles = theme => ({
  root: {
    padding: '40px 60px'
  },
  title: {
    color: '#D2A75F',
    fontSize: '20px',
    letterSpacing: '2px',
    margin: '20px 0 40px 0'
  },
  itemIcon: {
    width: '30px',
  },
  itemTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginLeft: '10px'
  },
  itemListWrapper: {
    marginLeft: '40px'
  },
  mainConentItem: {
    marginBottom: '20px'
  }
})

function Content(props) {
  const [selectIndex, setSelectIndex] = useState(0)
  const { classes } = props
  const allCategories = useQuery("HCNArticleCategories", () =>
    fetchHCNArticleCategoriesWarper(),
    { staleTime: Infinity }
  );

  const newContentList = [
    getContentList(allCategories, TabList[1].name),
    getContentList(allCategories, TabList[2].name)
  ];

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Tab
          tabs={TabList}
          selectIndex={selectIndex}
          onTabSelect={(tabIndex) => {
            setSelectIndex(tabIndex)
          }} />
      </Grid>
      <div className={classes.title}>
        Content
      </div>
      <Grid container>
        {
          MainContentList.map((item, itemIdx) => (
            <Grid key={itemIdx} item md={3} sm={6} xs={12} className={classes.mainConentItem}>
              <Grid container alignItems="center">
                <img src={item.icon} className={classes.itemIcon} alt="icon" />
                <span className={classes.itemTitle}>{item.title}</span>
              </Grid>
              {newContentList[0] &&
                <div className={classes.itemListWrapper}>
                  {
                    selectIndex === 0 || selectIndex === 1 ?
                      <ContentItemList mode={TabList[1].name.toUpperCase()} contentItem={newContentList[0][itemIdx]} /> : null
                  }
                  {
                    selectIndex === 0 || selectIndex === 2 ?
                      <ContentItemList mode={TabList[2].name.toUpperCase()} contentItem={newContentList[1][itemIdx]} /> : null
                  }
                </div>
              }

            </Grid>
          ))
        }
      </Grid>
    </div>
  )
}

export default withStyles(styles)(Content)