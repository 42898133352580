import icItemGreen from '~/assets/images/profile/icon-list-item-indicator-green.png';
import icItemPurple from '~/assets/images/profile/icon-list-item-indicator-purple.png';
import icItemWhite from '~/assets/images/profile/icon-list-item-indicator-white.png';
import icItemBlue from '~/assets/images/profile/icon-list-item-indicator-blue.png';
import icItemGray from '~/assets/images/profile/icon-list-item-indicator-gray.png';
import icItemPink from '~/assets/images/profile/icon-list-item-indicator-pink.png';
import icItemYellow from '~/assets/images/profile/icon-list-item-indicator-yellow.png';
import icItemRed from '~/assets/images/profile/icon-list-item-indicator-red.png';

import IconBioDiversity from '~/assets/images/profile/ic-item-bio-diversity-protection.png';
import IconBioEco from '~/assets/images/profile/ic-item-bio-eco-process.png';
import IconBioGreen from '~/assets/images/profile/ic-item-bio-green-ing.png';
import IconBioPositive from '~/assets/images/profile/ic-item-bio-positive-func.png';
import IconBioTrade from '~/assets/images/profile/ic-item-bio-trade-int.png';

import IconStarSalad from '~/assets/images/profile/ic-item-star-salad.png';
import IconStarSmoothies from '~/assets/images/profile/ic-item-star-smoothies.png';
import ImgStarSalad from '~/assets/images/profile/img-item-star-salad.png';
import ImgStarSmooth1 from '~/assets/images/profile/img-item-star-smooth1.png';
// import ImgStarSmooth2 from '~/assets/images/profile/img-item-star-smooth2.png'
import IconMyProfile from '~/assets/images/icons/profile-icon.png';
import IconMyNetwork from '~/assets/images/icons/share.png';
import IconMyMedia from '~/assets/images/icons/image-gallery.png';
import IconMyMessenger from '~/assets/images/icons/chatting.png';
import IconMyFeed from '~/assets/images/profile/ic-item-personal-profile-myfeed.png';
import GridIcon from '~/assets/images/icons/grid.png'
import MyAccountIcon from '~/assets/images/icons/personal-information.png'


export const profileListData = [
  {
    id: 0,
    name: 'BUSINESS PROFILE',
    icon: icItemPurple,
    tabs: [
      {
        name: 'PROFILE'
      },
      {
        name: 'NEWS FEED'
      },
      {
        name: 'NETWORK'
      }
    ]
  },
  {
    id: 1,
    name: 'BIOSPHERICALLY CORRECT',
    icon: icItemGreen,
    tabs: [
      {
        name: 'DIVERSITY PROTECTION',
        content: {
          icon: IconBioDiversity,
          sumarry: 'Protecting our key pillars: Biospheric services / Biodiversity / Cultural diversity',
          detailList: [
            {
              key: 'Orientation',
              value: 'Security – how ecologically and socially conscious is the sourcing of the raw materials?'
            },
            {
              key: 'Context statement',
              value:
                'In the broadest thinking terms, life on our planet  relies on the interlocking of three key pillars: healthy Biospheric services, the biodiversity of life and the vital cultural diversity of humans.'
            },
            {
              key: 'Guiding Question',
              value:
                'To what extent does the sourcing (cultivation and extraction) of the raw materials necessary to produce a product (or facilitate a service) enhance the diversity of life, that is, aids the greater biospheric services of the planet, the localised eco systems (species and habitat protection) and preserves the associated knowledge, customs and prosperity of the indigenous cultures of the territory? '
            }
          ],
          plusList: [
            {
              title: 'Biospheric services',
              content:
                'To what degree is the sourcing of raw materials relevant to the product or service benign, degrading or enhancing to:'
            },
            {
              title: 'Biodiversity',
              content:
                'Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a, ultricies velit. Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis metus enim. Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.'
            },
            {
              title: 'Cultural diversity',
              content:
                'Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a, ultricies velit. Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis metus enim. Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.'
            }
          ]
        }
      },
      {
        name: 'GREEN INGREDIENTS',
        content: {
          icon: IconBioGreen,
          sumarry:
            'Green ingredient selection in relation to a Global Produce Model: natural – organic / high yielding / sustainable/ fit for purpose.',
          detailList: [
            {
              key: 'Orientation',
              value:
                'Selection - how green, and mindful of our planet’s total resources, are the constituents of the product?'
            },
            {
              key: 'Context statement',
              value:
                'Maximising the planet’s carry capacity in order to produce enough for everyone requires thought, and the planning of a Global Produce Model, to produce Green Ingredients in ratios that mindfully utilise our planets resources.'
            },
            {
              key: 'Guiding Question',
              value:
                'Do the constituents of the product meet the criteria for being ‘green ingredients’? That is, are they naturally occurring in nature, high yielding for economy of use and are they managed in a sustainable way for generations to come. Alternatively do they have a high ‘fit for purpose’ value? Do the Green ingredients ratios fit within the planning and greater context of a long term Global Produce Model?'
            }
          ],
          plusList: [
            {
              title: 'Natural',
              content:
                'To what degree is the sourcing of raw materials relevant to the product or service benign, degrading or enhancing to:'
            },
            {
              title: 'Organic',
              content:
                'Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a, ultricies velit. Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis metus enim. Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.'
            },
            {
              title: 'High Yielding',
              content:
                'Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a, ultricies velit. Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis metus enim. Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.'
            },
            {
              title: 'Sustainable',
              content:
                'Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a, ultricies velit. Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis metus enim. Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.'
            },
            {
              title: 'Fit for purpose',
              content:
                'Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a, ultricies velit. Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis metus enim. Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.'
            }
          ]
        }
      },
      {
        name: 'POSITIVE FUNCTIONALITY',
        content: {
          icon: IconBioPositive,
          sumarry: 'Being conscious of the effects:  individual / society / environment',
          detailList: [
            {
              key: 'Orientation',
              value: 'Significance – what true ecological and social significance does this product have in our lives?'
            },
            {
              key: 'Context statement',
              value:
                'The presence and use of a product or service will have an effect upon our lives in one way or another and sometimes the long term accumulative effects are not found until many years, or even decades, later. '
            },
            {
              key: 'Guiding Question',
              value:
                'How positive for the individual, society and the environment is the function of the service in its final form?'
            }
          ],
          plusList: [
            {
              title: 'Individual',
              content:
                'To what degree is the sourcing of raw materials relevant to the product or service benign, degrading or enhancing to:'
            },
            {
              title: 'Society',
              content:
                'Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a, ultricies velit. Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis metus enim. Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.'
            },
            {
              title: 'Environment',
              content:
                'Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a, ultricies velit. Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis metus enim. Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.'
            }
          ]
        }
      },
      {
        name: 'ECO PROCESSES',
        content: {
          icon: IconBioEco,
          sumarry:
            'Engagement of Biomimetic technology & Biomimic processes concerning:  sourcing / manufacturing / packaging / distribution / selling / biointegration & re-integration',
          detailList: [
            {
              key: 'Orientation',
              value: 'Systems – how ecologically advanced are the systems that enable this service to exist?'
            },
            {
              key: 'Context statement',
              value:
                'Nature’s core structure consists of producers, consumers and decomposers, all of which are incredibly efficient and dependable parts of the service chain. Nature’s systems operate with full utilisation incurring no nett waste and creating aesthetic natural beauty while continuing on a pathway of further evolution.'
            },
            {
              key: 'Guiding Question',
              value:
                'Firstly, from the localised work environment and then secondly, throughout the Biospherically Correct Service Management Chain (BCSMC), how extensively do the design principles and system processes inherent to this service benefit and sustain nature?'
            }
          ],
          plusList: [
            {
              title: 'Sourcing',
              content:
                'To what degree is the sourcing of raw materials relevant to the product or service benign, degrading or enhancing to:'
            },
            {
              title: 'Manufacturing',
              content:
                'Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a, ultricies velit. Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis metus enim. Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.'
            },
            {
              title: 'Packaging',
              content:
                'Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a, ultricies velit. Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis metus enim. Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.'
            },
            {
              title: 'Distribution',
              content:
                'Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a, ultricies velit. Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis metus enim. Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.'
            },
            {
              title: 'Bio-integration & re-integration',
              content:
                'Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a, ultricies velit. Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis metus enim. Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.'
            }
          ]
        }
      },
      {
        name: 'TRADE INTEGRITY',
        content: {
          icon: IconBioTrade,
          sumarry:
            'The underpinning human spirit of trade:  social investment / Gratifying and harmonious business relationships / transparency of information / honest & accurate statement',
          detailList: [
            {
              key: 'Orientation',
              value:
                'Social Capital – what are the governing human values and how are they exercised and expressed by the enterprise?'
            },
            {
              key: 'Context statement',
              value:
                'The glue of a Business Eco System are the human values interlocking all trade and commerce. The motives behind the businesses that we forge, along with how well we as humans relate through these values, determines the essence of the enterprise and its eco-social contribution to our planet. '
            },
            {
              key: 'Guiding Question',
              value:
                'Does the legal structure of the enterprise (e.g. Articles of Incorporation / Charitable Trust Deed) include a greater human purpose and contribution? Is there evidence of Triple Bottom Line behaviour (TBL – people, planet, profit), a spirit of generating and operating within gratifying and harmonious business relationships, a transparency of information and honest/accurate well intended advertising claim?'
            }
          ],
          plusList: [
            {
              title: 'Social investment- ‘Increase’',
              content:
                'To what degree is the sourcing of raw materials relevant to the product or service benign, degrading or enhancing to:'
            },
            {
              title: 'Gratifying and harmonious business relationships',
              content:
                'Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a, ultricies velit. Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis metus enim. Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.'
            },
            {
              title: 'Transparency of Information',
              content:
                'Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a, ultricies velit. Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis metus enim. Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.'
            },
            {
              title: 'Honest & accurate statement',
              content:
                'Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a, ultricies velit. Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis metus enim. Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.'
            }
          ]
        }
      }
    ]
  },
  {
    id: 2,
    name: 'STAR PRODUCTS',
    icon: icItemWhite,
    tabs: [
      {
        name: 'SALADS',
        content: {
          icon: IconStarSalad,
          items: [
            {
              icon: ImgStarSalad,
              title: 'Salad 1',
              ingredients: 'Tomatoes, spinach, feta, Avocados.'
            },
            {
              icon: ImgStarSalad,
              title: 'Salad 2',
              ingredients: 'Tomatoes, spinach, feta, Avocados.'
            },
            {
              icon: ImgStarSalad,
              title: 'Salad 3',
              ingredients: 'Tomatoes, spinach, feta, Avocados.'
            }
          ]
        }
      },
      {
        name: 'MAINS'
      },
      {
        name: 'DESSERTS'
      },
      {
        name: 'SMOOTHIES',
        content: {
          icon: IconStarSmoothies,
          items: [
            {
              icon: ImgStarSmooth1,
              title: 'Smoothie 1',
              ingredients: 'Tomatoes, spinach, feta, Avocados.'
            },
            {
              icon: ImgStarSmooth1,
              title: 'Smoothie 2',
              ingredients: 'Tomatoes, spinach, feta, Avocados.'
            },
            {
              icon: ImgStarSmooth1,
              title: 'Smoothie 3',
              ingredients: 'Tomatoes, spinach, feta, Avocados.'
            }
          ]
        }
      },
      {
        name: 'JUICES'
      }
    ]
  },
  {
    id: 3,
    name: 'STORE LOCATION',
    icon: icItemGray
  },
  {
    id: 4,
    name: 'MY FAVORITES',
    icon: icItemRed,
    tabs: [
      {
        name: 'QUOTES'
      },
      {
        name: 'ARTICLES'
      },
      {
        name: 'VIDEOS'
      },
      {
        name: 'METAWHEEL GUIDES'
      }
    ]
  }
];

export const personalProfileListData = [
  {
    id: 0,
    name: 'PROFILE',
    icon: icItemPurple,
    tabs: [
      {
        name: 'MY ACCOUNT',
        icon: MyAccountIcon,
        visibleToProfileOwnerOnly: true,
      },
      {
        name: 'MY PROFILE',
        icon: IconMyProfile
      },
      {
        name: 'MY POSTS',
        icon: GridIcon
      },
      {
        name: 'MY NETWORK',
        icon: IconMyNetwork
      },
      {
        name: 'MY MEDIA',
        icon: IconMyMedia
      },
      {
        name: 'MESSENGER',
        icon: IconMyMessenger,
      }
    ]
  },
  {
    id: 1,
    name: 'HEALTH',
    icon: icItemGreen,
    tabs: [
      {
        name: 'HEALTH'
      }
    ]
  },
  {
    id: 2,
    name: 'PROSPERITY',
    icon: icItemBlue,
    tabs: [
      {
        name: 'PROSPERITY'
      }
    ]
  },
  {
    id: 3,
    name: 'INSPIRATION',
    icon: icItemPink,
    tabs: [
      {
        name: 'INSPIRATION'
      }
    ]
  },
  {
    id: 4,
    name: 'JOY',
    icon: icItemYellow,
    tabs: [
      {
        name: 'JOY'
      }
    ]
  },
  {
    id: 5,
    name: 'EDIT PROFILE',
    icon: icItemGray,
    tabs: [
      {
        name: 'EDIT PROFILE'
      }
    ]
  },
  {
    id: 6,
    name: 'MY FAVORITES',
    icon: icItemRed,
    tabs: [
      {
        name: 'QUOTES'
      },
      {
        name: 'ARTICLES'
      },
      {
        name: 'VIDEOS'
      },
      {
        name: 'METAWHEEL GUIDES'
      }
    ]
  }
];
