import oceanBG from '~/assets/images/content/introCharter/oceanBGcrop.jpg';
import booksBG from '~/assets/images/content/introCharter/books.png';

const styles = (theme) => ({
  mainContainer: {
    backgroundImage: 'url(' + oceanBG + '), linear-gradient(#021236, #01539f 40%)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom center'
  },
  cardsContainer: {
    width: '100%'
  },
  header: {
    color: 'white',
    textTransform: 'uppercase',
    letterSpacing: '0.8rem',
    [theme.breakpoints.only('md')]: {
      fontSize: '2rem'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '1.8rem'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.6rem'
    }
  },
  books: {
    backgroundImage: 'url(' + booksBG + ')',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom left'
  },

  root: {
    width: '70px',
    height: '40px',
    borderRadius: '20px',
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#c6e1fc'
  },

  switchBase: {
    padding: '0px',
    width: '50px',
    height: '38px',
    borderRadius: '19px',
    display: 'flex',
    alignItems: 'center',
    margin: '1px',

    background: '#fff',
    '&:hover': {
      background: '#fff'
    },

    '&$checked': {
      background: '#023B78',
      '&:hover': {
        background: '#023B78'
      },

      '& $rsbcSlider': {
        background: '#023B78'
      },
      '& $rsbcSliderIcon': {
        color: '#fff'
      },
      transform: 'translateX(18px)'
    },
    '&$checked + $track': {
      backgroundColor: '#c6e1fc'
    }
  },
  checked: {},
  track: {},

  animationBG: {
    width: '70px',
    height: '40px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',

    overflow: 'hidden',
    background: 'linear-gradient(90deg, #c6e1fc, #fff, #c6e1fc)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50%',

    backgroundClip: 'text',
    color: 'rgba(2, 59, 120, 1)',
    webkitBackgroundClip: 'text',
    animation: '$rightToLeft 3s infinite linear'
  },

  '@keyframes leftToRight': {
    '0%': {
      backgroundPosition: '-300%'
    },

    '100%': {
      backgroundPosition: '300%'
    }
  },
  '@keyframes rightToLeft': {
    '0%': {
      backgroundPosition: '300%'
    },

    '100%': {
      backgroundPosition: '-300%'
    }
  },

  rsbcSlider: {
    background: '#fff',
    width: '50px',
    height: '38px',
    position: 'relative',
    left: '0px',
    borderRadius: '19px',
    zIndex: 60,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  rsbcSliderIcon: {
    width: '30px',
    height: '30px',
    color: '#023B78'
  }
});

export default styles;
