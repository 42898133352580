import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {Grid, FormControl, InputLabel, InputBase, Button} from '@material-ui/core';
import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';
import SectionTitle from './SectionTitle';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#f9f9f9',
    border: 'none',
    fontSize: 16,
    color: '#5b5b5b',
    opacity: 0.65,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {}
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 24
  },
  title: {
    letterSpacing: 4
  },
  margin: {},
  formContainer: {
    marginTop: 50
  },
  inputLabel: {
    fontWeight: 700,
    color: '#5b5b5b',
    fontSize: '1.25rem',
    '&.Mui-focused': {
      color: '#5b5b5b'
    }
  },
  submit: {
    backgroundColor: '#03d943',
    color: '#ffffff',
    letterSpacing: 1.4,
    boxShadow: 'none',
    lineHeight: 2.2,
    paddingLeft: 40,
    paddingRight: 40,
    marginRight: 0,
    marginTop: 12,
    fontSize: '0.75rem',
    '&:hover': {
      backgroundColor: '#03d943',
      boxShadow: 'none'
    }
  },
  message: {
    marginTop: theme.spacing(3),
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#f9f9f9',
    border: 'none',
    fontSize: 16,
    color: '#5b5b5b',
    opacity: 0.65,
    resize: 'none',
    padding: '10px 12px',
    '&:focus': {
      outlineStyle: 'none'
    }
  }
}));

const AddComment = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SectionTitle>{intl.get(messageKeys.ARTICLE_DETAIL_ADD_COMMENT_TITLE)}</SectionTitle>
      <form className={classes.formContainer} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel className={classes.inputLabel} shrink htmlFor="name-input">
                {intl.get(messageKeys.ARTICLE_DETAIL_ADD_COMMENT_FORM_NAME)}
              </InputLabel>
              <BootstrapInput defaultValue="John Doe" id="name-input" />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel className={classes.inputLabel} shrink htmlFor="email-input">
                {intl.get(messageKeys.ARTICLE_DETAIL_ADD_COMMENT_FORM_EMAIL)}
              </InputLabel>
              <BootstrapInput defaultValue="error@mail.com" id="email-input" />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel className={classes.inputLabel} shrink htmlFor="message-input">
                {intl.get(messageKeys.ARTICLE_DETAIL_ADD_COMMENT_FORM_MESSAGE)}
              </InputLabel>
              <textarea className={classes.message} rows="4" placeholder="Type here" id="message-input"></textarea>
            </FormControl>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            <Button className={classes.submit} variant="contained">
              {intl.get(messageKeys.ARTICLE_DETAIL_ADD_COMMENT_FORM_BUTTON_SUBMIT)}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AddComment;
