const disperseData = {
  BannersRight: [
     '/images/disperse/right_banners/banner_1.jpg',
     '/images/disperse/right_banners/banner_2.jpg'
  ],
  TopPageText:
    "Top page text which explains what's going on on this page. lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst. lorem ipsum dolor sit amet, consectetur",
  Texts: [
    {
      id: 1,
      title: 'Coronavirus: Apple and Google team up to contact trace Covid-19',
      content:
        'Apple and Google are jointly developing technology to alert people if they have recently come into contact with others found to be infected with coronavirus. They hope to initially help third-party contact-tracing apps run efficiently. But ultimately, they aim to do away with the need to download dedicated apps, to encourage the practice. The two companies believe their approach - designed to keep users, whose participation would be voluntary, anonymous - addresses privacy concerns.',
      image:  '/images/disperse/apple_google.jpg',
      tags: 'quote, joy',
      disperseDate: '11 April 2020'
    },
    {
      id: 2,
      title: "Final Fantasy 7 Remake: 'This is not just for the players of the original'",
      content:
        "Cloud Strife and his iconic mop of spiked blonde hair emerge through the rising steam of an overcrowded city. The main character of Final Fantasy 7 has his distinctive buster sword strapped to his back. This time, however, he's not a pixelated image as seen in the 1997 PlayStation original - he's been fully rendered in life-like modern graphics. It's been five years since gamers got a first glimpse of the Final Fantasy 7 Remake, when the first trailer dropped. The remake, which is out now, is a completely rebuilt version of a role-playing game that has sold more than 12 million copies worldwide. And fans of the original are excited to make a return to Midgar, the fantasy city where the remake takes place.",
      image:  '/images/disperse/final_fantasy.jpg',
      tags: 'quote, joy',
      disperseDate: '10 April 2020'
    },
    {
      id: 3,
      title: "Final Fantasy 7 Remake: 'This is not just for the players of the original'",
      content:
        "lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst. lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse plates dictumst.Cloud Strife and his iconic mop of spiked blonde hair emerge through the rising steam of an overcrowded city. The main character of Final Fantasy 7 has his distinctive buster sword strapped to his back. This time, however, he's not a pixelated image as seen in the 1997 PlayStation original - he's been fully rendered in life-like modern graphics. It's been five years since gamers got a first glimpse of the Final Fantasy 7 Remake, when the first trailer dropped. The remake, which is out now, is a completely rebuilt version of a role-playing game that has sold more than 12 million copies worldwide. And fans of the original are excited to make a return to Midgar, the fantasy city where the remake takes place.",
      image:  '/images/disperse/is.mp4',
      tags: 'video, health',
      disperseDate: '09 April 2020',
      video: true
    },
    {
      id: 4,
      title: 'The people fighting fakes from their sofas',
      content:
        "Social media companies are struggling to contain a wave of coronavirus misinformation. Into the breach have stepped volunteers who are fighting to stop rumours, bad advice and conspiracy theories from going viral. Rachael Hogg is currently juggling her busy job as a project manager and teaching her nine-year-old from her home in the market town of Morpeth, about 15 miles north of Newcastle. But if that wasn't enough, each day she's also deleting as many as 50 misleading posts about coronavirus from the local Facebook group she runs.",
      image:  '/images/disperse/fake.png',
      tags: 'quote, joy',
      disperseDate: '07 April 2020'
    }
  ]
};

export default disperseData;
