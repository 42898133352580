import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Container, Tabs, Tab, Box, Typography, Paper} from '@material-ui/core';
import CKEditorSample from './CKEditorSample';
import ReactQuillSample from './ReactQuillSample';

const useStyles = makeStyles((theme) => ({
  root: {marginTop: theme.spacing(3)},
  flexGrow: 1
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const ContentEditor = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container className={classes.root}>
      <Paper>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="CKEditor 5" {...a11yProps(0)} />
          <Tab label="React Quill" {...a11yProps(1)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
        <TabPanel value={value} index={0}>
          <CKEditorSample />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ReactQuillSample />
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
      </Paper>
    </Container>
  );
};

export default ContentEditor;
