const styles = (theme) => {
  return {
    root: (props) => ({
      paddingTop: '2%',
      paddingBottom: '5%',
      [theme.breakpoints.up('md')]: {
        paddingTop: '5%',
        paddingBottom: '8%',
        maxWidth: `${theme.breakpoints.values['md']}px`
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: '2%',
        paddingBottom: '5%',
        maxWidth: `${theme.breakpoints.values['lg']}px`
      }
    }),
    titleContainer: {
      marginTop: 15,
      marginBottom: 10
    },
    title: {
      color: '#ffffff',
      fontSize: '0.75rem',
      fontWeight: '300',
      letterSpacing: '1.4px'
    },
    noMoreQuotes: {
      color: '#ffffff',
      marginTop: 15,
      marginBottom: 15
    }
  };
};

export default styles;
