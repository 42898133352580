import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link as RouterLink} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {withStyles, Grid, Modal, FormGroup, Button} from '@material-ui/core';
import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';

import Popover from './ui/Popover';
import ContentTypeRadioBtn from './sections/ContentTypeRadioBtn/ContentTypeRadioBtn';
import TermsModal from './sections/TermsModal/TermsModal';
import ContributeSidebar from './sections/ContributeSidebar/ContributeSidebar';
import UploadInputBox from './sections/UploadInputBox/UploadInputBox';

import {contributeStyles} from './Styles';
import globeIcon from '~/assets/images/icons/globe-02.png';
import sanctuaryIcon from '~/assets/images/icons/sanctuary-03.png';
import ContentEditor from './sections/ContentEditor/ContentEditor';

class Contribute extends Component {
  state = {
    contentType: '',
    organizeName: '',
    showModal: false,
    termsAccepted: false,
    file: {}
  };

  selectContentType = (e) => {
    this.setState({
      contentType: e.target.value
    });
  };

  getFile = (file) => {
    this.setState({
      file
    });
  };

  uploadContent = (e) => {
    e.preventDefault();
  };

  toggleTermsModal = () => {
    this.setState({
      showModal: !this.state.showModal,
      termsAccepted: false
    });
  };

  toggleTermsModalCheckBox = () => {
    this.setState({
      termsAccepted: !this.state.termsAccepted
    });
  };

  handleTermsAccepted = (event) => {
    event.preventDefault();
    if (this.state.termsAccepted) {
      this.setState({
        showModal: false
      });
    } else {
      alert('Please read and agree the terms and conditions');
    }
  };

  selectOrganize = (e) => {
    this.setState({
      organizeName: e.target.value
    });
  };

  previewContent = () => {};
  submitContentForm = () => {};

  render() {
    const {classes} = this.props;
    const {showModal} = this.state;
    return (
      <form className={classes.contributeContentForm}>
        <Grid container>
          <Grid item xs={12} md={6} lg={7}>
            {/* Content Header */}
            <Grid item xs={12}>
              <section>
                <p className={classes.contributeContentHeading}>{intl.get(messageKeys.CONTRIBUTE_CONTENT_HEADING)}</p>
                <p className={classes.contributeContentIntroText}>
                  {intl.get(messageKeys.CONTRIBUTE_CONTENT_INTRO_TEXT)}
                </p>
              </section>
            </Grid>

            {/* Content Type */}
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={2}>
                  <Typography>{intl.get(messageKeys.CONTRIBUTE_CONTENT_TYPE)}</Typography>
                </Grid>

                <Grid item xs={6} sm={2}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <img className={classes.contentTypeIcon} src={sanctuaryIcon} alt="" />
                    <Popover
                      className={classes.popoverLabel}
                      popoverLabel={intl.get(messageKeys.CONTRIBUTE_CONTENT_TYPE_PERSONAL)}
                      popoverText={intl.get(messageKeys.CONTRIBUTE_CONTENT_TYPE_POPOVER_TEXT_PERSONAL)}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={6} sm={2}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <img className={classes.contentTypeIcon} src={globeIcon} alt="" />
                    <Popover
                      className={classes.popoverLabel}
                      popoverLabel={intl.get(messageKeys.CONTRIBUTE_CONTENT_TYPE_GLOBAL)}
                      popoverText={intl.get(messageKeys.CONTRIBUTE_CONTENT_TYPE_POPOVER_TEXT_GLOBAL)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Content Type Radio Button Group */}
            <FormGroup className={classes.radioBtnFormGroup}>
              <Grid container justifyContent="flex-start">
                <ContentTypeRadioBtn
                  contentTypeTitle={intl.get(messageKeys.CONTRIBUTE_CONTENT_TYPE_TITLE_QUOTE)}
                  selectContentType={this.selectContentType}
                  personalRadioBtnValue="personal-quote"
                  globalRadioBtnValue="global-quote"
                />
                <ContentTypeRadioBtn
                  contentTypeTitle={intl.get(messageKeys.CONTRIBUTE_CONTENT_TYPE_TITLE_ARTICLES)}
                  selectContentType={this.selectContentType}
                  personalRadioBtnValue="personal-articles"
                  globalRadioBtnValue="global-articles"
                />
                <ContentTypeRadioBtn
                  contentTypeTitle={intl.get(messageKeys.CONTRIBUTE_CONTENT_TYPE_TITLE_VIDEOS)}
                  selectContentType={this.selectContentType}
                  personalRadioBtnValue="personal-videos"
                  globalRadioBtnValue="global-videos"
                />
                <ContentTypeRadioBtn
                  contentTypeTitle={intl.get(messageKeys.CONTRIBUTE_CONTENT_TYPE_TITLE_MESSAGE)}
                  selectContentType={this.selectContentType}
                  personalRadioBtnValue="personal-message"
                  globalRadioBtnValue="global-message"
                />
              </Grid>
            </FormGroup>

            <Grid item xs={12}>
              <section>
                <p className={classes.uploadContentNoteHeading}>
                  {intl.get(messageKeys.CONTRIBUTE_UPLOAD_CONTENT_NOTE_HEADING)}
                </p>
                <p className={classes.uploadContentNoteText}>
                  {intl.get(messageKeys.CONTRIBUTE_UPLOAD_CONTENT_NOTE_TEXT_1)}
                </p>
                <p className={classes.uploadContentNoteText}>
                  {intl.get(messageKeys.CONTRIBUTE_UPLOAD_CONTENT_NOTE_TEXT_2)}
                </p>
              </section>
            </Grid>

            {/* File Upload Component */}
            <Grid item container xs={12}>
              <UploadInputBox
                heading={intl.get(messageKeys.CONTRIBUTE_UPLOAD_INPUT_HEADING_TEXT)}
                inputId="uploadTextInputBox"
                format=".doc,.docx,.pdf"
                uploadHintText={intl.get(messageKeys.CONTRIBUTE_UPLOAD_INPUT_HINT_TEXT)}
                buttonId="uploadTextBtn"
                uploadContent={this.uploadContent}
                getFile={this.getFile}
              />

              <UploadInputBox
                heading={intl.get(messageKeys.CONTRIBUTE_UPLOAD_INPUT_HEADING_IMAGE)}
                inputId="uploadImageInputBox"
                format="image/jpeg, image/jpg, image/png"
                uploadHintText={intl.get(messageKeys.CONTRIBUTE_UPLOAD_INPUT_HINT_IMAGE)}
                buttonId="uploadImageBtn"
                uploadContent={this.uploadContent}
                getFile={this.getFile}
              />

              <UploadInputBox
                heading={intl.get(messageKeys.CONTRIBUTE_UPLOAD_INPUT_HEADING_VIDEO)}
                inputId="uploadVideoInputBox"
                format="video/mp4, video/mpeg, video/avi"
                uploadHintText={intl.get(messageKeys.CONTRIBUTE_UPLOAD_INPUT_HINT_VIDEO)}
                buttonId="uploadVideoBtn"
                uploadContent={this.uploadContent}
                getFile={this.getFile}
              />
            </Grid>

            {/* Term And Conditions Modal*/}
            <Grid item xs={12}>
              <RouterLink to="#" className={classes.termsModalLink} onClick={this.toggleTermsModal}>
                {intl.get(messageKeys.CONTRIBUTE_TERM_MODAL_LINK)}
              </RouterLink>
            </Grid>
            <Modal open={showModal} onClose={this.toggleTermsModal} className={classes.termsModal}>
              <div>
                <TermsModal
                  handleCloseForm={this.toggleTermsModal}
                  handleTermsAccepted={this.handleTermsAccepted}
                  termsAccepted={this.state.termsAccepted}
                  toggleTermsModalCheckBox={this.toggleTermsModalCheckBox}
                />
              </div>
            </Modal>
            <Grid item xs={12}>
              <ContentEditor />
            </Grid>
          </Grid>

          <Grid item xs={12} md={1} lg={1}>
            <div className={classes.cardDivider} />
          </Grid>

          {/* ContributeSidebar */}
          <Grid item xs={12} md={5} lg={4}>
            <ContributeSidebar selectOrganize={this.selectOrganize} />
          </Grid>

          {/* Preview and Submit Button */}
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button onClick={this.previewContent} className={classes.submitButton}>
                {intl.get(messageKeys.CONTRIBUTE_FORM_BUTTON_PREVIEW)}
              </Button>
            </Grid>

            <Grid item>
              <Button type="submit" className={classes.submitButton} onSubmit={this.submitContentForm}>
                {intl.get(messageKeys.CONTRIBUTE_FORM_BUTTON_SUBMIT)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

Contribute.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(contributeStyles)(Contribute));
