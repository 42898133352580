import React from 'react';
import {withStyles, Grid} from '@material-ui/core';

import ContentHeader from '../../ContentHeader';

const styles = (theme) => ({
  root: {
    padding: '30px 40px'
  },
  contentWrapper: {
    margin: '10px',
    marginTop: '18px',
    fontSize: '14px'
  },
  flexExpand: {
    flex: 1
  },
  itemWrapper: {
    overflow: 'hidden',
    backgroundColor: 'rgba(255, 255, 255, 0.48)',
    padding: '25px 20px',
    boxShadow: '0 3px 3px #00000023'
  },
  itemImg: {
    width: '100%'
  },
  itemTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '20px'
  },
  itemIng: {
    fontSize: '14px',
    marginTop: '10px',
    wordBreak: 'break-all'
  },
  itemIngKey: {
    fontWeight: 'bold'
  },
  itemBtnBuy: {
    alignSelf: 'flex-end',
    border: 'solid 1px #00B74E',
    cursor: 'pointer',
    padding: '0 15px 3px 15px',
    borderRadius: '5px',
    marginTop: '10px'
  }
});

function Content(props) {
  const {classes, tab} = props;
  return (
    <div className={classes.root}>
      <ContentHeader name={tab.name} icon={tab.content.icon} />
      <Grid container className={classes.contentWrapper} spacing={1}>
        {tab.content.items.map((item) => (
          <Grid item md={4}>
            <Grid container direction="column" alignItems="center" className={classes.itemWrapper}>
              <img className={classes.itemImg} src={item.icon} alt="icon" />
              <div className={classes.itemTitle}>{item.title}</div>
              <div className={classes.itemIng}>
                <span className={classes.itemIngKey}>Ingredients: </span>
                {item.ingredients}
              </div>
              <div className={classes.itemBtnBuy}>Buy</div>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Content);
