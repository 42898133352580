import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    borderRadius: '4px',
    fontWeight: 500,
    fontSize: '1rem',
    letterSpacing: 'normal',
    paddingTop: 3,
    paddingBottom: 2.4,
    paddingLeft: 10,
    paddingRight: 10,
    whiteSpace: 'nowrap',
    textTransform: 'none',
    '& span': {
      height: '16px'
    }
  },
  selected: {
    backgroundColor: 'white',
    color: '#595959',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.8)'
    }
  }
}));

const ToggleTitle = ({category, isSelected, onChange, children}) => {
  const classes = useStyles();
  const handleClick = () => {
    const newStatus = !isSelected;
    onChange(category, newStatus);
  };
  return (
    <Button className={clsx(classes.root, {[classes.selected]: !!isSelected})} onClick={handleClick}>
      {children}
    </Button>
  );
};

export default ToggleTitle;
