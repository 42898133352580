const styles = (theme) => ({
  content: {
    paddingTop: '5vh'
  },
  img: {
    width: '200px',
    height: '200px'
  },
  headingPart: {
    order: 1,
    [theme.breakpoints.only('xs')]: {
      order: 2
    },
    [theme.breakpoints.only('sm')]: {
      order: 2
    }
  },
  mainHeading: {
    fontSize: 'calc(6vw + 2em)',
    fontWeight: 'bold',
    letterSpacing: '2vw',
    color: 'white',
    marginTop: '2vh',
    textTransform: 'uppercase',
    lineHeight: '1',
    marginBottom: '2vh'
  },
  subHead: {
    borderRadius: '30px',
    backgroundColor: '#434343',
    color: 'white',
    display: 'inline-block',
    alignItems: 'center',
    paddingLeft: '2em',
    paddingRight: '2em',
    fontSize: '1.5em',
    fontWeight: 'bold',
    lineHeight: '2.4',
    [theme.breakpoints.only('md')]: {
      fontSize: '1.2em',
      lineHeight: '2.5'
    },
    [theme.breakpoints.only('sm')]: {
      width: '40%',
      display: 'block',
      fontSize: '1em',
      lineHeight: '1.8'
    },
    [theme.breakpoints.only('xs')]: {
      width: '50%',
      display: 'block',
      fontSize: '1em',
      lineHeight: '1.8'
    }
  },
  contentDescription: {
    width: '100%',
    fontSize: '1.1em',
    color: 'white',
    margin: '20px auto 6%',
    lineHeight: '1.6',
    letterSpacing: '2px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.8em',
      lineHeight: '1.5',
      letterSpacing: '2px'
    }
  }
});
export default styles;
