// @ts-check
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { ExpandBar } from "~/components/ui";
import styles from "./ArticlesMainStyles";
import MyFeed, { useCategoryFilter } from "~/components/layout/MyFeed/index";
import ToggleContentsTitles from "~/components/ui/ToggleContentTitles";
import DisplayContentItems from "../../contentData/DisplayContentItems";
import { fetchArticlesByCategoryIds } from "shared/react-query/strapiAPI";
import ArticleCardBox from "./ArticleCardBox";

const PERSON = "person";
const PLANET = "planet";

const customStylesMyFeed = (theme) => ({
  subCategoryContainer: {
    backgroundColor: 'transparent',
  },
  subCategoryButton: {
  },
});

const MyFeedCustomStyles = withStyles(customStylesMyFeed)(MyFeed);

const customStylesArticles = (theme) => ({
  loading: {
    color: "white",
  }
});

const CustomStylesDisplayContentItems = withStyles(customStylesArticles)(DisplayContentItems);

const useStyles = makeStyles(styles);

const Articles = (props) => {
  const classes = useStyles(props);
  const {
    titles,
    selectedTitleIds,
    onTitlesChange,
    goDetail,
    goExpanded,
  } = props;


  const {
    allCategories,
    selectedCategoryIds, setSelectedCategoryIds,
    selectedCategories,
    mode, setMode,
  } = useCategoryFilter();


  const handleSwitch = () => {
    const nextMode = mode === PERSON ? PLANET : PERSON;
    setMode(nextMode);
  }

  return (
    <Container className={classes.root}>
      <ExpandBar
        nav="Articles"
        subNav={mode}
        goExpanded={goExpanded}
        switcher={{
          items: ["Person", "Planet"],
          checkedIndex: mode === "planet" ? 1 : 0,
          onChange: handleSwitch,
        }}
      />
      <ToggleContentsTitles
        titles={titles}
        selectedIds={selectedTitleIds}
        onChange={onTitlesChange}
      />
      <MyFeedCustomStyles allCategories={allCategories.data ?? []} mode={mode}
        selectedCategoryIds={selectedCategoryIds}
        onCategoriesChange={setSelectedCategoryIds} />
      <CustomStylesDisplayContentItems
        allCategories={allCategories.data ?? []}
        filteredCategories={selectedCategories}
        goDetail={goDetail}
        // how to fetch data
        dataName="articles"
        fetcher={fetchArticlesByCategoryIds}
        // how to display data
        itemComponent={ArticleCardBox}
      />
    </Container>
  );
};

export default Articles;
