import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  navHeaderLink: {
    position: 'relative',
    textAlign: 'center',
    margin: 'auto 2em',
    [theme.breakpoints.down('md')]: {
      margin: 'auto 1em'
    },
    [`@media (max-width: 1050px)`]: {
      margin: 'auto 0.4em'
    }
  },
  navLink: {
    margin: '0 8px',
    padding: '0.25rem',
    fontSize: '1rem',
    lineHeight: '1.75',
    letterSpacing: '3px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    '&:hover': {
      background: 'none'
    }
  },
  navHoverContainer: {
    opacity: '0.11',
    pointerEvents: 'none',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0
  },
  navHover: {
    fontSize: '38px',
    fontWeight: 'bold',
    fontFamily: 'Calibri'
  }
}));

const HeaderLink = ({headerLinkName, to, activatedMenuName, isMenuOpen, menuEffect, onMenuOpen, onMenuClose}) => {
  const classes = useStyles();

  return (
    <div className={classes.navHeaderLink}>
      <div>
        <Button
          className={classes.navLink}
          component={RouterLink}
          to={to}
          onMouseEnter={(e) => onMenuOpen(e, headerLinkName)}
          onMouseLeave={(e) => onMenuClose(e, headerLinkName)}
        >
          {headerLinkName}
        </Button>
      </div>
      {isMenuOpen && headerLinkName === activatedMenuName ? (
        <Box className={classes.navHoverContainer}>
          <Typography className={classes.navHover} component="div">
            {menuEffect}
          </Typography>
        </Box>
      ) : null}
    </div>
  );
};

export default HeaderLink;
