import React from 'react'
import { fetchVideo } from '../react-query/strapiAPI';
import { useQuery } from 'react-query';

export function StrapiVideo({ segment, controls = true }) {
  // const { data: dataVideo, error, isloading } = useQuery(['video', segment], () => fetchVideo(segment), { enabled: !!segment });


  return (
    <>
      {<video controlsList="nodownload" key={segment} style={{ objectFit: "cover", width: "100%", height: "100%" }} controls={controls} autoPlay={controls} name="video">
        <source src={segment} type="video/mp4" />
      </video>}
    </>
  )
}