import React, {useState} from 'react';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';

import {Drawer, List, ListItem, Typography, Divider} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconAppsComm from '~/assets/images/layout/sidemenu/apps_comm.png';
import IconAppsHealthCreator from '~/assets/images/layout/sidemenu/apps_health_creator.png';
import IconAppsMetaWheel from '~/assets/images/layout/sidemenu/apps_meta_wheel.png';
import IconAppsProsperotyPal from '~/assets/images/layout/sidemenu/apps_prosperoty_pal.png';
import IconAppsTreasurebox from '~/assets/images/layout/sidemenu/apps_treasurebox.png';
import IconAppsWeightShifter from '~/assets/images/layout/sidemenu/apps_weight_shifter.png';

const styles = (theme) => ({
  root: {
    display: 'flex',
    zIndex: '0'
  },
  myApps: {
    textAlign: 'center',
    margin: '0px 10px',
    marginTop: 52,
    cursor: 'pointer'
  },
  name: {
    marginTop: '4px',
    fontFamily: 'Lato',
    fontSize: '1rem',
    color: '#878787',
    lineHeight: '1.2'
  },
  returnbtn: {
    color: '#878787',
    cursor: 'pointer',
    marginTop: 60
  },
  myappExpand: {
    display: 'flex',
    margin: '0px 10px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  hide: {
    display: 'none'
  },
  drawer: {
    position: 'fixed',
    top: '100px',
    width: 200,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '60px',
    [theme.breakpoints.up('sm')]: {
      width: '60px'
    }
  },
  icons: {
    width: 24,
    height: 24
  },
  appname: {
    fontFamily: 'Lato',
    marginLeft: '20px',
    fontSize: '13px'
  }
});

const myApps = [
  {
    name: 'Communication Coach',
    icon: IconAppsComm
  },
  {
    name: 'Health Creator',
    icon: IconAppsHealthCreator
  },
  {
    name: 'Meta Wheel',
    icon: IconAppsMetaWheel
  },
  {
    name: 'Prosperity Pal',
    icon: IconAppsProsperotyPal
  },
  {
    name: 'Treasure Box',
    icon: IconAppsTreasurebox
  },
  {
    name: 'Weight Shifter',
    icon: IconAppsWeightShifter
  }
];

function Sidebar(props) {
  const {classes} = props;
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        {!open === true ? (
          <div
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.myApps, {
              [classes.hide]: open
            })}
          >
            <Typography className={classes.name}>
              MY
              <br />
              APPS
            </Typography>
          </div>
        ) : (
          <div className={classes.myappExpand}>
            <div></div>
            <div className={classes.myApps}>
              <Typography className={classes.name}>
                MY
                <br />
                APPS
              </Typography>
            </div>
            <div>
              <ChevronLeftIcon onClick={handleDrawerClose} className={classes.returnbtn} />
            </div>
          </div>
        )}
        <List>
          <Divider />
          {myApps.map((app, index) => (
            <div key={index}>
              <ListItem button>
                <img src={app.icon} alt="icons" className={classes.icons} />
                <span className={classes.appname}>{app.name}</span>
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      </Drawer>
    </div>
  );
}

export default withStyles(styles)(Sidebar);
