/**
 * This page to be removed
 */
import image from '../assets/images/content/articles.png';
const title = 'LOREM IPSUM DOLOR SIT AMET';
const content = `In this world we have liberated humanity’s collective potential harnessed within the power of consciously creating together. This Increase is ultimately evident through the alignment of all of our newly co-created social constructs: Self, Education, Business, Economy, Politics, Law, Religion, Media, Health, Family and Community. All of which work in unison to enhance our abundance of ecological, physical, social, financial and spiritual well-being.`;
const topBtnText = 'TUTORIAL';
const bottomBtnText = 'READ MORE';

/* const articlesData = [
  {
    id: 1, 
    "title": "The internet's Own boy",
    "summary": null,
    "slug": "the-internet-s-own-boy",
    "content": "adalqh qwekg wewkuha werwre ",
    "createdAt": "2024-05-09T10:35:26.236Z",
    "updatedAt": "2024-05-09T10:35:26.236Z",
    "publishedAt": "2024-05-09T10:35:26.233Z",
    "image" : null,
    topbtntext: topBtnText,
    categoryId: 1,
    bottombtntext: bottomBtnText,
    desc:'Physiological Efficiencies'
  },
  {
    id: 2,
    title: title,
    content: content,
    image: image,
    topbtntext: topBtnText,
    categoryId: 3,
    bottombtntext: bottomBtnText,
    desc:'Longevity Activities'
  },
  {
    card: 'SUSPENDIS_BOX'
  },
  {
    id: 3,
    title: title,
    content: content,
    image: image,
    topbtntext: topBtnText,
    categoryId: 3,
    bottombtntext: bottomBtnText,
    desc:'Life Force Food'
  },
  {
    id: 4,
    title: title,
    content: content,
    image: image,
    topbtntext: topBtnText,
    categoryId: 4,
    bottombtntext: bottomBtnText,
    desc:'Health Psychology'
  },
  {
    id: 5,
    title: title,
    content: content,
    image: image,
    topbtntext: topBtnText,
    categoryId: 1,
    bottombtntext: bottomBtnText,
    desc:'Health Psychology'
  },
  {
    id: 6,
    title: title,
    content: content,
    image: image,
    topbtntext: topBtnText,
    categoryId: 3,
    bottombtntext: bottomBtnText,
    desc:'Life Force Food'
  },
  {
    id: 7,
    title: title,
    content: content,
    image: image,
    topbtntext: topBtnText,
    categoryId: 1,
    bottombtntext: bottomBtnText,
    desc:'Life Force Food'
  },
  {
    id: 8,
    title: title,
    content: content,
    image: image,
    topbtntext: topBtnText,
    categoryId: 2,
    bottombtntext: bottomBtnText,
    desc:'Physiological Efficiencies'
  }
]; */

 export const tableTitles = [
  {id: 1, text: 'VITALITY & LONGEVITY'},
  {id: 2, text: 'WISDOM & SUCCESS'},
  {id: 3, text: 'EMPOWERMENT & SIGNIFICANCE'},
  {id: 4, text: 'CLARITY & BEAUTY'}
];

/*export default articlesData; */
