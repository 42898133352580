import React, {Component} from 'react';
import {withStyles, Grid, FormGroup, InputLabel, Checkbox, Button} from '@material-ui/core';

import CheckboxOutlineCheckedIcon from '~/components/base/auth/ui/CheckboxOutlineCheckedIcon';
import CloseIcon from '@material-ui/icons/Close';

import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';

import {termsModalStyles} from './Styles';

export class TermsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errMsg: '',
      termsAccepted: this.props.termsAccepted
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.termsAccepted) {
      this.props.handleTermsAccepted();
    } else {
      this.setState({
        errMsg: intl.get(messageKeys.TERMS_AND_CONDITIONS_NOT_ACCEPTED)
      });
    }
  };

  handleChange = (event) => {
    this.setState({termsAccepted: !this.state.termsAccepted});
  };

  render() {
    const {classes, termsModalTexts} = this.props;

    return (
      <section className={classes.termsModalCard}>
        <div className={classes.modalHeading}>
          {intl.get(messageKeys.CONTRIBUTE_TERM_MODAL_HEADING)}
          <CloseIcon className={classes.clsIcon} onClick={this.props.toggleTermsModal} />
        </div>

        <div className={classes.triangle} />
        <div className={classes.cardBorderBottom} />
        <div className={classes.cardBorderTopA} />
        <div className={classes.cardBorderTopB} />
        <div className={classes.cardBorderLeft} />
        <div className={classes.cardBorderRight} />

        <Grid container>
          <Grid item xs={12}>
            {termsModalTexts.map((termsModalText, index) => (
              <div key={index}>
                <p className={classes.termsHeading}>{termsModalText.heading}</p>
                <p className={classes.termsText}>{termsModalText.text}</p>
              </div>
            ))}
          </Grid>

          <form className={classes.termsForm} onSubmit={this.handleSubmit}>
            <Grid item xs={12}>
              <FormGroup row className={classes.acceptCheckBoxGroup}>
                <Checkbox
                  color="default"
                  classes={{root: classes.checkbox, checked: classes.checkboxChecked}}
                  checkedIcon={<CheckboxOutlineCheckedIcon />}
                  checked={this.state.termsAccepted}
                  onChange={this.handleChange}
                  name="acceptCheckBox"
                />
                <InputLabel className={classes.checkBoxLabel}>
                  {intl.get(messageKeys.CONTRIBUTE_TERM_MODAL_CHECKBOX_LABEL)}
                </InputLabel>
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <p className={`${classes.termsHeading} ${classes.errMsg}`}>{this.state.errMsg}</p>
              <Button type="submit" className={classes.submitButton}>
                {intl.get(messageKeys.CONTRIBUTE_FORM_BUTTON_SUBMIT)}
              </Button>
            </Grid>
          </form>
        </Grid>
      </section>
    );
  }
}

export default withStyles(termsModalStyles)(TermsModal);
