import * as React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from '@material-ui/core';

import GuidesCard from '~/components/ui/GuideCard/GuideCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    cursor: 'pointer'
  }
}));

const GuidesCardBox = (props) => {
  const classes = useStyles();
  const {handleClick, className, item} = props;
  return (
    <Link className={clsx(classes.root, className)} onClick={handleClick}>
      <GuidesCard data={item} />
    </Link>
  );
};

export default GuidesCardBox;
