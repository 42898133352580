import React, { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ArrowBack, PlayCircleOutline } from '@material-ui/icons';
import { IconButton, withStyles } from '@material-ui/core';
import { ProfileCard } from '~/components/ui';

import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { HomeOutlined, Public } from '@material-ui/icons';
import userImg from '~/assets/images/profile/profile.png';
import IconLink from '~/assets/images/icons/link.png';
import Lightbox from 'yet-another-react-lightbox';
// import "yet-another-react-lightbox/styles.css";
import { StrapiVideo } from "shared/ui/StrapiVideo"
import {getStrapiAssetURL} from "shared/utils/strapiUtils"
import { Carousel } from 'react-responsive-carousel';
import { isNil } from 'lodash';
import { useAuth } from 'shared/auth';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center'
  },

  userName: {
    textAlign: 'left',
    fontSize: '2.5rem',

    [theme.breakpoints.only('xs')]: {
      // textAlign: 'center',
      fontSize: '1.2rem'
      // display: 'block',
      // marginTop: '5px'
    }
  },

  flexGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
    // [theme.breakpoints.only('xs')]: {
    //   flexDirection: 'column',
    //   marginTop: '0px',
    //   justifyContent: 'center',
    //   alignItems: 'center'
    // }
  },

  flexGridLink: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  icon: {
    height: '24px',
    marginRight: '5px'
  },

  text: {
    fontSize: '1rem'
  },

  cover: {
    margin: '10px',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    '&>div': {
      maxWidth: 350,
      border: 'solid 1px transparent',
      borderRadius: '10px'
    }
  },
  imgSize: {
    height: '100%',
    aspectRatio: '1/1',
    border: 'solid 1px transparent',
    borderRadius: '10px',
    objectFit: 'cover'
  },

  listItem: {
    display: 'inline-flex',
    alignItems: 'center'
    // [theme.breakpoints.only('xs')]: {
    //   justifyContent: 'center',
    //   margin: '5px auto'
    // }
  },
  avatar: {
    display: 'none',
    width: '80px',
    height: '80px',
    border: 'solid 1px transparent',
    borderRadius: '41px'
    // [theme.breakpoints.only('xs')]: {
    //   display: 'block',
    //   margin: 'auto'
    // }
  },
  btn: {
    margin: '5px 2px',
    boxShadow: 'none',
    color: '#676767',
    backgroundColor: '#C4C5C5',
    maxWidth: '200px',
    textWrap: 'noWrap'
  },
  blue: {
    color: '#006fff'
  },
  videoWrapper: {
    position: 'relative',
    paddingBottom: '56.25%',
    paddingTop: '25px',
    height: 0,

    '&> iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }
  },
  links: {
    width: "42%",
    [theme.breakpoints.down('md')]: {
      width: "100%"
    }
  }
});

function PersonCard(props) {
  const { classes, data } = props;
  const { auth } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const doesProfileBelongToLoggedInUser = !isNil(data?.email)

  const [open, setOpen] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [showLinks, setShowLinks] = useState(false);

  const images = data?.displayPhotosAndVideos?.data?.map((e, i) => {
    if (e?.attributes?.mime == "video/mp4") {
      return <div style={{ position: "relative", width: "100%", height: "100%" }} onClick={() => { setPlayVideo(e?.attributes?.url) }}> <StrapiVideo segment={e?.attributes?.url} controls={false} />
        <PlayCircleOutline style={{ position: "absolute", bottom: "35%", left: "50%", transform: "translateX(-50%)", height: "60px", width: "60px" }} />
      </div>
    }
    return (
      <img key={i} src={getStrapiAssetURL(e?.attributes?.url)} className={classes.imgSize} />
    )
  })

  function getYouTubeThumbnailUrl(videoUrl) {
    // Extract the video ID from the YouTube URL
    const videoIdMatch = videoUrl.match(
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i
    );
    const videoId = videoIdMatch ? videoIdMatch[1] : null;

    // Check if video ID is successfully extracted
    if (!videoId) {
      return null; // or throw an error
    }

    // Return the URL of the highest quality thumbnail
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  }

  return (
    <ProfileCard isMatchParent={true}>
      {playVideo ? (
        <div>
          <Button
            variant="text"
            startIcon={<ArrowBack />}
            onClick={() => {
              setPlayVideo(false);
            }}
          >
            Go Back
          </Button>
          <StrapiVideo segment={playVideo} />
        </div>
      ) : showLinks ? (
        <div>
          <Button
            variant="text"
            startIcon={<ArrowBack />}
            onClick={() => {
              setShowLinks(false);
            }}
          >
            Go Back
          </Button>
          <div>
            <ul style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
              {data?.linkTree?.map((link) => {
                return (
                  <li className={classes.links} style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.55)", padding: 15, borderRadius: "7px", }}>
                    <a target="_blank" href={link.url} style={{ display: "flex", flexDirection: "column", color: 'white', textAlign: "center", alignItems: "center", textDecoration: "none" }}>
                      {link.subTitle && <span style={{ fontWeight: "700" }}>{link.subTitle}</span>}
                      <span style={link.subTitle ? {} : { fontWeight: "700" }}>{link.title}</span>
                      {link.label && <span>{link.label}</span>}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : (
        <div>
          <Card className={classes.root}>
            <div className={classes.cover} alt="user profile">
              <Carousel showArrows={true} showThumbs={false}>
                {images}
              </Carousel>
            </div>
            <CardContent className={classes.details}>
              <div className={classes.userName}>
                <img src={userImg} className={classes.avatar} alt="user" />
                <Typography component="span" className={classes.userName}>
                  {`${data.firstName} ${data?.lastName ?? ""}`}
                </Typography>
              </div>

              <div className={classes.flexGrid} style={{ flexDirection: 'column' }}>
                <div className={classes.listItem}>
                  <span className={classes.icon}>
                    <HomeOutlined />
                  </span>
                  <span className={classes.text}>{data.location} </span>
                </div>
                <div className={classes.listItem}>
                  <span className={classes.icon + ' ' + classes.blue}>
                    <Public />
                  </span>
                  <span className={classes.text}>{data.title}</span>
                </div>
              </div>
              <p style={{ fontSize: '15px', marginLeft: '8px' }}>{data.summaryText}</p>
              <Button
                variant="text"
                onClick={() => {
                  setShowLinks(true);
                }}
                style={{
                  textAlign: 'left',
                  textTransform: 'none',
                  letterSpacing: 0,
                  marginTop: '1px',
                  marginLeft: '0px'
                }}
                className={classes.flexGridLink}
              >
                <img src={IconLink} style={{ width: '14px' }} />{' '}
                <span
                  style={{
                    marginLeft: '10px'
                  }}
                >
                  {`${data?.linkTree[0]?.url} and ${data?.linkTree.length - 1} more`}
                </span>
              </Button>
            </CardContent>
          </Card>
          <div alt="button profile">
            <div className={classes.flexGrid}>
              {doesProfileBelongToLoggedInUser && <Button className={classes.btn} variant="contained">
                Upload Image
              </Button>}
              {!doesProfileBelongToLoggedInUser && <Button className={classes.btn} variant="contained" onClick={auth.token ? () => {
                alert("This feature is under progress.")
              } : () => {
                history.replace({ pathname: location.pathname, search: 'login' });
              }}>
                Follow
              </Button>}
              <Button className={classes.btn} variant="contained">
                Share Profile
              </Button>
            </div>
          </div>
        </div>
      )}
      {/* <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={[
          {
            type: "custom-slide",
          },
          { src: Image1 },
          { src: Image2 },
          { src: Image3 },

        ]}
        render={{
          slide: ({ slide }) =>
            slide.type === "custom-slide" ? (
              <iframe width="560" height="315" src="https://www.youtube.com/embed/kBbKHyK86eI?si=TasCHJ_gqKV87jke" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            ) : undefined,
        }}
      /> */}
    </ProfileCard>
  );
}

export default withStyles(styles)(PersonCard);
