const styles = (theme) => {
  return {
    root: (props) => ({}),
    cardContainer: {
      width: '100%',
      position: 'relative',
      paddingTop: '150%',
      overflow: 'hidden'
    },
    card: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      margin: 'auto'
    },
    suspendisBox: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2%',
      textAlign: 'center'
    },
    titleContainer: {
      backgroundColor: '#434343',
      textAlign: 'center'
    },
    title: {
      color: '#ffffff',
      fontSize: '0.75rem',
      fontWeight: '300',
      letterSpacing: '1.4px'
    }
  };
};

export default styles;
