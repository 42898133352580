import React from 'react';
import { withStyles, Box } from '@material-ui/core';

import GridLayout from './GridLayout'
import QuoteCard from './QuoteCard'

import Image1 from '~/assets/images/profile/bottom-business-fav/1-1.png'
import Image2 from '~/assets/images/profile/bottom-business-fav/1-2.png'
import Image3 from '~/assets/images/profile/bottom-business-fav/1-3.png'
import Image4 from '~/assets/images/profile/bottom-business-fav/1-4.png'

const styles = (theme) => ({
  root: {
    padding: '20px 40px'
  }
});

const DataList = [
  {
    tag: {
      color: '#BC79BC',
      text: 'Inspiration'
    },
    title: 'QUOTE 2',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla purus velit',
    background: Image1
  },
  {
    tag: {
      color: '#D2A75F',
      text: 'JOY'
    },
    title: 'QUOTE 4',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla purus velit',
    background: Image2
  },
  {
    tag: {
      color: '#BC79BC',
      text: 'Inspiration'
    },
    title: 'QUOTE 10',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla purus velit',
    background: Image3
  },
  {
    tag: {
      color: '#7ECF79',
      text: 'HEALTH'
    },
    title: 'QUOTE 12',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla purus velit',
    background: Image4
  }
]
function Quotes(props) {
  const { classes } = props;
  return (
    <Box className={classes.root}>
      <GridLayout>
        {
          DataList.map(data => (
            <QuoteCard data={data} />
          ))
        }
      </GridLayout>
    </Box>
  )
}

export default withStyles(styles)(Quotes);
