import React from 'react';
import {withStyles} from '@material-ui/core';

const styles = (theme) => ({
  card: {
    border: 'solid 2px white',
    backgroundColor: 'rgba(255, 255, 255, 0.55)',
    borderRadius: '20px',
    padding: '10px',
  },
  matchParent: {
    height: '100%',
    boxSizing: 'border-box'
  }
});

function ProfileCard(props) {
  const {classes, isMatchParent} = props;

  return <div className={classes.card + ' ' + (isMatchParent ? classes.matchParent : '')}>{props.children}</div>;
}

export default withStyles(styles)(ProfileCard);
