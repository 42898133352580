import React from 'react';
import {withStyles, Grid} from '@material-ui/core';
import clsx from 'clsx';

import SlidingMediaContentStyles from './SlidingMediaContentStyles';

function SlidingMediaContent(props) {
  const {classes} = props;
  return (
    <Grid
      item
      container
      direction="column"
      className={clsx({
        [classes.mediaDetailContent]: true,
        [classes.slideInFromLeft]: props.slideInFromLeft,
        [classes.slideInFromRight]: props.slideInFromRight,
        [classes.slideOutToLeft]: props.slideOutToLeft,
        [classes.slideOutToRight]: props.slideOutToRight,

        [classes.slideInFromTop]: props.slideInFromTop,
        [classes.slideInFromBottom]: props.slideInFromBottom,
        [classes.slideOutToTop]: props.slideOutToTop,
        [classes.slideOutToBottom]: props.slideOutToBottom
      })}
    >
      {props.children}
    </Grid>
  );
}

export default withStyles(SlidingMediaContentStyles)(SlidingMediaContent);
