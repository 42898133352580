import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles, Container, Box} from '@material-ui/core';
import {SuspendisBox, MoreCardBox, ExpandBar, FlowGrid} from '~/components/ui';
import GuidesCardBox from './GuidesCardBox';
import Filter from './Filter';

import styles from './GuidesMainStyles';

const useStyles = makeStyles(styles);
const GuidesMain = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const [columnCount, setColumnCount] = useState(4);

  const {data, mode, typeListData, selectIndex, onFilterItemClick, handleSwitch} = props;

  const onColumnChange = (newCount) => {
    if (columnCount !== newCount) {
      setColumnCount(newCount);
    }
  };

  const getGuidesData = () => {
    return data.slice(0, 7).concat([{card: 'MORE_GUIDES', content: 'LOAD MORE REGIONAL GUIDES'}]);
  };
  return (
    <>
      <Container className={classes.root}>
        <div className={classes.flexBox}>
          <ExpandBar nav="Guide" subNav={mode} goExpanded={props.goExpanded} switcher={{
            items: ["Person", "Planet"],
            checkedIndex: mode === 'planet' ? 1 : 0,
            onChange: handleSwitch
          }}/>
        </div>

        <Filter
          itemList={typeListData}
          selectIndex={selectIndex}
          handleItemClick={onFilterItemClick}
          columnCount={columnCount}
        />
        <FlowGrid data={getGuidesData()} maxColumns={4} cardHeight="141%" onSetColumnsCount={onColumnChange}>
          {(v) => {
            const goDetailPage = () => {
              history.push({
                pathname: `/content/Guides/detail`,
                itemData: v
              });
            };
            let card = null;
            if (v.id) {
              card = <GuidesCardBox item={v} goDetail={props.goDetail} handleClick={goDetailPage}></GuidesCardBox>;
            } else if (v.card === 'SUSPENDIS_BOX') {
              card = (
                <Box className={classes.suspendisBox}>
                  <SuspendisBox desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tempus enim eu eros molestie, eget porta tellus fermentum. Pellentesque in ultricies tortor, ac fringilla libero. Nullam id sem a ipsum vulputate porta sed eget velit. " />
                </Box>
              );
            } else if (v.card === 'MORE_GUIDES') {
              card = <MoreCardBox content={v.content}></MoreCardBox>;
            }
            return card;
          }}
        </FlowGrid>
      </Container>
    </>
  );
};

export default GuidesMain;
