import React from 'react';

import {
  Home,
  OrganizeLanding,
  Articles,
  ArticleDetail,
  Quotes,
  Videos,
  VideoDetail,
  Initiatives,
  Health,
  Prosperity,
  Joy,
  Inspiration,
  Contribute,
  Disperse,
  CreationCompass,
  Metamorphosis,
  IntroCharter,
  InitiativesDetail,
  Sponsor,
  Profile,
  ContentMain,
  Guides,
  GuidesDetail,
  MetaWheel,
  Register
} from './pages';

import introCopmpass from './assets/images/layout/submenu/intro-compass.png';
import introCharter from './assets/images/layout/submenu/intro-charter.png';
import introMeta from './assets/images/layout/submenu/intro-metamorphosis.png';
import introMetawheel from './assets/images/layout/submenu/intro-metawheel.png';
import contentQuotes from './assets/images/layout/submenu/content-quotes.png';
import contentArticles from './assets/images/layout/submenu/content-articles.png';
import contentInit from './assets/images/layout/submenu/content-initiatives.png';
import contentVideos from './assets/images/layout/submenu/content-videos.png';
import networkContribute from './assets/images/layout/submenu/network-contribute.png';
import networkDisperse from './assets/images/layout/submenu/network-disperse.png';
import networkProfile from './assets/images/layout/submenu/network-profile.png';
import networkSponsor from './assets/images/layout/submenu/network-sponsor.png';
import organizeHealth from './assets/images/layout/submenu/organize-health.png';
import organizeIns from './assets/images/layout/submenu/organize-inspiration.png';
import organizeJoy from './assets/images/layout/submenu/organize-joy.png';
import organizePros from './assets/images/layout/submenu/organize-prosperity.png';

import * as messageKeys from '~/locales';

const routes = [
  {
    path:  '/',
    name: 'Home',
    component: Home,
    menu: null
  },
  //Intro Route
  {
    path:  '/',
    name: 'Intro',
    component: Home,
    menu: null
  },
  {
    path:  '/intro/compass',
    name: messageKeys.HEADER_INTROL_MENU_COMPASS,
    component: CreationCompass,
    menu: 'intro',
    image: introCopmpass
  },
  {
    path:  '/intro/charter',
    name: messageKeys.HEADER_INTROL_MENU_CHARTER,
    component: IntroCharter,
    menu: 'intro',
    image: introCharter
  },
  {
    path:  '/intro/metamorphosis',
    name: messageKeys.HEADER_INTROL_MENU_META,
    component: Metamorphosis,
    menu: 'intro',
    image: introMeta
  },
  {
    path:  '/intro/metawheel',
    name: messageKeys.HEADER_INTROL_MENU_METAWHEEL,
    component: MetaWheel,
    menu: 'intro',
    image: introMetawheel
  },
  //Content routes
  {
    path:  '/content',
    name: 'Content',
    component: ContentMain,
    menu: null
  },
  {
    path:  '/content/quotes',
    name: messageKeys.HEADER_CONTENT_MENU_QUOTES,
    component: Quotes,
    menu: 'content',
    image: contentQuotes,
    type: 'person'
  },
  {
    path:  '/content/quotes',
    name: messageKeys.HEADER_CONTENT_MENU_QUOTES,
    component: Quotes,
    image: contentQuotes,
    type: 'planet'
  },
  {
    path:  '/content/articles',
    name: messageKeys.HEADER_CONTENT_MENU_ARTICLES,
    component: Articles,
    menu: 'content',
    image: contentArticles,
    type: 'person'
  },
  {
    path:  '/content/articles',
    name: messageKeys.HEADER_CONTENT_MENU_ARTICLES,
    component: Articles,
    image: contentArticles,
    type: 'planet'
  },
  {
    path:  '/content/articles/:id',
    name: 'ArticleDetail',
    component: ArticleDetail,
    menu: null,
    image: null
  },
  {
    path:  '/content/videos',
    name: messageKeys.HEADER_CONTENT_MENU_VIDEOS,
    component: Videos,
    image: contentVideos,
    type: 'person'
  },
  {
    path:  '/content/videos',
    name: messageKeys.HEADER_CONTENT_MENU_VIDEOS,
    component: Videos,
    menu: 'content',
    image: contentVideos,
    type: 'planet'
  },

  {
    path:  '/content/videos/detail',
    name: 'videoDetail',
    component: VideoDetail,
    menu: null,
    image: null,
    type: null
  },
  {
    path:  '/content/initiatives',
    name: messageKeys.HEADER_CONTENT_MENU_SANCTUARY,
    component: Initiatives,
    menu: 'content',
    image: contentInit,
    type: 'person'
  },
  {
    path:  '/content/initiatives',
    name: messageKeys.HEADER_CONTENT_MENU_SANCTUARY,
    component: Initiatives,
    image: contentInit,
    type: 'planet'
  },
  {
    path:  '/content/initiatives/detail',
    name: 'InitiativesDetail',
    component: InitiativesDetail,
    menu: null,
    image: null
  },
  {
    path:  '/content/guides',
    name: messageKeys.HEADER_CONTENT_MENU_GUIDES,
    component: Guides,
    menu: 'content',
    image: contentInit,
    type: 'person'
  },
  {
    path:  '/content/guides',
    name: messageKeys.HEADER_CONTENT_MENU_GUIDES,
    component: Guides,
    image: contentInit,
    type: 'planet'
  },
  {
    path:  '/content/guides/detail',
    name: 'Guides',
    component: GuidesDetail,
    menu: null,
    image: null
  },
  //Organize Route
  {
    path:  '/organize',
    name: 'Organize',
    component: OrganizeLanding,
    menu: null
  },
  {
    path:  '/organize/health',
    name: messageKeys.HEADER_ORGANIZE_MENU_HEALTH,
    component: Health,
    menu: 'organize',
    image: organizeHealth
  },
  {
    path:  '/organize/prosperity',
    name: messageKeys.HEADER_ORGANIZE_MENU_PROSPERITY,
    component: Prosperity,
    menu: 'organize',
    image: organizePros
  },
  {
    path:  '/organize/inspiration',
    name: messageKeys.HEADER_ORGANIZE_MENU_INSPAIRATION,
    component: Inspiration,
    menu: 'organize',
    image: organizeIns
  },
  {
    path:  '/organize/joy',
    name: messageKeys.HEADER_ORGANIZE_MENU_JOY,
    component: Joy,
    menu: 'organize',
    image: organizeJoy
  },
  //Network Route
  {
    path:  '/network',
    name: 'Network',
    component: null,
    menu: null
  },
  {
    path:  '/network/contribute',
    name: messageKeys.HEADER_NETWORK_MENU_CONTRIBUTE,
    component: Contribute,
    menu: 'network',
    image: networkContribute
  },
  {
    path:  '/network/sponsor',
    name: messageKeys.HEADER_NETWORK_MENU_SPONSOR,
    component: Sponsor,
    menu: 'network',
    image: networkSponsor
  },
  {
    path:  '/network/disperse',
    name: messageKeys.HEADER_NETWORK_MENU_DISPERSE,
    component: Disperse,
    menu: 'network',
    image: networkDisperse
  },
  {
    path:  '/profile/:id',
    name: messageKeys.HEADER_NETWORK_MENU_PROFILE,
    component: Profile,
    menu: null,
    image: networkProfile,
    isSecondGenomePage: true
  },
  {
    path:  '/profile',
    name: messageKeys.HEADER_NETWORK_MENU_PROFILE,
    component: Profile,
    menu: 'network',
    image: networkProfile,
    isProtected: true,
    isSecondGenomePage: true
  },
  {
    path:  '/',
    name: 'Home second genome',
    component: ()=><h1>Welcome to second genome! Login Please.</h1>,
    menu: null,
    isSecondGenomePage: true
  },
  {
    path:  '/getStarted',
    name: messageKeys.HEADER_NETWORK_MENU_PROFILE,
    component: Register,
    menu: 'network',
    image: networkProfile,
    isProtected: false,
    isSecondGenomePage: true
  },
];

export default routes;
