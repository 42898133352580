import React from 'react';
import {withStyles, Grid} from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    background: 'white',
    height: '40px',
    width: '400px'
  },
  item: {
    height: '100%',
    borderRight: '1px #D2A75F solid',
    borderTop: '1px #D2A75F solid',
    borderBottom: '1px #D2A75F solid',
    cursor: 'pointer'
  },
  itemActive: {
    background: '#D2A75F',
    color: 'white',
    fontWeight: 'bold'
  },
  itemRight: {
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px'
  },
  itemLeft: {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    borderLeft: '1px #D2A75F solid'
  },
  itemIcon: {
    display: 'inline-block',
    marginRight: '10px',
    width: '15px'
  },
  itemName: {
    fontSize: '13px'
  }
});

function Tab(props) {
  const {classes, tabs, onTabSelect, selectIndex} = props;

  return (
    <Grid container className={classes.root}>
      {tabs.map((tab, index) => (
        <Grid
          key={index}
          item
          container
          xs={4}
          justifyContent="center"
          alignItems="center"
          className={clsx(classes.item, {
            [classes.itemActive]: index === selectIndex,
            [classes.itemRight]: index === tabs.length - 1,
            [classes.itemLeft]: index === 0
          })}
          onClick={() => {
            onTabSelect(index, tab);
          }}
        >
          {tab.icon ? <img src={tab.icon} className={classes.itemIcon} alt="item icon" /> : null}
          <span className={classes.itemName}>{tab.name}</span>
        </Grid>
      ))}
    </Grid>
  );
}

export default withStyles(styles)(Tab);
