import React from 'react';
import {withStyles, Grid} from '@material-ui/core';
import MessengerPanel from './MessengerPanel';

import clsx from 'clsx';

const styles = (theme) => ({
  item: {
    height: '50px',
    paddingLeft: '15px',
    borderBottom: '2px solid white',
    cursor: 'pointer'
  },
  icon: {
    width: '30px',
    height: '30px',
    marginRight: '10px'
  },
  textWrapper: {
    flex: 1
  },
  itemSelect: {
    backgroundColor: 'rgba(255, 255, 255, 0.76)',
    borderRight: '2px solid #6E00FF',
    boxShadow: '0 4px 4px #00000028'
  },
  itemFirst: {
    borderTopLeftRadius: '15px'
  }
});
function LeftList(props) {
  const {classes, selectIndex, dataList, onItemClick, isPersonal, selectTabIndex} = props;
  if (isPersonal && selectTabIndex === 4) {
    return <MessengerPanel />;
  }

  return (
    <>
      {dataList.map((item, index) => {
        return (
          <Grid
            container
            alignItems="center"
            key={index}
            className={clsx(classes.item, {
              [classes.itemSelect]: index === selectIndex,
              [classes.itemFirst]: index === 0
            })}
            onClick={() => onItemClick(index)}
          >
            <img className={classes.icon} src={item.icon} alt="icon"></img>
            <span className={classes.textWrapper}>{item.name}</span>
          </Grid>
        );
      })}
    </>
  );
}

export default withStyles(styles)(LeftList);
