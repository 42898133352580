const styles = (theme) => ({
  root: {
    display: 'block',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '&:hover $media': {
      transform: `translate(0, ${theme.cardBox.translate}px)`
    },
    '&:hover $meta': {
      opacity: 1,
      transform: `translate(0, ${theme.cardBox.translate}px)`
    }
  },
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    transition: `opacity .3s ease-out`,
    opacity: 1
  },
  hide: {
    opacity: 0
  },
  media: {
    position: 'absolute',
    top: -theme.cardBox.translate,
    bottom: 0,
    left: 0,
    right: 0,
    transform: 'translate(0, 0)',
    transition: `transform ${theme.cardBox.transition}`
  },
  meta: {
    position: 'absolute',
    backgroundColor: 'rgba(23, 23, 23, 0.73)',
    top: -theme.cardBox.translate,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    transition: `opacity  ${theme.cardBox.transition}, transform  ${theme.cardBox.transition}`
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  },
  title: {
    color: '#ffffff',
    fontWeight: 'bold',
    letterSpacing: '4px',
    marginTop: '20%',
    marginBottom: 12
  },
  content: {
    opacity: 0.5,
    color: '#fefefe',
    lineHeight: 1.86,
    letterSpacing: 1.3
  }
});

export {styles};
