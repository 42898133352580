import * as React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    padding: '5px 0'
  },
  itemContainer: {
    textAlign: 'center',
  },
  item: {
    padding: '0px 10px',
    height: '30px',
    color: 'white',
    lineHeight: '30px',
    display: 'inline-block',
    fontSize: '14px'
  },
  itemSelected: {
    background: 'white',
    color: '#595959',
    borderRadius: '15px'
  }
}));

const Fliter = (props) => {
  const classes = useStyles();
  const {handleItemClick, background, itemList, selectIndex, columnCount} = props;
  return (
    <Grid container style={background && {background}} className={clsx(classes.root)}>
      {itemList.map((item, index) => (
        <Grid
          item
          md={columnCount === 0 ? 3 : 12 / columnCount}
          onClick={() => {
            console.log('handleItemClick ' + index)
            handleItemClick(index);
          }}
        >
          <div className={classes.itemContainer}>
            <span
              className={clsx(classes.item, {
                [classes.itemSelected]: selectIndex === index
              })}
            >
              {item.text}
            </span>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default Fliter;
