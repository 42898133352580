import React, {useState, useEffect, useRef} from 'react';
import clsx from 'clsx';
import Measure from 'react-measure';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import styles from './SummaryCardStyles';

const useStyles = makeStyles(styles);

function getRootElementFontSize() {
  // Returns a number
  return parseFloat(
    // of the computed font-size, so in px
    getComputedStyle(
      // for the root <html> element
      document.documentElement
    ).fontSize
  );
}

function convertRem(value) {
  return value * getRootElementFontSize();
}

const SummaryCard = (props) => {
  const [linesCount, setLinesCount] = useState(1);
  const {lineHeight = 1.4, content, className} = props;
  const classes = useStyles({...props, linesCount, lineHeight});

  const handleResize = (contentRect) => {
    const {height} = contentRect.bounds;
    const count = Math.floor(height / convertRem(lineHeight));
    if (count !== linesCount) {
      setLinesCount(count);
    }
  };
  const timer = useRef(null);
  const scheduleResize = (contentRect) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => handleResize(contentRect), 10);
  };

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return (
    <Measure bounds onResize={scheduleResize}>
      {({measureRef}) => (
        <Box className={classes.root} ref={measureRef}>
          <Box className={clsx(classes.content, className)}>{content}</Box>
        </Box>
      )}
    </Measure>
  );
};

export default SummaryCard;
