import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import styles from './CarouselStyles';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import metawheel from '~/assets/images/layout/submenu/intro-metawheel.png';
import introCompass from '~/assets/images/layout/submenu/intro-compass.png'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          img: 'https://d2lm6fxwu08ot6.cloudfront.net/img-thumbs/960w/HA1RQCRQJ7.jpg',
          desc: "A successful planet where the attributes of love, forgiveness, compassion, tolerance and integrity form the basis of every human interaction and endeavour."
        },
        { img: 'https://d2lm6fxwu08ot6.cloudfront.net/img-thumbs/960w/EVHXF4MUT6.jpg', desc: "To be a catalyst to elevate global consciousness." },
        { img: 'https://d2lm6fxwu08ot6.cloudfront.net/img-thumbs/960w/D7VE3SK3RD.jpg', desc: "A Master Guide containing 11 wisdom statements which summarise each of the VFH Visions, making it easy for people to understand what we have identified as fundamental to bettering people and planet, and hence what we stand for." },
        { img: introCompass, desc: "Experience the magnitude of this universal equation to consciously create our world, discover it’s significance here and capture the power of  I = ER in your life to accumulate an abundance of love, wealth, health and happiness. Join Vision for Humanity and be a part of a powerful shift in global thinking." },
        { img: metawheel, desc: "Our specialised Meta Wheel interlocks the Charter, Visions and Initiatives with the Sanctuaries Guides, Courses and Services, all of which is accessed and interacts with the 2nd Genome Higher Consciousness Network." }
      ],
      text: ['VISION', 'MISSION', 'CHARTER', 'CREATION', 'METAWHEEL', 'INITIATIVES'],
      current: 0,
      isNext: true
    };

    this.goToHistoryClick = this.goToHistoryClick.bind(this);
  }
  goToHistoryClick(curIndex, index) {
    let next = curIndex < index;
    this.setNewState({
      current: index,
      isNext: next
    });
  }

  goToPrevious = (index) => {
    if (index !== 0) {
      this.setNewState({
        current: index - 1,
        isNext: this.state.current < index - 1
      });
    } else {
      this.setNewState({
        current: this.state.items.length - 1,
        isNext: false
      });
    }
  };

  goToNext = (index) => {
    if (index !== 4) {
      this.setNewState({
        isNext: this.state.current < index + 1,
        current: index + 1
      });
    } else {
      this.setNewState({
        isNext: true,
        current: 0
      });
    }
  };

  setNewState({ current, isNext }) {
    this.setState({
      ...this.state,
      isNext
    });
    setTimeout(() => {
      this.setState({
        ...this.state,
        current
      });
    });
  }

  render() {
    const { classes } = this.props;
    let index = this.state.current,
      src = this.state.items[index].img;
      let desc = this.state.items[index].desc;  // Fixed declaration

    let direction = this.state.isNext ? 'next' : 'prev';
    return (
      <div className={classes.carousel}>
        <TransitionGroup>
          <CSSTransition
            key={index}
            timeout={500}
            classNames={{
              enter: classes[`enter_${direction}`],
              enterActive: classes.enter_active,
              exit: classes.leave,
              exitActive: classes[`leave_active_${direction}`]
            }}
            style={{ height: '100%' }}
          >
            <div className={classes.carousel_slide}>
              <img src={src} alt="slide" />

              <div className={classes.carousel_desc}>
                {<p>
                  {desc}
                </p>}
              </div>
            </div>

          </CSSTransition>
        </TransitionGroup>
        <span className={classes.carousel_arrows}>
          <ChevronLeftIcon onClick={() => this.goToPrevious(index)} />
          <ChevronRightIcon onClick={() => this.goToNext(index)} />
        </span>
        <div className={classes.carousel_history}>
          <History
            current={this.state.current}
            items={this.state.items}
            changeSlide={this.goToHistoryClick}
            text={this.state.text}
            classes={classes}
          />
        </div>
      </div>
    );
  }
}

class History extends React.Component {
  render() {
    const { classes } = this.props;
    let current = this.props.current;
    let items = this.props.items.map((el, index) => {
      let name = index === current ? classes.carousel_history_ul_li_button_active : '';
      return (
        <li className={classes.carousel_history_ul_li} key={index}>
          <button
            className={`${classes.carousel_history_ul_li_button} ${name}`}
            onClick={() => this.props.changeSlide(current, index)}
          ></button>
          <div className={classes.carousel_history_ul_li_div} onClick={() => this.props.changeSlide(current, index)}>
            {this.props.text[index]}
          </div>
        </li>
      );
    });

    return (
      <React.Fragment>
        <ul className={classes.carousel_history_ul}>{items}</ul>
        <hr className={classes.carousel_history_hr} />
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Carousel));
