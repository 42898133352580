import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  titleContainer: {
    textAlign: 'center'
  },
  title: {
    display: 'inline-block',
    letterSpacing: 4,
    fontWeight: 700,
    border: 'solid 1px #cfcfcf',
    width: 350,
    lineHeight: 3
  }
}));

const SectionTitle = ({className, children}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title} align="center" variant="subtitle1">
          {children}
        </Typography>
      </Box>
    </div>
  );
};

export default SectionTitle;
