import React from 'react';
import { withStyles, Grid } from '@material-ui/core';

const styles = (theme) => ({
});

function GridLayout(props) {
  const { children } = props;
  return (
    <Grid container spacing={3}>
      {
        children.map(child => (
          <Grid item md={4} sm={6}>
            {child}
          </Grid>
        ))
      }
    </Grid>
  )
}

export default withStyles(styles)(GridLayout);
