import axios from '../../axios-api';

export const LOADING = 'LOADING';
export const GET_PAGE = 'GET_PAGE';
export const GET_PAGE_ERROR = 'GET_PAGE_ERROR';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const GET_QUOTES = 'GET_QUOTES';
export const GET_QUOTES_ERROR = 'GET_QUOTES_ERROR';
export const GET_MORE_QUOTES = 'GET_MORE_QUOTES';
export const GET_QUOTE = 'GET_QUOTE';
export const GET_QUOTE_ERROR = 'GET_QUOTE_ERROR';

//Get Pagination
export const getPagination = () => (dispatch) => {
  dispatch({type: LOADING});
  axios
    .get('/quote/list')
    .then((res) => {
      const {code, message} = res.data;
      if (code === 200) {
        dispatch(getPage(res.data.data.pagination));
      } else if (code === 400) {
        dispatch(getPageError(message));
      }
    })
    .catch((err) => {
      dispatch(getPageError(err));
    });
};

const getPage = (pageData) => {
  return {
    type: GET_PAGE,
    payload: pageData
  };
};

const getPageError = (error) => {
  return {
    type: GET_PAGE_ERROR,
    payload: error.response.data
  };
};

//Get Categories
export const getCategories = () => (dispatch) => {
  dispatch({type: LOADING});
  axios
    .get('/quote/config')
    .then((res) => {
      const {code, message} = res.data;
      if (code === 200) {
        dispatch(getAllCategories(res.data.data.categories));
      } else if (code === 400) {
        dispatch(getCategoriesError(message));
      }
    })
    .catch((err) => {
      dispatch(getCategoriesError(err));
    });
};

const getAllCategories = (categories) => {
  return {
    type: GET_CATEGORIES,
    payload: categories
  };
};

const getCategoriesError = (error) => {
  return {
    type: GET_CATEGORIES_ERROR,
    payload: error.response.data
  };
};

//Get All Quotes
export const getInitialQuotes = (page) => (dispatch) => {
  dispatch({type: LOADING});
  axios
    .get(`/quote/list/?size=8&sort_order=asc&page=${page}`)
    .then((res) => {
      const {code, message} = res.data;
      if (code === 200) {
        dispatch(getQuotes(res.data.data.items));
      } else if (code === 400) {
        dispatch(getQuotesError(message));
      }
    })
    .catch((err) => {
      dispatch(getQuotesError(err));
    });
};

const getQuotes = (quotes) => {
  return {
    type: GET_QUOTES,
    payload: quotes
  };
};

const getQuotesError = (error) => {
  return {
    type: GET_QUOTES_ERROR,
    payload: error.response.data
  };
};

//Get More Quotes
export const getMoreQuotesByPage = (page) => (dispatch) => {
  dispatch({type: LOADING});
  axios
    .get(`/quote/list/?size=8&sort_order=asc&page=${page}`)
    .then((res) => {
      const {code, message} = res.data;
      if (code === 200) {
        dispatch(getMoreQuotes(res.data.data.items));
      } else if (code === 400) {
        dispatch(getQuotesError(message));
      }
    })
    .catch((err) => {
      dispatch(getQuotesError(err));
    });
};

const getMoreQuotes = (quotes) => {
  return {
    type: GET_MORE_QUOTES,
    payload: quotes
  };
};

//Get Quotes By Category
export const getQuotesByCategory = (category) => (dispatch) => {
  dispatch({type: LOADING});
  axios
    .get(`/quote/list?size=8&sort_order=asc&category=${category}`)
    .then((res) => {
      const {code, message} = res.data;
      if (code === 200) {
        dispatch(getQuotes(res.data.data.items));
      } else if (code === 400) {
        dispatch(getQuotesError(message));
      }
    })
    .catch((err) => {
      dispatch(getQuotesError(err));
    });
};

//Get Quote By ID
export const getQuoteById = (id) => (dispatch) => {
  dispatch({type: LOADING});
  axios
    .get(`/quote/detail?id=${id}`)
    .then((res) => {
      const {code, message} = res.data;
      if (code === 200) {
        dispatch(getQuote(res.data.data.items[0]));
      } else if (code === 400) {
        dispatch(getQuoteError(message));
      }
    })
    .catch((err) => {
      dispatch(getQuoteError(err));
    });
};

const getQuote = (quote) => {
  return {
    type: GET_QUOTE,
    payload: quote
  };
};

const getQuoteError = (error) => {
  return {
    type: GET_QUOTE_ERROR,
    payload: error.response.data
  };
};
