import {connect} from 'react-redux';
import VideoLandingMainPage from './VideoLandingMainPage';

import {
  getPagination,
  getCategories,
  getInitialVideos,
  getMoreVideosByPage
} from '../../../store/actions/videosActions';

const mapStateToProps = (state) => {
  return {
    videos: state.videosReducer.videos,
    pagination: state.videosReducer.pagination,
    categories: state.videosReducer.categories,
    serverError: state.videosReducer.serverError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetPagination() {
      dispatch(getPagination());
    },

    handleGetCategories() {
      dispatch(getCategories());
    },

    handleGetInitialVideos(page) {
      dispatch(getInitialVideos(page));
    },

    handleGetMoreVideosByPage(page) {
      dispatch(getMoreVideosByPage(page));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoLandingMainPage);
