export const termsModalStyles = (theme) => ({
  modalHeading: {
    display: 'inline-block',
    textAlign: 'center',
    color: '#fff',
    fontWeight: '600',
    transform: 'translate(0, -80px)'
  },

  termsModalCard: {
    backgroundColor: 'rgba(77,94,101,0.8)',
    border: '1px solid rgba(255,255,255,0.5)',
    borderRadius: '1rem',
    marginBottom: '12px',
    padding: '1rem 3rem',
    outline: 'none',
    position: 'absolute',
    top: '100px',
    display: 'flex',
    flexDirection: 'column',
    wordWrap: 'break-word',
    backgroundClip: 'border-box',
    [theme.breakpoints.up('sm')]: {
      left: '22%',
      right: '22%'
    },
    [theme.breakpoints.down('sm')]: {
      left: '12px',
      right: '12px',
      padding: '1.5rem 2rem',
      bottom: '10px'
    },
    [theme.breakpoints.down('xs')]: {
      left: '12px',
      right: '12px',
      padding: '1.5rem 1rem',
      top: '110px',
      bottom: '10px'
    },
    '@media (max-width:200px)': {
      top: '164px'
    }
  },
  triangle: {
    position: 'absolute',
    margin: 'auto',
    top: '-7px',
    left: 0,
    right: 0,
    transform: 'rotate(225deg)',
    '-webkit-transform': 'rotate(225deg)',
    '-moz-transform': 'rotate(225deg)',
    '-o-transform': 'rotate(225deg)',
    '-ms-transform': 'rotate(225deg)',
    borderRight: '2.5px solid white',
    borderBottom: '2.5px solid white',
    width: '12px',
    height: '12px'
  },
  cardBorderBottom: {
    position: 'absolute',
    margin: 'auto',
    bottom: '-2px',
    left: '30%',
    width: '40%',
    borderBottom: '3px solid white'
  },
  cardBorderTopA: {
    position: 'absolute',
    margin: 'auto',
    top: '-2px',
    left: '30%',
    width: '19.3%',
    borderTop: '3px solid white'
  },

  cardBorderTopB: {
    position: 'absolute',
    margin: 'auto',
    top: '-2px',
    left: '50.7%',
    width: '19.3%',
    borderTop: '3px solid white'
  },

  cardBorderLeft: {
    position: 'absolute',
    margin: 'auto',
    left: '-2px',
    top: '15%',
    height: '70%',
    borderLeft: '3px solid white'
  },

  cardBorderRight: {
    position: 'absolute',
    margin: 'auto',
    right: '-2px',
    top: '15%',
    height: '70%',
    borderRight: '3px solid white'
  },

  termsDetails: {
    width: '100%',
    height: '50vh',
    overflow: 'auto'
  },

  termsHeading: {
    color: 'rgba(210,166,95,1)',
    display: 'inline-block',
    fontVariant: 'small-caps',
    fontSize: '1.55rem',
    fontWeight: 550,
    letterSpacing: '2px',
    transform: 'scale(.9, 1)',
    margin: '0'
  },
  termsText: {
    color: 'white',
    fontSize: '0.70rem',
    lineHeight: '1.625rem',
    transform: 'scale(.9, 1)',
    letterSpacing: '1px',
    marginLeft: '-5%',
    marginRight: '-2.5%',
    [theme.breakpoints.down('md')]: {
      marginLeft: '-5%',
      marginRight: '-2.5%',
      lineHeight: '1.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-5%',
      marginRight: '-5%',
      lineHeight: '1.375rem'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
      lineHeight: '1.25rem',
      letterSpacing: 0,
      transform: 'scale(1, 1)'
    }
  },

  termsForm: {
    margin: '1rem 0 1rem 1rem'
  },
  acceptCheckBoxGroup: {
    display: 'flex',
    flexWrap: 'nowrap',
    margin: '0.5rem 0',
    alignItems: 'center'
  },
  checkBoxLabel: {
    display: 'inline-block',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 400,
    margin: '0.125rem 0 0.125rem',
    transform: 'scale(.9, 1)'
  },
  checkbox: {
    margin: '0 0.5rem 0 0',
    padding: 0,
    color: 'white',
    opacity: 0.7
  },
  checkboxChecked: {},

  submitButton: {
    color: 'white',
    backgroundColor: 'rgb(210,166,95,1)',
    borderColor: 'rgb(210,166,95,1)',
    borderRadius: '0.25rem',
    padding: '0.75rem 2.5rem',
    fontSize: '0.66rem',
    fontWeight: 600,
    margin: '1rem 0',
    transition: 'background-color 1s linear 0',
    '&:hover': {
      backgroundColor: 'rgb(210,166,95,0.8)'
    }
  }
});
