import {connect} from 'react-redux';
import {
  getPagination,
  getCategories,
  getInitialQuotes,
  getMoreQuotesByPage
} from '../../../store/actions/quotesActions';
import Quotes from './QuotesMainPage';

const mapStateToProps = (state) => {
  return {
    quotes: state.quotesReducer.quotes,
    pagination: state.quotesReducer.pagination,
    categories: state.quotesReducer.categories,
    serverError: state.quotesReducer.serverError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetPagination() {
      dispatch(getPagination());
    },

    handleGetCategories() {
      dispatch(getCategories());
    },

    handleGetInitialQuotes(page) {
      dispatch(getInitialQuotes(page));
    },

    handleGetMoreQuotesByPage(page) {
      dispatch(getMoreQuotesByPage(page));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Quotes);
