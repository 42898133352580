import React from 'react';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';

import IconVideo from '~/assets/images/profile/ic-item-personal-profile-mymedia-video.png';

const styles = (_) => ({
  mediaWrapper: {
    position: 'relative'
  },
  mediaDesc: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    right: '0px',
    fontSize: '12px',
    color: 'white',
    background: 'rgba(0, 0, 0, 0.4)',
    padding: '3px'
  },
  mediaDescSingleLine: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  mediaImg: {
    width: '100%',
    display: 'block'
  },
  icVideo: {
    display: 'block',
    width: '30%',
    maxWidth: '40px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

function MediaCard(props) {
  const {media, classes, shortDesc} = props;
  return (
    <div className={classes.mediaWrapper}>
      <img src={media.src} className={classes.mediaImg} alt="media" />
      {media.desc ? (
        <div className={clsx(classes.mediaDesc, {[classes.mediaDescSingleLine]: shortDesc})}>{media.desc}</div>
      ) : null}
      {media.isVideo ? <img className={classes.icVideo} src={IconVideo} alt="IconVideo"></img> : null}
    </div>
  );
}

export default withStyles(styles)(MediaCard);
