import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Paper, Button} from '@material-ui/core';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6
import InitialContent from './data';

const useStyles = makeStyles((theme) => ({
  root: {}
}));

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
const EditorModules = {
  toolbar: [
    [{header: '1'}, {header: '2'}, {font: []}],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};

const ReactQuillSample = () => {
  const classes = useStyles();
  const initialData = InitialContent;
  const [data, setData] = useState(initialData);
  const [content, setContent] = useState('');
  const handleChange = (value) => {
    setData(value);
  };
  return (
    <Paper className={classes.root}>
      <Box pt={1} pb={6} px={3}>
        <h2>Using react-quill (Open Source, free)</h2>
        <ReactQuill theme="snow" value={data} onChange={handleChange} modules={EditorModules} />
        <Box mt={1}>
          <Button variant="contained" color="secondary" onClick={() => setContent(data)}>
            Save
          </Button>
        </Box>
        <div className="ql-container ql-snow">
          <div className="ql-editor" dangerouslySetInnerHTML={{__html: content}}></div>
        </div>
      </Box>
    </Paper>
  );
};

export default ReactQuillSample;
