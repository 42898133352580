// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Avatar, Typography, Button } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';
import SectionTitle from './SectionTitle';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    textDecoration: 'none',
    color: "inherit",
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    height: 150,
    width: 150,
    marginTop: 36,
    marginBottom: 33,
    [theme.breakpoints.down('md')]: {}
  },
  authorName: {
    marginTop: 24,
    marginBottom: 32,
    color: '#b2650c',
    opacity: 0.58,
    letterSpacing: 2.4,
    fontWeight: 700
  },
  button: {
    color: '#919191',
    borderRadius: '4px',
    fontWeight: 700,
    fontSize: '0.875rem',
    letterSpacing: 1.4,
    whiteSpace: 'nowrap',
    '& span': {
      height: '16px'
    }
  }
}));

const ArticleAuthor = ({ author }) => {
  const classes = useStyles();
  if (!author) {
    return <></>;
  }
  const { slug, firstName, lastName, displayPhotosAndVideos, title, summaryText } = author.data.attributes;
  let fullName = firstName;
  if (lastName) {
    fullName = `${firstName} ${lastName}`;
  }

  return (
    <Link to={`/profile/${slug}`} className={classes.root}>
      <Grid container justifyContent="center" direction='column'>
        <SectionTitle>{intl.get(messageKeys.ARTICLE_DETAIL_ABOUT_AUTHOR_TITLE)}</SectionTitle>
        <Grid container justifyContent="center">
          <Avatar className={classes.avatar} alt={fullName} src={displayPhotosAndVideos?.data[0].attributes.url} />
        </Grid>
        {!!title && <Typography variant="body2" align='center'>{title}</Typography>}
        {!!summaryText && <Typography variant="body2" align='center'>{summaryText}</Typography>}
        <Typography component="header" align="center" className={classes.authorName}>
          {fullName}
        </Typography>
        <Grid container justifyContent="center">
          <Grid item>
            <Button className={classes.button} startIcon={<InsertCommentIcon />}>
              {intl.get(messageKeys.ARTICLE_DETAIL_ABOUT_AUTHOR_BUTTON_MESSAGE)}
            </Button>
          </Grid>
          <Grid item>
            <Button className={classes.button} startIcon={<EventIcon />}>
              {intl.get(messageKeys.ARTICLE_DETAIL_ABOUT_AUTHOR_BUTTON_BOOK)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Link>
  );
};

export default ArticleAuthor;
