import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Avatar, Box, Grid, Typography, Divider, Paper, Link, IconButton } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Popover from 'material-ui-popup-state/HoverPopover';
import { bindPopover } from 'material-ui-popup-state/hooks';

import { styles } from './UserInfoPopoverStyles';

import MetaWheelImage from '~/assets/images/icons/metawheelAppBar.png';
import { useAuth } from 'shared/auth';

const useStyles = makeStyles(styles);

const UserInfoPopover = (props) => {
  const classes = useStyles();
  const { auth } = useAuth();
  let userInfo = (
    <Grid item>
      <Box pl={2}>
        <Typography align="center" variant="body2" component="p">
          Not Login?
        </Typography>
      </Box>
    </Grid>
  );
  if (props.user) {
    const { name, email, avatar } = props.user;
    userInfo = (
      <>
        <Grid item md={4} xs={3}>
          <Avatar className={classes.avatar} src={avatar} />
        </Grid>
        <Box className={classes.userInfoContainer} pr={2}>
          <Typography variant="h6" component="h2">
            {name}
          </Typography>
          <Typography variant="body2" component="p">
            {email}
          </Typography>
          <Grid container justifyContent="space-between">
            <Link href="#" className={classes.link}>
              Edit Profile
            </Link>
            <Link href="#" className={classes.link} onClick={() => props.logout()}>
              Logout
            </Link>
          </Grid>
        </Box>
      </>
    );
  }

  return (
    <Popover
      {...bindPopover(props.popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      elevation={0}
      className={classes.root}
    >
      <Card className={classes.card}>
        <div className={classes.triangle} />
        <Paper className={classes.content} variant="outlined">
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" md={6} xs={12}>
              {userInfo}
            </Grid>
            <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
            <Grid item xs={12} className={classes.horizontalDividerContainer}>
              <Divider className={classes.horizontalDivider} variant="middle" />
            </Grid>
            <Grid item md={4} xs={9}>
              <Typography variant="body2" component="p" className={classes.sanctuaryProfileSwitch}>
                Switch to
              </Typography>
              <Box className={classes.sanctuaryProfileLogoContainer} component="div">
                <img className={classes.sanctuaryProfileLogo} src={MetaWheelImage} alt="Sanctuary Profile Logo" />
                <Link href="#" className={classes.link}>
                  Sanctuary Profile
                </Link>
              </Box>
            </Grid>
            <Grid item md={1} xs={3} container direction="row" justifyContent="flex-end" alignItems="center">
              <IconButton color="default" aria-label="go to sanctuary profile">
                <ArrowForwardIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Card>
    </Popover>
  );
};

export default UserInfoPopover;
