import React from 'react';
import {withStyles, Typography, Checkbox, Button} from '@material-ui/core';
import {Sphere} from '~/components/ui';

const styles = (theme) => ({
  root: {
    padding: '20px',
    color: '#1B1B1B'
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  name: {
    fontSize: '2.5rem',
    fontWeight: '700',
    marginLeft: '20px',
    letterSpacing: '4px'
  },
  subTitle: {
    fontWeight: '700',
    fontSize: '1.3rem',
    marginLeft: '10px'
  },
  content: {
    fontSize: '1.2rem',
    margin: '10px',
    lineHeight: '1.5'
  },
  oneItem: {
    display: 'flex',
    alignItems: 'center'
  },
  submitGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '10px'
  },
  btn: {
    backgroundColor: '#8D8D8D',
    color: 'black',
    width: '80px'
  }
});

const CustomerCheckbox = withStyles({
  root: {
    color: '#0077FF',
    '&$checked': {
      color: '#0077FF'
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

function Prosperity(props) {
  const {classes} = props;
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div>
          <Sphere type={1} sphereRad={40} width={80} height={80} />
        </div>
        <Typography className={classes.name}>Prosperity</Typography>
      </div>
      <Typography className={classes.subTitle}>Be a part of the solution</Typography>
      <div className={classes.content}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur.
      </div>
      <div className={classes.select}>
        <div className={classes.oneItem}>
          <CustomerCheckbox checked />
          <Typography>I help reduce global warming by choosing to eat only 100gms of meat once per week.</Typography>
        </div>
        <div className={classes.oneItem}>
          <CustomerCheckbox />
          <Typography>I help reduce global warming by choosing to eat only 100gms of meat once per week.</Typography>
        </div>
        <div className={classes.oneItem}>
          <CustomerCheckbox />
          <Typography>I help reduce global warming by choosing to eat only 100gms of meat once per week.</Typography>
        </div>
        <div className={classes.oneItem}>
          <CustomerCheckbox />
          <Typography>I help reduce global warming by choosing to eat only 100gms of meat once per week.</Typography>
        </div>
      </div>
      <div className={classes.submitGroup}>
        <Button className={classes.btn}>Edit</Button>
        <Button className={classes.btn}>Save</Button>
        <Button className={classes.btn}>Cancel</Button>
      </div>
    </div>
  );
}

export default withStyles(styles)(Prosperity);
