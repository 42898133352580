const styles = (theme) => ({
    media: {
        position: 'relative',
        // paddingTop: '56.25%' // 16:9
        paddingTop: '80%'
    },
    title: {
        backgroundColor: 'black',
        opacity: 0.8,
        color: 'white',
        padding: '15px 40px',
        lineHeight: 2,
        fontSize: 13,
        fontWeight: 700,
        letterSpacing: 3
    },
    content: {
        paddingLeft: 20,
        paddingRight: 20
    },
    buttonBox: {
        paddingTop: 20
    }
});

export default styles;
