// import Auth from 'aws-amplify';
// import axios from '~/axios-api';
import axios from '../../axios-api';
const Auth = null
export const AUTH_SIGNEDUP = 'AUTH_SIGNEDUP';
export const AUTH_VERIFIED = 'AUTH_VERIFIED';
export const AUTH_SIGNEDIN = 'AUTH_SIGNEDIN';
export const AUTH_SIGNEDOUT = 'AUTH_SIGNEDOUT';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_PROCESSING = 'AUTH_PROCESSING';
export const AUTH_RESEND_SIGNUP = 'AUTH_RESEND_SIGNUP';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const AUTH_AUTHENTICATED = 'AUTH_AUTHENTICATED';
export const AUTH_FORGOT_PASSWORD_SUBMIT = 'AUTH_FORGOT_PASSWORD_SUBMIT';
export const AUTH_FORGOT_PASSWORD_REQUEST = 'AUTH_FORGOT_PASSWORD_REQUEST';
export const AUTH_VERIFY_ATTRIBUTE_CONFIRM = 'AUTH_VERIFY_ATTRIBUTE_CONFIRM';
export const AUTH_VERIFY_ATTRIBUTE = 'AUTH_VERIFY_ATTRIBUTE';
export const AUTH_UPDATE_ATTRIBUTE = 'AUTH_UPDATE_ATTRIBUTE';

/**
 * @param {string} name
 * @param {string} password
 * @param {string} email
 * @param {string} phone_number
 * @param {string} password_confirmation
 * @param {function} callback
 */
export function signUp(name, password, email, phone_no, password_confirmation, callback = null) {
  return (dispatch) => {
    dispatch(beginAuthRequest());

    axios
      .post('/register', { email, password, password_confirmation, name, phone_no, from: 'HCN' })
      .then((res) => {
        const { code } = res.data;
        if (code === 200) {
          dispatch(userSignedUp(res.data));
          if (callback !== null && callback !== undefined) {
            callback();
          }
        } else if (code === 400) {
          const errors = res.data.data.errors;
          dispatch(userSignUpError({ type: 'data', errors: errors }));
        }
      })
      .catch((err) => {
        dispatch(userSignUpError({ type: 'error', errors: err }));
      });
  };
}

function userSignedUp(user) {
  return {
    type: AUTH_SIGNEDUP,
    payload: { user: user }
  };
}

function userSignUpError(error) {
  return {
    type: AUTH_ERROR,
    payload: { error: error }
  };
}

/**
 * Invoke AWS Cognito confirm sign up with username and confirmation code.
 * Also @see https://aws-amplify.github.io/docs/js/authentication.
 *
 * @param {String} username
 * @param {String} code
 */
export function verifySignUp(username, code, callback = null) {
  return (dispatch) => {
    dispatch(beginAuthRequest());
    const xx = Auth?.confirmSignUp(username, code, {
      forceAliasCreation: true
    }).then((data) => {
        dispatch(userVerified(data));
        if (callback !== null && callback !== undefined) {
          callback();
        }
      }) .catch((err) => {
        dispatch(userVerifiedError(err));
      });
  };
}

function userVerified(user) {
  return {
    type: AUTH_VERIFIED,
    payload: { user: user }
  };
}

function userVerifiedError(error) {
  return {
    type: AUTH_ERROR,
    payload: { error: error }
  };
}

/**
 * @param {string} username
 * @param {string} password
 */
export function signIn(username, password, callback = null) {
  return (dispatch) => {
    dispatch(beginAuthRequest());

    axios
      .post('/login', { email: username, password })
      .then((res) => {
        const { code } = res.data;
        if (code === 200) {
          dispatch(userSignedIn(res.data));
          if (callback !== null && callback !== undefined) {
            callback();
          }
        } else if (code === 400) {
          const errors = res.data.data.errors;
          dispatch(userSignedInError({ type: 'data', errors: errors }));
        }
      })
      .catch((err) => {
        dispatch(userSignedInError({ type: 'error', errors: err }));
      });
  };
}

function userSignedIn(user) {
  return {
    type: AUTH_SIGNEDIN,
    payload: { user: user }
  };
}

function userSignedInError(error) {
  return {
    type: AUTH_ERROR,
    payload: { error: error }
  };
}

export function signOut(callback = null) {
  return (dispatch) => {
    axios
      .post('/logout')
      .then((res) => {
        dispatch(userSignedOut());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch(userSignedOutError({ type: 'error', errors: err }));
      });

    // const xx = Auth?.signOut()
    //   .then(() => {
    //     dispatch(userSignedOut());
    //     if (callback) {
    //       callback();
    //     }
    //   })
    //   .catch((err) => {
    //     dispatch(userSignedOutError(err));
    //   });
  };
}

function userSignedOut() {
  return {
    type: AUTH_SIGNEDOUT
  };
}

function userSignedOutError(error) {
  return {
    type: AUTH_ERROR,
    payload: { error: error }
  };
}

function beginAuthRequest() {
  return {
    type: AUTH_PROCESSING
  };
}

export function getUser(callback = null) {
  return (dispatch) => {
    dispatch(beginAuthRequest());
    axios
      .get('/profile')
      .then((res) => {
        const { code, data } = res.data;
        if (code === 200) {
          // Succeeded
          dispatch(getUserSuccess(data));
          if (callback !== null && callback !== undefined) {
            callback();
          }
        } else if (code === 401) {
          // Unauthorized user
          dispatch(userSignedOut());
        }
      })
      .catch((err) => dispatch(getUserError({ type: 'error', errors: err })));
  };
}

function getUserSuccess(user) {
  return {
    type: AUTH_USER,
    payload: { user }
  };
}

function getUserError(error) {
  return {
    type: AUTH_ERROR,
    payload: { error: error }
  };
}

/**
 * Invoke AWS Cognito resend sign up with username, AWS will resend the confirmation code.
 * Also @see https://aws-amplify.github.io/docs/js/authentication.
 *
 * @param {string} username
 */
export function resendSignUp(username, callback = null) {
  return (dispatch) => {
    dispatch(beginAuthRequest());
    const xx = Auth?.resendSignUp(username)
      .then(() => {
        dispatch(userResendSignUp());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch(userResendSignUpError(err));
      });
  };
}

function userResendSignUp() {
  return {
    type: AUTH_RESEND_SIGNUP
  };
}

function userResendSignUpError(error) {
  return {
    type: AUTH_ERROR,
    payload: { error: error }
  };
}

/**
 * Invoke AWS Cognito change password
 * Also @see https://aws-amplify.github.io/docs/js/authentication.
 *
 * @param {string} oldPassword the current user password
 * @param {string} newPassword the new password to change to
 */
export function changePassword(oldPassword, newPassword, callback = null) {
  return (dispatch) => {
    dispatch(beginAuthRequest());
    const xx = Auth?.currentAuthenticatedUser()
      .then((user) => {
        dispatch(beginAuthRequest());
        const xx = Auth?.changePassword(user, oldPassword, newPassword)
          .then(() => {
            dispatch(userChangedPassword(user));
            if (callback) {
              callback();
            }
          })
          .catch((err) => dispatch(userChangedPasswordError(err)));
      })
      .catch((err) => dispatch(userAuthenticatedError(err)));
  };
}

function userChangedPassword(user) {
  return {
    type: AUTH_CHANGE_PASSWORD,
    payload: { user: user }
  };
}

function userChangedPasswordError(error) {
  return {
    type: AUTH_ERROR,
    payload: { error: error }
  };
}

/**
 * Invoke AWS Cognito currentAuthenticatedUser
 * Also @see https://aws-amplify.github.io/docs/js/authentication.
 *
 */
export function checkAuthenticateUser(callback = null) {
  return (dispatch) => {
    const xx = Auth?.currentAuthenticatedUser()
      .then((user) => {
        dispatch(userAuthenticated(user));
        if (callback) {
          callback();
        }
      })
      .catch((err) => dispatch(userAuthenticatedError(err)));
  };
}

function userAuthenticated(user) {
  return {
    type: AUTH_AUTHENTICATED,
    payload: { user: user }
  };
}

function userAuthenticatedError(error) {
  return {
    type: AUTH_ERROR,
    payload: { error: error }
  };
}

/**
 * Invoke AWS Cognito forgotPassword
 * Also @see https://aws-amplify.github.io/docs/js/authentication.
 *
 * This begins the password recovery process. @see userRequestForgotPasswordSubmit
 * for completing the recovery process.
 *
 * @param {string} username
 */
export function userRequestForgotPassword(username, callback = null) {
  return (dispatch) => {
    dispatch(beginAuthRequest());
    const xx = Auth?.forgotPassword(username)
      .then((user) => {
        dispatch(userForgotPasswordBegin(user));
        if (callback) {
          callback();
        }
      })
      .catch((err) => dispatch(userForgotPasswordError(err)));
  };
}

function userForgotPasswordBegin(user) {
  return {
    type: AUTH_FORGOT_PASSWORD_REQUEST,
    payload: { user: user }
  };
}

/**
 * Invoke AWS Cognito forgot password confirmation
 * Also @see https://aws-amplify.github.io/docs/js/authentication.
 *
 * @param {string} username
 * @param {string} code confirms the recover password request is authentic
 * @param {string} newPassword the new password to change to
 */
export function userRequestForgotPasswordSubmit(username, code, newPassword, callback = null) {
  return (dispatch) => {
    dispatch(beginAuthRequest());
    const xx = Auth?.forgotPasswordSubmit(username, code, newPassword)
      .then((user) => {
        dispatch(userForgotPasswordSubmitted(user));
        if (callback) {
          callback();
        }
      })
      .catch((err) => dispatch(userForgotPasswordError(err)));
  };
}

function userForgotPasswordSubmitted(user) {
  return {
    type: AUTH_FORGOT_PASSWORD_SUBMIT,
    payload: { user: user }
  };
}

function userForgotPasswordError(error) {
  return {
    type: AUTH_ERROR,
    payload: { error: error }
  };
}

/**
 * Invoke AWS Cognito verifyCurrentUserAttribute
 * Also @see https://aws-amplify.github.io/docs/js/authentication.
 *
 * This will only verify the phone number.
 *
 * This begins the verification process. @see verifyPhoneNumberSubmit
 * for completing the process.
 */
export function verifyPhoneNumber(callback = null) {
  return (dispatch) => dispatch(verifyAttribute('phone_number', callback));
}

/**
 * Invoke AWS Cognito verifyCurrentUserAttributeSubmit
 * Also @see https://aws-amplify.github.io/docs/js/authentication.
 *
 * This will only verify the phone number.
 *
 * @param {string} code the confirmation code
 */
export function verifyPhoneNumberSubmit(code, callback = null) {
  return (dispatch) => dispatch(verifyAttributeSubmit('phone_number', code, callback));
}

/**
 * Invoke AWS Cognito verifyCurrentUserAttribute
 * Also @see https://aws-amplify.github.io/docs/js/authentication.
 *
 * This will only verify the email.
 *
 * This begins the verification process. @see verifyEmailAddressSubmit
 * for completing the process.
 */
export function verifyEmailAddress(callback = null) {
  return (dispatch) => dispatch(verifyAttribute('email', callback));
}

/**
 * Invoke AWS Cognito verifyCurrentUserAttribute
 * Also @see https://aws-amplify.github.io/docs/js/authentication.
 *
 * This will only verify the email.
 *
 * @param {string} code the confirmation code
 */
export function verifyEmailAddressSubmit(code, callback = null) {
  return (dispatch) => dispatch(verifyAttributeSubmit('email', code, callback));
}

/**
 * Invoke AWS Cognito updateUserAttributes
 * Also @see https://aws-amplify.github.io/docs/js/authentication.
 *
 * This will only verify the phone number.
 *
 * This begins the verification process. @see verifyPhoneNumberSubmit
 * for completing the process.
 */
export function updateUserAttributes(attributes, callback = null) {
  return (dispatch) => {
    dispatch(beginAuthRequest());
    const xx = Auth?.currentAuthenticatedUser()
      .then((user) => {
        const xx = Auth?.updateUserAttributes(user, attributes)
          .then((data) => {
            dispatch(updateUserAttributesBegin(data));
            if (callback) {
              callback();
            }
          })
          .catch((err) => dispatch(updateUserAttributesError(err)));
      })
      .catch((err) => dispatch(userAuthenticatedError(err)));
  };
}

function updateUserAttributesBegin(data) {
  return {
    type: AUTH_UPDATE_ATTRIBUTE,
    payload: { data: data }
  };
}

function updateUserAttributesError(error) {
  return {
    type: AUTH_ERROR,
    payload: { error: error }
  };
}

/**
 * Invoke AWS Cognito verifyCurrentUserAttribute
 * Also @see https://aws-amplify.github.io/docs/js/authentication.
 *
 * This begins the verification process. @see verifyEmailAddressSubmit
 * for completing the process.
 *
 * @param {string} attribute the attribute to verify, e.g. 'email', 'phone_number'.
 */
export function verifyAttribute(attribute, callback = null) {
  return (dispatch) => {
    dispatch(beginAuthRequest());
    const xx = Auth?.verifyCurrentUserAttribute(attribute)
      .then(() => {
        dispatch(verifyAttributeBegin());
        if (callback) {
          callback();
        }
      })
      .catch((err) => dispatch(verifyAttributeError(err)));
  };
}

function verifyAttributeBegin() {
  return {
    type: AUTH_VERIFY_ATTRIBUTE
  };
}

/**
 * Invoke AWS Cognito verifyCurrentUserAttributeSubmit
 * Also @see https://aws-amplify.github.io/docs/js/authentication.
 *
 * @param {string} attribute the attribute to confirm updating
 * @param {string} code the confirmation code
 */
export function verifyAttributeSubmit(attribute, code, callback = null) {
  return (dispatch) => {
    dispatch(beginAuthRequest());
    const xx = Auth?.verifyCurrentUserAttributeSubmit(attribute, code)
      .then((data) => {
        dispatch(verifyAttributeConfirm(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => dispatch(verifyAttributeError(err)));
  };
}

function verifyAttributeConfirm(data) {
  return {
    type: AUTH_VERIFY_ATTRIBUTE_CONFIRM,
    payload: { data: data }
  };
}

function verifyAttributeError(error) {
  return {
    type: AUTH_ERROR,
    payload: { error: error }
  };
}
