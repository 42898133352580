import React from 'react';
import {withStyles, MuiThemeProvider, Modal} from '@material-ui/core';
import NavigationBar from '../layout/Header';
import Footer from '../layout/Footer/Footer';
import {styles, theme} from './LayoutStyle';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Login from '~/auth/Login';
import { closeLoginModal, openLoginModal } from '~/utils/commonFunc';


function Layout(props) {
  const {classes} = props;
  const location = useLocation();
  const history = useHistory();

  const handleClose = () => {
    closeLoginModal(history, location);
  };
console.log(location,'location')
  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <main className={classes.main}>
          <NavigationBar/>
          <div className={classes.contentContainer}>{props.children}</div>
        </main>
        <Footer className={styles.footer} />
        <Modal
          open={location.search.includes('?login') || location.pathname.includes('?login')}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
          <>
          <Login closeLoginModal={() => closeLoginModal(history, location)} />
          </>
        </Modal>
      </MuiThemeProvider>
    </React.Fragment>
  );
}

export default withStyles(styles)(Layout);
