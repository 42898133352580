import React from 'react';
import {withStyles, Grid, Typography} from '@material-ui/core';

import MetaWheel from '~/assets/images/intro/metaWheel/scene12_bg.png';

const styles = (theme) => ({
  root: {
    background: '#F4F2F2'
  },
  metaWheel: {
    width: '70%',
    marginLeft: '26%',
    [theme.breakpoints.only('xs')]: {
      width: '80%',
      marginLeft: '10%'
    }
  },
  rightContainer: {
    color: '#707070',
    height: '100%',
    padding: '40px 25% 60px 5%',
    '& > p': {
      padding: '10px 0',
      fontSize: '0.8rem'
    },
    [theme.breakpoints.only('xs')]: {
      width: '80%',
      marginLeft: '10%',
      padding: '20px 0'
    }
  },
  title: {
    lineHeigth: '0.9'
  },
  desc: {
    fontSize: '0.8rem'
  },
  button: {
    height: '30px',
    lineHeight: '30px',
    border: '1px solid #FFD540',
    borderRadius: '20px',
    width: '150px',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none'
  }
});

function Scene12(props) {
  const {classes} = props;
  return (
    <Grid container alignItems="strech" className={classes.root}>
      <Grid child xs={12} sm={6}>
        <Grid container alignItems="center" style={{height: '100%'}}>
          <img className={classes.metaWheel} src={MetaWheel} alt="MetaWheel" />
        </Grid>
      </Grid>
      <Grid child xs={12} sm={6}>
        <Grid container direction="column" justifyContent="center" className={classes.rightContainer}>
          <Typography variant="h4">Something Greater</Typography>
          <Typography variant="h4">Global Metawheel</Typography>
          <Typography variant="subtitle1">Global Advancement Architecture</Typography>
          <Typography variant="body1">
            Your magnificence can help others. The Global Metawheel illustrates how your growth ultimately benefits the
            planet. Further, there is significant research revealing how helping others, or contributing to something
            meaningful, can provide you even greater happiness and wellbeing. Through little effort, but greater
            understanding, your advancement seamlessly Elevates Global Consciousness bringing more Increase to others.
          </Typography>
          <Typography variant="body1">
            We believe in first helping the individual in order that they may, from a position of strength and
            compassion, help others. The second ring of the Global Metawheel focuses on global advancement. The VFH
            Visions, Initiatives and Charter surrounds the wheel supporting and interlocking the blueprint of a 2nd
            Genome of Humanity.
          </Typography>
          <Typography variant="body1">
            The Sanctuary interlocks your development and advancement into planetary wellbeing. Through its greater
            purpose and ethos, the Sanctuary shows the path of greater people for better planet.
          </Typography>
          <Typography variant="body1">
            This may be something you wish to explore at a later date, or if of interest, take a look now at the
            interactive diagram and discover how, at the right time, you can engage a higher purpose to participate in
            creating something much greater than your magnificent self: “A Successful Planet.”
          </Typography>
          <div className={classes.button}>Know More</div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(Scene12);
