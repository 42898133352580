export const FETCH_PRODUCTS = `
query fetchProducts($first: Int!, $cursor: String, $collectionHandle: String!,$filters:[ProductFilter!]) {
  collectionByHandle(handle: $collectionHandle) {
    handle
  products(first: $first, after: $cursor, filters: $filters, sortKey: TITLE, reverse: false) {
    pageInfo {
      hasNextPage
      endCursor
    }
      edges {
        node {
          id
          handle
          title
          descriptionHtml
          text_above_product_title: metafield(namespace: "custom", key: "text_above_product_title") {
            value
          }
          text_below_product_title: metafield(namespace: "custom", key: "text_below_product_title") {
            value
          }
          images(first: 1) {
          edges {
            node {
              src
            }
          }
        }
          variants(first: 3) {
            nodes {
              image {
                src
              }
              priceV2 {
                amount
              }
              id
            }
          }
        }
      }
    }
  }
}
`;

export const FETCH_PRODUCTS_BY_QUERY = `
query fetchProductsByHandles($first: Int!, $query: String!) {

  products(first: $first, query: $query) {
      edges {
        node {
          id
          handle
          title
          descriptionHtml
          text_above_product_title: metafield(namespace: "custom", key: "text_above_product_title") {
            value
          }
          text_below_product_title: metafield(namespace: "custom", key: "text_below_product_title") {
            value
          }
          images(first: 1) {
          edges {
            node {
              src
            }
          }
        }
          variants(first: 3) {
            nodes {
              image {
                src
              }
              priceV2 {
                amount
              }
              id
            }
          }
        }
      }
    }
  
}
`;

export const FETCH_PRODUCT = `query fetchProduct($handle: String!) {
  productByHandle(handle: $handle) {
    id
    title
    descriptionHtml
    top_ingredients: metafield(namespace: "custom", key: "top_ingredients") {
      references(first: 250) {
        nodes {
          ... on Metaobject {
            name: field(key: "name") {
              value
            }
            image: field(key: "image") {
              value
              reference {
                ... on MediaImage {
                  image {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    food_science: metafield(
      namespace: "sanctuaryStoreFoodProducts"
      key: "food_science"
    ) {
      value
    }
    preparation: metafield(
      namespace: "sanctuaryStoreFoodProducts"
      key: "preparation"
    ) {
      value
    }
    video: metafield(namespace: "sanctuaryStoreFoodProducts", key: "video") {
      reference {
        ... on Video {
          id
          sources {
            url
            format
          }
        }
      }
    }
    made_in_nz: metafield(namespace: "custom", key: "made_in_nz") {
      value
    }
    packed_in_nz: metafield(namespace: "custom", key: "packed_in_nz") {
      value
    }
    certified_organic: metafield(namespace: "custom", key: "certified_organic") {
      value
    }
    functional_ingredients: metafield(
      namespace: "custom"
      key: "functional_ingredients"
    ) {
      value
    }
    give_forward: metafield(namespace: "custom", key: "give_forward") {
      value
    }
    natural: metafield(namespace: "custom", key: "100_natural") {
      value
    }
    reuse_recycle_system: metafield(namespace: "custom", key: "reuse_recycle_system") {
      value
    }
    nutrient_dense: metafield(namespace: "custom", key: "nutrient_dense") {
      value
    }
    palm_oil_free: metafield(namespace: "custom", key: "palm_oil_free") {
      value
    }
    protein_source: metafield(namespace: "custom", key: "protein_source") {
      value
    }
    ecopackaged: metafield(namespace: "custom", key: "ecopackaged") {
      value
    }
    ingredients: metafield(namespace: "custom", key: "ingredients") {
      value
    }
    video_caption: metafield(namespace: "custom", key: "video_caption") {
      value
    }
    text_above_product_title: metafield(namespace: "custom", key: "text_above_product_title") {
      value
    }
    text_below_product_title: metafield(namespace: "custom", key: "text_below_product_title") {
      value
    }
    nutrition_information: metafield(
      namespace: "custom"
      key: "nutrition_information"
    ) {
      reference {
        ... on MediaImage {
          image {
            url
          }
        }
      }
    }
    variants(first: 3) {
      edges {
        node {
          id
          title
          image {
            id
            originalSrc
          }
          priceV2 {
            amount
          }
        }
      }
    }
    images(first: 3) {
      edges {
        node {
          id
          originalSrc
        }
      }
    }
  }
}
`;

export const ADD_PRODUCT_TO_CART = `
  mutation addItemToCart($cartId: ID!, $lineItems: [CartLineInput!]!)  {
  cartLinesAdd(cartId: $cartId, lines: $lineItems) {
    cart {
      id
    }
  }
}

`;

export const CREATE_CART = `
mutation createCart {
  cartCreate {
    cart {
      id
      checkoutUrl
    }
  }
}
`;

export const FETCH_CART = `
  query fetchCart($id: ID!) {
  cart(id: $id) {
    checkoutUrl
    lines(first: 100) {
      edges {
        node {
          id
          merchandise {
            ... on ProductVariant {
              id
              title
              priceV2 {
                amount
              }
              image {
                id
                originalSrc
              }
              product {
                title
              }
              quantityAvailable
            }
          }
         quantity
          cost {
            totalAmount {
              amount
            }
          }
        }
      }
    }
    cost {
      totalAmount {
        amount
      }
    }
    totalQuantity
  }
}
`;

export const CART_ITEM_QUANTITY_UPDATE = `
mutation cartItemQuantityUpdate($cartId: ID!, $lineItems:[CartLineUpdateInput!]!) {
  cartLinesUpdate(cartId: $cartId, lines: $lineItems) {
    cart {
      id
    }
  }
}
`;

export const CART_ITEM_REMOVE = `
mutation cartItemRemove($cartId: ID!, $lineIds:[ID!]!) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
    cart {
      id
    }
  }
}
`;

export const FETCH_LIFEINGREDIENTS = `
query fetchLifeIngredients{
metaobjects(type:"ingredient",first:250){
  nodes{
    name: field(key:"name"){
        value
    }
     description: field(key:"description"){
        value
    }
    image: field(key:"image"){
        value
              reference{
... on MediaImage{
  image{
    url
  }
}     }
    }
  }
}}`;

export const FETCH_RELATEDPRODUCTS = `
query fetchRelatedProducts($id: ID!) {
  productRecommendations(productId:$id){
    id
    handle
    collections(first:1){
        edges{
            node{
                handle
            }
        }
    }
    title
        text_above_product_title: metafield(namespace: "custom", key: "text_above_product_title") {
      value
    }
    text_below_product_title: metafield(namespace: "custom", key: "text_below_product_title") {
      value
    }
    variants(first: 1) {
            nodes {
              image {
                src
              }
              priceV2 {
                amount
              }
              id
            }
          }
  }
}`;

export const FETCH_RELATEDPRODUCTSFORPAGE = `
query fetchRelatedProductsForPage{
metaobject(handle:{handle:"shop-by-health-interest",type:"related_products_for_page"}){
    id: field(key:"page_id"){
        value
    } 
    products: field(key:"products"){
      references(first:10) {
        edges{
          node{
            ... on Product{
              title
              handle
              collections(first:1){
                edges{
                    node{
                        handle
                    }
                }
            }
        text_above_product_title: metafield(namespace: "custom", key: "text_above_product_title") {
      value
    }
    text_below_product_title: metafield(namespace: "custom", key: "text_below_product_title") {
      value
    }
    variants(first: 1) {
            nodes {
              image {
                src
              }
              priceV2 {
                amount
              }
              id
            }
          }
            }
          }
        }
      }
  } 
    }
  }`;

export const LOGIN_CUSTOMER = `
  mutation loginCustomer($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export const TOKEN_RENEW_CUSTOMER = `
mutation tokenRenewCustomer($customerAccessToken:String!) { 
  customerAccessTokenRenew(customerAccessToken: $customerAccessToken) {
  customerAccessToken {
    accessToken
    expiresAt
  }
  userErrors {
    field
    message
    }
  }
}
`;
export const CREATE_CUSTOMER = `
mutation createCustomer($input: CustomerCreateInput!) {
  customerCreate(input: $input) {
    userErrors {
      field
      message
    }
  }
}
`;

export const ASSOCIATE_CHECKOUT = `
mutation associateCheckout($buyerIdentity: CartBuyerIdentityInput!, $cartId:ID!) {
  cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
    cart {
      checkoutUrl
    }
  }
}`;

export const FETCH_ORDER_HISTORY = `
query fetchOrderHistory($token:String!){
  customer(customerAccessToken: $token) {
    orders(first: 10) {
      nodes {
        totalPrice {
          amount
        }
        totalTaxV2 {
          amount
        }
        totalShippingPriceV2 {
          amount
        }
        totalPriceV2 {
          amount
        }
        statusUrl
        name
        fulfillmentStatus
        customerUrl
        id
        orderNumber
      }
    }
  }
}
`;

export const FETCH_CUSTOMER = `
query fetchCustomer($token:String!){
  customer(customerAccessToken: $token) {
      firstName
      lastName
    }
}
`;

export const FETCH_ORDER_HISTORY_WITH_LINEITEMS = `
query fetchOrderHistoryWithLineitems($token:String!){
  customer(customerAccessToken: $token) {
    orders(first: 250) {
      nodes {
        lineItems(first: 250) {
          nodes {
            variant {
              id
            }
          }
        }
      }
    }
  }
}
`
