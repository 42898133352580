// @ts-check
import { Button, IconButton, makeStyles, TextField, Typography } from '@material-ui/core';
import { Comment, Share, ThumbUpSharp } from '@material-ui/icons';
import React from 'react';
import TextOverlayImage from '~/components/ui/TextOverlayImage';
import { PublicProfileThumbnail } from './PublicProfileThumbnail';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles((theme) => ({
  commentSection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(0)
  },
  commentInput: {
    flex: 1,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%'
  }
}));

export const ArticleDetailItem = (props) => {
  const classes = useStyles();
  const { category, item, index2, profilePic } = props;
  console.log("item", item);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', background: 'white' }}
    >
      <PublicProfileThumbnail
        style={{
          position: 'absolute',
          margin: 10,
        }}
        slug={item.visionaire?.slug} />
      <TextOverlayImage
        color={category?.color}
        key={`${index2}`}
        src={item.img}
        text={category?.title}
      />
      <div className={classes.commentSection}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
            <IconButton aria-label="Like">
              <ThumbUpSharp />
            </IconButton>

            <IconButton aria-label="share">
              <Comment />
            </IconButton>

            <IconButton aria-label="share">
              <Share />
            </IconButton>

            {item.slug && (
              <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Link to={`/content/articles/${item.slug}`}>
                  <Button>Read Full Article on HCN</Button>
                </Link>
              </div>
            )}
          </div>
          {item.summary && (
            <Typography variant="body1" style={{ textAlign: 'left', width: '100%', padding: 10 }}>
              {item.summary}
            </Typography>
          )}
          <TextField
            className={classes.commentInput}
            variant="outlined"
            size="small"
            placeholder="Add a comment..."
          />
        </div>
        {/* Additional code for displaying comments can go here */}
      </div>
      {/* <img
          key={`${index}-${index2}`}
          src={item.img}
          className={classes.gridItem}
          style={{width: '100%', objectFit: 'cover'}}
        />
        <p>{item.description}</p> */}
    </div>
  );
}