import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {QueryClient, QueryClientProvider} from 'react-query';

import './index.css';
import App from './AppContainer';

import intl from 'react-intl-universal';
//import Amplify from 'aws-amplify';
//import config from './aws-exports';
import store from './store';
import {loadLocales} from './locales';
import { AuthProvider } from 'shared/auth';

//Amplify.configure(config);

window.intl = intl;
window.reduxStore = store;

const history = createBrowserHistory();

loadLocales(); // Load locales.  Default language: en-US
// loadLocales('de-DE');

const queryClient = new QueryClient();

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <QueryClientProvider client={queryClient}>
    <AuthProvider>
        <App />
      </ AuthProvider>
      </QueryClientProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
