import React from 'react';
import { withStyles, Box } from '@material-ui/core';

import GridLayout from './GridLayout'
import VideoCard from './VideoCard'

import Image1 from '~/assets/images/profile/bottom-business-fav/3-1.png'
import Image2 from '~/assets/images/profile/bottom-business-fav/3-2.png'
import Image3 from '~/assets/images/profile/bottom-business-fav/3-3.png'
import Image4 from '~/assets/images/profile/bottom-business-fav/3-4.png'
import Image5 from '~/assets/images/profile/bottom-business-fav/3-5.png'

const styles = (theme) => ({
  root: {
    padding: '20px 40px'
  }
});

const DataList = [
  {
    tag: {
      color: '#7ECF79',
      text: 'LIFE FORCE FOOD'
    },
    title: 'VIDEO NAME',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse platea dictumst.',
    background: Image1
  },
  {
    tag: {
      color: '#7ECF79',
      text: 'LIFE FORCE FOOD'
    },
    title: 'VIDEO NAME',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse platea dictumst.',
    background: Image2
  },
  {
    tag: {
      color: '#7ECF79',
      text: 'LIFE FORCE FOOD'
    },
    title: 'VIDEO NAME',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse platea dictumst.',
    background: Image3
  },
  {
    tag: {
      color: '#7ECF79',
      text: 'LIFE FORCE FOOD'
    },
    title: 'VIDEO NAME',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse platea dictumst.',
    background: Image4
  },
  {
    tag: {
      color: '#7ECF79',
      text: 'LIFE FORCE FOOD'
    },
    title: 'VIDEO NAME',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse platea dictumst.',
    background: Image5
  },
]

function Videos(props) {
  const { classes } = props;
  return (
    <Box className={classes.root}>
      <GridLayout>
        {
          DataList.map(data => (
            <VideoCard data={data} />
          ))
        }
      </GridLayout>
    </Box>
  )
}

export default withStyles(styles)(Videos);
