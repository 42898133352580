// @ts-check
import React from "react";
import TextOverlayImage from "~/components/ui/TextOverlayImage";

/**
 * @typedef {Object} ArticleCardBoxProps
 * @property {import("shared/react-query/strapiAPI").ArticleEntity} item - article data
 * @property {import("shared/react-query/strapiAPI").HCNCategory} category - article category
 * @property {Function} handleClick - click event handler
 */

/**
 * ArticleCardBox component
 * @param {ArticleCardBoxProps} props - component props
 */
const ArticleOverlayImage = ({ item, category, handleClick }) => {
  const { img, slug } = item;

  return (
    <div onClick={() => handleClick({ slug })}>
      <TextOverlayImage color={category?.color} src={img} text={category?.title} />
    </div>
  );
};

export default ArticleOverlayImage;
