const OrganizeLandingStyles = (theme) => ({
  black_border: {
    width: '100vw',
    height: '10px',
    backgroundColor: 'rgba(0, 0, 0, 1)'
  },
  metawheel_content: {
    padding: '2% 5%'
  },

  metawheel_description: {
    textAlign: 'left'
  },

  metawheel_switcher: {
    height: '160px'
  },

  metawheel_switcher_list: {
    height: '50px',
    listStyle: 'none',
    padding: '0',
    margin: '40px 0 0',
    display: 'flex',
    WebkitBoxPack: 'justify',
    justifyContent: 'space-between'
  },

  metawheel_switcher_item: {
    width: '150px',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      width: '22vw'
    }
  },

  metawheel_switcher_text: {
    fontSize: '12px',
    fontWeight: '700',
    letterSpacing: '2px',
    color: '#9e6200',
    opacity: '0.5',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px'
    }
  },

  metawheel_switcher_btn: {
    outline: 'none',
    border: 'none',
    borderRadius: '7px',
    backgroundColor: '#e9d3ae',
    width: '14px',
    height: '14px',
    cursor: 'pointer',
    zIndex: '2',
    WebkitTransition: 'background-color 300ms ease',
    transition: 'background-color 300ms ease'
  },

  metawheel_switcher_text_active: {
    opacity: '1'
  },

  metawheel_switcher_btn_active: {
    backgroundColor: '#d2a75f'
  },

  metawheel_switcher_hr: {
    opacity: '0.5',
    border: '1px solid #d2a75f',
    marginTop: '-7px',
    width: 'calc(100% - 150px)',
    [theme.breakpoints.down('xs')]: {
      width: '66vw'
    }
  },

  getApp: {
    marginTop: '80px',
    justifyContent: 'center',

    [theme.breakpoints.only('md')]: {
      marginTop: '40px',
      alignItems: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '0'
    }
  },

  getAppLink: {
    textDecoration: 'none'
  },

  icon_m: {
    width: '50px',
    height: '50px',
    margin: '0 20px',
    [theme.breakpoints.down('xs')]: {
      width: '40px',
      height: '40px'
    }
  },

  getAppText: {
    width: '100px',
    textAlign: 'left',
    fontSize: '12px',
    fontWeight: '700',
    letterSpacing: '2px',
    color: '#9e6200',
    [theme.breakpoints.only('md')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px'
    }
  },

  metawheelImage: {
    maxWidth: '100%',
    height: 'auto'
  }
});

export default OrganizeLandingStyles;
