import React from 'react';
import {withStyles, Grid, Divider} from '@material-ui/core';
import clsx from 'clsx';

import IconHeadRight from '~/assets/images/profile/ic-item-personal-profile-mymessenger-head-right.png';
import IconHeadLeft from '~/assets/images/profile/ic-item-personal-profile-mymessenger-head-left.png';

import IconSearch from '~/assets/images/profile/ic-item-personal-profile-msgpanel-search.png';
import IconChat from '~/assets/images/profile/ic-item-personal-profile-msgpanel-chat.png';
import IconPeople from '~/assets/images/profile/ic-item-personal-profile-msgpanel-people.png';
import IconGroup from '~/assets/images/profile/ic-item-personal-profile-msgpanel-group.png';
import IconAlert from '~/assets/images/profile/ic-item-personal-profile-msgpanel-alert.png';

const styles = (theme) => ({
  flexExpand: {
    flex: 1
  },
  header: {
    padding: '15px 20px'
  },
  headerHeadWrapper: {
    position: 'relative',
    marginRight: '10px'
  },
  headerHead: {
    width: '60px'
  },
  onlineStatus: {
    width: '12px',
    height: '12px',
    position: 'absolute',
    right: '5px',
    bottom: '5px',
    background: '#06A800',
    borderRadius: '6px'
  },
  headerUserName: {
    fontSize: '16px',
    flex: 1
  },
  iconSearch: {
    width: '25px',
    cursor: 'pointer'
  },
  tabWrapper: {
    background: 'rgba(225, 225, 225, 0.37)',
    marginBottom: '15px'
  },
  tabItem: {
    flex: 1,
    height: '40px'
  },
  tabDivider: {
    height: '20px'
  },
  tabImg: {
    height: '20px'
  },
  friendItem: {
    padding: '10px 20px'
  },
  friendActive: {
    background: 'rgba(225, 225, 225, 0.37)'
  },
  friendHeadWrapper: {
    position: 'relative',
    marginRight: '10px'
  },
  friendHead: {
    width: '50px',
    height: '50px'
  },
  friendOnlineStatus: {
    width: '8px',
    height: '8px',
    position: 'absolute',
    right: '6px',
    bottom: '6px',
    background: '#06A800',
    borderRadius: '4px'
  },
  friendDesc: {
    color: '#4B4B4B',
    fontSize: '14px'
  },
  friendName: {
    flex: 1,
    fontSize: '16px'
  },
  chatTime: {
    fontSize: '12px',
    color: '#4B4B4B'
  }
});

const friendList = [
  {
    userName: 'John Doe',
    desc: 'Duis aute irure dolor in repre',
    lastChatTime: '15/06/2019',
    isOnline: true,
    userHead: IconHeadLeft,
    active: true
  },
  {
    userName: 'Friend B',
    desc: 'Duis aute irure dolor in repre',
    lastChatTime: '15/06/2019',
    isOnline: true,
    userHead: IconHeadLeft
  },
  {
    userName: 'Friend C',
    desc: 'Duis aute irure dolor in repre',
    lastChatTime: '15/06/2019',
    isOnline: true,
    userHead: IconHeadLeft
  },
  {
    userName: 'Friend D',
    desc: 'Duis aute irure dolor in repre',
    lastChatTime: '15/06/2019',
    isOnline: true,
    userHead: IconHeadLeft
  },
  {
    userName: 'Friend E',
    desc: 'Duis aute irure dolor in repre',
    lastChatTime: '15/06/2019',
    isOnline: true,
    userHead: IconHeadLeft
  },
  {
    userName: 'Friend F',
    desc: 'Duis aute irure dolor in repre',
    lastChatTime: '15/06/2019',
    isOnline: true,
    userHead: IconHeadLeft
  }
];

function MessengerPanel(props) {
  const {classes} = props;

  return (
    <div>
      <Grid container alignItems="center" className={classes.header}>
        <div className={classes.headerHeadWrapper}>
          <img src={IconHeadRight} className={classes.headerHead} alt="user head" />
          <div className={classes.onlineStatus} />
        </div>

        <div className={classes.headerUserName}>Jane Adams</div>
        <img src={IconSearch} className={classes.iconSearch} alt="icon search" />
      </Grid>

      <Grid container alignItems="center" className={classes.tabWrapper}>
        {[IconChat, IconPeople, IconGroup, IconAlert].map((icon, index) => (
          <>
            <Grid container justifyContent="center" alignItems="center" className={classes.tabItem}>
              <img src={icon} className={classes.tabImg} alt="tab icon" />
            </Grid>
            <Divider orientation="vertical" className={classes.tabDivider} />
          </>
        ))}
      </Grid>

      <div className={classes.friendList}>
        {friendList.map((friend) => (
          <Grid
            container
            alignItems="center"
            className={clsx(classes.friendItem, {
              [classes.friendActive]: friend.active
            })}
          >
            <div className={classes.friendHeadWrapper}>
              <img src={friend.userHead} className={classes.friendHead} alt="friend head" />
              {friend.isOnline ? <div className={classes.friendOnlineStatus} /> : null}
            </div>
            <div className={classes.flexExpand}>
              <Grid container alignItems="center">
                <div className={classes.friendName}>{friend.userName}</div>
                <div className={classes.chatTime}>{friend.lastChatTime}</div>
              </Grid>
              <div className={classes.friendDesc}>{friend.desc}</div>
            </div>
          </Grid>
        ))}
      </div>
    </div>
  );
}

export default withStyles(styles)(MessengerPanel);
