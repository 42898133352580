
const styles = (theme) => ({
  root: {},
  sence1: {
    background: '#E5E5E5',
    position: 'relative',
  
  }
});

export {styles};
