import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles, Grid, Button} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';

import {UploadInputBoxStyles} from './Styles';

export class UploadInputBox extends Component {
  state = {
    fileName: ''
  };

  selectFile = (e) => {
    if (e.target.files.length === 0) {
      this.setState({
        fileName: ''
      });
    } else {
      let selectedFile = e.target.files.item(0);
      this.setState({
        fileName: selectedFile.name
      });
      this.props.getFile(selectedFile);
    }
  };

  render() {
    const {classes} = this.props;
    const {heading, inputId, format, uploadHintText, buttonId} = this.props;
    return (
      <Grid container item direction="column" justifyContent="flex-start" xs={12} sm={12} md={6}>
        <section>
          <Typography className={classes.uploadInputBoxHeading}>{heading}</Typography>
          <div>
            <label htmlFor={inputId} className={classes.labelForInput}>
              <Typography className={classes.fileName}>{this.state.fileName}</Typography>
            </label>
            <input id={inputId} className={classes.input} type="file" accept={format} onChange={this.selectFile} />
          </div>
          <Typography className={classes.uploadHintText}>{uploadHintText}</Typography>
          <Button type="submit" id={buttonId} className={classes.uploadBtn} onClick={this.props.uploadContent}>
            {intl.get(messageKeys.CONTRIBUTE_FORM_BUTTON_UPLOAD)}
          </Button>
        </section>
      </Grid>
    );
  }
}

UploadInputBox.propTypes = {
  classes: PropTypes.object.isRequired,
  getFile: PropTypes.func.isRequired,
  uploadContent: PropTypes.func.isRequired
};
export default withStyles(UploadInputBoxStyles)(UploadInputBox);
