// @ts-check
import { useQuery } from 'react-query'
import { useAuth } from '../auth'
import { fetchVisionaireProfile } from './strapiAPI';

export const useFetchPublicProfile = (slug) => {
  const { auth } = useAuth();
  return useQuery(['visionaireProfile', slug],
    () => fetchVisionaireProfile(slug, auth.token),
    {
      enabled: !!slug,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 60 * 1000 * 1000,
    });
};