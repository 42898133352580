import React from 'react';
import {withStyles, Grid} from '@material-ui/core';

import ContentHeader from '../../ContentHeader';

import Icon from '~/assets/images/profile/ic-item-personal-profile-mynetwork.png';
import Image1 from '~/assets/images/profile/img-item-personal-profile-mynetwork-1.png';
import Image2 from '~/assets/images/profile/img-item-personal-profile-mynetwork-2.png';
import Image3 from '~/assets/images/profile/img-item-personal-profile-mynetwork-3.png';
import Image4 from '~/assets/images/profile/img-item-personal-profile-mynetwork-4.png';
import Image5 from '~/assets/images/profile/img-item-personal-profile-mynetwork-5.png';

const styles = (theme) => ({
  root: {
    padding: '30px 40px'
  },
  contentWrapper: {
    margin: '10px'
  },
  flexExpand: {
    flex: 1
  },
  subtitle: {
    width: '100%',
    fontSize: '16px'
  },
  imageItem: {
    marginTop: '15px'
  },
  image: {
    width: '80px'
  },
  imageInfo: {
    marginLeft: '5px',
    paddingTop: '10px'
  },
  author: {
    fontSize: '14px'
  },
  kind: {
    fontSize: '12px',
    color: '#616161'
  }
});

const dataList = [
  {
    title: 'MY NETWORK',
    imageList: [
      {
        src: Image1,
        author: 'Laura Ipsum',
        kind: 'Writer'
      },
      {
        src: Image2,
        author: 'Laura Ipsum',
        kind: 'Writer'
      },
      {
        src: Image2,
        author: 'Laura Ipsum',
        kind: 'Writer'
      },
      {
        src: Image1,
        author: 'Laura Ipsum',
        kind: 'Writer'
      },
      {
        src: Image1,
        author: 'Laura Ipsum',
        kind: 'Writer'
      },
      {
        src: Image2,
        author: 'Laura Ipsum',
        kind: 'Writer'
      }
    ]
  },
  {
    title: 'SUGGESTIONS',
    imageList: [
      {
        src: Image3,
        author: 'Laura Ipsum',
        kind: 'Writer'
      },
      {
        src: Image4,
        author: 'Laura Ipsum',
        kind: 'Writer'
      },
      {
        src: Image4,
        author: 'Laura Ipsum',
        kind: 'Writer'
      },
      {
        src: Image5,
        author: 'Laura Ipsum',
        kind: 'Writer'
      },
      {
        src: Image3,
        author: 'Laura Ipsum',
        kind: 'Writer'
      },
      {
        src: Image4,
        author: 'Laura Ipsum',
        kind: 'Writer'
      }
    ]
  }
];
function MyNetwork(props) {
  const {classes} = props;
  return (
    <div className={classes.root}>
      <ContentHeader name="MY NETWORK" icon={Icon} />
      <Grid container className={classes.contentWrapper}>
        {dataList.map((item) => (
          <Grid item container md={6}>
            <div className={classes.subtitle}>{item.title}</div>
            {item.imageList.map((imageItem) => (
              <Grid item container sm={6} className={classes.imageItem}>
                <img className={classes.image} src={imageItem.src} alt="mymedia" />
                <div className={classes.imageInfo}>
                  <div className={classes.author}>{imageItem.author}</div>
                  <div className={classes.kind}>{imageItem.kind}</div>
                </div>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(MyNetwork);
