import {withStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core';
const BottomButton = withStyles({
  root: {
    border: 'solid 2px #c5c5c5',
    borderRadius: 6,
    color: '#a5a5a5',
    fontSize: 9,
    letterSpacing: '1.3px',
    padding: '4px 8px 2px 8px',
    '&:hover': {
      backgroundColor: 'white'
    }
  }
})(Button);

export default BottomButton;
