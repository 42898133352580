const styles = (theme) => ({
  media: {
    position: 'relative',
    // paddingTop: '56.25%' // 16:9
    paddingTop: '100%'
  },
  title: {
    backgroundColor: 'black',
    opacity: 0.8,
    color: 'white',
    padding: '15px 40px',
    lineHeight: 2,
    fontSize: 13,
    fontWeight: 700,
    letterSpacing: 3,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    boxSizing: 'border-box'
  },
  content: {
    paddingLeft: 20,
    paddingRight: 20
  },
  buttonBox: {
    paddingTop: 20
  },
  moreContainer: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    paddingTop: '100%',
    backgroundColor: '#ffffff',
    textAlign: 'center'
  },
  more: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingBottom: 65,
    overflow: 'hidden',
    textAlign: 'left',
    color: '#a5a5a5',
    letterSpacing: '1.3px'
  },
  moreContent: {
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  },
  bottomButton: {
    fontSize: 11,
    position: 'absolute',
    left: 'calc(50% - 60px)',
    bottom: 25
  }
});

export default styles;
