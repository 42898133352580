import React from 'react';
import {useHistory} from 'react-router-dom';
import {SuspendisBox, MoreCardBox, ExpandBar, FlowGrid, ToggleContentTitles} from '~/components/ui';
import {makeStyles, Container, Box} from '@material-ui/core';
import InitiativeCardBox from './InitiativeCardBox';

import styles from './InitiativesMainStyles';

const useStyles = makeStyles(styles);

const InitiativesMain = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const {data, mode, titles, selectedTitleIds, onTitlesChange, handleSwitch} = props;

  const getInitiativesData = () => {
    return data.slice(0, 7).concat([{card: 'MORE_INITIATIVES', content: 'LOAD MORE REGIONAL INITIATIVES'}]);
  };
  return (
    <>
      <Container className={classes.root}>
        <div className={classes.flexBox}>
          <ExpandBar nav="Initiative" subNav={mode} goExpanded={props.goExpanded} switcher={{
            items: ["Person", "Planet"],
            checkedIndex: mode === 'planet' ? 1 : 0,
            onChange: handleSwitch
          }}/>
        </div>
        <ToggleContentTitles titles={titles} selectedIds={selectedTitleIds} onChange={onTitlesChange} />
        <FlowGrid data={getInitiativesData()} maxColumns={4}>
          {(v) => {
            const goDetailPage = () => {
              history.push({
                pathname: `/content/initiatives/detail`,
                itemData: v
              });
            };
            let card = null;
            if (v.id) {
              card = (
                <InitiativeCardBox item={v} goDetail={props.goDetail} handleClick={goDetailPage}></InitiativeCardBox>
              );
            } else if (v.card === 'SUSPENDIS_BOX') {
              card = (
                <Box className={classes.suspendisBox}>
                  <SuspendisBox />
                </Box>
              );
            } else if (v.card === 'MORE_INITIATIVES') {
              card = <MoreCardBox content={v.content}></MoreCardBox>;
            }
            return card;
          }}
        </FlowGrid>
      </Container>
    </>
  );
};

export default InitiativesMain;
