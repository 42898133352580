import React from 'react';
import {withStyles, Grid, Typography, Button} from '@material-ui/core';

import Mobile from '~/assets/images/intro/metaWheel/scene1_mobile.png';
import PhoneList from '~/assets/images/intro/metaWheel/scene1_phone_list.png';
import MetaWheel from '~/assets/images/intro/metaWheel/scene1_wheel.png';

const styles = (theme) => ({
  root: {
    background: '#E5E5E5',
    position: 'relative',
    overflow: 'hidden'
  },
  leftPart: {
    [theme.breakpoints.up('sm')]: {
      flex: '1'
    },
    '& > div': {
      fontSize: '10vw',
      color: 'rgba(112, 112, 112, 0.1)',
      margin: '5% 0 0 15%'
    }
  },
  rightPart: {
    [theme.breakpoints.up('sm')]: {
      flex: '1'
    },
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '15%'
    },
    color: '#707070'
  },
  centerPart: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '26%'
    },
    '& > img': {
      width: '100%'
    }
  },
  phoneList: {
    height: '40px'
  },
  title1: {
    marginTop: '30px',
    lineHeight: '0.9'
  },
  title2: {
    marginTop: '30px',
    fontWeight: 'bold'
  },
  desc2: {
    lineHeight: '1.2'
  },
  metaWheel: {
    position: 'absolute',
    width: '40%',
    minWidth: '300px',
    right: 0,
    bottom: 0,
    transform: 'translate(35%, 45%)'
  }
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#7E489C',
    '&:hover': {
      backgroundColor: '#7E489C'
    },
    borderRadius: '18px',
    padding: '6px 20px',
    marginTop: '50px',
    textTransform: 'none',
    marginBottom: '50px'
  }
}))(Button);

function Scene1(props) {
  const {classes} = props;
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid child xs={12} className={classes.leftPart}>
          <div>
            Meta
            <br />
            Wheel
          </div>
        </Grid>
        <Grid child xs={12} className={classes.centerPart}>
          <img src={Mobile} alt="Mobile" />
        </Grid>
        <Grid child container alignItems="center" xs={12} className={classes.rightPart}>
          <div>
            <img src={PhoneList} className={classes.phoneList} alt="PhoneList" />

            <Typography variant="h4" className={classes.title1}>
              Meta Wheel
            </Typography>
            <Typography variant="body2" className={classes.desc1}>
              Discover, Shift, Evolve, Create
            </Typography>
            <Typography variant="body1" className={classes.title2}>
              Human advancement architecture
            </Typography>
            <Typography variant="body1" className={classes.desc2}>
              Unlock the pathways to your full Life force, Holistic Human Vibrancy, Significance and Purpose.
            </Typography>

            <ColorButton>Read More</ColorButton>
          </div>
        </Grid>
      </Grid>
      <img src={MetaWheel} className={classes.metaWheel} alt="MetaWheel" />
    </div>
  );
}

export default withStyles(styles)(Scene1);
