// @ts-check
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from "@material-ui/core";
import { TopButton, BottomButton, SummaryCard } from "~/components/ui";
import styles from "./ArticleCardBoxStyles";

const useStyles = makeStyles(styles);

/**
 * @typedef {Object} ArticleCardBoxProps
 * @property {import("shared/react-query/strapiAPI").ArticleEntity} item - article data
 * @property {import("shared/react-query/strapiAPI").HCNCategory} category - category 
 * @property {Function} handleClick - click event handler
 */

/**
 * ArticleCardBox component
 * @param {ArticleCardBoxProps} props - component props
 */
const ArticleCardBox = ({ item, category, handleClick }) => {
  const { img, title, slug, summary } = item;
  const classes = useStyles();

  return (
    <Card elevation={0} square={true}>
      <CardMedia
        className={classes.media}
        image={img}
      >
        <TopButton style={{ backgroundColor: category?.color }}>{category?.title}</TopButton>
        <Typography className={classes.title}>{title}</Typography>
      </CardMedia>
      <Box className={classes.moreContainer}>
        <CardContent className={classes.more}>
          <SummaryCard content={summary} />
        </CardContent>
        <BottomButton
          className={classes.bottomButton}
          onClick={() => handleClick({ slug })}
        >
          READ MORE
        </BottomButton>
      </Box>
    </Card>
  );
};

export default ArticleCardBox;
