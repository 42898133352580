const styles = (theme) => ({
  root: {
    height: '100%',
    cursor: 'pointer'
  },
  content: {
    color: '#fff',
    letterSpacing: 4,
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center'
  }
});

export default styles;
