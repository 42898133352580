import React from 'react';
import IntroScene from './IntroScene';

import Background from '~/assets/images/intro/metaWheel/scene7_bg.png';

function Scene7(props) {
  return (
    <IntroScene
      background={Background}
      ratio={0.421}
      container
      right
      titleList={[{text: 'Evolve ', highlight: true}, 'your human potential horizon beyond your expectations.']}
      descList={[
        '',
        '',
        '',
        'With the guidance of the Sanctuary, custom generated Recommendation Schedule; work with a structured program and time frame to achieve your desires in an achievable step by step process.',
        'Tap into the right combination of Sanctuary resources for your optimum development: Personal Guides, Workshops, Courses, Advanced Exercise Programs, Health Protocols, Food Science Foods, Natural Highs Drinks, Therapeutic Massage and Treatments, Natural Lifestyle Products, other Advancement Apps and our in-house Health Professionals.',
        '',
        ''
      ]}
    ></IntroScene>
  );
}

export default Scene7;
