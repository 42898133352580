import {withStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core';

const TopButton = withStyles({
  root: {
    position: 'absolute',
    right: 20,
    top: 20,
    color: 'white',
    fontSize: 9,
    fontWeight: 700,
    letterSpacing: 1.4,
    borderRadius: 3,
    padding: '8px 10px 6px 10px',
    backgroundColor: '#5fd26e',
    '&:hover': {
      backgroundColor: '#5fd26e'
    }
  }
})(Button);

export default TopButton;
