const VideoDetailStyle = (theme) => ({
  container: {
    width: '100vw',
    backgroundColor: '#000'
  },

  video: {
    outline: 'none',
    width: '100%',
    height: '100%'
  },

  share: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '1.25rem'
    },
    '& p': {
      color: '#b3b9bb',
      fontSize: '0.625rem',
      fontWeight: '600',
      paddingLeft: '0.25rem',
      paddingTop: '1px'
    }
  },

  sider: {
    minHeight: 'calc(100vh - 53.4px)',
    height: '100%',
    backgroundColor: '#fff',
    zIndex: 2
  },

  longDivider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#ccc'
  },

  videoDetailTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    letterSpacing: '2px'
  },

  videoDetailText: {
    padding: '1.25rem 2.5rem',
    fontSize: '0.75rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      padding: '1.25rem 1rem'
    }
  },

  detailInfoTitle: {
    fontSize: '0.75rem',
    letterSpacing: '2px',
    opacity: '0.8'
  },

  detailInfoDescription: {
    marginBottom: '2rem',
    fontSize: '0.75rem',
    letterSpacing: '1px',
    opacity: '0.7'
  },

  shortDivider: {
    width: '2.5rem',
    margin: '0 auto',
    backgroundColor: 'rgba(0,0,0,1)'
  },

  socialMediaTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    letterSpacing: '2px',
    marginTop: '0.5rem',
    marginBottom: '1.5rem'
  },

  socialMediaImg: {
    '& img': {
      margin: '0.25rem'
    }
  }
});
export default VideoDetailStyle;
