import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import ToggleTitle from './ToggleTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 3,
    paddingBottom: 2,
    textAlign: 'center'
  }
}));

const ToggleContentsTitles = ({className, selectedCategories, onChange, exclusive}) => {
  const classes = useStyles();
  const handleChange = (category, isSelected) => {
    const {code} = category;
    if (isSelected) {
      if (selectedCategories.indexOf(code) === -1) {
        const newIds = !!exclusive ? [code] : selectedCategories.concat([code]);
        onChange(newIds);
      }
    } else {
      if (selectedCategories.indexOf(code) > -1) {
        const newIds = selectedCategories.filter((tid) => tid !== code);
        onChange(newIds);
      }
    }
  };
  return (
    <Grid item xs={12} container className={clsx(classes.root, className)}>
      {[].map((category) => {
        return (
          <Grid item xs={12} md={3} key={category.code}>
            <ToggleTitle
              category={category}
              isSelected={selectedCategories.some((code) => code === category.code)}
              onChange={handleChange}
            >
              {category.title}
            </ToggleTitle>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ToggleContentsTitles;
