import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import AnimatedSwitchStyles from './AnimatedSwitchStyles';
import clsx from 'clsx';
import AnimatedArrow from '~/components/ui/AnimatedArrow/AnimatedArrow';

class AnimatedSwitch extends Component {
  constructor(props) {
    super(props);
    this[`container${props.switchIndex}`] = React.createRef();
    this[`slider${props.switchIndex}`] = React.createRef();
  }

  isDragging = false;
  sliderLeft = 0;
  state = {
    isChecked: false
  };

  componentDidMount() {
    document.addEventListener('touchmove', this.onDrag);
    document.addEventListener('touchend', this.stopDrag);

    this.containerWidth = this[`container${this.props.switchIndex}`].current.clientWidth - 40;
  }

  startDrag = (e) => {
    this.isDragging = true;
    this.startX = e.touches[0].clientX;
  };

  onDrag = (e) => {
    if (this.isDragging) {
      if (this.state.isChecked) {
        this.sliderLeft = Math.min(
          Math.max(0, this.containerWidth - (this.startX - e.touches[0].clientX)),
          this.containerWidth
        );
      } else {
        this.sliderLeft = Math.min(Math.max(0, e.touches[0].clientX - this.startX), this.containerWidth);
      }
      this.updateSliderStyle();
    }
  };

  updateSliderStyle = () => {
    this[`slider${this.props.switchIndex}`].current.style.left = this.sliderLeft + 'px';
  };

  stopDrag = () => {
    if (this.isDragging) {
      this.isDragging = false;

      if (!this.state.isChecked && this.sliderLeft > this.containerWidth * 0.1) {
        this.sliderLeft = this.containerWidth;
        this.setState({
          isChecked: true
        });
        this.props.toggleCheck();
      }

      if (this.state.isChecked && this.sliderLeft < this.containerWidth * 0.9) {
        this.sliderLeft = 0;
        this.setState({
          isChecked: false
        });
        this.props.toggleCheck();
      }

      this.updateSliderStyle();
    }
  };

  toggleSlide = () => {
    if (this.state.isChecked) {
      this.sliderLeft = 0;
      this.setState({
        isChecked: false
      });
      this.props.toggleCheck();
    } else {
      this.sliderLeft = 30;
      this.setState({
        isChecked: true
      });
      this.props.toggleCheck();
    }
    this.updateSliderStyle();
  };

  render() {
    const {classes} = this.props;

    return (
      <div
        className={clsx({
          [classes.switchContainer]: true,
          [classes.checked]: this.state.isChecked
        })}
        ref={this[`container${this.props.switchIndex}`]}
      >
        <div className={classes.animationBG}>
          <div
            className={classes.switchSlider}
            ref={this[`slider${this.props.switchIndex}`]}
            onClick={this.toggleSlide}
            onTouchStart={this.startDrag}
          >
            <AnimatedArrow
              extraClass={clsx({
                [classes.arrowIconRight]: true,
                [classes.arrowIconLeft]: this.state.isChecked
              })}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(AnimatedSwitchStyles)(AnimatedSwitch);
