import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link as RouterLink} from 'react-router-dom';
import {withStyles, Grid, Modal, Button, TextField, FormControl} from '@material-ui/core';
import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';

//data from test data storage
import networkSponsorData from '~/data/networkSponsorData';

//modal window with terms & conditions
import TermsModal from '~/components/ui/TermsModal/TermsModal';

import DonationStars from './sections/DonationStars/DonationStars';

//right sidebar with content selection is imported from 'contribute' pages!!!
import ContributeSidebar from '~/pages/network/contribute/sections/ContributeSidebar/ContributeSidebar';

import {sponsorStyles} from './Styles';

/********* TO DO ************
 * No form return from star and d/m/w selector
 * Check how the DONATION GUIDELINES calculator really works
 * No fields validation
 *
 * **************************/

class Sponsor extends Component {
  state = {
    organizeName: '',
    showModal: false,
    termsAccepted: false
  };

  toggleTermsModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  handleTermsAccepted = () => {
    this.setState({
      showModal: false,
      termsAccepted: true
    });
  };

  selectOrganize = (e) => {
    this.setState({
      organizeName: e.target.value
    });
  };

  submitContentForm = () => {};

  render() {
    const {classes} = this.props;
    const {showModal} = this.state;
    return (
      <form className={classes.contributeContentForm}>
        <Grid container>
          <Grid item xs={12} md={6} lg={7}>
            {/* Content Header */}
            <Grid item xs={12}>
              <section>
                <p className={classes.contributeContentHeading}>{intl.get(messageKeys.SPONSOR_TITLE)}</p>
                <p className={classes.contributeHeadingDescription}>{intl.get(messageKeys.SPONSOR_SUBTITLE)}</p>
                <p className={classes.contributeContentSubHeading}>{intl.get(messageKeys.SPONSOR_CRITERIA)}</p>
                <p className={classes.contributeContentIntroText}>{intl.get(messageKeys.SPONSOR_DESCRIPTION)}</p>
              </section>
            </Grid>

            {/* File Upload Component */}
            <Grid item container md={12} spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    id="NameInputBox"
                    label={intl.get(messageKeys.SPONSOR_NAME)}
                    variant="outlined"
                    color="secondary"
                    required={true}
                  />
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <TextField
                    id="EmailInputBox"
                    label={intl.get(messageKeys.SPONSOR_EMAIL)}
                    variant="outlined"
                    color="secondary"
                    required={true}
                  />
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <TextField
                    id="PhoneInputBox"
                    label={intl.get(messageKeys.SPONSOR_PHONE)}
                    variant="outlined"
                    color="secondary"
                    required={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    id="WebsiteInputBox"
                    label={intl.get(messageKeys.SPONSOR_WEBSITE)}
                    variant="outlined"
                    color="secondary"
                  />
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <TextField
                    id="OrganizationInputBox"
                    label={intl.get(messageKeys.SPONSOR_ORGANIZATION)}
                    variant="outlined"
                    color="secondary"
                  />
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <TextField
                    id="AddressInputBox"
                    label={intl.get(messageKeys.SPONSOR_ADDRESS)}
                    variant="outlined"
                    color="secondary"
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container md={12} spacing={3}>
              {/* Donation Guidelines */}
              <Grid item md={6} xs={12} sm={12} className={classes.contributeContentIntroText}>
                <p className={classes.contributeContentSubHeading}>
                  {intl.get(messageKeys.SPONSOR_DONATION_GUIDELINES)}
                </p>
                <DonationStars starsContent={networkSponsorData.starsContent} />
              </Grid>

              {/* Term And Conditions Modal*/}
              <Grid item md={6} xs={12} sm={12}>
                <div className={classes.contributeContentSubHeading}>
                  <RouterLink to="#" onClick={this.toggleTermsModal}>
                    {intl.get(messageKeys.SPONSOR_READ_TERMS)}
                  </RouterLink>
                </div>
                <div className={classes.contributeContentIntroText}>
                  (
                  {this.state.termsAccepted
                    ? intl.get(messageKeys.TERMS_AND_CONDITIONS_ACCEPTED)
                    : intl.get(messageKeys.TERMS_AND_CONDITIONS_NOT_ACCEPTED)}
                  )
                </div>
                <br />
                <Modal open={showModal} onClose={this.toggleTermsModal} className={classes.termsModal}>
                  <div>
                    <TermsModal
                      handleTermsAccepted={this.handleTermsAccepted}
                      termsAccepted={this.state.termsAccepted}
                      toggleTermsModal={this.toggleTermsModal}
                      termsModalTexts={networkSponsorData.termsTexts}
                    />
                  </div>
                </Modal>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={1} lg={1}>
            <div className={classes.cardDivider} />
          </Grid>

          {/* ContributeSidebar */}
          <Grid item xs={12} md={5} lg={4}>
            <ContributeSidebar selectOrganize={this.selectOrganize} />
          </Grid>

          {/* Preview and Submit Button */}
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button type="submit" className={classes.submitButton} onSubmit={this.submitContentForm}>
                {intl.get(messageKeys.CONTRIBUTE_FORM_BUTTON_SUBMIT)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

Sponsor.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(sponsorStyles)(Sponsor));
