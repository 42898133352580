import React from 'react';
import {withStyles, Grid, Typography} from '@material-ui/core';
import Bg from '~/assets/images/intro/metaWheel/scene8_bg.png';
import ButtonPlay from '~/assets/images/intro/metaWheel/scene8_btn_play.png';

const styles = (theme) => ({
  root: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: 'calc(49.6vw)',
    background: `url(${Bg})`
  },
  contentBox: {
    color: 'white',
    minHeight: 'calc(49.6vw)',
    background: 'rgba(4, 4, 4, 0.2)',
    cursor: 'pointer'
  },
  title: {
    color: 'white'
  },
  btnPlay: {
    marginTop: '40px',
    width: '60px'
  }
});

function Scene8(props) {
  const {classes} = props;

  return (
    <div className={classes.root}>
      <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.contentBox}>
        <Typography className={classes.title} variant="h4">
          View Live App Demo
        </Typography>
        <img className={classes.btnPlay} src={ButtonPlay} alt="Play button" />
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Scene8);
