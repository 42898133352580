import * as React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {CardContent, Box, Button} from '@material-ui/core';
import {SummaryCard} from '~/components/ui';
import InitiativeCardBox from './InitiativeCardBox';
import styles from './InitiativeExpandedCardBoxStyles';

const BottomButton = withStyles({
  root: {
    border: 'solid 2px #c5c5c5',
    borderRadius: 6,
    color: '#a5a5a5',
    fontSize: 9,
    letterSpacing: '1.3px',
    padding: '4px 8px 2px 8px',
    position: 'absolute',
    left: 'calc(50% - 50px)',
    bottom: 25,
    '&:hover': {
      backgroundColor: 'white'
    }
  }
})(Button);

const useStyles = makeStyles(styles);

const InitiativeExpandedCardBox = (props) => {
  const classes = useStyles();
  const {handleClick} = props;
  const {id, content, bottombtntext} = props.item;

  return (
    <Box className={classes.root}>
      <Box className={classes.cardContainer}>
        <InitiativeCardBox className={classes.card} {...props} />
      </Box>
      <Box className={classes.moreContainer}>
        <CardContent className={classes.more}>
          <SummaryCard content={content} />
        </CardContent>
        <BottomButton onClick={() => handleClick(id)}>{bottombtntext}</BottomButton>
      </Box>
    </Box>
  );
};

export default InitiativeExpandedCardBox;
