import React from 'react';
import {withStyles, Grid, Box} from '@material-ui/core';
import starImg from '~/assets/images/icons/Star.png';
import starImgFilled from '~/assets/images/icons/StarFilled.png';

import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';

import {StarsStyles} from './Styles';

import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';

function DonationStars(props) {
  const {classes} = props;
  const {starsContent} = props;

  //change selected star
  const [starSelected, setstarSelected] = React.useState(NaN);

  //set default selected d, w or m
  const daySelectedDefault = [];
  for (let i = 0; i < starsContent.length; i++) {
    daySelectedDefault[i] = 'd';
  }
  const [daySelected, setDaySelected] = React.useState(daySelectedDefault);

  //change selected d, w or m
  const updateSelectedDayArray = (index, day) => {
    var newDaySelected = [...daySelected];
    newDaySelected[index] = day;
    setDaySelected(newDaySelected);
  };

  //popover handle
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popId = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment>
      {/*Stars*/}
      <Grid container spacing={1} direction="column">
        <Grid item xs={12}>
          {starsContent.map((starContent, index) => (
            <img
              src={starSelected >= index ? starImgFilled : starImg}
              alt={starContent.title}
              className={classes.starImage}
              key={`starImg${index}`}
              onClick={() => setstarSelected(index)}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <p className={classes.SubHeading}>{intl.get(messageKeys.SPONSOR_DONATION_TYPE)}</p>
        </Grid>

        {/*Stars description & days, weeks, months*/}
        <Grid item xs={12} container spacing={0} direction="column">
          {starsContent.map((starContent, index) => (
            <Grid
              item
              xs={12}
              container
              spacing={0}
              key={`starText${index}`}
              alignItems="center"
              className={starSelected === index ? classes.StarRowSelected : classes.StarRow}
            >
              <Grid item xs={4}>
                <span onClick={handlePopoverOpen} aria-describedby={popId} className={classes.spanPopover}>
                  {starContent.title}
                  <InfoIcon className={classes.infoIcon} />
                </span>
                <Popover
                  id={popId}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <Box element="div" p={2} className={classes.popoverText}>
                    {starContent.description}
                  </Box>
                </Popover>
              </Grid>
              <Grid item xs={4}>
                {starContent.summ}
              </Grid>
              <Grid item xs={4}>
                <span
                  className={`${classes.day}  ${daySelected[index] === 'd' ? classes.daySelected : ''}`}
                  onClick={() => updateSelectedDayArray(index, 'd')}
                >
                  {intl.get(messageKeys.SPONSOR_D)}
                </span>
                <span
                  className={`${classes.day}  ${daySelected[index] === 'w' ? classes.daySelected : ''}`}
                  onClick={() => updateSelectedDayArray(index, 'w')}
                >
                  {intl.get(messageKeys.SPONSOR_W)}
                </span>
                <span
                  className={`${classes.day}  ${daySelected[index] === 'm' ? classes.daySelected : ''}`}
                  onClick={() => updateSelectedDayArray(index, 'm')}
                >
                  {intl.get(messageKeys.SPONSOR_M)}
                </span>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withStyles(StarsStyles)(DonationStars);
