const networkSponsorData = {
  termsTexts: [
    {
      heading: 'Terms & Conditions 1',
      text:
        '1111 Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia illo nisi praesentium voluptates veritatis voluptatem aspernatur, quisquam odit officia? Iure, explicabo nostrum. Ipsa laboriosam incidunt rem totam corporis, dolores itaque consequuntur ratione amet et accusantium natus nostrum veniam temporibus soluta'
    },
    {
      heading: 'Terms & Conditions 2',
      text: '22222  Lorem ipsum dolor sit amet consectetur adipisicing elit. '
    },
    {
      heading: 'Terms & Conditions 2',
      text:
        '33333 Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia illo nisi praesentium voluptates veritatis voluptatem aspernatur, quisquam odit officia? Iure, explicabo nostrum. Ipsa laboriosam incidunt rem totam corporis, dolores '
    }
  ],
  starsContent: [
    {
      title: '1 star',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia illo nisi praesentium voluptates veritatis voluptatem aspernatur, quisquam odit officia? Iure',
      summ: '$500'
    },
    {
      title: '2 star',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia illo nisi praesentium voluptates veritatis voluptatem aspernatur, quisquam odit officia? Iure',
      summ: '$1000'
    },
    {
      title: '3 star',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia illo nisi praesentium voluptates veritatis voluptatem aspernatur, quisquam odit officia? Iure',
      summ: '$5K'
    },
    {
      title: '4 star',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia illo nisi praesentium voluptates veritatis voluptatem aspernatur, quisquam odit officia? Iure',
      summ: '$10K'
    },
    {
      title: '5 star',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia illo nisi praesentium voluptates veritatis voluptatem aspernatur, quisquam odit officia? Iure',
      summ: '$100K'
    }
  ]
};

export default networkSponsorData;
