export const statusObj = (status, message = '') => {
  return {
    status,
    message,
  };
};

export const productObj = (obj, shopifyNode) => {
  const newObj = {
    ...obj,
    price: shopifyNode.priceRange.minVariantPrice.amount,
    image: shopifyNode.images.edges[0].node.originalSrc,
    variants: shopifyNode.variants.edges.map((ele) => ele.node),
  };
  newObj.variants = newObj.variants.map((ele) => ({...ele, image: ele.image.originalSrc}));
  return newObj;
};

export const openLoginModal = (history, location) => {
  const searchParams = new URLSearchParams(location.search);
  
  if (!searchParams.has('login')) {
    searchParams.append('login', '');
    history.push(`${location.pathname}?${searchParams.toString()}`);
  }
};


export const closeLoginModal = (history, location) => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.delete('login');
  
  const newSearch = searchParams.toString();
  history.push(`${location.pathname}${newSearch ? `?${newSearch}` : ''}`);
};

export const loggedInCheck = (firebase) => {
  if (firebase.auth().currentUser === null) {
    return new Promise((resolve, reject) => {
      reject('notLoggedIn');
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve(firebase.auth().currentUser.uid);
    });
  }
};

export const setSSCheckoutID = (firestore, firebase, ID) => {
  return loggedInCheck(firebase).then((uid) => {
    return firestore.collection('users').doc(uid).update({
      SSCheckoutID: ID,
    });
  });
};

export const getSSCheckoutID = (firestore, firebase) => {
  return loggedInCheck(firebase).then((uid) => {
    return firestore
      .collection('users')
      .doc(uid)
      .get()
      .then((doc) => doc.data());
  });
};

// export const addItemToCart = (recipeID, shopifyID, quantity, itemCat, firestore, docLocation, mealPlanner = false) => {
//   return firestore
//     .doc(docLocation)
//     .get()
//     .then((doc) => {
//       const cart = doc.data().cart;
//       const obj2 = {recipeID, quantity, shopifyID, itemCat, itemID: Date.now()};
//       if (
//         cart.find((ele) => {
//           return ele.shopifyID == obj2.shopifyID;
//         }) &&
//         !mealPlanner
//       ) {
//         console.log('nope');
//         return null;
//       } else {
//         if (mealPlanner == true && itemCat == null) {
//           return null;
//         } else {
//           cart.push(obj2);
//           return firestore
//             .doc(docLocation)
//             .update({cart})
//             .then(() => {
//               return 'done';
//             });
//         }
//       }
//     });
// };

