const styles = (theme) => ({
  root: {
    margin: '0px 0px 40px 0px',
    borderColor: '#999999'
  },
  media: {
    maxHeight: '500px',
    minHeight: '315px'
  },
  title: {
    fontSize: '1.6rem',
    fontWeight: 'Bold',
    color: '#666666',
    padding: '0px 0px 5px 0px'
  },
  titleHref: {
    fontSize: '1.6rem',
    fontWeight: 'Bold',
    color: '#666666',
    padding: '0px 0px 5px 0px'
  },
  content: {
    padding: '5x'
  },
  divider: {
    Color: '#999999'
  },
  iconButtonArea: {
    fontSize: '0.6rem',
    textTransform: 'uppercase',
    Color: '#999999'
  },
  popoverContent: {
    textAlign: 'center',
    padding: '4px'
  },
  shareButton: {
    margin: '4px',
    display: 'inline'
  },
  tags: {
    marginLeft: 'auto'
  },
  tag: {
    display: 'inline',
    padding: '4px',
    marginRight: '5px',
    backgroundColor: '#deaf43',
    borderRadius: '4px',
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    fontFamily: 'Calibri',
    color: '#333333'
  }
});

export default styles;
