import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles, FormGroup, Grid} from '@material-ui/core';
import intl from 'react-intl-universal';
import {Sphere} from '~/components/ui';
import * as messageKeys from '~/locales';

import {contributeSideBarStyles} from './Styles';

export class ContributeSidebar extends Component {
  render() {
    const {classes} = this.props;
    return (
      <aside className={classes.contributeSidebarBase}>
        <FormGroup row className={classes.contributeSidebarRadioGroup}>
          <Grid container className={classes.contributeSidebarSection}>
            <Grid item xs={1}>
              <div className={classes.sideBarRadioBtn}>
                <input type="radio" value="health" name="organizeName" onChange={this.props.selectOrganize} />
              </div>
            </Grid>
            <Grid item xs={11} sm={7}>
              <p className={`${classes.contributeSidebarHeading} ${classes.textGreen}`}>
                {intl.get(messageKeys.AUTH_SIGNUP_SIDEBAR_HEALTH_HEADING)}
              </p>
              <p className={classes.contributeSidebarText}>{intl.get(messageKeys.AUTH_SIGNUP_SIDEBAR_HEALTH_TEXT)}</p>
            </Grid>
            <Grid item container xs={12} sm={4} justifyContent="center" alignItems="center">
              <Sphere type={0} sphereRad={60} width={120} height={120} />
            </Grid>
          </Grid>

          <Grid container className={classes.contributeSidebarSection}>
            <Grid item xs={1}>
              <div className={classes.sideBarRadioBtn}>
                <input type="radio" value="prosperity" name="organizeName" onChange={this.props.selectOrganize} />
              </div>
            </Grid>

            <Grid item xs={11} sm={7}>
              <p className={`${classes.contributeSidebarHeading} ${classes.textBlue}`}>
                {intl.get(messageKeys.AUTH_SIGNUP_SIDEBAR_PROSPERITY_HEADING)}
              </p>
              <p className={classes.contributeSidebarText}>
                {intl.get(messageKeys.AUTH_SIGNUP_SIDEBAR_PROSPERITY_TEXT)}
              </p>
            </Grid>
            <Grid item container xs={12} sm={4} justifyContent="center" alignItems="center">
              <Sphere type={1} sphereRad={60} width={120} height={120} />
            </Grid>
          </Grid>

          <Grid container className={classes.contributeSidebarSection}>
            <Grid item xs={1}>
              <div className={classes.sideBarRadioBtn}>
                <input type="radio" value="inspiration" name="organizeName" onChange={this.props.selectOrganize} />
              </div>
            </Grid>
            <Grid item xs={11} sm={7}>
              <p className={`${classes.contributeSidebarHeading} ${classes.textPink}`}>
                {intl.get(messageKeys.AUTH_SIGNUP_SIDEBAR_INSPIRATION_HEADING)}
              </p>
              <p className={classes.contributeSidebarText}>
                {intl.get(messageKeys.AUTH_SIGNUP_SIDEBAR_INSPIRATION_TEXT)}
              </p>
            </Grid>
            <Grid item container xs={12} sm={4} justifyContent="center" alignItems="center">
              <Sphere type={2} sphereRad={60} width={120} height={120} />
            </Grid>
          </Grid>
          <Grid container className={classes.contributeSidebarSection}>
            <Grid item xs={1}>
              <div className={classes.sideBarRadioBtn}>
                <input type="radio" value="joy" name="organizeName" onChange={this.props.selectOrganize} />
              </div>
            </Grid>
            <Grid item xs={11} sm={7}>
              <p className={`${classes.contributeSidebarHeading} ${classes.textYellow}`}>
                {intl.get(messageKeys.AUTH_SIGNUP_SIDEBAR_JOY_HEADING)}
              </p>
              <p className={classes.contributeSidebarText}>{intl.get(messageKeys.AUTH_SIGNUP_SIDEBAR_JOY_TEXT)}</p>
            </Grid>
            <Grid item container xs={12} sm={4} justifyContent="center" alignItems="center">
              <Sphere type={3} sphereRad={60} width={120} height={120} />
            </Grid>
          </Grid>
        </FormGroup>
      </aside>
    );
  }
}

ContributeSidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  selectOrganize: PropTypes.func.isRequired
};

export default withStyles(contributeSideBarStyles)(ContributeSidebar);
