import React from 'react';
import {withStyles} from '@material-ui/core';

const animationDuration = '500ms';
const styles = () => ({
  '@keyframes anticlockwiseRotate': {
    from: {
      transform: 'rotate(-45deg) translateX(4px)'
    },

    to: {
      transform: 'rotate(-225deg) translateX(4px)'
    }
  },

  '@keyframes clockwiseRotate': {
    from: {
      transform: 'rotate(45deg) translateX(-4px)'
    },

    to: {
      transform: 'rotate(225deg) translateX(-4px)'
    }
  },

  arrowIcon: {
    width: '36px',
    height: '36px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      '& > $anticlockwise': {
        animation: '$anticlockwiseRotate ease-in-out',
        animationDuration: animationDuration
      },

      '& > $clockwise': {
        animation: '$clockwiseRotate ease-in-out',
        animationDuration: animationDuration
      }
    }
  },
  halfArrow: {
    width: '17px',
    height: '3px',
    backgroundColor: 'rgba(0,0,0,1)'
  },

  anticlockwise: {
    transform: 'rotate(-45deg) translateX(4px)',
    transformOrigin: '12.5px center'
  },

  clockwise: {
    transform: 'rotate(45deg) translateX(-4px)',
    transformOrigin: '4.5px center'
  }
});

function AnimatedArrow(props) {
  const {classes} = props;

  return (
    <div className={`${classes.arrowIcon} ${props.extraClass}`} onClick={props.handleClick}>
      <div className={`${classes.halfArrow} ${classes.anticlockwise}`}></div>
      <div className={`${classes.halfArrow} ${classes.clockwise}`}></div>
    </div>
  );
}

export default withStyles(styles)(AnimatedArrow);
