import React from 'react';
import { withStyles, Box } from '@material-ui/core';
import clsx from 'clsx'

const styles = (theme) => ({
  root: {
    width: '100%',
    paddingTop: '100%',
    overflow: 'hidden',
    position: 'relative'
  },
  card: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  hoverShow: {
    top: -theme.cardBox.translate,
    overflow: 'hidden',
    transform: 'translate(0, 0)',
    transition: `transform ${theme.cardBox.transition}`,
    '&:hover $media': {
      transform: `translate(0, ${theme.cardBox.translate}px)`
    },
    '&:hover $meta': {
      opacity: 1,
      transform: `translate(0, ${theme.cardBox.translate}px)`
    }
  },
  media: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  mediaHoverShow: {
    transition: `transform ${theme.cardBox.transition}`
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  },
  meta: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0
  },
  metaHoverShow: {
    opacity: 0,
    transition: `opacity  ${theme.cardBox.transition}, transform  ${theme.cardBox.transition}`
  }
});

function Card(props) {
  const { classes, hoverShow, ratio, imageSrc, meta } = props;
  return (
    <Box className={classes.root} style={{
      paddingTop: ratio ? ratio : '100%'
    }}>
      <div className={
        clsx(classes.card, {
          [classes.hoverShow]: hoverShow
        })
      }>
        <div className={clsx(classes.media, {
          [classes.mediaHoverShow]: hoverShow
        })}>
          <img src={imageSrc} className={classes.image} alt="Media"/>
        </div>
        <div className={clsx(classes.meta, {
          [classes.metaHoverShow]: hoverShow
        })}>
          {meta}
        </div>
      </div>
    </Box>
  )
}

export default withStyles(styles)(Card);
