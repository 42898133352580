import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Box, Avatar, Typography} from '@material-ui/core';
import PublishDate from './PublishDate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: 24,
    position: 'relative',
    paddingLeft: 200,
    minHeight: 150,
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 0,
      minHeight: 0
    }
  },
  avatarContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    [theme.breakpoints.only('xs')]: {
      position: 'relative'
    }
  },
  avatar: {
    height: 120,
    width: 120,
    marginTop: 25,
    marginLeft: 20,
    marginRight: 60,
    [theme.breakpoints.only('xs')]: {
      height: 36,
      width: 36,
      marginTop: -6,
      marginLeft: 5,
      marginRight: 12
    }
  },
  fieldContainer: {
    marginTop: 26,
    marginBottom: 8,
    [theme.breakpoints.only('xs')]: {
      marginTop: 16,
      marginBottom: 2
    }
  },
  authorNameContainer: {},
  authorName: {
    fontWeight: 700,
    letterSpacing: 2.4,
    color: '#b2650c',
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.875rem'
    }
  },
  publishDate: {
    justifyContent: 'flex-end',
    letterSpacing: 2.1,
    textAlign: 'right',
  }
}));

const ReaderComment = ({comment}) => {
  const {authorImage, authorName, publishDate, content} = comment;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={6} container className={clsx(classes.fieldContainer, classes.authorNameContainer)}>
          <Box className={classes.avatarContainer}>
            <Avatar className={classes.avatar} alt={authorName} src={authorImage} />
          </Box>
          <Typography component="span" className={classes.authorName}>
            {authorName}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.fieldContainer}>
          <PublishDate date={publishDate} className={classes.publishDate} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{content}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReaderComment;
