import joyBackground from '~/assets/images/organize/joy_bg.png';

export const styles = (theme) => ({
  backgroundWrapper: {
    background: `url(${joyBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top'
  },
  content: {
    backgroundColor: 'rgba(183, 116, 0, 0.7)',
    paddingBottom: '10%'
  },

  oneLink: {
    display: 'inline',
    fontSize: '1.5em',
    color: '#fec503',
    fontWeight: 'bold',
    lineHeight: '2.4',
    [theme.breakpoints.only('md')]: {
      fontSize: '1.2em',
      lineHeight: '2.5'
    },
    [theme.breakpoints.only('sm')]: {
      display: 'block',
      fontSize: '1em',
      lineHeight: '1.8'
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      fontSize: '1em',
      lineHeight: '1.8'
    }
  }
});
