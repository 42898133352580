import React from 'react';
import {withStyles, Grid} from '@material-ui/core';

import ContentHeader from '../ContentHeader';

const styles = (theme) => ({
  root: {
    padding: '30px 40px'
  },
  contentWrapper: {
    margin: '10px'
  },
  flexExpand: {
    flex: 1
  },
  listItem: {
    marginTop: '10px'
  },
  itemTitle: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  itemContent: {
    fontSize: '13px',
    marginTop: '3px',
    marginLeft: '25px',
    whiteSpace: 'pre-line'
  },
  titleIcon: {
    width: '15px',
    height: '15px',
    marginRight: '10px'
  },
  detailTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '15px'
  },
  detailItem: {
    padding: '5px 0'
  },
  detailItemIcon: {
    width: '15px',
    marginRight: '10px',
    marginTop: '5px'
  },
  detailItemTitle: {
    fontSize: '13px',
    display: 'inline-block',
    marginRight: '8px'
  },
  detailItemDesc: {
    fontSize: '13px',
    wordBreak: 'break-all'
  }
});

function Profile(props) {
  const {classes, titleIcon, title, titleList, detailTitle, detailList} = props;
  return (
    <div className={classes.root}>
      <ContentHeader name={title} icon={titleIcon} />
      <div className={classes.contentWrapper}>
        {titleList.map((item) => (
          <div className={classes.listItem}>
            <Grid container className={classes.flexExpand} alignItems="center">
              <Grid item src={item.icon} component="img" className={classes.titleIcon} />
              <Grid item className={classes.itemTitle}>
                {item.title}
              </Grid>
            </Grid>
            <div className={classes.itemContent}>{item.desc}</div>
          </div>
        ))}
        <div className={classes.detailTitle}>{detailTitle}</div>
        <Grid container spacing={2}>
          {[0, 1].map((offset) => (
            <Grid item container direction="column" md={5}>
              {detailList
                .filter((_, index) => index % 2 === offset)
                .map((item) => (
                  <Grid item container alignItems="flex-start" className={classes.detailItem}>
                    <Grid item component="img" src={item.icon} className={classes.detailItemIcon}></Grid>
                    <Grid item className={classes.flexExpand}>
                      <span className={classes.detailItemTitle}>{item.title}</span>
                      <span className={classes.detailItemDesc}>{item.desc}</span>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default withStyles(styles)(Profile);
