// import axios from '../../axios-api';
import {useFetchCustomer} from 'shared/react-query/hooks';

export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
const token = 'e21df26ffec447235bc29fc88e387a12';

// export default getProfile=() =>{
  
//   // return (dispatch) => {
//   //   axios
//   //     .get('/profile')
//   //     .then((res) => {
//   //       const {code, message} = res.data;
//   //       if (code === 200) {
//   //         dispatch({
//   //           type: GET_PROFILE,
//   //           payload: res.data
//   //         });
//   //       } else if (code === 400) {
//   //         dispatch(getProfileError(message));
//   //       }
//   //     })
//   //     .catch((err) => {
//   //       dispatch(getProfileError(err));
//   //     });
//   // };
// }

function getProfileError(error) {
  return {
    type: PROFILE_ERROR,
    payload: error.response.data
  };
}
