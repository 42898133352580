import * as React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    paddingTop: '100%'
  },
  image: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    position: 'absolute'
  }
}));

export default ({src, alt, className}) => {
  const classes = useStyles();
  const [hasMoreWidthThanHeight, setHasMoreWidthThanHeight] = React.useState(null);
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const loaded = (target) => {
    setHasMoreWidthThanHeight(target.currentTarget.naturalWidth > target.currentTarget.naturalHeight);
    setHasLoaded(true);
  };
  return (
    <div className={clsx(classes.root, className)}>
      <img
        className={classes.image}
        src={src}
        onLoad={loaded}
        alt={alt}
        style={{
          width: hasMoreWidthThanHeight ? 'auto' : '100%',
          height: hasMoreWidthThanHeight ? '100%' : 'auto',
          display: hasLoaded ? 'block' : 'none'
        }}
      />
    </div>
  );
};
