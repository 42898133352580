import React from 'react';
import {withStyles, Divider, Grid} from '@material-ui/core';

import ChatItem from './messenger/ChatItem';
import ContentHeader from '../../ContentHeader';

import Icon from '~/assets/images/profile/ic-item-personal-profile-mymessenger.png';
import IconShare from '~/assets/images/profile/ic-item-personal-profile-mymessenger-share.png';
import IconAddGroup from '~/assets/images/profile/ic-item-personal-profile-mymessenger-add-group.png';
import IconSmile from '~/assets/images/profile/ic-item-personal-profile-mymessenger-smile.png';
import IconAdd from '~/assets/images/profile/ic-item-personal-profile-mymessenger-add.png';
import IconHeadLeft from '~/assets/images/profile/ic-item-personal-profile-mymessenger-head-left.png';
import IconHeadRight from '~/assets/images/profile/ic-item-personal-profile-mymessenger-head-right.png';

const messageList = [
  {
    userName: 'John Doe',
    sendTime: '2:30 PM',
    userHead: IconHeadLeft,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
  },
  {
    userName: 'David Doe',
    sendTime: '2:32 PM',
    userHead: IconHeadRight,
    isSender: true,
    text:
      'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  },
  {
    userName: 'John Doe',
    sendTime: '2:35 PM',
    userHead: IconHeadLeft,
    text:
      'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  }
];

const styles = (_) => ({
  root: {
    padding: '30px 40px'
  },
  flexExpand: {
    flex: 1
  },
  contentWrapper: {
    margin: '10px'
  },
  headUserName: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '8px'
  },
  onlineStateIndicator: {
    width: '10px',
    height: '10px',
    background: '#06A800',
    borderRadius: '5px'
  },
  headText: {
    color: '#4B4B4B',
    fontSize: '13px',
    paddingLeft: '3px'
  },
  headDivider: {
    height: '10px',
    margin: '0 10px'
  },
  chatDivider: {
    marginTop: '10px'
  },
  chatMessageBox: {
    margin: '10px 0'
  },
  chatTime: {
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  footerWrapper: {
    border: '1px solid #707070',
    background: 'white',
    borderRadius: '15px',
    padding: '20px',
    marginTop: '20px'
  },
  footerEmoji: {
    width: '30px'
  },
  footerAdd: {
    width: '30px'
  },
  footerInput: {
    flex: 1,
    border: 'none',
    margin: '0 10px'
  }
});

function Messenger(props) {
  const {classes} = props;
  return (
    <div className={classes.root}>
      <ContentHeader name="MY MESSENGER" icon={Icon} />
      <div className={classes.contentWrapper}>
        <div>
          <div className={classes.headUserName}>John Doe</div>
          <Grid container alignItems="center">
            <span className={classes.onlineStateIndicator}></span>
            <span className={classes.headText}>Active</span>
            <Divider className={classes.headDivider} orientation="vertical" />
            <img src={IconShare} alt="share" />
            <span className={classes.headText}>Shared Media</span>
            <Divider className={classes.headDivider} orientation="vertical" />
            <img src={IconAddGroup} alt="add to group" />
            <span className={classes.headText}>Add to group</span>
          </Grid>
          <Divider className={classes.chatDivider} />
        </div>
        <div className={classes.chatMessageBox}>
          <div className={classes.chatTime}>June 10, 2019</div>
          {messageList.map((message) => (
            <ChatItem message={message} />
          ))}
        </div>

        <Grid container alignItems="center" className={classes.footerWrapper}>
          <img src={IconSmile} className={classes.footerEmoji} alt="button emoji" />
          <input className={classes.footerInput} placeholder="Start typing here...." />
          <img src={IconAdd} className={classes.footerAdd} alt="button add" />
        </Grid>
      </div>
    </div>
  );
}

export default withStyles(styles)(Messenger);
