import React from 'react';
import {withStyles, Container, Typography, Box, Grid} from '@material-ui/core';
import styles from './IntroCharterStyles';

//getting data from test data storage
import introCharterData from '~/data/introCharterData';
//introCard component
import IntroCharterCard from '~/components/ui/IntroCharterCard/IntroCharterCard';
import AnimatedSwitch from '~/components/ui/AnimatedSwitch/AnimatedSwitch';

import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';

function IntroCharter(props) {
  const {classes} = props;
  const [displayGrid, setDisplayGrid] = React.useState(false);
  const [displayText, setDisplayText] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  const toggleCheck = () => {
    setChecked((prev) => !prev);
    if (!checked) {
      setDisplayGrid(true);
      setTimeout(() => {
        setDisplayText(true);
      }, 500);
    } else {
      setDisplayText(false);
      setTimeout(() => {
        setDisplayGrid(false);
      }, 500);
    }
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl" className={classes.mainContainer} align="center">
        <Box p={4} align="center" className={classes.books}>
          <Typography variant="h1" className={classes.header}>
            {intl.get(messageKeys.INTRO_CHARTER_TITLE)}
          </Typography>
        </Box>
        <Container className={classes.cardsContainer}>
          <Grid container direction="row" justifyContent="center">
            <AnimatedSwitch toggleCheck={toggleCheck} />
          </Grid>
          {introCharterData.map((charterDataCard, index) => (
            <IntroCharterCard
              number={index}
              title={charterDataCard.cardTitleText}
              text={charterDataCard.cardMainText}
              key={index}
              displayGrid={displayGrid}
              displayText={displayText}
            />
          ))}
        </Container>
      </Container>
    </React.Fragment>
  );
}

export default withStyles(styles)(IntroCharter);
