import React from 'react';
import {withStyles, Grid, Container, Typography, Box} from '@material-ui/core';
import styles from './CreationCompassStyles';

//getting data from test data storage
import creationCompassData from '~/data/creationCompassData';
//creation Compass switcher component
import CreationCompassSwitcher from '~/components/ui/CreationCompassSwitcher/CreationCompassSwitcher';

import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';

function CreationCompass(props) {
  const {classes} = props;
  const CompassData = creationCompassData;
  const pageTitle = intl.get(messageKeys.HEADER_INTRO_COMPASS_PAGE_TITLE);
  const pageTitleFormatted = pageTitle.replace(/\s/g, '<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');

  return (
    <React.Fragment>
      <Container maxWidth="xl" className={classes.bodyContainer}>
        <Box className={classes.compassImgContainer} p={6}>
          <Typography
            variant="h1"
            className={classes.header}
            dangerouslySetInnerHTML={{__html: pageTitleFormatted}}
          ></Typography>
        </Box>
        <Container className={classes.gridContainer}>
          <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
            <Grid item xs={12} md={6}>
              <CreationCompassSwitcher
                position={''}
                switchIndex={1}
                ico={CompassData.personal.ico}
                txt={[CompassData.personal.decrease.text, CompassData.personal.increase.text]}
                title={[CompassData.personal.decrease.title, CompassData.personal.increase.title]}
                img={[CompassData.personal.decrease.img, CompassData.personal.increase.img]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CreationCompassSwitcher
                position={'-reverse'}
                switchIndex={2}
                ico={CompassData.global.ico}
                txt={[CompassData.global.decrease.text, CompassData.global.increase.text]}
                title={[CompassData.global.decrease.title, CompassData.global.increase.title]}
                img={[CompassData.global.decrease.img, CompassData.global.increase.img]}
              />
            </Grid>
          </Grid>
        </Container>
      </Container>
    </React.Fragment>
  );
}

export default withStyles(styles)(CreationCompass);
