import React, {useState, useRef} from 'react';
import {styles} from './GuidesDetailPageStyle';
import {Grid, withStyles, Typography, Select, MenuItem, FormControl, InputLabel} from '@material-ui/core';

import GuidesCard from '~/components/ui/GuideCard/GuideCard';

import IconPlanet from '~/assets/images/profile/ic-item-business-fav-planet.png';
import IconHuman from '~/assets/images/profile/ic-item-business-fav-human.png';
import NavLeft from '~/assets/images/content/guides/ic_guides_nav_left.png';
import NavRight from '~/assets/images/content/guides/ic_guides_nav_right.png';

import {guidesPersonData} from '~/data/guidesData';

const recDataList = guidesPersonData.filter((item) => item.tag);

function GuidesDetailPage(props) {
  const {classes} = props;
  const {itemData} = props.location;

  const scrollEle = useRef(null);
  const [selectItem, setSelectItem] = useState(null);
  let showItem = selectItem ? selectItem : itemData;
  if (!showItem) {
    showItem = recDataList[0];
  }

  const navItem = (isLeft) => {
    const parent = scrollEle.current;
    let nextIndex = -1;

    [...parent.children].forEach((child, index) => {
      if (Math.abs(parent.scrollLeft - child.offsetLeft) < 5) {
        if (isLeft && nextIndex < 0) {
          if (index > 0) {
            nextIndex = index - 1;
          } else {
            nextIndex = parent.children.length - 1;
          }
        }
      } else if (Math.abs(parent.scrollLeft + parent.clientWidth - child.offsetLeft - child.offsetWidth) < 5) {
        if (!isLeft && nextIndex < 0) {
          if (index >= parent.children.length - 1) {
            nextIndex = 0;
          } else {
            nextIndex = index + 1;
          }
        }
      }
    });

    if (nextIndex >= 0) {
      parent.children[nextIndex].scrollIntoView({behavior: 'smooth'});
    }
  };

  return (
    <div>
      <div className={classes.topBox}>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Metawheel Guides
        </Typography>
        <Typography variant="h2" className={classes.title}>
          {showItem.desc.toUpperCase()}
        </Typography>
        <Grid container>
          <Grid item md={6} xs={12}>
            <div className={classes.contentCard}>
              <GuidesCard data={showItem} />
              <div className={classes.cardDesc}>
                Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut porta facilisis eletum. Sed condimentum sed
                massa quis ullamcorper. Donec at scelerisque neque.{' '}
              </div>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className={classes.contentInfo}>
              <Typography variant="subtitle1" className={classes.topDesc}>
                <span className={classes.highLight}>Vitality and Longevity Series</span> - Living in Vibrant Health to
                120 Years of Age (a series of six journals).
              </Typography>

              <div className={classes.cartBox}>
                <Typography variant="subtitle1" className={classes.cartBoxTitle}>
                  PURE PRODUCTS AND ENVIROMENT
                </Typography>
                <Grid container>
                  <Grid item sm={6} xs={12} className={classes.cartLeft}>
                    <Grid container alignItems="center" className={classes.priceRow}>
                      <div className={classes.categorySelect}>
                        <FormControl variant="outlined">
                          <InputLabel id="demo-simple-select-outlined-label" className={classes.inputLabel}>
                            Price
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-disabled-label"
                            id="demo-simple-select-disabled"
                            defaultValue="EBook"
                            className={classes.selectBtn}
                          >
                            <MenuItem value="EBook">E-Book</MenuItem>
                            <MenuItem value="Brochure">Brochure</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className={classes.price}>$9.99</div>
                    </Grid>
                    <div className={classes.btnAddCart}>ADD TO CART</div>
                    <div className={classes.btnViewCart}>VIEW CART</div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className={classes.cartRight}>
                      <Typography variant="h6" className={classes.infoTitle}>
                        INFORMATION
                      </Typography>
                      <Grid container className={classes.infoRow}>
                        <span>Length:</span>
                        <span>12 Pages</span>
                      </Grid>
                      <Grid container className={classes.infoRow}>
                        <span>Language:</span>
                        <span>English</span>
                      </Grid>
                      <Grid container className={classes.infoRow}>
                        <span>Language:</span>
                        <span>PDF</span>
                      </Grid>
                      <Grid container className={classes.infoRow}>
                        <span>Word:</span>
                        <span>3306</span>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Typography variant="body1" className={classes.cartBottomDesc}>
                  * For Ebook: once you complete your order,we will send you a link for download, the link is valid for
                  three days.
                </Typography>
              </div>

              <Typography variant="subtitle1" className={classes.bottomDesc}>
                Take control of your well-being by investing in these concise health guides, developed by the Sanctuary,
                to help provide you vital context to our health systems and protocols. Compliment your well-being
                knowledge through the unique perspectives contained in this Vitality and Longevity series. Vital Guides
                to greater Health Span.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.recContainer}>
        <Typography className={classes.recTitle} variant="h4">
          RECOMMENDATION SCHEDULE
        </Typography>
        <Grid container direction="column" alignItems="flex-end" className={classes.viewAllWrapper}>
          <Grid className={classes.viewAllItem} item container alignItems="center" justifyContent="space-between">
            <img src={IconPlanet} alt="IconPlanet"></img>
            <span>See all global health guides</span>
          </Grid>
          <Grid className={classes.viewAllItem} item container alignItems="center" justifyContent="space-between">
            <img src={IconHuman} alt="IconHuman"></img>
            <span>See all personal health guides</span>
          </Grid>
        </Grid>

        <Grid container className={classes.cardRowBox} alignItems="center">
          <img
            className={classes.btnLeft}
            src={NavLeft}
            alt="NavLeft"
            onClick={() => {
              navItem(true);
            }}
          />
          <div className={classes.cardRow} ref={scrollEle}>
            {recDataList.map((item) => (
              <div
                className={classes.cardRowItem}
                onClick={() => {
                  setSelectItem(item);
                }}
              >
                <Grid container direction="column" alignItems="center">
                  <GuidesCard data={item} />
                  <span className={classes.btnAddCardSmall}>ADD TO CART</span>
                </Grid>
              </div>
            ))}
          </div>

          <img
            className={classes.btnRight}
            src={NavRight}
            alt="NavRight"
            onClick={() => {
              navItem(false);
            }}
          />
        </Grid>
      </div>
    </div>
  );
}

export default withStyles(styles)(GuidesDetailPage);
