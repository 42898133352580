import React from 'react';
import {withStyles, Grid, Typography} from '@material-ui/core';

import Phone from '~/assets/images/intro/metaWheel/scene6_phone.png';

const styles = (theme) => ({
  root: {
    background: 'white'
  },
  phone: {
    width: '84%',
    marginRight: '16%',
    [theme.breakpoints.only('xs')]: {
      width: '80%',
      marginLeft: '10%'
    }
  },
  leftContainer: {
    color: '#707070',
    height: '100%',
    padding: '20px 10% 20px 26%',
    '& > p': {
      padding: '10px 0',
      fontSize: '0.8rem'
    },
    [theme.breakpoints.only('xs')]: {
      width: '80%',
      marginLeft: '10%',
      padding: '20px 0'
    }
  },
  title: {
    lineHeigth: '0.9'
  },
  desc: {
    fontSize: '0.8rem'
  }
});

function Scene6(props) {
  const {classes} = props;
  return (
    <Grid container className={classes.root}>
      <Grid child xs={12} sm={6}>
        <Grid container direction="column" justifyContent="center" className={classes.leftContainer}>
          <Typography variant="h4" className={classes.title}>
            Become a Powerful Creator
          </Typography>
          <Typography variant="body1" component="p" className={classes.highlight}>
            - Access age and gender orientated Self Discovery questionnaires and micro apps.
          </Typography>
          <Typography variant="body1" component="p">
            - Map, understand and evaluate for yourself, your personal Life Architecture.
          </Typography>
          <Typography variant="body1" component="p">
            - Receive personalised and comprehensive analysis reports based around your Physical, Mental, Emotional and
            Spiritual wellbeing.
          </Typography>
          <Typography variant="body1" component="p">
            - Equip yourself with a Sanctuary Recommendation Schedules to best advance your life.
          </Typography>
          <Typography variant="body1" component="p" className={classes.highlight}>
            - Tap directly into the best Human Advancement services for you, at the Sanctuary. Become a master creator
            with the MetaWheel.
          </Typography>
          <div>Download App Now</div>
        </Grid>
      </Grid>
      <Grid child xs={12} sm={6}>
        <img className={classes.phone} src={Phone} alt="Phone" />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(Scene6);
