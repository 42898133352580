import React from 'react';
import {withRouter} from 'react-router';
//import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import styles from './SectionHeadingStyle';
// Styled Component
import {Typography, Grid} from '@material-ui/core';

const useStyles = makeStyles(styles);

function SectionHeading({title, description, subHeading, history}) {
  const classes = useStyles();
  // const pathname = history.location.pathname;

  return (
    <Grid container className={classes.content}>
      <Grid item className={classes.headingPart}>
        <Typography className={classes.mainHeading}>{title}</Typography>
        <div>
          <span className={classes.subHead}>{subHeading}</span>
        </div>
        <Grid container>
          <Grid item md={8} sm={8} xs={12}>
            <Typography className={classes.contentDescription}>{description}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withRouter(SectionHeading);
