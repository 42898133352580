import {connect} from 'react-redux';

import OrganizeLanding from './OrganizeLanding';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizeLanding);
