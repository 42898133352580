import React from 'react';
import {withStyles, Grid, Typography, makeStyles} from '@material-ui/core';

const itemStyles = makeStyles((theme) => ({
  root: {
    padding: '0 8px',
    height: '100%'
  },
  innerBox: {
    border: '1px solid #707070',
    borderRadius: '8px',
    height: '100%',
    paddingBottom: '20px'
  },
  badgeBox: {
    marginTop: '20px',
    marginLeft: '-8px',
    marginRight: '-8px',
    position: 'relative',
    color: 'white',
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)'
  },
  badgeTitle: {
    paddingTop: '20px',
    textAlign: 'center'
  },
  badgePrice: {
    marginTop: '6px',
    textAlign: 'center',
    whiteSpace: 'pre'
  },
  leftTriangle: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: 0,
    width: 0,
    borderBottom: '5.65px solid #5B4E64',
    borderLeft: '5.65px solid transparent',
    borderRight: '5.65px solid transparent',
    transformOrigin: 'bottom left',
    transform: 'rotate(45deg)'
  },
  rightTriangle: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    height: 0,
    width: 0,
    borderBottom: '5.65px solid #5B4E64',
    borderLeft: '5.65px solid transparent',
    borderRight: '5.65px solid transparent',
    transformOrigin: 'bottom right',
    transform: 'rotate(-45deg)'
  },
  contentBox: {
    padding: '15px',
    color: '#595959'
  },
  contentItem: {
    marginBottom: '20px'
  },
  additionItem: {
    fontWeight: 'bold',
    marginBottom: '10px'
  },
  additionTitle: {
    fontWeight: 'bold',
    marginBottom: '10px',
    marginTop: '10px'
  },
  clickInfo: {
    color: '#9AA200',
    margin: '10px 0'
  },
  button: {
    color: '#9AA200',
    height: '30px',
    lineHeight: '30px',
    border: '1px solid #9AA200',
    borderRadius: '20px',
    width: '150px',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none'
  }
}));

function ColumnItem({itemData}) {
  const classes = itemStyles();
  return (
    <div className={classes.root}>
      <Grid container direction="column" justifyContent="space-between" alignItems="center" className={classes.innerBox}>
        <div>
          <div
            className={classes.badgeBox}
            style={{
              backgroundColor: itemData.badgeColor
            }}
          >
            <Typography variant="h4" className={classes.badgeTitle}>
              {itemData.title}
            </Typography>
            <Typography variant="body1" className={classes.badgePrice}>
              {itemData.price || ' '}
            </Typography>
            <Typography variant="body1" className={classes.badgePrice}>
              {itemData.priceDesc || ' '}
            </Typography>
            <div className={classes.leftTriangle}></div>
            <div className={classes.rightTriangle}></div>
          </div>

          <div className={classes.contentBox}>
            {itemData.contentList.map((contentItem) => (
              <Typography variant="body1" className={classes.contentItem}>
                {contentItem}
              </Typography>
            ))}

            <Typography variant="body1" className={classes.additionTitle}>
              {itemData.additionTitle}
            </Typography>

            {itemData.additionList.map((additionItem) => (
              <Typography variant="body1" className={classes.additionItem}>
                - {additionItem}
              </Typography>
            ))}

            {itemData.additionDesc && (
              <Typography variant="body2" className={classes.additionDesc}>
                {itemData.additionDesc}
              </Typography>
            )}
          </div>
        </div>
        {itemData.showClickInfo && (
          <Typography variant="body2" className={classes.clickInfo}>
            Click here for detailed Precision Process
          </Typography>
        )}
        <div className={classes.button}>GET NOW</div>
      </Grid>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    padding: '30px 5%'
  },
  title: {
    color: '#595959',
    textAlign: 'center',
    marginBottom: '60px'
  }
});

const DataList = [
  {
    badgeColor: '#7756A2',
    title: 'Explore',
    price: 'Free',
    priceDesc: '',
    contentList: [
      'Use our Health Creator App to analyse hundreds of available meals, snacks, spreads, deserts, smoothies, tonics and dressings available at the Sanctuary or at any of the Sanctuary Stores. ',
      'Use our Food science depository to understand and arrange meals to achieve the greatest benefits. Access your Explore subscription of Metawheel to evaluate your Cellular Purity and populate your Original Food and Drink Pyramids. ',
      'Link directly from the Health Creator App, as you are selecting foods and drinks, to your Sanctuary account to quick purchase products. '
    ],
    additionTitle: '',
    additionList: [
      'Precise Nutritional Analysis',
      'Access our Food Science depository',
      'Evaluate your Cellular Purity',
      'Metawheel App Integration',
      'Quick purchase link for all products',
      'Discover your impact up on the health of our planet'
    ],
    additionDesc: '',
    showClickInfo: false
  },
  {
    badgeColor: '#E8B500',
    title: 'Premium',
    price: '$150/Year',
    priceDesc: 'Add Hair test $120.00',
    contentList: [
      'Use our Premium service to gain access to one of our Sanctuary Health Facilitators (either online or in person) to assist in completing and managing your health data.',
      'Utilise our in-house hair testing facilities to gauge your primary health arenas: nutritional efficiencies, cellular purity, cellular hydration and micro-biome balance.',
      'Integrate Metawheel Premium to populate your Original food and drinks pyramids, cellular purity levels and health notes. Allow Health Creator to generate a gender and age and body type specific food, and drink pyramid as a base guide, and your cellular purity chart. Add our 20 minutes Sanctuary hair test to refine your pyramid data. Benefit from the feel good factor and fat loss consequences of detoxing and purifying the body with our complimentary Purity Guide.',
      'Populate your Weight Shifter App with food data direct from Health creator to gain accurate live readings of all nutritional inputs to enhance your muscle gain and fat loss.'
    ],
    additionTitle: 'Explore services plus:',
    additionList: [
      'Gender, age and body type specific food pyramids',
      'Personalised guidance managed by a Health Facilitator',
      'Hair Test to evaluate your basic health needs and further refined pyramids ',
      'Introductory detoxification and fat reduction program',
      'Weight Shifter App Integration'
    ],
    additionDesc: '',
    showClickInfo: false
  },
  {
    badgeColor: '#CD59E8',
    title: 'Precision',
    price: 'Price on Request *',
    priceDesc: '',
    contentList: [
      'As a Precision user opt to engage specific health tests to gain the most precise and valuable information to integrate with your Health Creator App. Specialised Sanctuary tests include; Blood, Hair, Dutch test, Epigenetic test, Organic acid test, Micro-biome mapping and 3D Body Scanning. ',
      'Use Precision to enhance physical performance, define your biological age, trace the cause of symptoms, or to identify any potential ill health in its Prodromal period (prior to it being obvious), for pro-active prevention. ',
      'Allow our health facilitators and professionals to use the analysis results to generate personalised and accurate food and drink pyramids that help you determine the exact choices to deliver your Optimum Nutritional Design. '
    ],
    additionTitle: 'Explore and Premium services plus: ',
    additionList: [
      'Accurate Biological age and longevity analysis',
      'Prodromal mapping for pro-active prevention',
      'Identify optimum Sanctuary Health Protocols',
      'Determine your personal Optimum Nutritional Design.'
    ],
    additionDesc: '* Price depends on the tests required',
    showClickInfo: true
  }
];
function Scene11(props) {
  const {classes} = props;

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Select your plan
      </Typography>
      <Grid container spacing={1} alignItems="stretch">
        {DataList.map((item) => (
          <Grid item md={4} sm={12}>
            <ColumnItem itemData={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Scene11);
