import React from 'react';
import {withStyles, Divider, Grid} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    padding: '20px'
  },
  selectWrapper: {
    position: 'relative'
  },
  select: {
    '-webkit-appearance': 'none',
    height: '30px',
    border: '1px solid #424242',
    borderRadius: '5px',
    padding: '0 20px 0 40px'
  },
  selectDecor: {
    position: 'absolute',
    left: '0px',
    top: '0px',
    background: '#E2E2E2',
    height: '30px',
    width: '25px',
    border: '1px solid #424242',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    boxSizing: 'border-box',
    '&::after': {
      content: '"."',
      width: '0',
      height: '0',
      position: 'absolute',
      top: '10px',
      left: '6px',
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderTop: '6px solid #424242'
    }
  },
  mapWrapper: {
    marginTop: '20px',
    position: 'relative',
    border: '1px solid #424242',
    height: '450px'
  },
  googleMap: {
    width: '100%',
    height: '100%',
    display: 'block'
  },
  divider: {
    marginTop: '15px',
    marginBottom: '10px',
    height: '2px'
  },
  detalWrapper: {
    fontSize: '13px'
  },
  detailTitle: {
    fontWeight: 'bold'
  },
  detailLeft: {
    width: '120px'
  },
  detailMiddle: {
    width: '160px'
  },
  detailTable: {
    borderSpacing: '0px'
  },
  detailTdTime: {
    width: '100px',
    paddingLeft: '20px'
  }
});

function Location(props) {
  const {classes} = props;
  return (
    <div className={classes.root}>
      <div className={classes.selectWrapper}>
        <select className={classes.select}>
          <option value="" disabled selected>
            Select Region
          </option>
          <option value="Aukland">Aukland</option>
        </select>
        <div className={classes.selectDecor}></div>
      </div>

      <div className={classes.mapWrapper}>
        <iframe
          title="googleMap"
          className={classes.googleMap}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3192.7509525029086!2d174.75999351529887!3d-36.84844018719487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d471436b6e181%3A0x2b2afdfd7c0f0056!2ssky%20tower!5e0!3m2!1sen!2snz!4v1595805331885!5m2!1sen!2snz"
          frameborder="0"
          allowfullscreen=""
          aria-hidden="false"
        ></iframe>
      </div>

      <Divider className={classes.divider}></Divider>

      <Grid container className={classes.detalWrapper}>
        <div className={classes.detailLeft}>
          <div className={classes.detailTitle}>Brand Name</div>
          <div>5, City street, Auckland Central, Auckland 1010,</div>
        </div>
        <div className={classes.detailMiddle}>
          <div className={classes.detailTitle}>Phone</div>
          <div>+64 9776760834</div>
          <div className={classes.detailTitle}>Email</div>
          <div>info@xyz.com</div>
        </div>
        <div>
          <div className={classes.detailTitle}>Opening hours</div>
          <table className={classes.detailTable}>
            <tr>
              <td>Monday</td>
              <td className={classes.detailTdTime}>8 AM - 5 PM</td>
              <td>Thursday</td>
              <td className={classes.detailTdTime}>8 AM - 5 PM</td>
            </tr>
            <tr>
              <td>Tuesday</td>
              <td className={classes.detailTdTime}>8 AM - 5 PM</td>
              <td>Friday</td>
              <td className={classes.detailTdTime}>8 AM - 5 PM</td>
            </tr>
            <tr>
              <td>Wednesday</td>
              <td className={classes.detailTdTime}>8 AM - 5 PM</td>
              <td>Saturday & Sunday </td>
              <td className={classes.detailTdTime}>Closed</td>
            </tr>
          </table>
        </div>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Location);
