const styles = (theme) => ({
  longPaper: {
    transition: 'all 0.5s ease-in-out',
    height: '780px',
    overflow: 'hidden',
    [theme.breakpoints.only('md')]: {
      height: '1050px'
    },
    [theme.breakpoints.only('sm')]: {
      height: '850px'
    },
    [theme.breakpoints.only('xs')]: {
      height: '1150px'
    }
  },

  shortPaper: {
    transition: 'all 0.5s ease-in-out',
    height: '380px',
    overflow: 'hidden',
    [theme.breakpoints.only('md')]: {
      height: '450px'
    },
    [theme.breakpoints.only('sm')]: {
      height: '400px'
    },
    [theme.breakpoints.only('xs')]: {
      height: '550px'
    }
  }
});

export default styles;
