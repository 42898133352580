import IconPerson from '~/assets/images/content/main/ic_tab_human.png';
import IconEarth from '~/assets/images/content/main/ic_tab_earth.png';
import IconPlanetRed from '~/assets/images/content/main/ic_sphere_red.png';
import IconPlanetBlue from '~/assets/images/content/main/ic_sphere_blue.png';
import IconPlanetGreen from '~/assets/images/content/main/ic_sphere_green.png';
import IconPlanetYellow from '~/assets/images/content/main/ic_sphere_yellow.png';

export const TabList = [
  {
    name: 'All'
  },
  {
    name: 'PERSON',
    icon: IconPerson
  },
  {
    name: 'PLANET',
    icon: IconEarth
  }
];

export const MainContentList = [
  {
    title: 'HEALTH',
    icon: IconPlanetGreen,
  },
  {
    title: 'PROSPERITY',
    icon: IconPlanetBlue,
  },
  {
    title: 'INSPIRATION',
    icon: IconPlanetRed,
  },
  {
    title: 'JOY',
    icon: IconPlanetYellow,
  }
];
