import React from 'react';
import * as messageKeys from '~/locales';
import intl from 'react-intl-universal';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

const useStyles = (InitiativesBg) =>
  makeStyles({
    content: {
      backgroundImage: `url(${InitiativesBg})`,
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      height: '450px',
      width: '100%',
      position: 'relative',
      paddingTop: '2em',
      [`@media (max-width:768px)`]: {
        height: '300px'
      }
    },
    heading: {
      fontSize: '1em',
      letterSpacing: '5px',
      fontWeight: 'bold',
      padding: '0 5%'
    },
    bottomContainer: {
      height: '8%',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.64)',
      position: 'absolute',
      bottom: '0',
      padding: '4% auto',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      [`@media (max-width:768px)`]: {
        height: '8%'
      }
    },
    bottomHeading: {
      color: '#d2a75f',
      fontWeight: 'bold',
      fontSize: '0.85em',
      letterSpacing: '2.5px',
      display: 'inline-block',
      [`@media (max-width:768px)`]: {
        fontSize: '0.5em',
        letterSpacing: '2px'
      }
    },
    bottomDescription: {
      padding: '5%',
      backgroundColor: 'rgba(0,0,0,0.64)',
      position: 'absolute',
      bottom: '0',
      color: 'rgba(255,255,255,0.64)'
    }
  });

function InitiativesBox(props) {
  const classes = useStyles(props.initiativesBg)();
  return (
    <div className={classes.content}>
      <Typography className={classes.heading} align={props.isTitleLeft ? 'left' : 'center'}>
        {intl.get(messageKeys.ORGANIZE_INITIATIVESBOX_TITLE)}
      </Typography>
      {props.description ? (
        <div className={classes.bottomDescription}>{props.description}</div>
      ) : (
        <div className={classes.bottomContainer}>
          <Typography className={classes.bottomHeading}>
            {intl.get(messageKeys.ORGANIZE_INITIATIVESBOX_VISION)}
          </Typography>
          <Typography className={classes.bottomHeading}>
            {intl.get(messageKeys.ORGANIZE_INITIATIVESBOX_MISSION)}
          </Typography>
          <Typography className={classes.bottomHeading}>
            {intl.get(messageKeys.ORGANIZE_INITIATIVESBOX_CHARACTER)}
          </Typography>
          <Typography className={classes.bottomHeading}>
            {intl.get(messageKeys.ORGANIZE_INITIATIVESBOX_CREATION)}
          </Typography>
          <Typography className={classes.bottomHeading}>
            {intl.get(messageKeys.ORGANIZE_INITIATIVESBOX_METAWHEEL)}
          </Typography>
          <Typography className={classes.bottomHeading}>
            {intl.get(messageKeys.ORGANIZE_INITIATIVESBOX_INITIATIVES)}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default InitiativesBox;
