import React from 'react';
import * as messageKeys from '~/locales';
import intl from 'react-intl-universal';
import {styles} from './MetaStyle';
import { useHistory } from 'react-router-dom';

import {Container, withStyles, Grid} from '@material-ui/core';

function Page({classes}) {
  const history = useHistory();

  const handleClick = (hashText) => {
    history.push('/organize#'+ hashText); // The path to navigate to
  };

  return (
    <div className={classes.backgroundWrapper}>
      <Container maxWidth="lg">
        <div className={classes.title}>{intl.get(messageKeys.INTRO_META_TITLE)}</div>
        <Grid container className={classes.contentContainer}>
          <Grid item container md={7} sm={12}>
            <Grid item md={7} sm={12} className={classes.subTitle}>
              {intl.get(messageKeys.INTRO_META_SUB_TITLE)}
            </Grid>
            <Grid item md={5} sm={12} className={classes.desc}>
              <div className={classes.descTitle}>{intl.get(messageKeys.INTRO_META_DESC_TITLE)}</div>
              <div className={classes.descContent}>
                <p>{intl.get(messageKeys.INTRO_META_DESC_CONTENT)}</p>
              </div>
            </Grid>
            <Grid item md={12} className={classes.quote}>
              <div className={classes.quoteTitle}>{intl.get(messageKeys.INTRO_META_QUOTE)}</div>
              <div className={classes.quoteContent}>{intl.get(messageKeys.INTRO_META_QUOTE_CONTENT)}</div>
            </Grid>
          </Grid>
          <Grid item md={5} sm={12} className={classes.article}>
            <div className={classes.articleBg}></div>
            <div className={classes.articleCard}>
              <div className={classes.articleTitle}>{intl.get(messageKeys.INTRO_META_ARTICLE_TITLE)}</div>
              <div className={classes.articleContent}>{intl.get(messageKeys.INTRO_META_ARTICLE_CONTENT)}</div>
            </div>
          </Grid>
          <Grid item md={2} sm={0}></Grid>
          <Grid item md={10} sm={12} className={classes.decorImg}></Grid>
          <Grid item md={8} sm={12} className={classes.humanAdvance}>
            <div className={classes.humanAdvanceTitle}>{intl.get(messageKeys.INTRO_META_HUMAN_ADVANCE_TITLE)}</div>
            <div className={classes.humanAdvanceContent}>
              <p>{intl.get(messageKeys.INTRO_META_HUMAN_ADVANCE_CONTENT)}</p>
            </div>

            <div className={classes.humanAdvanceTitle}>{intl.get(messageKeys.INTRO_META_HUMAN_ADVANCE_TITLE2)}</div>
            <div className={classes.humanAdvanceContent}>
              <p>{intl.get(messageKeys.INTRO_META_HUMAN_ADVANCE_CONTENT2)}</p>
            </div>

            <div className={classes.humanAdvanceTitle}>{intl.get(messageKeys.INTRO_META_HUMAN_ADVANCE_TITLE3)}</div>
            <div className={classes.humanAdvanceContent}>
              <p>{intl.get(messageKeys.INTRO_META_HUMAN_ADVANCE_CONTENT3)}</p>
            </div>
          </Grid>
        </Grid>
        <div classeName={classes.wheels}>
          <img style={{cursor:'pointer'}} className={classes.wheel1} alt="meta wheel" onClick={(e) => {handleClick('MetaWheelPersonal')}}/>
          <img style={{cursor:'pointer'}} className={classes.wheel2} alt="meta wheel" onClick={(e) => {handleClick('MetaWheelGlobal')}}/>
          <img style={{cursor:'pointer'}} className={classes.wheel3} alt="meta wheel" onClick={(e) => {handleClick('MetaWheelEntire')}}/>
        </div>
        <div className={classes.bottomLine}>
          <span className={classes.bottomButton}>{intl.get(messageKeys.INTRO_META_SINGUP)}</span>
        </div>
      </Container>
    </div>
  );
}
export default withStyles(styles)(Page);
