export const contributeStyles = (theme) => ({
  contributeContentForm: {
    margin: '1.25rem 5rem 5rem 5rem',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 1rem 1.5rem 1rem'
    }
  },
  contentTypeIcon: {
    width: 20,
    height: 20
  },

  contributeContentHeading: {
    display: 'inline-block',
    fontVariant: 'small-caps',
    fontSize: '2.7rem',
    fontWeight: 550,
    letterSpacing: '2px',
    marginTop: 0,
    marginBottom: 0
  },
  contributeContentIntroText: {
    fontSize: '0.70rem',
    lineHeight: '1.625rem',
    transform: 'scale(.9, 1)',
    letterSpacing: '1px',
    marginLeft: '-5%',
    marginRight: '-2.5%',
    [theme.breakpoints.down('md')]: {
      marginLeft: '-5%',
      marginRight: '-2.5%',
      lineHeight: '1.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-5%',
      marginRight: '-5%',
      lineHeight: '1.375rem'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
      lineHeight: '1.25rem',
      letterSpacing: 0,
      transform: 'scale(1, 1)'
    }
  },

  radioBtnFormGroup: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.5rem 0'
  },

  uploadContentNoteHeading: {
    display: 'inline-block',
    fontSize: '0.75rem',
    fontWeight: 650,
    letterSpacing: '2px',
    margin: 0
  },

  uploadContentNoteText: {
    fontSize: '0.70rem',
    lineHeight: '0.75rem',
    margin: 0
  },

  termsModalLink: {
    display: 'inline-block',
    color: '#3433f0',
    fontSize: '0.8rem',
    fontWeight: 700,
    letterSpacing: '1px',
    textDecoration: 'none',
    margin: '1rem 0'
  },

  submitButton: {
    color: 'white',
    backgroundColor: 'rgb(210,166,95,1)',
    borderColor: 'rgb(210,166,95,1)',
    borderRadius: '0.25rem',
    padding: '0.75rem 2.5rem',
    fontSize: '0.66rem',
    fontWeight: 600,
    margin: '0.25rem 0.75rem',
    transition: 'background-color 1s linear 0',
    '&:hover': {
      backgroundColor: 'rgb(210,166,95,0.8)'
    }
  },

  cardDivider: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      margin: '0 auto',
      width: '35.5%',
      top: '15%',
      height: '70%',
      borderLeft: '2px solid #ccc'
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto 0',
      width: '70%',
      left: '15%',
      height: '35.5%',
      borderTop: '2px solid #ccc'
    }
  }
});
