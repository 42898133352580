export const UploadInputBoxStyles = () => ({
  uploadInputBoxHeading: {
    fontSize: '0.7rem',
    fontWeight: 600,
    letterSpacing: '2px',
    margin: '1rem 0 0.3rem 0'
  },

  labelForInput: {
    display: 'block',
    width: '90%',
    height: 42,
    borderColor: '#ccc',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '0.75rem'
  },
  fileName: {
    margin: '0.5rem'
  },
  input: {
    display: 'none'
  },

  uploadHintText: {
    fontSize: '0.70rem',
    lineHeight: '0.75rem',
    margin: '0.25rem 0'
  },

  uploadBtn: {
    fontSize: '0.6rem',
    fontWeight: 600,
    padding: '0.5rem 1.5rem',
    margin: 0,
    color: '#fff',
    backgroundColor: '#727272',
    borderRadius: 0,
    '&:hover': {
      color: 'black',
      backgroundColor: '#ddd'
    }
  }
});
