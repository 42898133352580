import {
  LOADING,
  GET_PAGE,
  GET_PAGE_ERROR,
  GET_CATEGORIES,
  GET_CATEGORIES_ERROR,
  GET_QUOTES,
  GET_MORE_QUOTES,
  GET_QUOTES_ERROR,
  GET_QUOTE,
  GET_QUOTE_ERROR
} from '../actions/quotesActions';

const initialState = {
  quote: {},
  pagination: {},
  quotes: [],
  categories: [],
  loading: false,
  serverError: null
};

const quotes = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true
      };

    case GET_PAGE:
      return {
        ...state,
        pagination: action.payload,
        loading: false
      };

    case GET_PAGE_ERROR:
      return {
        ...state,
        serverError: action.payload,
        loading: false
      };

    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        loading: false
      };

    case GET_CATEGORIES_ERROR:
      return {
        ...state,
        serverError: action.payload,
        loading: false
      };

    case GET_QUOTES:
      return {
        ...state,
        quotes: action.payload,
        loading: false
      };

    case GET_QUOTES_ERROR:
      return {
        ...state,
        serverError: action.payload,
        loading: false
      };

    case GET_MORE_QUOTES:
      return {
        ...state,
        quotes: state.quotes.concat(action.payload),
        loading: false
      };

    case GET_QUOTE:
      return {
        ...state,
        quote: action.payload,
        loading: false
      };

    case GET_QUOTE_ERROR:
      return {
        ...state,
        serverError: action.payload,
        loading: false
      };

    default:
      return state;
  }
};

export default quotes;
